import React, { useEffect, useRef, useState } from 'react';
import { FaFolderPlus } from 'react-icons/fa';

import styled, { css } from '../../../../common/styled';
import { partialApply } from '../../../../helpers/pureFunc';
import { Holder, LabelWithError, Input } from '../../../shared/FormElements';
import { StyledButtonWithIcon } from '../../../shared/Navs';
import {
	footerGroupInterface,
	footerItemInterface,
	FooterGroup,
} from './FooterContentGroups';

const StyledFooterContentGroupEditor = styled.div<{
	show: boolean;
}>(
	({ show }) => css`
		width: 100%;
		height: auto;
		overflow: hidden;

		transform: translateY(${show ? '0%' : '-50%'})
			scaleY(${show ? '100%' : '0%'});
		max-height: ${show ? '100vh' : '0px'};
		transition: all 0.3s;
	`,
);

interface FooterContentGroupEditorProps {
	children: {
		groups: footerGroupInterface[];
		compactLayout: boolean;
	};
	setGroups: (input: footerGroupInterface[]) => void;
}
const FooterContentGroupEditor = ({
	children,
	setGroups,
}: FooterContentGroupEditorProps) => {
	const { groups, compactLayout } = children;

	return (
		<StyledFooterContentGroupEditor show={!compactLayout}>
			<FooterAddGroup setGroups={setGroups}>
				{{
					groups: groups,
				}}
			</FooterAddGroup>
			<FooterGroupDisplay setGroups={setGroups}>
				{{ groups }}
			</FooterGroupDisplay>
		</StyledFooterContentGroupEditor>
	);
};

interface FooterAddGroupProps {
	setGroups: (input: footerGroupInterface[]) => void;
	children: {
		groups: footerGroupInterface[];
	};
}

const FooterAddGroup = ({
	setGroups,
	children: { groups },
}: FooterAddGroupProps) => {
	const [groupAddError, setGroupAddError] = useState('');
	const [groupAddValue, setGroupAddValue] = useState('');

	useEffect(() => {
		setGroupAddError(
			groups.map((group) => group.groupName).includes(groupAddValue)
				? 'Group Name Already in use'
				: '',
		);
	}, [groupAddValue, groups]);
	const StyledButtonWithIconWithNoMargin = styled(StyledButtonWithIcon)`
		margin-top: ${({theme})=>theme.spacings.tight}px;
		margin-left:0px;
		max-width: 15em;
		justify-content: center;
	`
	const addGroupClickHandler =
		groupAddError.length === 0 && groupAddValue.length !== 0
			? () => {
					setGroups([...groups, { groupName: groupAddValue, Items: [] }]);
					setGroupAddValue('');
			  }
			: null;
	

	const newGroupInput = useRef(null);
	useEffect(()=> {
		const InputElement = newGroupInput.current;
		const handleKeypress = (e:KeyboardEvent) => {
			if (e.key === 'Enter' && addGroupClickHandler) void e.preventDefault() || addGroupClickHandler()
			else if (e.key === 'Escape' || e.key === 'Esc') void e.preventDefault() || setGroupAddValue('');
		}
		InputElement.addEventListener('keydown', handleKeypress);
		return ()=> InputElement.removeEventListener('keydown', handleKeypress);
	})

	return (
		<Holder width="100%">
			<span>Content Groups</span>
			<LabelWithError
				htmlFor="addGroup"
				title=""
				hint="Create new Groups Here"
				error={groupAddError}
			>
				<Input
					ref={newGroupInput}
					type="text"
					id="addGroup"
					name="addGroup"
					onChange={(e) => setGroupAddValue(e.target.value)}
					value={groupAddValue}
					placeholder="Enter a Title"
				/>
				<StyledButtonWithIconWithNoMargin
					onClick={addGroupClickHandler}
					icon={<FaFolderPlus />}
					type="button"
					disabled={!addGroupClickHandler}
				>
					Add New Group
				</StyledButtonWithIconWithNoMargin>
			</LabelWithError>
		</Holder>
	);
};

interface FooterGroupDisplayProps {
	setGroups: (input: footerGroupInterface[]) => void;
	children: {
		groups: footerGroupInterface[];
	};
}
const FooterGroupDisplay = ({
	setGroups,
	children: { groups },
}: FooterGroupDisplayProps) => {
	const [groupOpen, setGroupOpen] = useState<null | number>(null);
	const [pendingSetGroup, setPendingSetGroup] = useState<
		null | footerGroupInterface[]
	>(null);
	const updateFooterGroupAtIndex = (
		index: number,
		update: footerGroupInterface,
	) => {
		const [footerGroupsPrev, footerGroupsNext] = [
			groups.slice(0, index),
			groups.slice(index + 1),
		];
		setPendingSetGroup([...footerGroupsPrev, update, ...footerGroupsNext]);
	};
	const moveGroup = (index: number, direction: 'up' | 'down') => {
		let newIndex: number = index;
		if (direction === 'up' && index === 0) newIndex = groups.length - 1;
		else if (direction === 'down' && index === groups.length - 1) newIndex = 0;
		else if (direction === 'up') newIndex = index - 1;
		else if (direction === 'down') newIndex = index + 1;

		const newFooterGroups = [...groups];
		const tempSwap = { ...newFooterGroups[newIndex] };
		newFooterGroups[newIndex] = newFooterGroups[index];
		newFooterGroups[index] = tempSwap;
		setGroupOpen(null);
		setGroups(newFooterGroups);
	};

	const deleteGroup = (index) => {
		if (window.confirm('Are you sure you want to delete Content Group'))
			setPendingSetGroup(groups.filter((_group, i) => i !== index));
	};

	useEffect(() => {
		if (pendingSetGroup) {
			const temp = [...pendingSetGroup];
			setPendingSetGroup(null);
			setGroups(temp);
		}
	}, [pendingSetGroup, setGroups]);
	const StyledGroupContainer = styled.div``;
	return (
		<Holder width="100%">
			{groups.length > 0 &&
			<LabelWithError
				title="Manage Groups"
				htmlFor=""
				error=""
				hint="Hint: You can delete, and click on each one to reorder"
			>
				<StyledGroupContainer>
					{groups.map((footerGroup, index) => {
						const updateGroupItems = (footerItems: footerItemInterface[]) =>
							updateFooterGroupAtIndex(index, {
								...footerGroup,
								Items: footerItems,
							});
						
						return (
							<FooterGroup
								key={footerGroup.groupName + index}
								{...footerGroup}
								selected={groupOpen === index}
								setSelected={partialApply(
									setGroupOpen,
									groupOpen !== index ? index : null,
								)}
								moveGroup={partialApply(moveGroup, index)}
								updateGroupItems={updateGroupItems}
								deleteSelf={partialApply(deleteGroup, index)}
							/>
						);
					})}
				</StyledGroupContainer>
			</LabelWithError>}
		</Holder>
	);
};

export default FooterContentGroupEditor;
