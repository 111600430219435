import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { ToastsStore } from 'react-toasts';
import styled from '../common/styled';
import PromotionEngineAddressBlocker from '../components/promotionEngine/PromotionEngineAddressBlocker';
import PromotionHeader from '../components/promotionEngine/PromotionEngineHeader';
import PromotionEngineManageScreen from '../components/promotionEngine/promotionEngineManageScreen';
import usePromotionEngineAPI from '../components/promotionEngine/usePromotionEngineAPI';
import Card from '../components/shared/Card';
import { useBrandConfig } from '../context/brandConfig/brandConfigContext';
import { debounceWithDelayedPromises } from '../helpers';
import PromotionEngineAPI from '../helpers/promotionEngine/promotionEngineAPI';
import useForceRender from '../hooks/useForceRender';

const StyleRow = styled('p')`
	font-size: 12px;
	font-style: italic;
	color: #838383;
	user-select: none;
`;

const debounceAFunc500 = debounceWithDelayedPromises(
	(fn: (...args: unknown[]) => unknown, ...args: unknown[]) => {
		return fn(...args);
	},
	500,
);

const PromotionEngineContainer = ({ authToken }) => {
	const { brandConfig } = useBrandConfig();
	const {
		aclBrandCode: BRAND_ID,
		bettingEngineLegacyApi: BETTING_ENGINE_API,
	} = brandConfig;

	const promotionUrl =
		BETTING_ENGINE_API &&
		`${BETTING_ENGINE_API}/brandmanager/${BRAND_ID}/promotions`;

	const [searchPhrase, setSearchPhrase] = useState('');

	const [tabInUse] = useState('manage');

	const APIHandler = useRef<PromotionEngineAPI>();

	const forceRender = useForceRender();
	const usePromotionHandler = usePromotionEngineAPI(APIHandler);
	const PromotionHandlerClear = usePromotionHandler.clearState;

	useEffect(() => {
		const fetchMe = () =>
			APIHandler.current
				.updateAuth(authToken)
				.executeFetchPromotions()
				.then(result => {
					if (result instanceof Error) {
						ToastsStore.error('Error Fetching Promotions from PromotionEngine');
						PromotionHandlerClear();
					}
				});

		if (
			promotionUrl &&
			(!APIHandler.current || APIHandler.current.url !== promotionUrl)
		) {
			APIHandler.current = new PromotionEngineAPI(promotionUrl);
			PromotionHandlerClear();
			fetchMe();
			forceRender();
		} else if (authToken && promotionUrl) {
			fetchMe();
			forceRender();
		}
	}, [authToken, promotionUrl, forceRender, PromotionHandlerClear]);

	return (
		<Card>
			<PromotionHeader
				searchFieldOnChange={input => {
					setSearchPhrase(input);
					APIHandler.current.PromotionSetSearchTerm(input);
					debounceAFunc500(
						APIHandler.current.executeFetchPromotions.bind(APIHandler.current),
					);
				}}
				searchFieldValue={searchPhrase}
			/>

			<PromotionEngineAddressBlocker
				brandConfig={brandConfig}
				brandId={BRAND_ID}
			/>
			<StyleRow>
				Select how you would like to qualify the location of the customer for
				promotion eligibility.
			</StyleRow>

			{(tabInUse === 'manage' || searchPhrase) && (
				<PromotionEngineManageScreen
					usePromotionHandler={usePromotionHandler}
					searchPhrase={searchPhrase}
					setSearchPhrase={setSearchPhrase}
				/>
			)}
		</Card>
	);
};

const mapStateToProp = state => ({
	authToken: state.auth.accessToken,
});
export default connect(mapStateToProp)(PromotionEngineContainer);
