import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import PromotionEndpoints, {
	promotionItemType,
} from '../helpers/promotionEndpoints';
import { ToastsStore } from 'react-toasts';
import { configurationLoader } from '../context/brandConfig/brandConfig';

export interface promotionsInterface {
	data: promotionItemType[];
	selectedPromotion: string;
	isFetching?: Boolean;
}

const initialState: promotionsInterface = {
	data: [],
	selectedPromotion: null,
	isFetching: false,
};

const promotionApiFactory = (state) => {
	const authToken = state?.auth?.accessToken;
	const brand = state?.application?.brand;
	const brandManagerEndpoint = configurationLoader(brand).brandManagerEndpoint;
	const promotionApi = new PromotionEndpoints(
		`${brandManagerEndpoint}/promotion`,
	);
	promotionApi.useAuthCode(authToken);
	return promotionApi;
};

let fetchAllPromotionsLastId;
export const fetchAllPromotions = createAsyncThunk(
	'promotion/fetchAllPromotion',
	async (_, thunkAPI) => {
		const randomId = Math.random() * 1000;
		fetchAllPromotionsLastId = randomId;
		const promotionApi = promotionApiFactory(thunkAPI.getState());
		const result = await promotionApi.fetchAllPromotions();
		if (fetchAllPromotionsLastId === randomId) return result;
		else throw new Error('fetch disregarding');
	},
);

export const deletePromotion = createAsyncThunk(
	'promotion/deletePromotion',
	async (id: string, thunkAPI) => {
		const promotionApi = promotionApiFactory(thunkAPI.getState());
		await promotionApi.deletePromotion(id);
		await thunkAPI.dispatch(fetchAllPromotions());
	},
);

export const createPromotion = createAsyncThunk(
	'promotion/createPromotion',
	async (item: any, thunkAPI) => {
		const promotionApi = promotionApiFactory(thunkAPI.getState());
		await promotionApi.createPromotion(item);
		await thunkAPI.dispatch(fetchAllPromotions());
		await thunkAPI.dispatch(setSelectedPromotion(null));
	},
);

export const updatePromotion = createAsyncThunk(
	'promotion/updatePromotion',
	async (item: any, thunkAPI) => {
		const promotionApi = promotionApiFactory(thunkAPI.getState());
		await promotionApi.updatePromotion(item);
		await thunkAPI.dispatch(fetchAllPromotions());
		await thunkAPI.dispatch(setSelectedPromotion(null));
	},
);

const { actions, reducer } = createSlice({
	name: 'loadPromotions',
	initialState,
	reducers: {
		setSelectedPromotion(state, { payload }: { payload: string }) {
			state.selectedPromotion = payload;
		},
	},
	extraReducers: {
		// FetchAll
		[fetchAllPromotions.pending as any]: (state) => {
			state.isFetching = true;
		},
		[fetchAllPromotions.fulfilled as any]: (state, action) => {
			state.data = action.payload;
			state.isFetching = false;
		},
		// Delete
		[deletePromotion.pending as any]: (state) => {
			state.isFetching = true;
		},
		[deletePromotion.fulfilled as any]: (state) => {
			state.isFetching = false;
			ToastsStore.success('Promotion deleted', 2000);
		},
		[deletePromotion.rejected as any]: (state) => {
			state.isFetching = false;
			ToastsStore.error('There was a problem deleting promotion', 3000);
		},
		// Create
		[createPromotion.pending as any]: (state) => {
			state.isFetching = true;
		},
		[createPromotion.fulfilled as any]: (state) => {
			state.isFetching = false;
			ToastsStore.success('Promotion created', 2000);
		},
		[createPromotion.rejected as any]: (state) => {
			state.isFetching = false;
			ToastsStore.error('There was a problem creating promotion', 3000);
		},
		// Update
		[updatePromotion.pending as any]: (state) => {
			state.isFetching = true;
		},
		[updatePromotion.fulfilled as any]: (state) => {
			state.isFetching = false;
			ToastsStore.success('Promotion updated', 2000);
		},
		[updatePromotion.rejected as any]: (state) => {
			state.isFetching = false;
			ToastsStore.error('There was a problem updating promotion', 3000);
		},
	},
});

export const { setSelectedPromotion } = actions;
export default reducer;
