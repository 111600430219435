import { useOktaAuth } from '@okta/okta-react';

// was accessing from state, but seemed to be bug in redux so now accessing dirrectly
// import { useSelector } from 'react-redux';

const useAuthHeader = () => {
	// previously was getting token from redux state with
	// useSelector below but broke something which looks like a bug in redux so moved onto accessing dirrectly
	// const { accessToken } = useSelector((state) => state.auth);

	//@ts-ignore
	const OktaAuth = useOktaAuth();

	const authState = OktaAuth?.authState;

	if (!authState?.isAuthenticated || !authState?.accessToken)
		return { Authorization: 'Token Missing' };

	return { Authorization: `Bearer ${authState?.accessToken}` };
};

export default useAuthHeader;
