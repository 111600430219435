import React, { useEffect, useState } from 'react';
import { ToastsStore } from 'react-toasts';
import styled from '../../common/styled';
import useAuthHeader from '../../hooks/useAuthHeader';
import ToggleSwitch from '../shared/ToggleSwitch';
import {
	createIpAddressConfig,
	getIpAddressConfig,
} from './PromotionEngineAddressAPI';

const LabeledArea = styled('div')`
	display: flex;
	margin-right: 22px;
	font-size: 14px;
	font-weight: 500;
	gap: 7px;
`;

const PromotionEngineAddressWrapper = styled('div')`
	display: flex;
	margin: 9px 0 9px 0;
`;

interface PromotionEngineAddressBlockerI {
	brandConfig: any;
	brandId: any;
}

const PromotionEngineAddressBlocker = ({
	brandConfig,
	brandId,
}: PromotionEngineAddressBlockerI) => {
	const AuthHeader = useAuthHeader();

	const [blockedItems, updateBlockedItems] = useState({
		restrict_by_address: null,
		restrict_by_ip: null,
	});

	const [receiveData, hasReceiveDate] = useState<boolean>(true);
	const [dataUpdated, hasDataUpdated] = useState<boolean>(false);

	const displayMessage = (id: string, checked: boolean) => {
		updateBlockedItems(blockedItems => ({
			...blockedItems,
			[id]: checked,
		}));
		let displayData =
			id === 'restrict_by_ip'
				? 'Successfully updated promotions to block via user’s IP address'
				: 'Successfully updated promotions to block via user’s residential address';
		ToastsStore.success(displayData);
	};

	const updateMessages = (id, checked) => {
		hasDataUpdated(true);
		displayMessage(id, checked);
	};

	const changeBlockers = e => {
		const { checked, id } = e.target;
		const { restrict_by_address, restrict_by_ip } = blockedItems;

		/** if both selected, user is allowed to change only 1 selection */
		if (restrict_by_address && restrict_by_ip) {
			updateMessages(id, checked);
		}

		/** either one has to be checked, otherwise last selection will be there */
		if (restrict_by_address || restrict_by_ip) {
			if (checked) {
				updateMessages(id, checked);
			}
		}
	};

	useEffect(() => {
		if (!brandConfig?.bettingEngineLegacyApi || !brandId) return;

		if (receiveData) {
			getIpAddressConfig(AuthHeader, brandConfig, brandId).then(result => {
				hasReceiveDate(() => false);
				updateBlockedItems({
					...blockedItems,
					restrict_by_address: result.data.restrict_by_address,
					restrict_by_ip: result.data.restrict_by_ip,
				});
			});
		}
		/** this will check changes on blockedItems it will trigger update request */
		if (dataUpdated) {
			createIpAddressConfig(blockedItems, AuthHeader, brandConfig, brandId);
			hasDataUpdated(false);
		}
	}, [
		AuthHeader,
		brandConfig,
		brandId,
		blockedItems,
		receiveData,
		dataUpdated,
	]);

	return (
		<PromotionEngineAddressWrapper>
			<LabeledArea>
				Address
				<ToggleSwitch
					checked={blockedItems.restrict_by_address}
					onChange={changeBlockers}
					id={'restrict_by_address'}
					tooltipContent="Block By Address"
				/>
			</LabeledArea>

			<LabeledArea>
				IP Location
				<ToggleSwitch
					checked={blockedItems.restrict_by_ip}
					onChange={changeBlockers}
					id={'restrict_by_ip'}
					tooltipContent="Block By IP Address"
				/>
			</LabeledArea>
		</PromotionEngineAddressWrapper>
	);
};

export default PromotionEngineAddressBlocker;
