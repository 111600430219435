let mql =
	typeof window !== 'undefined' && typeof window.matchMedia !== 'undefined'
		? window.matchMedia('(max-width: 850px)')
		: { matches: true };

export const ModalStyle = {
	overlay: {
		width: '100vw',
		height: '100%',
		backgroundColor: 'rgb(0,0,0,0.75)',
		zIndex: 100,
	},
	content: {
		padding: 0,
		maxWidth: '1170px',
		margin: '0 auto',
		height: 'max-content',
		maxHeight: '90%',
		overflowY: 'scroll',
		top: mql.matches ? '20px' : '40px',
		left: mql.matches ? '0px' : '40px',
		right: mql.matches ? '0px' : '40px',
		bottom: mql.matches ? '10px' : '40px',
		zIndex: 100,
	},
};
