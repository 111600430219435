import React, { useRef, useState } from 'react';
import { FaLock, FaTrash, FaUnlockAlt } from 'react-icons/fa';
import { FiSave, FiX } from 'react-icons/fi';
import styled, { css } from '../../common/styled';
import useElementSize from '../../hooks/useElementSize';
import { Input, Label, Textarea } from '../shared/FormElements';
import { StyledButton } from '../shared/Navs';
import PageHeader from '../shared/PageHeader';
import FormSwitcher from './FormSwitcher';


export const ColumnContainer = styled.div<{
	id: string;
	snapPoint: string;
	margin: string;
}>`
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	align-items: center;

	${({ id, snapPoint }) => css`
        --widthMultiply${id} : calc(${snapPoint} - 100%);
        margin: calc(margin * -1);

        &>*{
            flex-basis: calc(var(--widthMultiply${id}) * 999);
            margin: {margin}
        }
    `}
`;

const Column = styled.div<{ flexGrow: string }>`
	label: DespositMatchForm_Column;
	${({ flexGrow, theme }) => css`
		flex-grow: ${flexGrow || 'unset'};
		margin: ${theme.spacings.compact}px;
	`};
`;

export const InlineLabel = styled(Label)`
	display: inline-flex;
	margin-bottom: 0px;
	height: 100%;
	margin-top: auto;
	margin-bottom: auto;
	vertical-align: center;

	width: 100%;
`;

const StyledFieldSet = styled.fieldset<{ showLocked?: boolean }>`
	position: relative;
	border: 2px solid #dddddd;
	margin-top: 8px;
	:disabled:hover .hiddenMessage {
		opacity: 0.8;
	}

	.hiddenMessage {
	}

	:hover .hiddenMessage {
		opacity: ${({ showLocked }) => (showLocked ? '0.8' : 0)};
	}
	.hiddenMessage {
		transition: all 0.5s ease-in-out;
		top: 15px;
		float: right;
		position: sticky;
		pointer-events: none;
		font-size: 1em;
		z-index: 2;
		opacity: 0;
		color: #cf1b41;
	}
`;

export const DepositMatchFieldSet = ({
	children,
	...props
}: React.DetailedHTMLProps<
	React.FieldsetHTMLAttributes<HTMLFieldSetElement>,
	HTMLFieldSetElement
> & { showLocked?: boolean }) => {
	return (
		<StyledFieldSet {...props}>
			<div className="hiddenMessage">
				<span>
					<FaLock /> View Mode
				</span>
			</div>
			{children}
		</StyledFieldSet>
	);
};

export const InlineTextArea = styled(Textarea)`
	background: #ececeb;
	width: 100%;
	min-height: 10em;

	outline: ${({ isError, theme }) => (isError ? '#cf1b41' : 'none')};
	resize: none;
	&:disabled {
		background-color: transparent;
		cursor: not-allowed;
		outline: none;
	}
	&:hover,
	&:focus {
		outline: 2px solid
			${({ isError, theme }) =>
				isError ? theme.ui.color_danger : theme.ui.color_info};
	}
`;

export const InlineInput = styled(Input)`
	cursor: text;
	display: inline-block;
	width: 100%;
	border-radius: 4px 4px 0px 0px;
	border: none;
	border-bottom: 2px solid ${(props) => props.theme.ui.color_4};
	background: ${(props) => props.theme.ui.color_3};
	text-align: left;
	padding-left: 25px;
	&:disabled {
		background-color: transparent;
		cursor: not-allowed;
	}
	${(props) =>
		props.isError
			? css`
					outline 2px solid ${props.theme.ui.color_danger};
			  `
			: css`
					&:hover,
					&:focus {
						outline: 2px solid ${props.theme.ui.color_info};
					}
					&:disabled:hover {
						outline: none;
					}
			  `}
`;

export const StyledLeadingI = styled.i`
	padding: 8px 0;
	position: absolute;
	display: block;
	transform: translate(0, -50%);
	top: 50%;
	pointer-events: none;
	text-align: center;
	width: 25px;
`;

export const StyledTrailingI = styled.i`
	padding: 8px 25px 8px 8px;
	position: absolute;
	display: block;
	transform: translate(0, -50%);
	top: 50%;
	right: 0;
	pointer-events: none;
	text-align: center;
	width: 25px;
`;

interface ColumnAreaProp {
	children:
		| { flexGrow: string; child: React.ReactNode }
		| Array<{
				flexGrow: string;
				child: React.ReactNode;
		  }>;
	snapWidth: string;
	margin: string;
	id: string;
}

const ColumnChildRender = ({ flexGrow, child }, index) => (
	<Column key={index} flexGrow={flexGrow}>
		{child}
	</Column>
);

export const ColumnedArea = ({
	id,
	children,
	snapWidth,
	margin,
}: ColumnAreaProp) => {
	return (
		<ColumnContainer id={id} snapPoint={snapWidth} margin={margin}>
			{Array.isArray(children)
				? children.map(ColumnChildRender)
				: ColumnChildRender(children, 1)}
		</ColumnContainer>
	);
};

export const ColumnedLabeledArea = ({
	label,
	children,
	...props
}: ColumnAreaProp & { label: string }) => {
	const newChildren: ColumnAreaProp['children'] = [
		{
			flexGrow: '1',
			child: <InlineLabel htmlFor={props.id}>{label}</InlineLabel>,
		},
		...(Array.isArray(children) ? children : [children]),
	];
	return <ColumnedArea children={newChildren} {...props} />;
};

const StyledFormSubmitCloseArea = styled.div<{
	location: 'top' | 'bottom';
}>`
	display: flex;

	${({ location, theme }) =>
		location === 'top'
			? css`
					margin-left: auto;
			  `
			: css`
					width: 100%;
					justify-content: center;
					padding: ${theme.spacings.tight}px;
					margin-top: ${theme.spacings.cozy}px;
			  `}
`;

interface FormSubmitCloseAreaProps {
	location: 'top' | 'bottom';
	isMobile: boolean;
	mode: 'view' | 'edit' | 'new' | 'unsupported' | 'redeem';
	formError: string;
	actionClose: () => void;
	actionEdit?: () => void;
	actionView?: () => void;
	actionDelete?: () => void;
	actionSave?: () => Promise<any>;
	ExtraButton?: ({ isMobile: boolean }) => JSX.Element;
}

const FormSubmitCloseArea = ({
	mode,
	location,
	isMobile,
	formError,
	actionClose,
	actionEdit,
	actionDelete,
	actionSave,
	ExtraButton,
}: FormSubmitCloseAreaProps) => {
	const [isSubmitting, setIsSubmitting] = useState(false);
	const handleSave = () => {
		const reset = () => setIsSubmitting(false);
		setIsSubmitting(true);
		actionSave()?.then(reset)?.catch(reset) || reset();
	};

	const inTest = !!process.env.JEST_WORKER_ID;
	return (
		<StyledFormSubmitCloseArea location={location}>
			{ExtraButton && <ExtraButton isMobile={isMobile} />}
			{mode === 'unsupported' && (
				<>
					<StyledButton
						isactive={true}
						style={location === 'top' ? { marginLeft: 'auto' } : {}}
						onClick={actionDelete}
					>
						{!isMobile && 'Delete '}
						<FaTrash className="icon-only-mobile" />
					</StyledButton>
				</>
			)}
			{mode === 'view' && (
				<>
					<StyledButton
						isactive={true}
						style={location === 'top' ? { marginLeft: 'auto' } : {}}
						onClick={actionDelete}
					>
						{!isMobile && 'Delete '}
						<FaTrash className="icon-only-mobile" />
					</StyledButton>
					<StyledButton
						isactive={true}
						onClick={actionEdit}
						{...(inTest ? { 'data-testid': `buttonEdit-${location}` } : {})}
						// data-testid={`buttonEdit-${location}`}
					>
						{!isMobile && 'Edit '}
						<FaUnlockAlt className="icon-only-mobile" />
					</StyledButton>
				</>
			)}
			{(mode === 'edit' || mode === 'new') && (
				<StyledButton
					isactive={true}
					style={location === 'top' ? { marginLeft: 'auto' } : {}}
					disabled={!!formError || isSubmitting}
					onClick={handleSave}
				>
					{!isMobile && 'Save '}
					<FiSave className="icon-only-mobile" />
				</StyledButton>
			)}
			<StyledButton
				onClick={actionClose}
				{...(inTest ? { 'data-testid': `buttonClose-${location}` } : {})}
			>
				{!isMobile && 'Close '}
				<FiX className="icon-only-mobile" />
			</StyledButton>
		</StyledFormSubmitCloseArea>
	);
};

const StyledBodyDiv = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
`;

interface DepositFormLayoutProps {
	mode: 'view' | 'edit' | 'new' | 'unsupported' | 'redeem';
	formError?: string;
	children: React.ReactNode;
	setFormElement: any;
	formElement: number;
	actionClose: () => void;
	actionEdit?: () => void;
	actionView?: () => void;
	actionDelete?: () => void;
	actionSave?: () => Promise<any>;
	ExtraButton?: ({ isMobile: boolean }) => JSX.Element;
}
export const DepositFormLayout = ({
	mode,
	formError,
	children,
	setFormElement,
	formElement,
	...restOfProps
}: DepositFormLayoutProps) => {
	const windowRef = useRef<Window>(window);

	const windowSize = useElementSize<Window>(windowRef);

	const isMobile = windowSize.width <= 500;

	const title =
		mode !== 'redeem'
			? `${mode.substr(0, 1).toUpperCase()}${mode.substr(1)} Promotion Item`
			: 'Promotion Redeemed Events';

	return (
		<div>
			<PageHeader title={title}>
				{formError && (
					<span style={{ color: 'red', marginLeft: 'auto' }}>{formError}</span>
				)}
				<FormSubmitCloseArea
					location="top"
					mode={mode}
					formError={formError}
					isMobile={isMobile}
					{...restOfProps}
				/>
			</PageHeader>
			<FormSwitcher formElement={formElement} setFormElement={setFormElement} />
			<StyledBodyDiv>{children}</StyledBodyDiv>
		</div>
	);
};
