import { AxiosResponse, AxiosInstance, AxiosError } from 'axios';
import { PromotionEngineItem } from './common';

export type promotionsCreateResponse<T = Record<string, unknown>> = {
	timestamp: string;
	http_status_code: number;
	data: T;
};

export type promotionsCreateFailResponse<T = Record<string, unknown>> = {
	timestamp: string;
	http_status_code: 422;
	errors?: {
		[K in keyof T]?: string[];
	};
};

function promotionsCreate(
	getConfig: () => {
		axiosInstance: AxiosInstance;
	},
	promotion: Partial<PromotionEngineItem>,
): Promise<
	| AxiosResponse<promotionsCreateResponse>
	| AxiosError<promotionsCreateFailResponse | any>
> {
	return getConfig()
		.axiosInstance.post('', promotion)
		.catch((e: AxiosError) => e);
}

export default promotionsCreate;
