import slugify from 'slugify';
import * as Yup from 'yup';
import { mobileRouteValidator } from '../helpers/mobileRouteInterfaces';
import { promotion_categories } from '../helpers/promotionEndpoints';

export const contentSlugify = (input: string) =>
	slugify(input.trim(), {
		lower: true,
		replacement: '-',
		strict: true,
	});

export const contentValidation = (slugsNotAllowed: string[]) =>
	Yup.object().shape({
		title: Yup.string()
			.required()
			.min(1),
		slug: Yup.string()
			.required()
			.min(1)
			.transform(contentSlugify)
			.notOneOf(slugsNotAllowed.map(contentSlugify), 'Slug already in use'),
		publish_date: Yup.date().required(),
		content: Yup.string().required(),
		version: Yup.string(),
	});

export const promotionsValidation = Yup.object().shape({
	title: Yup.string()
		.required()
		.min(5),
	expiry: Yup.date().required(),
	route: Yup.lazy(input =>
		typeof input === 'string' && input.startsWith('http')
			? Yup.string()
					.required()
					.url('external link must be a valid URL')
			: Yup.string().required('link must be either a absolute or relative URL'),
	),
	category: Yup.string()
		.required()
		.oneOf(Object.values(promotion_categories)),
	description: Yup.string().required(),
	terms_and_conditions: Yup.string().required(),
	hide_button: Yup.boolean(),
	mobileRoute: mobileRouteValidator,
});

export const bannersValidation = Yup.object().shape(
	{
		link: Yup.string().url(),
		mobileRoute: mobileRouteValidator,
		image_src: Yup.string()
			.nullable()
			.when('image_src_mobile', {
				is: image_src_mobile => !image_src_mobile,
				then: Yup.string()
					.nullable()
					.required('At Least One Image is Required')
					.url(),
				otherwise: Yup.string()
					.nullable()
					.url(),
			}),
		image_src_mobile: Yup.string().when('image_src', {
			is: image_src => !image_src,
			then: Yup.string()
				.nullable()
				.required('At Least One Image is Required')
				.url(),
			otherwise: Yup.string()
				.nullable()
				.url(),
		}),
	},
	[['image_src', 'image_src_mobile']],
);

export const highlightsValidation = Yup.object().shape({
	title: Yup.string().required('Title is a required field'),
	expiryDate: Yup.date().min(new Date(), 'Date must be in the future'),
	iconKey: Yup.string(),
	link: Yup.string().required('Link is a required field'),
});

export const legacyRouterKeys = ['Racing', 'Sport', 'User'];
export const supportedRouterKeys = [
	'today_racing',
	'race',
	'racing_futures',
	'meeting',
	'event',
	'sport',
	'my_account',
	'my_bets',
	'promotions',
	'base_competition',
	'registration',
];
// supports relative path with query params and fragments
const regexRelPath = /^(\/[a-z0-9\-._~%!$&'()*+,;=:@]+\/?)+(\?[a-z0-9\-._~%!$&'()*+,;=:@/?]+)?(#[a-z0-9\-._~%!$&'()*+,;=:@/?]*)?$/;

export const quickLinksValidation = Yup.object().shape({
	icon: Yup.string().required(),
	expiry: Yup.date()
		.min(new Date(), 'Expiry Date must be in the future')
		.nullable(),
	route: Yup.lazy(input =>
		typeof input === 'string' && input.startsWith('/')
			? Yup.string()
					.required()
					.matches(regexRelPath, 'Must be a Valid URL or Absolute Path')
			: Yup.string()
					.required()
					.url('Must be a Valid URL or Absolute Path'),
	),
	order: Yup.number()
		.required()
		.integer()
		.positive(),
	title: Yup.string().required(),
	mobileRoute: mobileRouteValidator,
	location: Yup.array()
		.required()
		.min(1),
	disabled: Yup.bool()
		.required()
		.default(false),
	authentication_status: Yup.string().required(),
});
