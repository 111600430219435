export const FALL_OVER_BRAND = 'NOT_LOGGED_IN' as const;

export const brandsList = [
	'benchmark',
	'integration',
	'voyager',
	'betdeluxe',
	'wat',
	'realbookie',
	'crossbet',
	'texbet',
	'picnic',
	'pla',
	'zbet',
	'caymanasbet',
	'monmouth',
	'readybet',
	'swiftbet',
	'wish',
	'betnation',
	'getsetbet',
	'marantellibet',
	'punt123',
	'betgold',
	'mintbet',
	'dev1',
	'dev2',
	'dev4',
	'diamondbet',
	'upcoz',
	'okebet',
	'newhorizons',
	'bossbet',
	'crossbetca',
	'betr',
	'bookiebet',
	'baggybet',
	'eskanderbet',
	'bitwinning',
	'monmouthtech',
	'foxcatcherbetting',
	'thunderbet',
	'preprodwhitelabel',
	'chasebet',
	'sportchamps',
	'tonybet',
	'betestate',
	'playwest',
	'premiumbet',
	'bet66',
	'betlocal'
] as const;

export type BrandNameType = typeof brandsList[number];

const featureNames = ['banners', 'promotionEngine', 'pages'] as const;

export type brandType = typeof brandsList[number];
type featureNameType = typeof featureNames[number];

type propertyByBrand<T> = {
	[K in brandType]: T;
} &
	{
		[K in typeof FALL_OVER_BRAND]?: T;
	};
type propertyByBrandOptional<T> = { [K in brandType]?: T } &
	{
		[K in typeof FALL_OVER_BRAND]?: T;
	};

type featureFlagsByBrand = propertyByBrandOptional<{
	banners?: featureBanners;
	promotionEngine?: featurePromotionEngine;
	pages?: featurePage;
}>;

export interface brandConfigType {
	displayName: propertyByBrand<string>;
	logo: propertyByBrand<string>;
	favicon: propertyByBrand<string>;
	brandManagerEndpoint: propertyByBrand<string>;
	appUrl: propertyByBrand<string>;
	primaryColors: propertyByBrand<string>;
	secondaryColors: propertyByBrand<string>;
	bettingEngineApi: propertyByBrand<string>;
	bettingEngineLegacyApi: propertyByBrand<string>;
	aclBrandCode: propertyByBrand<number>;
	featureFlags: featureFlagsByBrand;
}

export const brandFeatureConfig = (
	brand: brandType,
	featureName: featureNameType,
): featureBanners | featurePromotionEngine | featurePage | undefined =>
	brandConfig['featureFlags']?.[brand.toLowerCase()]?.[featureName];

export const brandConfig: brandConfigType = {
	displayName: {
		benchmark: 'Sandbox - Benchmark',
		integration: 'Sandbox - Integration',
		voyager: 'Pre-production - Voyager',
		betdeluxe: 'BetDeluxe',
		wat: 'Rob Waterhouse',
		realbookie: 'RealBookie',
		crossbet: 'CrossBet',
		readybet: 'ReadyBet',
		swiftbet: 'SwiftBet',
		texbet: 'TexBet',
		picnic: 'PicnicBet',
		pla: 'PlayaBet',
		zbet: 'ZBet',
		mintbet: 'MintBet',
		caymanasbet: 'CaymanasBet',
		monmouth: 'Monmouth',
		wish: 'WishBet',
		betnation: 'BetNation',
		getsetbet: 'GetSetBet',
		marantellibet: 'MarantelliBet',
		punt123: 'Punt123',
		betgold: 'BetGold',
		dev1: 'Dev1',
		dev2: 'Dev2',
		dev4: 'Dev4',
		diamondbet: 'DiamondBet',
		upcoz: 'UpCoz',
		okebet: 'OkeBet',
		newhorizons: 'NewHorizons',
		bossbet: 'BossBet',
		crossbetca: 'CrossBet CA',
		betr: 'Betr',
		bookiebet: 'BookieBet',
		baggybet: 'BaggyBet',
		eskanderbet: 'EskanderBet',
		bitwinning: 'BitWinning',
		monmouthtech: 'MonmouthTech',
		foxcatcherbetting: 'Foxcatcher Betting',
		thunderbet: 'Thunderbet',
		preprodwhitelabel: 'Pre-production - White-label',
		chasebet: 'ChaseBet',
		sportchamps: 'SportChamps',
		tonybet: 'TonyBet',
		betestate: 'BetEstate',
		playwest: 'PlayWest',
		premiumbet: 'PremiumBet',
		bet66: 'Bet66',
		betlocal: 'BetLocal'
	},
	logo: {
		NOT_LOGGED_IN:
			'https://bm-brand-assets.s3-ap-southeast-2.amazonaws.com/betmakers/logo.png',
		benchmark:
			'https://bm-brand-assets.s3-ap-southeast-2.amazonaws.com/betmakers/logo.png',
		integration:
			'https://bm-brand-assets.s3-ap-southeast-2.amazonaws.com/betmakers/logo.png',
		voyager:
			'https://bm-brand-assets.s3-ap-southeast-2.amazonaws.com/betmakers/logo.png',
		betdeluxe:
			'https://bm-brand-assets.s3-ap-southeast-2.amazonaws.com/betdeluxe/logo.png',
		wat:
			'https://bm-brand-assets.s3-ap-southeast-2.amazonaws.com/robwaterhouse/logo.png',
		realbookie:
			'https://bm-brand-assets.s3-ap-southeast-2.amazonaws.com/realbookie/logo.png',
		crossbet:
			'https://bm-brand-assets.s3-ap-southeast-2.amazonaws.com/crossbet/logo.png',
		texbet:
			'https://bm-brand-assets.s3-ap-southeast-2.amazonaws.com/texbet/logo.png',
		picnic:
			'https://bm-brand-assets.s3-ap-southeast-2.amazonaws.com/picnicbet/logo.png',
		pla:
			'https://bm-brand-assets.s3-ap-southeast-2.amazonaws.com/playabet/logo.png',
		zbet:
			'https://bm-brand-assets.s3-ap-southeast-2.amazonaws.com/zbet/logo.png',
		caymanasbet:
			'https://bm-brand-assets.s3-ap-southeast-2.amazonaws.com/caymanasbet/logo.png',
		monmouth:
			'https://bm-brand-assets.s3.ap-southeast-2.amazonaws.com/MonmouthUS/Monmouth.png',
		wish:
			'https://bm-brand-assets.s3-ap-southeast-2.amazonaws.com/wishbet/logo.png',
		betnation:
			'https://bm-brand-assets.s3-ap-southeast-2.amazonaws.com/betnation/logo.png',
		getsetbet:
			'https://bm-brand-assets.s3-ap-southeast-2.amazonaws.com/getsetbet/logo.png',
		marantellibet:
			'https://bm-brand-assets.s3.ap-southeast-2.amazonaws.com/marantellibet/logo.png',
		punt123:
			'https://bm-brand-assets.s3.ap-southeast-2.amazonaws.com/punt123/logo.png',
		mintbet:
			'https://bm-brand-assets.s3.ap-southeast-2.amazonaws.com/mintbet/logo.png',
		betgold:
			'https://bm-brand-assets.s3.ap-southeast-2.amazonaws.com/betgold/betgold_logo.png',
		readybet:
			'https://bm-brand-assets.s3.ap-southeast-2.amazonaws.com/readybet/logo.png',
		swiftbet:
			'https://bm-brand-assets.s3.ap-southeast-2.amazonaws.com/swiftbet/logo.png',
		dev1:
			'https://bm-brand-assets.s3-ap-southeast-2.amazonaws.com/betmakers/logo.png',
		dev2:
			'https://bm-brand-assets.s3-ap-southeast-2.amazonaws.com/betmakers/logo.png',
		dev4:
			'https://bm-brand-assets.s3-ap-southeast-2.amazonaws.com/betmakers/logo.png',
		diamondbet:
			'https://bm-brand-assets.s3-ap-southeast-2.amazonaws.com/diamondbet/logo.png',
		upcoz:
			'https://bm-brand-assets.s3.ap-southeast-2.amazonaws.com/upcoz/logo.png',
		okebet:
			'https://bm-brand-assets.s3.ap-southeast-2.amazonaws.com/okebet/okebet_logo.png',
		newhorizons:
			'https://bm-brand-assets.s3-ap-southeast-2.amazonaws.com/betmakers/logo.png',
		bossbet:
			'https://bm-brand-assets.s3.ap-southeast-2.amazonaws.com/bossbet/bossbet_logo_large.png',
		crossbetca:
			'https://bm-brand-assets.s3-ap-southeast-2.amazonaws.com/betmakers/logo.png',
		betr:
			'https://bm-brand-assets.s3-ap-southeast-2.amazonaws.com/betr/logo.png',
		bookiebet:
			'https://bm-brand-assets.s3-ap-southeast-2.amazonaws.com/bookiebet/bookiebet_logo.png',
		baggybet:
			'https://bm-brand-assets.s3-ap-southeast-2.amazonaws.com/baggybet/logo.png',
		eskanderbet:
			'https://bm-brand-assets.s3-ap-southeast-2.amazonaws.com/eskanderbet/logo.png',
		bitwinning:
			'https://bm-brand-assets.s3-ap-southeast-2.amazonaws.com/bitwinning/logo.png',
		monmouthtech:
			'https://bm-brand-assets.s3.ap-southeast-2.amazonaws.com/MonmouthUS/Monmouth.png',
		foxcatcherbetting:
			'https://bm-brand-assets.s3.ap-southeast-2.amazonaws.com/foxcatcher/logo.png',
		thunderbet:
			'https://bm-brand-assets.s3.ap-southeast-2.amazonaws.com/thunderbet/logo.png',
		preprodwhitelabel:
			'https://bm-brand-assets.s3.ap-southeast-2.amazonaws.com/preprodwhitelabel/preprodwhitelabel.png',
		chasebet:
			'https://bm-brand-assets.s3.ap-southeast-2.amazonaws.com/chasebet/logo.svg',
		sportchamps:
			'https://bm-brand-assets.s3-ap-southeast-2.amazonaws.com/sportchamps/logo.png',
		tonybet:
			'https://bm-brand-assets.s3-ap-southeast-2.amazonaws.com/tonybet/logo.svg',
		betestate:
			'https://bm-brand-assets.s3-ap-southeast-2.amazonaws.com/betestate/logo.png',
		playwest:
			'https://bm-brand-assets.s3-ap-southeast-2.amazonaws.com/playwest/logo-alternate.png',
		premiumbet:
			'https://bm-brand-assets.s3-ap-southeast-2.amazonaws.com/premiumbet/logo-alternate.png',
		bet66:
			'https://bm-brand-assets.s3-ap-southeast-2.amazonaws.com/bet66/logo.png',
		betlocal:
			'https://bm-brand-assets.s3-ap-southeast-2.amazonaws.com/betlocal/logo.png'
	},
	favicon: {
		NOT_LOGGED_IN:
			'https://bm-brand-assets.s3-ap-southeast-2.amazonaws.com/betmakers/favicon.ico',
		benchmark:
			'https://bm-brand-assets.s3-ap-southeast-2.amazonaws.com/betmakers/favicon.ico',
		integration:
			'https://bm-brand-assets.s3-ap-southeast-2.amazonaws.com/betmakers/favicon.ico',
		voyager:
			'https://bm-brand-assets.s3-ap-southeast-2.amazonaws.com/betmakers/favicon.ico',
		betdeluxe:
			'https://bm-brand-assets.s3-ap-southeast-2.amazonaws.com/betdeluxe/favicon.png',
		wat:
			'https://bm-brand-assets.s3-ap-southeast-2.amazonaws.com/robwaterhouse/favicon.png',
		realbookie:
			'https://bm-brand-assets.s3-ap-southeast-2.amazonaws.com/realbookie/favicon.png',
		crossbet:
			'https://bm-brand-assets.s3-ap-southeast-2.amazonaws.com/crossbet/favicon.png',
		texbet:
			'https://bm-brand-assets.s3-ap-southeast-2.amazonaws.com/texbet/favicon.png',
		picnic:
			'https://bm-brand-assets.s3-ap-southeast-2.amazonaws.com/picnicbet/favicon.png',
		pla:
			'https://bm-brand-assets.s3-ap-southeast-2.amazonaws.com/playabet/favicon.ico',
		zbet:
			'https://bm-brand-assets.s3-ap-southeast-2.amazonaws.com/zbet/favicon.png',
		caymanasbet:
			'https://bm-brand-assets.s3-ap-southeast-2.amazonaws.com/caymanasbet/favicon.png',
		monmouth:
			'https://bm-brand-assets.s3.ap-southeast-2.amazonaws.com/MonmouthUS/favicon.png',
		wish:
			'https://bm-brand-assets.s3-ap-southeast-2.amazonaws.com/wishbet/favicon.png',
		betnation:
			'https://bm-brand-assets.s3-ap-southeast-2.amazonaws.com/betnation/favicon.png',
		getsetbet:
			'https://bm-brand-assets.s3-ap-southeast-2.amazonaws.com/getsetbet/favicon-32x32.png',
		marantellibet:
			'https://bm-brand-assets.s3.ap-southeast-2.amazonaws.com/marantellibet/favicon.png',
		punt123:
			'https://bm-brand-assets.s3.ap-southeast-2.amazonaws.com/punt123/favicon.png',
		mintbet:
			'https://bm-brand-assets.s3.ap-southeast-2.amazonaws.com/mintbet/favicon.png',
		betgold:
			'https://bm-brand-assets.s3.ap-southeast-2.amazonaws.com/betgold/favicon.png',
		readybet:
			'https://bm-brand-assets.s3.ap-southeast-2.amazonaws.com/readybet/favicon.png',
		swiftbet:
			'https://bm-brand-assets.s3.ap-southeast-2.amazonaws.com/swiftbet/favicon.png',
		dev1:
			'https://bm-brand-assets.s3-ap-southeast-2.amazonaws.com/betmakers/favicon.ico',
		dev2:
			'https://bm-brand-assets.s3-ap-southeast-2.amazonaws.com/betmakers/favicon.ico',
		dev4:
			'https://bm-brand-assets.s3-ap-southeast-2.amazonaws.com/betmakers/favicon.ico',
		diamondbet:
			'https://bm-brand-assets.s3.ap-southeast-2.amazonaws.com/diamondbet/favicon.png',
		upcoz:
			'https://bm-brand-assets.s3.ap-southeast-2.amazonaws.com/upcoz/favicon.png',
		okebet:
			'https://bm-brand-assets.s3.ap-southeast-2.amazonaws.com/okebet/favicon.png',
		newhorizons:
			'https://bm-brand-assets.s3-ap-southeast-2.amazonaws.com/betmakers/favicon.ico',
		bossbet:
			'https://bm-brand-assets.s3.ap-southeast-2.amazonaws.com/bossbet/favicon.png',
		crossbetca:
			'https://bm-brand-assets.s3-ap-southeast-2.amazonaws.com/betmakers/favicon.ico',
		betr:
			'https://bm-brand-assets.s3-ap-southeast-2.amazonaws.com/betr/favicon.webp',
		bookiebet:
			'https://bm-brand-assets.s3.ap-southeast-2.amazonaws.com/bookiebet/bookiebet_logo_small.png',
		baggybet:
			'https://bm-brand-assets.s3.ap-southeast-2.amazonaws.com/baggybet/favicon.png',
		eskanderbet:
			'https://bm-brand-assets.s3-ap-southeast-2.amazonaws.com/eskanderbet/favicon.ico',
		bitwinning:
			'https://bm-brand-assets.s3-ap-southeast-2.amazonaws.com/bitwinning/favicon.png',
		monmouthtech:
			'https://bm-brand-assets.s3.ap-southeast-2.amazonaws.com/MonmouthUS/favicon.png',
		foxcatcherbetting:
			'https://bm-brand-assets.s3.ap-southeast-2.amazonaws.com/foxcatcherbetting/favicon.png',
		thunderbet:
			'https://bm-brand-assets.s3.ap-southeast-2.amazonaws.com/thunderbet/favicon.png',
		preprodwhitelabel:
			'https://bm-brand-assets.s3.ap-southeast-2.amazonaws.com/preprodwhitelabel/favicon.png',
		chasebet:
			'https://bm-brand-assets.s3.ap-southeast-2.amazonaws.com/chasebet/favicon.png',
		sportchamps:
			'https://bm-brand-assets.s3-ap-southeast-2.amazonaws.com/sportchamps/favicon.png',
		tonybet:
			'https://bm-brand-assets.s3-ap-southeast-2.amazonaws.com/tonybet/favicon.png',
		betestate:
			'https://bm-brand-assets.s3-ap-southeast-2.amazonaws.com/betestate/favicon.png',
		playwest:
			'https://bm-brand-assets.s3-ap-southeast-2.amazonaws.com/playwest/favicon.ico',
		premiumbet:
			'https://bm-brand-assets.s3-ap-southeast-2.amazonaws.com/premiumbet/favicon.ico',
		bet66:
			'https://bm-brand-assets.s3-ap-southeast-2.amazonaws.com/bet66/favicon.ico',
		betlocal:
			'https://bm-brand-assets.s3-ap-southeast-2.amazonaws.com/betlocal/favicon.ico',
	},
	brandManagerEndpoint: {
		benchmark: 'https://cms.benchmark.ext.thebetmakers.com',
		integration: 'https://cms.integration.ext.thebetmakers.com',
		voyager: 'https://cms.voyager.ext.thebetmakers.com',
		betdeluxe: 'https://cms.betdeluxe.com.au',
		wat: 'https://cms.robwaterhouse.com',
		realbookie: 'https://cms.realbookie.com.au',
		crossbet: 'https://cms.crossbet.com.au',
		texbet: 'https://cms.texbet.com.au',
		picnic: 'https://cms.picnicbet.com',
		pla: 'https://cms.playabets.ext.thebetmakers.com',
		zbet: 'https://cms.zbet.com.au',
		caymanasbet: 'https://cms.caymanasbet.com',
		monmouth: 'https://cms.monmouthbets.com',
		wish: 'https://cms.wishbet.com.au',
		betnation: 'https://cms.betnation.com.au',
		readybet: 'https://cms.readybet.com.au',
		mintbet: 'https://cms.mintbet.com.au',
		swiftbet: 'https://cms.swiftbet.com.au',
		getsetbet: 'https://cms.getsetbet.com.au',
		marantellibet: 'https://cms.marantellibet.com',
		punt123: 'https://cms.punt123.bet',
		betgold: 'https://cms.betgold.com.au',
		dev1: 'https://cms.dev1.ext.thebetmakers.com',
		dev2: 'https://cms.dev2.ext.thebetmakers.com',
		dev4: 'https://cms.dev4.ext.thebetmakers.com',
		diamondbet: 'https://cms.diamondbet.com.au',
		upcoz: 'https://cms.upcoz.com',
		okebet: 'https://cms.okebet.com.au',
		newhorizons: 'https://cms.newhorizons.ext.thebetmakers.com',
		bossbet: 'https://cms.bossbet.com.au',
		crossbetca: 'https://cms.crossbet.ca',
		betr: 'https://cms.betr.ext.thebetmakers.com',
		bookiebet: 'https://cms.bookiebet.com.au',
		baggybet: 'https://cms.baggybet.com',
		eskanderbet: 'https://cms.eskanderbet.com.au',
		bitwinning: 'https://cms.bitwinning.com',
		monmouthtech: 'https://cms.monmouthbets.tech',
		foxcatcherbetting: 'https://cms.foxcatcherbetting.com',
		thunderbet: 'https://cms.thunderbet.com.au',
		preprodwhitelabel: 'https://cms.preprodwhitelabel.ext.thebetmakers.com',
		chasebet: 'https://cms.chasebet.ext.thebetmakers.com',
		sportchamps: 'https://cms.sportchamps.ext.thebetmakers.com',
		tonybet: 'https://cms.tonybet.ext.thebetmakers.com',
		betestate: 'https://cms.betestate.ext.thebetmakers.com',
		playwest: 'https://cms.playwestbet.com',
		premiumbet: 'https://cms.premiumbet.ext.thebetmakers.com',
		bet66: 'https://cms.bet66.ext.thebetmakers.com',
		betlocal: 'https://cms.betlocal.com.au'
	},
	appUrl: {
		benchmark: 'https://benchmark.thebetmakers.com',
		integration: 'https://integration.thebetmakers.com',
		voyager: 'https://voyager.ext.thebetmakers.com',
		betdeluxe: 'https://betdeluxe.com.au',
		wat: 'https://robwaterhouse.com',
		realbookie: 'https://realbookie.com.au',
		crossbet: 'https://crossbet.com.au',
		texbet: 'https://texbet.com.au',
		picnic: 'https://picnicbet.com',
		pla: 'https://playabets.ext.thebetmakers.com/',
		zbet: 'https://zbet.com.au',
		caymanasbet: 'https://caymanasbet.com',
		monmouth: 'https://monmouthbets.com',
		wish: 'https://wishbet.com.au',
		betnation: 'https://betnation.com.au',
		getsetbet: 'https://getsetbet.com.au',
		marantellibet: 'https://marantellibet.com',
		punt123: 'https://punt123.bet',
		betgold: 'https://betgold.com.au',
		readybet: 'https://readybet.com.au',
		mintbet: 'https://mintbet.com.au',
		swiftbet: 'https://swiftbet.com.au',
		dev1: 'https://dev1.ext.thebetmakers.com',
		dev2: 'https://dev2.ext.thebetmakers.com',
		dev4: 'https://dev4.ext.thebetmakers.com',
		diamondbet: 'https://diamondbet.com.au',
		upcoz: 'https://upcoz.com',
		okebet: 'https://okebet.com.au',
		newhorizons: 'https://newhorizons.ext.thebetmakers.com',
		bossbet: 'https://bossbet.com.au',
		crossbetca: 'https://crossbet.ca',
		betr: 'https://betr.ext.thebetmakers.com',
		bookiebet: 'https://bookiebet.com.au',
		baggybet: 'https://baggybet.com',
		eskanderbet: 'https://eskanderbet.com.au',
		bitwinning: 'https://bitwinning.com',
		monmouthtech: 'https://monmouthbets.tech',
		foxcatcherbetting: 'https://foxcatcherbetting.com',
		thunderbet: 'https://thunderbet.com.au',
		preprodwhitelabel: 'https://preprodwhitelabel.ext.thebetmakers.com',
		chasebet: 'https://chasebet.ext.thebetmakers.com',
		sportchamps: 'https://sportchamps.com.au',
		tonybet: 'https://tonybet.ext.thebetmakers.com',
		betestate: 'https://betestate.ext.thebetmakers.com',
		playwest: 'https://playwestbet.com',
		premiumbet: 'https://premiumbet.ext.thebetmakers.com',
		bet66: 'https://bet66.ext.thebetmakers.com',
		betlocal: 'https://betlocal.com.au'
	},
	primaryColors: {
		NOT_LOGGED_IN: '#ff0080',
		benchmark: '#ff0080',
		integration: '#ff0080',
		voyager: '#ff0080',
		betdeluxe: '#f37168',
		wat: '#333333',
		realbookie: '#008c95',
		crossbet: '#51b0a0',
		texbet: '#9adf3e',
		picnic: '#ffbd59',
		pla: '#1f1e1e',
		zbet: '#4070f6',
		caymanasbet: '#ffcd00',
		monmouth: '#2e5746',
		wish: '#efb121',
		betnation: '#00c5f0',
		getsetbet: '#f70588',
		marantellibet: '#fe4747',
		punt123: '#fe4747',
		betgold: '#fe4747',
		dev1: '#ff0080',
		dev2: '#ff0080',
		dev4: '#ff0080',
		diamondbet: '#ff0080',
		upcoz: '#ff0080',
		okebet: '#ff0080',
		newhorizons: '#ff0080',
		readybet: '#ff0080',
		mintbet: '#1E773B',
		swiftbet: '#ff0080',
		bossbet: '#ff0080',
		crossbetca: '#ff0080',
		betr: '#ff0080',
		bookiebet: '#ff0080',
		baggybet: '#ff0080',
		eskanderbet: '#ff0080',
		bitwinning: '#ff0080',
		monmouthtech: '#ff0080',
		foxcatcherbetting: '#ff0080',
		thunderbet: '#ff0080',
		preprodwhitelabel: '#ff0080',
		chasebet: '#ff28ae',
		sportchamps: '#ff4000',
		tonybet: '#ff4000',
		betestate: '#ffe605',
		playwest: '#ffcc00',
		premiumbet: '#8CEB34',
		bet66: '#ED1C24',
		betlocal: '#ffba00'
	},
	secondaryColors: {
		NOT_LOGGED_IN: '#293842',
		benchmark: '#293842',
		integration: '#293842',
		voyager: '#293842',
		betdeluxe: '#000000',
		wat: '#000000',
		realbookie: '#555759',
		crossbet: '#111d2b',
		texbet: '#1c5693',
		picnic: '#0c4da2',
		pla: '#7fb902',
		zbet: '#050b3c',
		caymanasbet: '#26b47b',
		monmouth: '#000000',
		wish: '#000000',
		betnation: '#00274e',
		getsetbet: '#19252a',
		marantellibet: '#312c6d',
		readybet: '#293842',
		swiftbet: '#293842',
		punt123: '#312c6d',
		betgold: '#312c6d',
		mintbet: '#3aa674',
		dev1: '#293842',
		dev2: '#293842',
		dev4: '#293842',
		diamondbet: '#293842',
		upcoz: '#293842',
		okebet: '#293842',
		newhorizons: '#293842',
		bossbet: '#293842',
		crossbetca: '#293842',
		betr: '#293842',
		bookiebet: '#293842',
		baggybet: '#293842',
		eskanderbet: '#293842',
		bitwinning: '#293842',
		monmouthtech: '#293842',
		foxcatcherbetting: '#293842',
		thunderbet: '#293842',
		preprodwhitelabel: '#293842',
		chasebet: '#762a90',
		sportchamps: '#011a38',
		tonybet: '#293842',
		betestate: '#58595b',
		playwest: '#13ADD4',
		premiumbet: '#3489EB',
		bet66: '#4050A2',
		betlocal: '#6e299a'
	},
	bettingEngineApi: {
		benchmark: 'https://api.benchmark.ext.thebetmakers.com/api/v2',
		integration: 'https://api.integration.ext.thebetmakers.com/api/v2',
		voyager: 'https://api.voyager.ext.thebetmakers.com/api/v2',
		betdeluxe: 'https://api.betdeluxe.com.au/api/v2',
		wat: 'https://api.robwaterhouse.com/api/v2',
		realbookie: 'https://api.realbookie.com.au/api/v2',
		crossbet: 'https://api.crossbet.com.au/api/v2',
		texbet: 'https://api.texbet.com.au/api/v2',
		picnic: 'https://api.picnicbet.com/api/v2',
		pla: 'https://api.pla.ext.thebetmakers.com/api/v2',
		zbet: 'https://api.zbet.com.au/api/v2',
		caymanasbet: 'https://api.caymanasbet.com/api/v2',
		monmouth: 'https://api.monmouthbets.com/api/v2',
		wish: 'https://api.wishbet.com.au/api/v2',
		betnation: 'https://api.betnation.com.au/api/v2',
		getsetbet: 'https://api.getsetbet.com.au/api/v2',
		marantellibet: 'https://api.marantellibet.com/api/v2',
		punt123: 'https://api.punt123.bet/api/v2',
		betgold: 'https://api.betgold.com.au/api/v2',
		mintbet: 'https://api.mintbet.com.au/api/v2',
		dev1: 'https://api.dev1.ext.thebetmakers.com/api/v2',
		dev2: 'https://api.dev2.ext.thebetmakers.com/api/v2',
		diamondbet: 'https://api.diamondbet.com.au/api/v2',
		readybet: 'https://api.readybet.com.au/api/v2',
		swiftbet: 'https://api.swiftbet.com.au/api/v2',
		dev4: 'https://api.dev4.ext.thebetmakers.com/api/v2',
		upcoz: 'https://api.upcoz.com/api/v2',
		okebet: 'https://api.okebet.com.au/api/v2',
		newhorizons: 'https://api.newhorizons.ext.thebetmakers.com/api/v2',
		bossbet: 'https://api.bossbet.com.au/api/v2',
		crossbetca: 'https://api.crossbet.ca/api/v2',
		betr: 'https://api.betr.ext.thebetmakers.com/api/v2',
		bookiebet: 'https://api.bookiebet.com.au/api/v2',
		baggybet: 'https://api.baggybet.com/api/v2',
		eskanderbet: 'https://api.eskanderbet.com.au/api/v2',
		bitwinning: 'https://api.bitwinning.com/api/v2',
		monmouthtech: 'https://api.monmouthbets.tech/api/v2',
		foxcatcherbetting: 'https://api.foxcatcherbetting.com/api/v2',
		thunderbet: 'https://api.thunderbet.com.au/api/v2',
		preprodwhitelabel: 'https://api.preprodwhitelabel.ext.thebetmakers.com/api/v2',
		chasebet: 'https://api.chasebet.ext.thebetmakers.com/api/v2',
		sportchamps: 'https://api.sportchamps.ext.thebetmakers.com/api/v2',
		tonybet: 'https://api.tonybet.ext.thebetmakers.com/api/v2',
		betestate: 'https://api.betestate.ext.thebetmakers.com/api/v2',
		playwest: 'https://api.playwestbet.com/api/v2',
		premiumbet: 'https://api.premiumbet.ext.thebetmakers.com/api/v2',
		bet66: 'https://api.bet66.ext.thebetmakers.com/api/v2',
		betlocal: 'https://api.betlocal.com.au/api/v2',
	},
	bettingEngineLegacyApi: {
		benchmark: 'https://api.benchmark.ext.thebetmakers.com/api/v1',
		integration: 'https://api.integration.ext.thebetmakers.com/api/v1',
		voyager: 'https://api.voyager.ext.thebetmakers.com/api/v1',
		betdeluxe: 'https://api.betdeluxe.com.au/api/v1',
		wat: 'https://api.robwaterhouse.com/api/v1',
		realbookie: 'https://api.realbookie.com.au/api/v1',
		crossbet: 'https://api.crossbet.com.au/api/v1',
		texbet: 'https://api.texbet.com.au/api/v1',
		picnic: 'https://api.picnicbet.com/api/v1',
		pla: 'https://api.pla.ext.thebetmakers.com/api/v1',
		zbet: 'https://api.zbet.com.au/api/v1',
		mintbet: 'https://api.mintbet.com.au/api/v1',
		caymanasbet: 'https://api.caymanasbet.com/api/v1',
		monmouth: 'https://api.monmouthbets.com/api/v1',
		wish: 'https://api.wishbet.com.au/api/v1',
		betnation: 'https://api.betnation.com.au/api/v1',
		getsetbet: 'https://api.getsetbet.com.au/api/v1',
		marantellibet: 'https://api.marantellibet.com/api/v1',
		punt123: 'https://api.punt123.bet/api/v1',
		betgold: 'https://api.betgold.com.au/api/v1',
		readybet: 'https://api.readybet.com.au/api/v1',
		swiftbet: 'https://api.swiftbet.com.au/api/v1',
		dev1: 'https://api.dev1.ext.thebetmakers.com/api/v1',
		dev2: 'https://api.dev2.ext.thebetmakers.com/api/v1',
		dev4: 'https://api.dev4.ext.thebetmakers.com/api/v1',
		diamondbet: 'https://api.diamondbet.com.au/api/v1',
		upcoz: 'https://api.upcoz.com/api/v1',
		okebet: 'https://api.okebet.com.au/api/v1',
		newhorizons: 'https://api.newhorizons.ext.thebetmakers.com/api/v1',
		bossbet: 'https://api.bossbet.com.au/api/v1',
		crossbetca: 'https://api.crossbetca.ca/api/v1',
		betr: 'https://api.betr.ext.thebetmakers.com/api/v1',
		bookiebet: 'https://api.bookiebet.com.au/api/v1',
		baggybet: 'https://api.baggybet.com/api/v1',
		eskanderbet: 'https://api.eskanderbet.com.au/api/v1',
		bitwinning: 'https://api.bitwinning.com/api/v1',
		monmouthtech: 'https://api.monmouthbets.tech/api/v1',
		foxcatcherbetting: 'https://api.foxcatcherbetting.com/api/v1',
		thunderbet: 'https://api.thunderbet.com.au/api/v1',
		preprodwhitelabel: 'https://api.preprodwhitelabel.ext.thebetmakers.com/api/v1',
		chasebet: 'https://api.chasebet.ext.thebetmakers.com/api/v1',
		sportchamps: 'https://api.sportchamps.ext.thebetmakers.com/api/v1',
		tonybet: 'https://api.tonybet.ext.thebetmakers.com/api/v1',
		betestate: 'https://api.betestate.ext.thebetmakers.com/api/v1',
		playwest: 'https://api.playwestbet.com/api/v1',
		premiumbet: 'https://api.premiumbet.ext.thebetmakers.com/api/v1',
		bet66: 'https://api.bet66.ext.thebetmakers.com/api/v1',
		betlocal: 'https://api.betlocal.com.au/api/v1',
	},
	aclBrandCode: {
		benchmark: 1,
		integration: 1,
		voyager: 1,
		betdeluxe: 1,
		wat: 1,
		realbookie: 1,
		crossbet: 1,
		texbet: 1,
		picnic: 1,
		pla: 1,
		zbet: 1,
		caymanasbet: 1,
		monmouth: 1,
		wish: 1,
		betnation: 1,
		getsetbet: 1,
		marantellibet: 1,
		punt123: 1,
		betgold: 1,
		readybet: 1,
		swiftbet: 1,
		dev1: 1,
		dev2: 1,
		dev4: 1,
		diamondbet: 1,
		upcoz: 1,
		okebet: 1,
		mintbet: 1,
		newhorizons: 1,
		bossbet: 1,
		crossbetca: 1,
		betr: 1,
		bookiebet: 1,
		baggybet: 1,
		eskanderbet: 1,
		bitwinning: 1,
		monmouthtech: 1,
		foxcatcherbetting: 1,
		thunderbet: 1,
		preprodwhitelabel: 1,
		chasebet: 1,
		sportchamps: 1,
		tonybet: 1,
		betestate: 1,
		playwest: 1,
		premiumbet: 1,
		bet66: 1,
		betlocal: 1,
	},
	featureFlags: {
		benchmark: {
			banners: { enabled: true, tags: true },
			promotionEngine: {
				enabled: true,
				runnerForm: true,
				config: {},
				sportsBoost: false,
				racingBoost: false,
			},
			pages: { version: true },
		},
		integration: {
			promotionEngine: {
				enabled: true,
				runnerForm: true,
				config: {},
				sportsBoost: false,
				racingBoost: false,
			},
			pages: { version: true },
		},
		voyager: {
			promotionEngine: {
				enabled: true,
				runnerForm: true,
				config: {},
				sportsBoost: true,
				racingBoost: true,
			},
			pages: { version: true },
		},
		realbookie: {
			promotionEngine: {
				enabled: true,
				runnerForm: true,
				config: {},
				sportsBoost: false,
				racingBoost: false,
			},
		},
		wat: {
			promotionEngine: {
				enabled: true,
				runnerForm: true,
				config: {},
				sportsBoost: false,
				racingBoost: false,
			},
		},
		texbet: {
			promotionEngine: {
				enabled: true,
				runnerForm: true,
				config: {},
				sportsBoost: false,
				racingBoost: false,
			},
		},
		crossbet: {
			promotionEngine: {
				enabled: true,
				runnerForm: true,
				config: {},
				sportsBoost: false,
				racingBoost: false,
			},
		},
		picnic: {
			promotionEngine: {
				enabled: true,
				runnerForm: true,
				config: {},
				sportsBoost: false,
				racingBoost: false,
			},
		},
		zbet: {
			promotionEngine: {
				enabled: true,
				runnerForm: true,
				config: {},
				sportsBoost: false,
				racingBoost: false,
			},
		},
		wish: {
			promotionEngine: {
				enabled: true,
				runnerForm: true,
				config: {},
				sportsBoost: false,
				racingBoost: false,
			},
		},
		betnation: {
			promotionEngine: {
				enabled: true,
				runnerForm: true,
				config: {},
				sportsBoost: false,
				racingBoost: false,
			},
		},
		betdeluxe: {
			promotionEngine: {
				enabled: true,
				runnerForm: true,
				config: {},
				sportsBoost: false,
				racingBoost: false,
			},
		},
		getsetbet: {
			promotionEngine: {
				enabled: true,
				runnerForm: true,
				config: {},
				sportsBoost: false,
				racingBoost: false,
			},
		},
		marantellibet: {
			promotionEngine: {
				enabled: true,
				runnerForm: true,
				config: {},
				sportsBoost: false,
				racingBoost: false,
			},
		},
		punt123: {
			promotionEngine: {
				enabled: true,
				runnerForm: true,
				config: {},
				sportsBoost: false,
				racingBoost: false,
			},
		},
		dev1: {
			promotionEngine: {
				enabled: true,
				runnerForm: true,
				config: {},
				sportsBoost: false,
				racingBoost: false,
			},
		},
		mintbet: {
			promotionEngine: {
				enabled: true,
				runnerForm: true,
				config: {},
				sportsBoost: false,
				racingBoost: false,
			},
		},
		dev2: {
			promotionEngine: {
				enabled: true,
				runnerForm: true,
				config: {},
				sportsBoost: false,
				racingBoost: false,
			},
		},
		dev4: {
			promotionEngine: {
				enabled: true,
				runnerForm: true,
				config: {},
				sportsBoost: false,
				racingBoost: false,
			},
		},
		diamondbet: {
			promotionEngine: {
				enabled: true,
				runnerForm: true,
				config: {},
				sportsBoost: false,
				racingBoost: false,
			},
		},
		okebet: {
			promotionEngine: {
				enabled: true,
				runnerForm: true,
				config: {},
				sportsBoost: false,
				racingBoost: false,
			},
		},
		upcoz: {
			promotionEngine: {
				enabled: true,
				runnerForm: true,
				config: {},
				sportsBoost: false,
				racingBoost: false,
			},
		},
		readybet: {
			promotionEngine: {
				enabled: true,
				runnerForm: true,
				config: {},
				sportsBoost: false,
				racingBoost: false,
			},
		},
		swiftbet: {
			promotionEngine: {
				enabled: true,
				runnerForm: true,
				config: {},
				sportsBoost: false,
				racingBoost: false,
			},
		},
		betgold: {
			promotionEngine: {
				enabled: true,
				runnerForm: true,
				config: {},
				sportsBoost: false,
				racingBoost: false,
			},
		},
		newhorizons: {
			promotionEngine: {
				enabled: true,
				runnerForm: true,
				config: {},
				sportsBoost: true,
				racingBoost: true,
			},
		},
		bossbet: {
			promotionEngine: {
				enabled: true,
				runnerForm: true,
				config: {},
				sportsBoost: false,
				racingBoost: false,
			},
		},
		crossbetca: {
			promotionEngine: {
				enabled: true,
				runnerForm: true,
				config: {},
				sportsBoost: false,
				racingBoost: false,
			},
		},
		betr: {
			promotionEngine: {
				enabled: true,
				runnerForm: true,
				config: {},
				sportsBoost: true,
				racingBoost: true,
			},
		},
		bookiebet: {
			promotionEngine: {
				enabled: true,
				runnerForm: true,
				config: {},
				sportsBoost: false,
				racingBoost: false,
			},
		},
		baggybet: {
			promotionEngine: {
				enabled: true,
				runnerForm: true,
				config: {},
				sportsBoost: false,
				racingBoost: false,
			},
		},
		eskanderbet: {
			promotionEngine: {
				enabled: true,
				runnerForm: true,
				config: {},
				sportsBoost: false,
				racingBoost: false,
			},
		},
		bitwinning: {
			promotionEngine: {
				enabled: true,
				runnerForm: true,
				config: {},
				sportsBoost: false,
				racingBoost: false,
			},
		},
		monmouth: {
			promotionEngine: {
				enabled: true,
				runnerForm: true,
				config: {},
				sportsBoost: false,
				racingBoost: false,
			},
			pages: { version: true },
		},
		monmouthtech: {
			promotionEngine: {
				enabled: true,
				runnerForm: true,
				config: {},
				sportsBoost: false,
				racingBoost: false,
			},
			pages: { version: true },
		},
		thunderbet: {
			promotionEngine: {
				enabled: true,
				runnerForm: true,
				config: {},
				sportsBoost: false,
				racingBoost: false,
			},
			pages: { version: true },
		},
		foxcatcherbetting: {
			promotionEngine: {
				enabled: true,
				runnerForm: true,
				config: {},
				sportsBoost: false,
				racingBoost: false,
			},
			pages: { version: true },
		},
		preprodwhitelabel: {
			promotionEngine: {
				enabled: true,
				runnerForm: true,
				config: {},
				sportsBoost: false,
				racingBoost: false,
			},
			pages: { version: true },
		},
		chasebet: {
			promotionEngine: {
				enabled: true,
				runnerForm: true,
				config: {},
				sportsBoost: false,
				racingBoost: false,
			},
			pages: { version: true },
		},
		sportchamps: {
			promotionEngine: {
				enabled: true,
				runnerForm: true,
				config: {},
				sportsBoost: false,
				racingBoost: false,
			},
		},
		tonybet: {
			promotionEngine: {
				enabled: true,
				runnerForm: true,
				config: {},
				sportsBoost: false,
				racingBoost: false,
			},
		},
		betestate: {
			promotionEngine: {
				enabled: true,
				runnerForm: true,
				config: {},
				sportsBoost: false,
				racingBoost: false,
			},
		},
		playwest: {
			promotionEngine: {
				enabled: true,
				runnerForm: true,
				config: {},
				sportsBoost: false,
				racingBoost: false,
			},
		},
		premiumbet: {
			promotionEngine: {
				enabled: true,
				runnerForm: true,
				config: {},
				sportsBoost: false,
				racingBoost: false,
			},
		},
		bet66: {
			promotionEngine: {
				enabled: true,
				runnerForm: true,
				config: {},
				sportsBoost: false,
				racingBoost: false,
			},
		},
		caymanasbet: {
			promotionEngine: {
				enabled: true,
				runnerForm: true,
				config: {},
				sportsBoost: false,
				racingBoost: false,
			},
			pages: { version: true },
		},
		betlocal: {
			promotionEngine: {
				enabled: true,
				runnerForm: true,
				config: {},
				sportsBoost: false,
				racingBoost: false,
			},
		},
	},
};

export interface useableBrandConfigInterface {
	logo: string;
	displayName: string;
	favicon: string;
	brandManagerEndpoint: string;
	appUrl: string;
	primaryColor: string;
	secondaryColor: string;
	bettingEngineApi: string;
	bettingEngineLegacyApi: string;
	aclBrandCode: number;
	featureFlags: {
		banners?: featureBanners;
		promotionEngine?: featurePromotionEngine<{}>;
		pages?: featurePage;
	};
}

interface featureBanners {
	enabled: boolean;
	tags: boolean;
}

interface featurePromotionEngine<T = any> {
	enabled: boolean;
	runnerForm: boolean;
	config: T;
	sportsBoost: boolean;
	racingBoost: boolean;
}
interface featurePage<T = any> {
	version: T;
}

export const brandConfigLoader = (
	brand: BrandNameType | typeof FALL_OVER_BRAND = FALL_OVER_BRAND,
): useableBrandConfigInterface => {
	return brand !== FALL_OVER_BRAND && !brandsList.includes(brand)
		? brandConfigLoader()
		: {
				displayName: brandConfig.displayName[brand],
				logo: brandConfig.logo[brand],
				favicon: brandConfig.favicon[brand],
				brandManagerEndpoint: brandConfig.brandManagerEndpoint[brand],
				appUrl: brandConfig.appUrl[brand],
				primaryColor: brandConfig.primaryColors[brand],
				secondaryColor: brandConfig.secondaryColors[brand],
				bettingEngineApi: brandConfig.bettingEngineApi[brand],
				bettingEngineLegacyApi: brandConfig.bettingEngineLegacyApi[brand],
				aclBrandCode: brandConfig.aclBrandCode[brand],
				featureFlags: brandConfig?.featureFlags?.[brand] || {},
		  };
};

export const configurationLoader = brandConfigLoader;
