import { useFormik } from 'formik';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { ToastsStore } from 'react-toasts';
import Tooltip from 'react-tooltip';
import { PromotionViewEditProps } from '.';
import { ModalStyle } from '../../../common/modalStyle';
import { useBrandConfig } from '../../../context/brandConfig/brandConfigContext';
import { partialApply } from '../../../helpers/pureFunc';
import DaysSelections from '../../shared/DaysSelections';
import FileUploader from '../../shared/FileUploader';
import { Select, SelectBox } from '../../shared/FormElements';
import { StyledButton } from '../../shared/Navs';
import ToggleSwitch from '../../shared/ToggleSwitch';
import {
	ColumnedArea,
	ColumnedLabeledArea,
	DepositFormLayout,
	DepositMatchFieldSet,
	InlineInput,
	InlineLabel,
	StyledLeadingI,
} from '../DepositMatchFormComponents';
import useBoostHookAPI from '../useBoostHookAPI';
import ClientTags from './ClientTags';
import {
	boostPercentValues,
	dateTimeToHTMLSafe,
	formattedDateValue,
	handleChange,
	handleFormikBlur,
	handleFormikChange,
	StyledErrorHint,
} from './Common';
import EligibleTerritories from './EligibleTerritories';
import PriceLadder from './PriceLadder';
import RestrictToClientArea from './RestrictToClientArea';

const initialValues = () => {
	const now = new Date();

	return {
		enabled: false,
		start_date: `${now
			.getFullYear()
			.toFixed(0)
			.padStart(4, '0')}-${(now.getMonth() + 1)
			.toFixed(0)
			.padStart(2, '0')}-${now
			.getDate()
			.toFixed(0)
			.padStart(2, '0')}`,
		end_date: null,
		description: '',
		internal_description: '',
		boost_frequency: [],
		restrict_to_clients: false,
		promotion_type_id: 4,
		excluded_tags: [],
		eligible_territories: [],
		boost_type_id: 4,
		max_stake: 1000,
		max_liability: 1000,
		intercept: false,
		boost_percent: 1,
		price_rolls: 1,
		number_of_boosts: null,
		boost_status: null,
		included_tags: [],
		price_ladder: null,
	};
};

const RacingBoostForm = ({
	item,
	setViewEditCloseDelete,
	mode,
	cachedPromotionEndpoints,
	formElement,
	setFormElement,
	saveChanges,
}: PromotionViewEditProps) => {
	const existingItems = mode === 'new' ? null : item;

	const { brandConfig } = useBrandConfig();
	const [fileName, updateFileName] = useState('');

	// enable feature according to brand config
	const racingBoostEnabled =
		brandConfig?.featureFlags?.promotionEngine?.racingBoost || false;

	const [boostTypes, updateBoostTypes] = useState([]);
	const [disablePricePerce, updatedisablePricePerce] = useState(false);

	const {
		saveBoostPromotion,
		getBoostTypes,
		instantUpdate,
		arrangeFormData,
		getPriceLadder,
		disableInstantBoost,
	} = useBoostHookAPI();

	useEffect(() => {
		if (boostTypes.length > 1) return;
		getBoostTypes('race')
			.then(res => updateBoostTypes(res))
			.catch(res => {
				ToastsStore.warning('Could not load Boost Promotions');
			});
	}, [boostTypes, boostTypes.length, getBoostTypes]);

	const formik = useFormik({
		initialValues: {
			...initialValues(),
			...existingItems,
		},
		validateOnBlur: false,
		validateOnMount: mode !== 'new',
		validateOnChange: false,
		onSubmit: async values => {
			let result;
			let formikValues = values;

			if (!formikValues?.end_date) {
				delete formikValues?.end_date;
			}

			const { price_ladder, id, boost_percent } = formikValues;
			//assemble data for form-data
			if (price_ladder && !boost_percent) {
				delete formikValues.boost_percent;

				result = await saveBoostPromotion(
					arrangeFormData(formikValues, id),
					id,
				);
			} else {
				delete formikValues.price_ladder;
				result = await saveChanges(formikValues);
			}

			if (result instanceof Error) {
				ToastsStore.error('Failed To Submit Form');
			} else {
				const successMessage = item?.id
					? 'Successfully Updated Item'
					: 'Successfully Created Item';
				ToastsStore.success(successMessage);
				setViewEditCloseDelete('close');
			}
		},
		enableReinitialize: true,
	});

	const setFieldValueFunction = formik.setFieldValue;

	const [close, setClose] = useState(false);

	const [manageClientListOpen, setManageClientListOpen] = useState(false);
	// To view price ladder list
	const [openPriceLadder, setopenPriceLadder] = useState(false);

	const [maxTakeValue, setMaxTakeValue] = useState(
		formik.values?.max_stake / 100,
	);
	const [maxLiable, setMaxLiable] = useState(
		formik.values?.max_liability / 100,
	);

	const updateMaxTake = (e: any) => {
		setMaxTakeValue(e.target.value);
	};
	const updateLiable = (e: any) => {
		setMaxLiable(e.target.value);
	};

	// Formik manual functions
	const onFileChange = (e: any) => {
		updateFileName(e.target.files[0]?.name);
		formik.setFieldValue('price_ladder', e.target.files[0]);
		formik.setFieldValue('boost_percent', 0);
	};

	// On boost change checking boost available or not
	const onBoostTypeChange = (e: any) => {
		let value = e.target.value;
		formik.setFieldValue('boost_type_id', e.target.value);
		if (value === '3' || value === '1') {
			updatedisablePricePerce(true);
			formik.setFieldValue('boost_percent', 0);
		} else updatedisablePricePerce(false);
		// if excotic set default price rolls
		if (value === '2') {
			formik.setFieldValue('price_rolls', 1);
		}
	};

	const removeFile = (e: any) => {
		updateFileName('');
		formik.setFieldValue('price_ladder', null);
	};

	const disableInputs = formik.isSubmitting || mode === 'view';

	const territoriesCallback = territories => {
		formik.setFieldValue('eligible_territories', territories);
	};

	const tagCallback = tags => {
		formik.setFieldValue('excluded_tags', tags);
	};
	const includedTagCallback = tags => {
		formik.setFieldValue('included_tags', tags);
	};
	const daysCallback = days => {
		formik.setFieldValue('boost_frequency', days);
	};

	// Funtion to request update boost instantly
	const updateBoostNow = async e => {
		e.preventDefault();

		let result = await instantUpdate(item?.id);
		if (result instanceof Error) {
			ToastsStore.error('The promotion has been already boosted');
		} else {
			ToastsStore.success('Instant Upload Succes!');
			setViewEditCloseDelete('close');
		}
	};

	// Funtion to request remove boost
	const disableBoostNow = async e => {
		e.preventDefault();

		let result = await disableInstantBoost(item?.id);
		if (result instanceof Error) {
			ToastsStore.error('The promotion has been Romoved already');
		} else {
			ToastsStore.success('Promotion has been Romoved!');
			setViewEditCloseDelete('close');
		}
	};

	// Change the Instant button text according to status
	const instantButtonText =
		item?.boost_status === 1 ? 'Remove Boost' : 'Instant Upload';

	// Change the Instant button function according to status
	const instantButtonFunc =
		item?.boost_status === 1 ? disableBoostNow : updateBoostNow;

	// Hide instant button
	const hideInstantButton = mode === 'edit' && item?.id;

	const disablePriceRolls =
		Number(formik?.values?.boost_type_id || item?.boost_type_id) === 2;

	useEffect(() => {
		setFieldValueFunction('max_stake', Math.round(maxTakeValue * 100));
		setFieldValueFunction('max_liability', Math.round(maxLiable * 100));
	}, [maxLiable, maxTakeValue, setFieldValueFunction]);

	// Disable price boost FO and SRM
	const disablePriceBoost =
		formik.values?.boost_type_id === 1 ||
		formik.values?.boost_type_id === 3 ||
		formik.values.price_ladder;

	let formErrorMessage = '';
	if (!formik.isValid) formErrorMessage = "See Error's below";
	if (formik.isValidating) formErrorMessage = 'Checking';

	return (
		<DepositFormLayout
			formError={mode !== 'view' ? formErrorMessage : ''}
			mode={mode}
			actionClose={partialApply(setViewEditCloseDelete, 'close')}
			actionDelete={partialApply(setViewEditCloseDelete, 'delete')}
			actionEdit={partialApply(setViewEditCloseDelete, 'edit')}
			actionView={partialApply(setViewEditCloseDelete, 'view')}
			actionSave={formik.submitForm}
			formElement={formElement}
			setFormElement={setFormElement}
		>
			{!racingBoostEnabled ? (
				<p>Please contact the product team to enable this feature.</p>
			) : (
				<>
					<Modal
						isOpen={manageClientListOpen}
						style={ModalStyle}
						shouldCloseOnOverlayClick={false}
					>
						{mode === 'view' && (
							<RestrictToClientArea
								promotionId={item?.id}
								closeMe={partialApply(setManageClientListOpen, false)}
								fetchPromotions={cachedPromotionEndpoints.promotionClientList}
								nukeClientList={
									cachedPromotionEndpoints.promotionClientListNuke
								}
								setClientList={cachedPromotionEndpoints.promotionClientListSet}
							/>
						)}
					</Modal>

					<Modal
						isOpen={openPriceLadder}
						style={ModalStyle}
						shouldCloseOnOverlayClick={false}
					>
						<PriceLadder
							promotionId={item?.id}
							closeMe={partialApply(setopenPriceLadder, false)}
							fetchLadders={getPriceLadder}
						/>
					</Modal>

					<form
						onClick={e => {
							e.stopPropagation();
							setClose(!close);
						}}
						onSubmit={formik.handleSubmit}
						style={{ width: '100%' }}
					>
						<div>
							<Tooltip id="tooltipManageCLientList">
								{mode !== 'view'
									? 'Save Promotion To Manage its Client List'
									: 'Manage Client List (restrict promotion to client list)'}
								Save Promotion To Edit Client List
							</Tooltip>
						</div>
						<DepositMatchFieldSet disabled={disableInputs}>
							<legend>General</legend>
							<ColumnedLabeledArea
								id="activeArea"
								snapWidth="250px"
								margin="4px"
								label="Boost Type"
							>
								{[
									{
										flexGrow: '2',
										child: (
											<SelectBox>
												<Select
													name="boost_type_id"
													value={formik.values?.boost_type_id}
													onChange={e => onBoostTypeChange(e)}
													onBlur={e => onBoostTypeChange(e)}
												>
													{boostTypes?.map((types: any) => (
														<option key={types?.id} value={types?.id}>
															{types?.name}
														</option>
													))}
												</Select>
											</SelectBox>
										),
									},
									{
										flexGrow: '3',
										child: (
											<StyledErrorHint>
												{mode !== 'view' && formik.errors?.boost_type_id
													? `(${formik.errors?.boost_type_id})`
													: ''}
											</StyledErrorHint>
										),
									},
									{
										flexGrow: '1',
										child: (
											<>
												{hideInstantButton && (
													<StyledButton onClick={instantButtonFunc}>
														{instantButtonText}
													</StyledButton>
												)}
											</>
										),
									},
								]}
							</ColumnedLabeledArea>

							<ColumnedLabeledArea
								id="activeArea"
								snapWidth="250px"
								margin="4px"
								label="Active"
							>
								{[
									{
										flexGrow: '1',
										child: (
											<ToggleSwitch
												id="enabled"
												checked={formik.values.enabled}
												onChange={e => handleChange(e, formik)}
												onBlur={formik.handleBlur}
												title="active"
												displayNotAllowedOnDisabled
												tooltipContent="Enable/Disable Promotion"
											/>
										),
									},
									{
										flexGrow: '4',
										child: (
											<StyledErrorHint>
												{mode !== 'view' && formik.errors?.enabled
													? `(${formik.errors?.enabled})`
													: ''}
											</StyledErrorHint>
										),
									},
								]}
							</ColumnedLabeledArea>
							<br />

							<ColumnedArea
								id="activeControlArea"
								snapWidth="550px"
								margin="4px"
							>
								{[
									{
										flexGrow: '1',
										child: (
											<InlineLabel htmlFor="start_date">Start</InlineLabel>
										),
									},
									{
										flexGrow: '1',
										child: (
											<InlineInput
												id="start_date"
												type="date"
												onChange={e => handleChange(e, formik)}
												value={dateTimeToHTMLSafe(formik.values.start_date)}
												onBlur={formik.handleBlur}
												max={formattedDateValue(formik.values.end_date)}
											/>
										),
									},
									{
										flexGrow: '1',
										child: <></>,
									},
									{
										flexGrow: '1',
										child: (
											<InlineLabel htmlFor="dateFinish">Finish</InlineLabel>
										),
									},
									{
										flexGrow: '1',
										child: (
											<InlineInput
												id="end_date"
												type="date"
												min={
													formik.values?.start_date
														? moment(formik.values?.start_date, 'yyyy-MM-DD')
																.add('1', 'd')
																.format('yyyy-MM-DD')
														: undefined
												}
												onChange={e => handleChange(e, formik)}
												value={dateTimeToHTMLSafe(formik.values?.end_date)}
												onBlur={formik.handleBlur}
											/>
										),
									},
									{
										flexGrow: '1',
										child: <div></div>,
									},
								]}
							</ColumnedArea>
							{(formik.errors.start_date || formik.errors?.end_date) && (
								<StyledErrorHint>
									{mode !== 'view' &&
										Object.entries(formik.errors)
											.filter(
												([field, error]) =>
													field === 'start_date' || field === 'end_date',
											)
											.map(([_, value]) => <div>({value})</div>)}
									<br />
								</StyledErrorHint>
							)}
							<br />
							<br />
							<ColumnedLabeledArea
								id="description"
								snapWidth="1000px"
								margin="4px"
								label="Description"
							>
								{{
									flexGrow: '4',
									child: (
										<InlineInput
											type="text"
											id="description"
											onChange={e => handleChange(e, formik)}
											value={formik.values?.description}
											isError={!!formik.errors?.description}
											onBlur={formik.handleBlur}
										/>
									),
								}}
							</ColumnedLabeledArea>
							{mode !== 'view' && formik.errors.description && (
								<div>
									<StyledErrorHint>
										{formik.errors?.description}
									</StyledErrorHint>
									<br />
									<br />
								</div>
							)}
							<ColumnedLabeledArea
								id="InternalDescription"
								snapWidth="1000px"
								margin="4px"
								label="Internal Description"
							>
								{{
									flexGrow: '4',
									child: (
										<InlineInput
											type="text"
											id="internal_description"
											onChange={e => handleChange(e, formik)}
											value={formik.values?.internal_description}
											isError={!!formik.errors?.internal_description}
											onBlur={formik.handleBlur}
										/>
									),
								}}
							</ColumnedLabeledArea>
							{mode !== 'view' && formik.errors.description && (
								<div>
									<StyledErrorHint>
										{formik.errors.internal_description}
									</StyledErrorHint>
									<br />
								</div>
							)}
						</DepositMatchFieldSet>
						<DepositMatchFieldSet showLocked={disableInputs}>
							<legend>Details</legend>

							<DaysSelections
								label={'Boost Upload'}
								onClose={close}
								daysCallback={daysCallback}
								existingDays={formik.values?.boost_frequency}
							/>

							<ColumnedLabeledArea
								id="activeArea"
								snapWidth="250px"
								margin="4px"
								label="Number of Boosts"
							>
								{[
									{
										flexGrow: '1',
										child: (
											<>
												<SelectBox>
													<Select
														name="number_of_boosts"
														value={formik.values.number_of_boosts}
														onChange={e => handleFormikChange(e, formik)}
														onBlur={e => handleFormikBlur(e, formik)}
													>
														<option value="" disabled selected>
															Select number of boosts
														</option>
														{[1, 2, 3, 4].map(i => (
															<option key={i} value={i}>
																{i}
															</option>
														))}
													</Select>
												</SelectBox>
											</>
										),
									},
									{
										flexGrow: '3',
										child: (
											<StyledErrorHint>
												{mode !== 'view' && formik.errors?.number_of_boosts
													? `(${formik.errors?.number_of_boosts})`
													: ''}
											</StyledErrorHint>
										),
									},
								]}
							</ColumnedLabeledArea>
							<ColumnedLabeledArea
								id="activeArea"
								snapWidth="250px"
								margin="4px"
								label="Max Stake"
							>
								{[
									{
										flexGrow: '1',
										child: (
											<div
												style={{
													position: 'relative',
												}}
											>
												<InlineInput
													disabled={disableInputs}
													id="max_stake"
													type="number"
													min={0}
													step={1}
													value={maxTakeValue}
													inputMode="decimal"
													onChange={e => updateMaxTake(e)}
													onBlur={formik.handleBlur}
													isError={!!formik.errors.max_stake}
													required
												/>
												<StyledLeadingI>$</StyledLeadingI>
											</div>
										),
									},
									{
										flexGrow: '3',
										child: (
											<StyledErrorHint>
												{mode !== 'view' && formik.errors?.max_stake
													? `(${formik.errors?.max_stake})`
													: ''}
											</StyledErrorHint>
										),
									},
								]}
							</ColumnedLabeledArea>
							<ColumnedLabeledArea
								id="activeArea"
								snapWidth="250px"
								margin="4px"
								label="Max Liability"
							>
								{[
									{
										flexGrow: '1',
										child: (
											<div
												style={{
													position: 'relative',
												}}
											>
												<InlineInput
													disabled={disableInputs}
													id="max_liability"
													type="number"
													min={0}
													step={1}
													value={maxLiable}
													inputMode="decimal"
													onChange={e => updateLiable(e)}
													onBlur={formik.handleBlur}
													isError={!!formik.errors.max_liability}
													required
												/>
												<StyledLeadingI>$</StyledLeadingI>
											</div>
										),
									},
									{
										flexGrow: '3',
										child: (
											<StyledErrorHint>
												{mode !== 'view' && formik.errors?.max_liability
													? `(${formik.errors?.max_liability})`
													: ''}
											</StyledErrorHint>
										),
									},
								]}
							</ColumnedLabeledArea>
							<ColumnedArea id="" snapWidth="350px" margin="4px">
								{[
									{
										flexGrow: '1',
										child: <InlineLabel>Price Ladder</InlineLabel>,
									},
									{
										flexGrow: '3',
										child: (
											<FileUploader
												onChange={onFileChange}
												fileName={fileName}
												removeFile={removeFile}
												message={
													'Default ladder will be used if no Price Ladder is uploaded'
												}
											/>
										),
									},
									{
										flexGrow: '1',
										child: (
											<>
												<div
													style={{
														width: '100%',
														display: 'flex',
														justifyContent: 'center',
													}}
												>
													<Tooltip id="Price Ladders">
														{mode !== 'view'
															? 'Save Promotion First'
															: 'Open Price Ladders'}
													</Tooltip>
													<div data-tip data-for="Price Ladders">
														<StyledButton
															disabled={
																mode === 'new' && !formik.errors?.price_ladder
															}
															form={'differentFormDontDisableWithParentForm'}
															onClick={partialApply(setopenPriceLadder, true)}
															isactive={true}
															title={
																mode === 'new' ||
																!(
																	mode === 'view' &&
																	!formik.errors?.price_ladder
																)
																	? 'Save Promotion First'
																	: null
															}
															data-testid="buttonRestrictClients"
														>
															Price Ladder
														</StyledButton>
													</div>
												</div>
											</>
										),
									},
								]}
							</ColumnedArea>

							<br />

							<ColumnedArea
								id="RestrictToClient"
								snapWidth="350px"
								margin="4px"
							>
								{[
									{
										flexGrow: '2',
										child: <InlineLabel>Client List</InlineLabel>,
									},
									{
										flexGrow: '3',
										child: (
											<ToggleSwitch
												disabled={disableInputs}
												title="restrict_to_clients"
												id="restrict_to_clients"
												checked={formik.values.restrict_to_clients}
												onBlur={formik.handleBlur}
												onChange={e => handleChange(e, formik)}
												displayNotAllowedOnDisabled
												tooltipContent="Restrict Promotion to client list"
											/>
										),
									},
									{
										flexGrow: '1',
										child: (
											<>
												{(mode === 'edit' || mode === 'new') &&
													formik.errors?.restrict_to_clients && (
														<StyledErrorHint>
															{formik.errors?.restrict_to_clients}
														</StyledErrorHint>
													)}
												{!(
													(mode === 'edit' || mode === 'new') &&
													formik.errors?.restrict_to_clients
												) &&
													formik.values.restrict_to_clients && (
														<div
															style={{
																width: '100%',
																display: 'flex',
																justifyContent: 'center',
															}}
														>
															<Tooltip id="tooltip_button_client_list">
																{mode !== 'view'
																	? 'Save Promotion First'
																	: 'Open Manage Client List Area'}
															</Tooltip>
															<div
																data-tip
																data-for="tooltip_button_client_list"
															>
																<StyledButton
																	disabled={
																		mode === 'new' ||
																		!(
																			mode === 'view' &&
																			!formik.errors?.restrict_to_clients
																		)
																	}
																	form={
																		'differentFormDontDisableWithParentForm'
																	}
																	onClick={partialApply(
																		setManageClientListOpen,
																		true,
																	)}
																	isactive={true}
																	title={
																		mode === 'new' ||
																		!(
																			mode === 'view' &&
																			!formik.errors?.restrict_to_clients
																		)
																			? 'Save Promotion First'
																			: null
																	}
																	data-testid="buttonRestrictClients"
																>
																	Manage Client List
																</StyledButton>
															</div>
														</div>
													)}
											</>
										),
									},
								]}
							</ColumnedArea>
							<ColumnedArea
								id="RestrictToClient"
								snapWidth="350px"
								margin="4px"
							>
								{[
									{
										flexGrow: '2',
										child: <InlineLabel>Intercept</InlineLabel>,
									},
									{
										flexGrow: '1',
										child: (
											<ToggleSwitch
												disabled={disableInputs}
												title="intercept"
												id="intercept"
												checked={formik.values.intercept}
												onBlur={formik.handleBlur}
												onChange={e => handleChange(e, formik)}
												displayNotAllowedOnDisabled
												tooltipContent="Restrict Promotion to client list"
											/>
										),
									},
									{
										flexGrow: '3',
										child: (
											<StyledErrorHint>
												{mode !== 'view' && formik.errors?.intercept
													? `(${formik.errors?.intercept})`
													: ''}
											</StyledErrorHint>
										),
									},
								]}
							</ColumnedArea>
							<ColumnedLabeledArea
								id="activeArea"
								snapWidth="250px"
								margin="4px"
								label="Boost % Increase"
							>
								{[
									{
										flexGrow: '1',
										child: (
											<SelectBox>
												<Select
													name="boost_percent"
													value={formik.values?.boost_percent}
													onChange={e => handleFormikChange(e, formik)}
													onBlur={e => handleFormikBlur(e, formik)}
													disabled={disablePriceBoost || disablePricePerce}
												>
													<option value={0} disabled selected>
														Select boosts percent
													</option>
													{boostPercentValues.map(val => (
														<option key={val} value={val}>
															{val}
														</option>
													))}
												</Select>
											</SelectBox>
										),
									},
									{
										flexGrow: '3',
										child: (
											<StyledErrorHint>
												{mode !== 'view' && formik.errors?.boost_percent
													? `(${formik.errors?.boost_percent})`
													: ''}
												{formik.values?.price_ladder &&
													'Price Ladder aleady selected '}
											</StyledErrorHint>
										),
									},
								]}
							</ColumnedLabeledArea>

							<ColumnedLabeledArea
								id="activeArea"
								snapWidth="250px"
								margin="4px"
								label="Price Rolls"
							>
								{[
									{
										flexGrow: '1',
										child: (
											<SelectBox>
												<Select
													name="price_rolls"
													value={
														formik.values?.price_rolls === 0
															? null
															: formik.values?.price_rolls
													}
													onChange={e => handleFormikChange(e, formik)}
													onBlur={e => handleFormikBlur(e, formik)}
													disabled={disablePriceRolls}
												>
													<option value={0} disabled selected>
														Select price rolls
													</option>
													<option key={1}>1</option>
													<option key={2}>2</option>
													<option key={3}>3</option>
												</Select>
											</SelectBox>
										),
									},
									{
										flexGrow: '3',
										child: (
											<StyledErrorHint>
												{mode !== 'view' && formik.errors?.price_rolls
													? `(${formik.errors?.price_rolls})`
													: ''}
											</StyledErrorHint>
										),
									},
								]}
							</ColumnedLabeledArea>
							<EligibleTerritories
								onClose={close}
								territoriesCallback={territoriesCallback}
								existingTerritories={formik.values?.eligible_territories}
							/>
							<ClientTags
								tagCallback={tagCallback}
								existingTags={formik.values?.excluded_tags}
							/>
							<ClientTags
								label={'Selected Tags'}
								tagCallback={includedTagCallback}
								existingTags={formik.values?.included_tags}
							/>
						</DepositMatchFieldSet>
					</form>
				</>
			)}
		</DepositFormLayout>
	);
};

export default RacingBoostForm;
