import React, { useState } from 'react';
import useImagePresignedUrl from '../../hooks/useImageUploadUrl';
import UserImageHoverDelete from './UserImageHoverDelete';

import 'react-dropzone-uploader/dist/styles.css';
import Dropzone, {
	IUploadParams,
	IFileWithMeta,
} from 'react-dropzone-uploader';

export interface imagePickerProps {
	folder: 'banner' | 'promotion' | 'content';
	selectedImage?: string;
	setSelectedImage?: (input: string) => void;
	validationTest?: (input: object) => void;
	getObjectMeta?: (input: object) => void;
}

const ImagePicker = ({
	folder,
	selectedImage,
	setSelectedImage,
	validationTest,
	getObjectMeta,
}: imagePickerProps) => {
	const putLinkGenerator = useImagePresignedUrl();
	const [newFilename, setNewFilename] = useState(selectedImage);
	const defaultInstructions = selectedImage
		? 'Drag Image File to Replace Image'
		: 'Drag Image File';

	const getUploadParams: (
		file: IFileWithMeta,
	) => IUploadParams | Promise<IUploadParams> = async ({ file, meta }) => {
		const { Url, FutureAccessUrl } = await putLinkGenerator(folder, file.type, {
			orgName: meta.name,
			width: meta.width,
			height: meta.height,
		});
		setNewFilename(FutureAccessUrl);

		return {
			body: file,
			url: Url,
			headers: { 'Content-Type': file.type },
			method: 'PUT',
		};
	};

	return (
		<div>
			{selectedImage ? (
				<UserImageHoverDelete
					imageSrc={selectedImage}
					onClick={() => {
						if (window.confirm('Would you like to delete this image'))
							setSelectedImage(null);
					}}
				/>
			) : (
				<Dropzone
					accept="image/png,image/jpeg"
					inputContent={(files, extra) =>
						extra.reject ? 'Image jpeg,jpg,png only' : defaultInstructions
					}
					maxFiles={1}
					multiple={false}
					canCancel={false}
					getUploadParams={getUploadParams}
					onChangeStatus={({ meta, file }, status) => {
						if (status === 'done') setSelectedImage(newFilename);
						if (getObjectMeta) getObjectMeta(meta);
					}}
					validate={validationTest ? (obj) => validationTest(obj) : () => {}}
				/>
			)}
		</div>
	);
};

export default ImagePicker;
