import { PromotionEngineItem } from './rawEndpointFunc/common';

export enum promotionTriggerTypes {
	DEPOSIT = 1,
	RUNNER = 2,
	PROMO = 3,
	SPORT = 5,
	RACING = 4,
}

export const promotionTypeLabels = {
	1: 'Deposit Match',
	2: 'Money Back',
	3: 'Manual Promo',
	5: 'Sports Boost',
	4: 'Racing Boost',
};

export const promotionCriteriaLabels = {
	1: 'Money Back 2nd',
	2: 'Money Back 2nd / 3rd',
	3: 'Money Back 2nd / 3rd / 4th',
};

export enum promotionRewardTypes {
	BONUS_BETS = 1,
	REAL_MONEY = 2,
}

export enum promotionEngineClassEvents {
	fetch = 'PromotionEngineFetch',
	update = 'PromotionEngineUpdate',
	create = 'PromotionEngineCreate',
	delete = 'PromotionEngineDelete',
	mutationStart = 'PromotionEngineMutationStart',
	mutationFinish = 'PromotionEngineMutationFinish',
}

export const boostTypes = {
	1: 'Racing Fixed Odds Boost',
	2: 'Racing Exotic Boost',
	3: 'Racing SRM Boost',
	4: 'Racing Multi Boost',
	5: 'Sport Singles Boost',
	6: 'Sport Multi Boost',
	7: 'Sport SGM Boost',
};

export interface runnerFormInterface {
	id: number;
	promotion_type_id: number;
	criteria: number;
	name: string;
	description: string;
	internal_description: string;
	enabled: boolean;
	max_deposit: number;
	winning_turnover_factor: number;
	redemptions_per_customer: number;
	reward_type: number;
	first_bet_only: true;
	excluded_tags: Array<string>;
	eligible_events: Array<string>;
	display_on_site: boolean;
	fixed_odds_only: boolean;
	restrict_to_clients: boolean;
	eligible_territories: Array<any>;
	boost_status?: number;
	boost_type_id?: any;
}

export interface promotionItemInterface extends PromotionEngineItem {
	reward_type?: promotionRewardTypes.BONUS_BETS;
	criteria?: number;
	boost_type_id?: any;
}

export interface manualPromoFormInterface {
	id: number;
	promotion_type_id: number;
	description: string;
	internal_description: string;
	details: string;
	enabled: boolean;
	excluded_tags: Array<string>;
	eligible_events: Array<string>;
	display_on_site: boolean;
	restrict_to_clients: boolean;
	eligible_territories: Array<any>;
	boost_status?: number;
	boost_type_id?: any;
}

export interface sportBoostFormInterface {
	id: number;
	enabled: boolean;
	promotion_type_id: number;
	description: string;
	internal_description: string;
	boost_frequency: [];
	restrict_to_clients: true;
	excluded_tags: Array<string>;
	eligible_territories: Array<string>;
	boost_type_id: any;
	max_stake: number;
	max_liability: number;
	intercept: boolean;
	boost_percent: number;
	price_rolls: number;
	number_of_boosts: number;
	boost_status: number;
	included_tags: Array<any>;
	price_ladder: any;
}
export interface racingBoostFormInterface {
	id: number;
	enabled: boolean;
	promotion_type_id: any;
	description: string;
	internal_description: string;
	boost_frequency: [];
	restrict_to_clients: true;
	excluded_tags: Array<string>;
	eligible_territories: Array<string>;
	boost_type_id?: any;
	max_stake: number;
	max_liability: number;
	intercept: boolean;
	boost_percent: number;
	price_rolls: number;
	number_of_boosts: number;
	boost_status?: number;
	included_tags: Array<any>;
	price_ladder: any;
}
