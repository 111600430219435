import { AxiosResponse, AxiosInstance, AxiosError } from 'axios';

export interface promotionClientListNukeResponse {
	timestamp: string;
	http_status_code: number;
	data: [];
}

function promotionClientListNuke(
	getConfig: () => {
		axiosInstance: AxiosInstance;
	},
	promoId: number,
): Promise<AxiosResponse<promotionClientListNukeResponse> | AxiosError<any>> {
	return getConfig()
		.axiosInstance.delete(`/${promoId}/clients`)
		.catch((e) => e);
}

export default promotionClientListNuke;
