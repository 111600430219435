import React, { Component } from 'react';
import alertConfirm from 'react-alert-confirm';
import { FiEye, FiFile } from 'react-icons/fi';
import Loading from 'react-loading-bar';
import 'react-loading-bar/dist/index.css';
import Modal from 'react-modal';
import { connect } from 'react-redux';
import { ModalStyle } from '../common/modalStyle';
import { staticTheme } from '../common/theme';
import PromotionsForm from '../components/promotions/PromotionsForm';
import PromotionsTable from '../components/promotions/PromotionsTable';
import Card from '../components/shared/Card';
import { NavGroup, StyledButton } from '../components/shared/Navs';
import PageHeader from '../components/shared/PageHeader';
import {
	BrandConfigContextValue,
	withBrandConfig,
} from '../context/brandConfig/brandConfigContext';
import {
	deletePromotion,
	fetchAllPromotions,
	setSelectedPromotion,
} from '../store/promotions';

export interface PromotionsProps {
	promotions: Array<object>;
	isLoading: boolean;
	fetchPromotions: () => void;
	handleSelectedKey?: (input: number | null) => void;
	handlePromotionDelete?: (input: number | null) => void;
	brandConfig: BrandConfigContextValue['brandConfig'];
}

export interface PromotionsState {}

class Promotions extends Component<PromotionsProps, PromotionsState> {
	componentDidMount() {
		this.props.fetchPromotions();
	}
	componentDidUpdate(prevProps) {
		const [prevEndpoint, nextEndpoint] = [
			prevProps.brandConfig?.brandManagerEndpoint,
			this.props.brandConfig?.brandManagerEndpoint,
		];
		// not if user changes endpoints quickly there is a race condition here
		if (prevEndpoint !== nextEndpoint) this.props.fetchPromotions();
	}

	state = {
		isModalOpen: false,
	};

	render() {
		const {
			promotions,
			isLoading,
			handleSelectedKey,
			handlePromotionDelete,
			brandConfig,
		} = this.props;

		const { isModalOpen } = this.state;
		const isMobile = window.innerWidth <= 500;

		const toggleModal = state => {
			this.setState({ isModalOpen: state });
		};

		const handleDelete = promotionId => {
			alertConfirm({
				title: 'Delete Promotion',
				content: 'Are you sure you want to delete this promotion?',
				okText: 'Delete Promotion',
				cancelText: 'Cancel',
				onOk: () => {
					handlePromotionDelete(promotionId);
					this.setState({ isModalOpen: false });
				},
				onCancel: () => {},
			});
		};

		return (
			<>
				<Card>
					<Loading
						show={isLoading}
						color={staticTheme.brand.color_1}
						showSpinner={false}
					/>
					<PageHeader title="Promotions">
						<NavGroup>
							<StyledButton
								isactive={false}
								onClick={() => window.open(`${brandConfig.appUrl}/promotions`)}
							>
								{!isMobile && 'View'} <FiEye className="icon-only-mobile" />
							</StyledButton>
							<StyledButton
								isactive={true}
								onClick={(e: any) => {
									e.preventDefault();
									this.setState({ isModalOpen: true });
									handleSelectedKey(null);
								}}
								disabled={isLoading}
							>
								{!isMobile && 'New'} <FiFile className="icon-only-mobile" />
							</StyledButton>
						</NavGroup>
					</PageHeader>
					{!isLoading && (
						<PromotionsTable
							promotions={promotions}
							handleSelectedKey={handleSelectedKey}
							handlePromotionDelete={handlePromotionDelete}
							toggleModal={toggleModal}
						/>
					)}
				</Card>
				<Modal
					isOpen={isModalOpen}
					onRequestClose={() => toggleModal(false)}
					style={ModalStyle}
					shouldCloseOnOverlayClick={false}
				>
					<PromotionsForm
						toggleModal={toggleModal}
						handleDelete={handleDelete}
					/>
				</Modal>
			</>
		);
	}
}

const mapStateToProps = state => ({
	promotions: state.promotions.data ?? [],
	isLoading: state.promotions.isFetching,
});

const mapDispatchToProps = dispatch => {
	return {
		fetchPromotions: () => dispatch(fetchAllPromotions()),
		handleSelectedKey: id => dispatch(setSelectedPromotion(id)),
		handlePromotionDelete: id => dispatch(deletePromotion(id)),
	};
};

export default withBrandConfig(
	connect(mapStateToProps, mapDispatchToProps)(Promotions),
);
