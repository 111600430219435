import React, { forwardRef, useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { useFormik } from 'formik';
import { FiEdit, FiX } from 'react-icons/fi';
import { AiFillDelete } from 'react-icons/ai';
import { TiTick } from 'react-icons/ti';

import { partialApply } from '../../../../helpers/pureFunc';
import styled from '../../../../common/styled';
import {
	Holder,
	LabelWithError,
	Input,
	Select,
} from '../../../shared/FormElements';
import { StyledButtonInverse } from '../../../shared/Navs';
import { Table, Thead, Tbody, Th, Tr, Td } from '../../../shared/Table';
import ToggleSwitch from '../../../shared/ToggleSwitch';
import {
	footerGroupInterface,
	footerItemInterface,
} from './FooterContentGroups';
import { footerItemValidator } from '../FooterValidation';
import useCombinedRefs from '../../../../hooks/useCombineRef';

const pageSlugInGroupItemsIndex = (
	group: footerGroupInterface,
	target: footerItemInterface & { id: string },
) =>
	group
		? group.Items.findIndex((item) => item.slug === target.slug)
		: undefined;

function findGroupWithSlug(
	gs: footerGroupInterface[],
	recommendation: footerItemInterface & { id: string },
): footerGroupInterface | undefined {
	const [ga, ...gbs] = gs;
	if (!ga) return undefined;
	const slugIndex = pageSlugInGroupItemsIndex(ga, recommendation);
	if (slugIndex >= 0) return ga;
	else if (gbs.length === 0) return void 0;

	return findGroupWithSlug(gbs, recommendation);
}

interface FooterContentItemsEditorProps {
	setGroups: (input: footerGroupInterface[]) => void;
	children: {
		contentPages: (footerItemInterface & { id: string })[];
		compactLayout: boolean;
		groups: footerGroupInterface[];
	};
}

const mapPendingGroupUpdateToGroupInterfaceArray = (
	groups: footerGroupInterface[],
	pendingGroupsUpdate: (footerItemInterface & { groupName?: string })[],
) => {
	if (pendingGroupsUpdate) {
		const newGroupInterfaces: footerGroupInterface[] = pendingGroupsUpdate.reduce(
			(acc, next) => {
				const newAcc = [...acc];
				const existingItem = newAcc.find(
					(group) => group.groupName === next.groupName,
				);
				if (existingItem) {
					existingItem.Items.push(next);
				} else if (!!next.groupName) {
					// if group doesn't exist and groupName isn't blank
					newAcc.push({ groupName: next.groupName, Items: [next] });
				}
				return newAcc;
			},
			groups.map((group) => ({ ...group, Items: [] })),
		);
		return newGroupInterfaces;
	}
};

const FooterContentItemsEditor = ({
	children: { compactLayout, groups, contentPages },
	setGroups,
}: FooterContentItemsEditorProps) => {
	const PagesAsFooterItemsWithGroup = contentPages.map((page) => {
		const group = findGroupWithSlug(groups, page);
		return { ...page, groupName: group?.groupName };
	});

	const FooterItemsExternalLinksWithGroups: (footerItemInterface & {
		id?: string;
		groupName?: string;
	})[] = groups
		.flatMap<footerItemInterface & { id?: string; groupName?: string }>(
			(group) =>
				group.Items.map((footerItem) => ({
					...footerItem,
					groupName: group.groupName,
				})),
		)
		.filter((item) => !item.slug);

	const AllFooterItemsWithGroupName = [
		...FooterItemsExternalLinksWithGroups,
		...PagesAsFooterItemsWithGroup,
	];

	const [pendingGroupsUpdate, setPendingGroupsUpdate] = useState<
		typeof AllFooterItemsWithGroupName | null
	>(null);

	/**
	 * itemInEdit is the index of footerItem being edited.
	 * -1 represents new Item in edit
	 * less than -1 is non in edit
	 */
	const [itemInEdit, setItemInEdit] = useState<number>(-2);

	useEffect(() => {
		if (pendingGroupsUpdate) {
			const newGroups = mapPendingGroupUpdateToGroupInterfaceArray(
				groups,
				pendingGroupsUpdate,
			);
			setPendingGroupsUpdate(null);
			setGroups(newGroups);
		}
	}, [pendingGroupsUpdate, setGroups, groups]);

	useEffect(() => {
		// groups and compactLayout has changed,
		setItemInEdit(-2);
	}, [groups, compactLayout]);

	const deleteItemAtIndex = (index) =>
		// is a Content Link (with slug) or window.Content
		(AllFooterItemsWithGroupName[index].slug ||
			window.confirm('Are you sure you want to delete this Item')) &&
		setPendingGroupsUpdate(
			AllFooterItemsWithGroupName.filter((_value, i) => index !== i),
		);

	const updateItemAtIndex = (index: number, update: footerItemInterface) => {
		const fullUpdate = [...AllFooterItemsWithGroupName];
		fullUpdate[index] = update;
		setPendingGroupsUpdate(fullUpdate);
	};

	const addItem = (newItem: footerItemInterface & { groupName: string }) => {
		const newFooterItemsWithGroup = [...AllFooterItemsWithGroupName, newItem];

		setPendingGroupsUpdate(newFooterItemsWithGroup);
	};

	const initialNewItem = { title: '', href: '', groupName: 'Links' };
	const [newItemState, setNewItemState] = useState(initialNewItem);
	const WidthTable = styled(Table)`
		table-layout: fixed;
	`;
	return (
		<Holder width="100%">
			<LabelWithError title="Pages" htmlFor="" error="">
				<WidthTable>
					<Thead>
						<tr>
							<Th>Title</Th>
							<Th>Link</Th>
							<Th>Type</Th>
							{<Th>{compactLayout ? 'Visibility' : 'Group'}</Th>}
							<Th>Actions</Th>
						</tr>
					</Thead>
					<Tbody>
						{AllFooterItemsWithGroupName.map((page, index) => {
							const setEditMode = (request: boolean) =>
								setItemInEdit(request ? index : -2);
							const deleteSelf = partialApply(deleteItemAtIndex, index);
							const updateSelf = partialApply(updateItemAtIndex, index);

							return (
								<ItemRow
									key={index}
									editMode={itemInEdit === index}
									setEditMode={setEditMode}
									deleteSelf={deleteSelf}
									updateSelf={updateSelf}
									FormId={`form_${index}`}
								>
									{{
										Item: page,
										compactLayout,
										groups,
									}}
								</ItemRow>
							);
						})}
						<ItemRow
							key={-1}
							editMode={itemInEdit === -1}
							setEditMode={(request) => setItemInEdit(request ? -1 : -2)}
							deleteSelf={partialApply(setNewItemState, initialNewItem)}
							updateSelf={partialApply(addItem)}
							FormId="form_NEW"
						>
							{{
								Item: newItemState,
								compactLayout,
								groups,
							}}
						</ItemRow>
					</Tbody>
				</WidthTable>
			</LabelWithError>
		</Holder>
	);
};

interface ItemRowProps {
	setEditMode: (input: boolean) => void;
	editMode: boolean;
	updateSelf: (
		updates: footerItemInterface & { id?: string; groupName?: string },
	) => void;
	deleteSelf?: () => void;
	children: {
		Item: footerItemInterface & { id?: string; groupName?: string };
		compactLayout: boolean;
		groups: footerGroupInterface[];
	};
	FormId: string;
}

const StyledActionRow = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
	column-gap: 4px;
	& > * {
		padding: ${({ theme }) => theme.spacings.compact}px;
		cursor: pointer;
	}
`;

const ItemRow = ({
	editMode,
	setEditMode,
	updateSelf,
	deleteSelf,
	children: { Item, compactLayout, groups },
	FormId,
}: ItemRowProps) => {
	const firstInputFieldRef = useRef<HTMLInputElement>();
	const formik = useFormik({
		onSubmit: (values) => {
			updateSelf(values);
			setEditMode(false);
		},
		initialValues: Item,
		validationSchema: footerItemValidator,
		validateOnMount: true,
	});
	const resetForm = formik.resetForm;
	useEffect(() => {
		resetForm({ values: Item });
	}, [Item, editMode, resetForm]);

	const isExternalLink = !!Item.href || !Item.slug;

	const handleDelete = (e) => {
		e.preventDefault();
		deleteSelf();
	};
	const handleEdit = (e) => {
		e.preventDefault();
		setEditMode(true);
	};
	const handleToggleGroup = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (isExternalLink) {
			return;
		}

		const newGroupName = e.target.checked
			? formik.initialValues.groupName || 'Links'
			: undefined;
		formik.setFieldValue('groupName', newGroupName);
		if (!editMode) formik.submitForm();
	};
	useEffect(() => {
		if (editMode && firstInputFieldRef.current) {
			firstInputFieldRef.current.focus();
		}
	}, [editMode]);

	return (
		<Tr onDoubleClick={partialApply(setEditMode, true)}>
			{FormId === 'form_NEW' && !editMode ? (
				<Td colSpan={5}>
					<Link
						to="#"
						onClick={(e) => {
							e.preventDefault();
							setEditMode(true);
						}}
					>
						Add Custom Link
					</Link>
				</Td>
			) : (
				<>
					<Td>
						<form id={FormId} onSubmit={formik.handleSubmit}>
							<Inputer
								form={FormId}
								editMode={editMode}
								isExternalLink={isExternalLink}
								value={formik.values.title}
								id="title"
								name="title"
								placeholder="Enter a Title"
								onChange={formik.handleChange}
								error={formik.errors.title}
								handleEnter={formik.submitForm}
								handleEscape={partialApply(setEditMode, false)}
								ref={firstInputFieldRef}
							/>
						</form>
					</Td>

					<Td>
						<Inputer
							form={FormId}
							editMode={editMode}
							isExternalLink={isExternalLink}
							value={isExternalLink ? formik.values.href : formik.values.slug}
							id={isExternalLink ? 'href' : 'slug'}
							name={isExternalLink ? 'href' : 'slug'}
							placeholder={
								isExternalLink ? 'Enter a valid URL' : 'Enter a valid Slug'
							}
							leadingChar={isExternalLink ? '' : '/'}
							onChange={formik.handleChange}
							error={isExternalLink ? formik.errors.href : formik.errors.slug}
							handleEnter={formik.submitForm}
							handleEscape={partialApply(setEditMode, false)}
						/>
					</Td>
					<Td width="min-content">{isExternalLink ? 'Link' : 'Page'}</Td>
					<Td width="min-content">
						{compactLayout ? (
							<>
								<ToggleSwitch
									title="groupName"
									id={'groupName' + FormId}
									checked={!!formik.values.groupName}
									onChange={handleToggleGroup}
								/>
							</>
						) : editMode ? (
							<LabelWithError
								htmlFor="groupName"
								title=""
								error={formik.errors.groupName}
								hint={groups.length === 0 && 'HINT: create a group first'}
							>
								<Select
									value={formik.values.groupName || ''}
									onChange={(e) =>
										formik.setFieldValue('groupName', e.target.value)
									}
								>
									{groups.map(({ groupName }) => (
										<option key={groupName} value={groupName}>
											{groupName}
										</option>
									))}
									{!isExternalLink && <option value="">HIDDEN</option>}
								</Select>
							</LabelWithError>
						) : (
							formik.values.groupName || 'n/a'
						)}
					</Td>
					<Td width="min-content">
						<StyledActionRow>
							<>
								<StyledButtonInverseVisibility
									isactive={true}
									type={editMode ? 'submit' : 'button'}
									form={FormId}
									disabled={editMode && !formik.isValid}
									onClick={editMode ? null : handleEdit}
								>
									{editMode ? <TiTick /> : <FiEdit />}
								</StyledButtonInverseVisibility>
								<StyledButtonInverseVisibility
									isactive={false}
									type="button"
									onClick={
										editMode ? partialApply(setEditMode, false) : handleDelete
									}
									disabled={
										!editMode ? !(formik.values.groupName && deleteSelf) : false
									}
								>
									{editMode ? <FiX /> : <AiFillDelete />}
								</StyledButtonInverseVisibility>
							</>
						</StyledActionRow>
					</Td>
				</>
			)}
		</Tr>
	);
};

const StyledButtonInverseVisibility = styled(StyledButtonInverse)<{
	visibility?: 'hidden' | 'unset';
}>`
	visibility: ${({ visibility }) => visibility || 'unset'};
	color: white;
	display: flex;
	justify-content: center;

	svg {
		margin-left: 0px;
	}
`;
interface InputerProps {
	editMode: boolean;
	isExternalLink: boolean;
	value: string;
	id: string;
	name: string;
	placeholder: string;
	leadingChar?: string;
	form: string;
	onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
	error?: string;
	handleEscape?: () => void;
	handleEnter?: () => void;
}

const StyledInputer = styled(LabelWithError)``;

const Inputer = forwardRef<HTMLInputElement, InputerProps>(
	(
		{
			editMode,
			isExternalLink,
			value,
			id,
			name,
			placeholder,
			leadingChar,
			form,
			onChange,
			error = '',
			handleEscape,
			handleEnter,
		},
		ref,
	) => {
		const innerRef = useRef<HTMLInputElement>(null);
		const combinedRef = useCombinedRefs<HTMLInputElement>(ref, innerRef);

		useEffect(() => {
			const combineRefCurrent = combinedRef.current;
			const keyHandler = (e: KeyboardEvent) => {
				switch (e.key) {
					case 'Enter':
						e.preventDefault();
						handleEnter?.();
						break;
					case 'Escape':
					case 'Esc':
						e.preventDefault();
						handleEscape?.();
						break;
					default:
				}
			};
			combineRefCurrent?.addEventListener?.('keydown', keyHandler);
			return () =>
				combineRefCurrent?.removeEventListener?.('keydown', keyHandler);
		}, [handleEscape, handleEnter, combinedRef]);
		const SpanWithPadding = styled.span`
			padding: ${({ theme }) => theme.spacings.compact}px;
		`;
		const handleInputClicked = () => {
			if (!isExternalLink) {
				alert('Title and Location of Pages must be edited in Pages');
			}
		};

		return (
			<StyledInputer title={''} error="" htmlFor={name} hint="">
				{editMode ? (
					<Input
						ref={combinedRef}
						form={form}
						value={value}
						id={id}
						name={name}
						placeholder={placeholder}
						onChange={onChange}
						disabled={!isExternalLink}
						onClick={handleInputClicked}
					/>
				) : (
					<SpanWithPadding>
						{leadingChar}
						{value}
					</SpanWithPadding>
				)}
			</StyledInputer>
		);
	},
);

export default FooterContentItemsEditor;
