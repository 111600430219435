import { createSlice } from '@reduxjs/toolkit';
import {
	BrandNameType,
	FALL_OVER_BRAND,
} from '../context/brandConfig/brandConfig';

export interface ApplicationInterface {
	brand?: BrandDisplayed;
}

type BrandDisplayed = BrandNameType | typeof FALL_OVER_BRAND;
interface PayloadInterface {
	payload: BrandDisplayed;
}

type SetBrandFunction = (state: ApplicationInterface, PayloadInterface) => void;

const { actions, reducer } = createSlice<
	ApplicationInterface,
	{
		setBrand: SetBrandFunction;
	},
	'setBrand'
>({
	name: 'setBrand',
	initialState: {
		brand: FALL_OVER_BRAND,
	},
	reducers: {
		setBrand(state: ApplicationInterface, { payload }: PayloadInterface) {
			state.brand = payload;
		},
	},
});

export const { setBrand } = actions;

export default reducer;
