import axios from 'axios';
import { useBrandConfig } from '../../context/brandConfig/brandConfigContext';
import useAuthHeader from '../../hooks/useAuthHeader';

const useBoostHookAPI = () => {
	const { brandConfig } = useBrandConfig();
	const AuthHeader = useAuthHeader();

	/**
	 * using this API to submit formdata
	 *
	 * @param data
	 * @returns
	 */
	const saveBoostPromotion = (data: any, id: any = null) => {
		return axios({
			url: `${
				brandConfig.bettingEngineLegacyApi
			}/brandmanager/1/promotions/${id ?? ''}`,
			method: 'POST',
			headers: {
				'Content-Type': 'multipart/form-data',
				...AuthHeader,
			},
			data: data,
		})
			.then(res => res.data)
			.catch(res => res);
	};

	/**
	 * use this API to update price manual boost
	 *
	 * @param promotionId
	 * @returns
	 */
	const instantUpdate = (promotionId: number) => {
		return axios({
			url: `${brandConfig.bettingEngineLegacyApi}/brandmanager/1/promotions/${promotionId}/boost`,
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				...AuthHeader,
			},
		})
			.then(res => res.data)
			.catch(res => res);
	};

	/**
	 * get boost types
	 *
	 * @returns
	 */
	const getBoostTypes = (type: string) => {
		return axios({
			url: `${brandConfig.bettingEngineLegacyApi}/brandmanager/1/promotion/boost/types?type=${type}`,
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				...AuthHeader,
			},
		})
			.then(res => res.data.data)
			.catch(res => res);
	};

	/**
	 *
	 * @param id
	 * @returns
	 */
	const getPriceLadder = (id: number, page: number = 1) => {
		return axios({
			url: `${brandConfig.bettingEngineLegacyApi}/brandmanager/1/promotions/${id}/price-ladder?page=${page}`,
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				...AuthHeader,
			},
		})
			.then(res => res.data)
			.catch(res => res);
	};

	/**
	 * use this API to disable price manual boost
	 *
	 * @param promotionId
	 * @returns
	 */
	const disableInstantBoost = (promotionId: number) => {
		return axios({
			url: `${brandConfig.bettingEngineLegacyApi}/brandmanager/1/promotions/${promotionId}/remove-boost`,
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				...AuthHeader,
			},
		})
			.then(res => res.data)
			.catch(res => res);
	};

	/**
	 * need to convert all data to supportable way to submit form data
	 *
	 * @param formikData
	 * @returns
	 */
	const arrangeFormData = (formikData: any, id: any = null) => {
		let formData = new FormData();

		Object.entries(formikData).forEach(([key, value]) => {
			// checking value is an Array
			if (Array.isArray(value)) {
				value.forEach(function(data) {
					formData.append(`${key}[]`, data.toString());
				});
			}

			// checking value is boolean
			if (typeof value === 'boolean') {
				formData.append(key, value ? '1' : '0');
			}
			//checking value is number
			if (Number.isInteger(value)) {
				formData.append(key, value.toString());
			}
			if (typeof value === 'string' || value instanceof Blob) {
				formData.append(key, value);
			}
		});
		if (id) {
			formData.append('_method', 'PUT');
		}
		return formData;
	};

	return {
		saveBoostPromotion,
		instantUpdate,
		getBoostTypes,
		getPriceLadder,
		disableInstantBoost,
		arrangeFormData,
	};
};

export default useBoostHookAPI;
