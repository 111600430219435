import {
	MutableRefObject,
	useCallback,
	useLayoutEffect,
	useReducer,
} from 'react';
import PromotionEngineAPI from '../../helpers/promotionEngine/promotionEngineAPI';
import { promotionEngineClassEvents } from '../../helpers/promotionEngine/promotionEngineInterfaces';

//| MutableRefObject<PromotionEngineAPI>
type usePromotionEngineType = (
	APIHandler: MutableRefObject<PromotionEngineAPI>,
) => usePromotionReturnType;

interface usePromotionReturnType {
	PromotionEngineListPageData?: PromotionEngineAPI['PromotionEngineListPageData'];
	underMutation: boolean;
	APIHandler: PromotionEngineAPI;
	clearState: () => void;
}

const usePromotionEngine: usePromotionEngineType = APIHandler => {
	const internalHandler = APIHandler.current;

	const initialState: Omit<
		usePromotionReturnType,
		'APIHandler' | 'clearState'
	> = {
		PromotionEngineListPageData: internalHandler?.PromotionEngineListPageData,
		underMutation: false,
	};

	function reducer(
		state: Omit<usePromotionReturnType, 'APIHandler'>,
		action: { type: string; data: any },
	) {
		switch (action.type) {
			case 'reset':
				return { ...initialState };
			case promotionEngineClassEvents.update:
			case promotionEngineClassEvents.fetch:
				return {
					...state,
					PromotionEngineListPageData: action.data,
				};
			case promotionEngineClassEvents.mutationStart:
				return { ...state, underMutation: true };
			case promotionEngineClassEvents.mutationFinish:
				return { ...state, underMutation: false };
			default:
				return state;
		}
	}

	const [state, dispatch] = useReducer<typeof reducer>(reducer, initialState);

	useLayoutEffect(() => {
		if (!internalHandler) return;

		const APIEventListener = (
			eName: promotionEngineClassEvents,
			data: any,
			_handler: PromotionEngineAPI,
		) => {
			dispatch({ type: eName, data });
		};

		// subscribe to future events
		const events = Object.values(promotionEngineClassEvents);
		internalHandler.subscribe(events, APIEventListener);

		return () => internalHandler.unsubscribe(events, APIEventListener);
	}, [internalHandler]);

	return {
		...state,
		APIHandler: internalHandler,
		clearState: useCallback(() => {
			dispatch({ type: 'reset', data: {} });
		}, []),
	};
};

export default usePromotionEngine;
