import React from 'react';
import { useFormik, FormikContext } from 'formik';
import DatePicker from 'react-datepicker';
import { FiSave, FiX } from 'react-icons/fi';
import moment from 'moment';

import { quickLinksValidation } from '../../common/validations';
import { partialApply } from '../../helpers/pureFunc';
import { icons } from '../../common/icons';

import {
	LabelWithError,
	Input,
	Select,
	CheckboxLabel,
	CheckboxGroup,
	StyledCheckbox,
} from '../shared/FormElements';
import { NavGroup, StyledButton } from '../shared/Navs';
import PageHeader from '../shared/PageHeader';
import Card from '../shared/Card';
import MobileRouteEditor, {
	cleanMobileRoute,
} from '../mobileRoute/mobileRouteEditor';

import 'react-datepicker/dist/react-datepicker.css';
import { quicklinkInterface } from '../../helpers/quickLinkEndpoints';

const locations = [
	{ title: 'Homepage', value: 'homepage-racing' },
	{ title: 'Racing', value: 'racing' },
	{ title: 'Sports', value: 'sports' },
	{ title: 'Left Navigation', value: 'left-nav' },
];

const handleDateSubmit = (setFieldValue, date) =>
	void setFieldValue('expiry', date, true);

interface QuickLinkFormProps {
	closeModal: () => void;
	initialValues: quicklinkInterface;
	isEditing: boolean;
	addQuickLink: (input: quicklinkInterface) => Promise<void>;
	updateQuickLink: (input: quicklinkInterface) => Promise<void>;
}

const QuickLinksForm = ({
	closeModal,
	initialValues,
	isEditing,
	addQuickLink,
	updateQuickLink,
}: QuickLinkFormProps) => {
	const handleOnSubmit = (values: quicklinkInterface) => {
		const expiryDate = values.expiry
			? moment(values.expiry).format('YYYY-MM-DD HH:mm')
			: null;

		const mobileRoute =
			(values.mobileRoute && cleanMobileRoute(values.mobileRoute)) || null;

		const newValues = {
			...values,
			expiry: expiryDate,
			mobileRoute,
		};

		if (isEditing) updateQuickLink(newValues);
		else addQuickLink(newValues);

		closeModal();
	};

	const formik = useFormik<quicklinkInterface>({
		initialValues: initialValues,
		validationSchema: quickLinksValidation,
		validateOnMount: true,
		onSubmit: handleOnSubmit,
	});

	const {
		values,
		setFieldValue,
		handleBlur,
		handleSubmit,
		handleChange,
		errors,
		touched,
		isValid,
		isValidating,
		isSubmitting,
	} = formik;

	return (
		<Card isModal>
			<FormikContext.Provider value={formik}>
				<form onSubmit={handleSubmit}>
					<PageHeader title={isEditing ? 'Edit QuickLink' : 'Create Quicklink'}>
						<NavGroup>
							<StyledButton
								isactive={true}
								type="submit"
								disabled={!isValid || isValidating || isSubmitting}
							>
								Save <FiSave />
							</StyledButton>
							<StyledButton onClick={closeModal}>
								Close <FiX />
							</StyledButton>
						</NavGroup>
					</PageHeader>
					<LabelWithError
						htmlFor="title"
						title="Title"
						error={touched.title && errors.title}
					>
						<Input
							name="title"
							value={values.title}
							onChange={handleChange}
							onBlur={handleBlur}
							isError={!!(touched.title && errors.title)}
							type="text"
							disabled={values.disabled}
						/>
					</LabelWithError>
					<LabelWithError
						htmlFor="route"
						title="Link to target event/page"
						error={(touched.route || values.route) && errors.route}
						hint="example valid URL https://yoursite.com/racing and example absolute path /racing "
					>
						<Input
							name="route"
							value={values.route}
							onChange={handleChange}
							onBlur={handleBlur}
							isError={!!((touched.route || values.route) && errors.route)}
							type="text"
							disabled={values.disabled}
							placeholder={
								'e.g. https://yoursite.com.au/racing/gallops/randwick/race-4-1350539-1234670'
							}
						/>
					</LabelWithError>
					<LabelWithError
						htmlFor="expiry"
						title="Expiry Date"
						error={errors.expiry}
						hint={'Leave blank for no expiry date'}
					>
						<DatePicker
							selected={values.expiry}
							onChange={partialApply(handleDateSubmit, setFieldValue)}
							showTimeSelect
							popperPlacement="top-start"
							timeFormat="HH:mm"
							timeIntervals={15}
							timeCaption="time"
							dateFormat="MMM d, yyyy h:mm aa"
							showPopperArrow={false}
							placeholderText="Select a date and time"
							isClearable
						/>
					</LabelWithError>
					<LabelWithError
						htmlFor="icon"
						title="Icon"
						error={errors.icon}
						hint={'Required'}
					>
						<Select
							name="icon"
							value={values.icon}
							onChange={handleChange}
							onBlur={handleBlur}
							isError={!!errors.icon}
							disabled={!!values.disabled}
						>
							<option value="" label="None" />
							{icons.map((icon) => (
								<option
									key={icon.value}
									value={icon.value}
									label={icon.label}
								/>
							))}
						</Select>
					</LabelWithError>
					<LabelWithError
						title="Location"
						error={
							touched.location &&
							(Array.isArray(errors.location)
								? errors.location.join(',')
								: errors.location)
						}
						htmlFor="location"
					>
						<CheckboxGroup role="group" aria-labelledby="checkbox-group">
							{locations.map((l) => (
								<CheckboxLabel key={l.value}>
									<StyledCheckbox
										type="checkbox"
										name="location"
										value={l.value}
									/>
									{l.title}
								</CheckboxLabel>
							))}
						</CheckboxGroup>
					</LabelWithError>

					<MobileRouteEditor
						values={values.mobileRoute}
						errors={errors.mobileRoute}
						setField={(fieldName, value) =>
							void setFieldValue(`mobileRoute.${fieldName}`, value)
						}
						touched={touched.mobileRoute}
						disabled={values.disabled}
					/>
					<LabelWithError
						htmlFor={values.authentication_status}
						title="Display"
						error={
							touched.authentication_status && errors.authentication_status
						}
					>
						<Select
							name="authentication_status"
							className="thin"
							value={values.authentication_status}
							//@ts-ignore
							onChange={handleChange}
							onBlur={handleBlur}
							isError={false}
						>
							<option value="signed_in" label="Logged In Users" />
							<option value="signed_out" label="Guests Only" />
							<option value="any" label="All" />
						</Select>
					</LabelWithError>
				</form>
			</FormikContext.Provider>
		</Card>
	);
};

export default QuickLinksForm;
