import React, { useEffect, useRef } from 'react';
import { partialApply } from '../../../../helpers/pureFunc';
import { PartnerDataFull } from './ApprovedPartnerIcons';
import { PaymentMethodsData } from './PaymentMethodIcons';
import { configItemProp } from '../index';
import styled from '../../../../common/styled';
import ImageFromConfig from './IconFromConfig';
import { LabelWithError } from '../../../shared/FormElements';
import ToggleSwitch from '../../../shared/ToggleSwitch';

export type ThirdPartyConfig = {
	code: string;
	type: string;
	encoding: string;
	data: any;
}[];

const StyledFooterPromotedThirdPartiesEditor = styled.div`
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: center;
	background: ${({ theme }) => theme.brand.color_4_dark};
	padding: ${({ theme }) => theme.spacings.compact}px;
`;

const StyledFooterIconContainer = styled.div<{ disabled?: boolean }>`
	flex: 1 0 200px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: ${({ theme }) => theme.spacings.cozy}px;
	margin: ${({ theme }) => theme.spacings.cozy}px;
	border-radius: ${({ theme }) => {
		const rad = theme.application.border_radius_2;
		return typeof rad === 'number' ? rad + 'px' : rad;
	}};
	background: ${({ disabled: dontShowMe }) => (dontShowMe ? 'unset' : 'white')};
	& > * {
		margin: ${({ theme }) => theme.spacings.tight}px;
	}
`;

const StyledScreenReadOnlySelect = styled.select`
	/* The Following hides it from user but not screenreaders */
	position: absolute;
	width: 1px;
	height: 1px;
	padding: 0;
	margin: -1px;
	overflow: hidden;
	clip: rect(0, 0, 0, 0);
	white-space: nowrap;
	border-width: 0;
`;

interface FooterPromotedThirdPartiesEditorProps extends configItemProp {
	Items: string[];
}
const FooterPromotedThirdPartiesEditor = (config: ThirdPartyConfig) => {
	const Element = ({
		Items,
		setField,
	}: FooterPromotedThirdPartiesEditorProps) => {
		const screenReadOnlySelectRef = useRef(null);
		function repeat(
			item: React.ReactNode | ((count: number) => React.ReactNode),
			count: number,
		) {
			if (count <= 0) return [];
			const renderedItem = typeof item === 'function' ? item(count) : item;
			return [renderedItem, ...repeat(item, count - 1)];
		}
		const fillerEmptySlots = repeat(
			(key) => <StyledFooterIconContainer disabled key={key + 1000} />,
			4 - (config.length % 4),
		);

		const toggleItemByCode = (
			code: string,
			e: React.ChangeEvent<HTMLInputElement> | null = null,
		) => {
			let checked = e?.target?.checked ?? !Items.includes(code);
			const newItemsSet = new Set(Items);
			if (checked) newItemsSet.add(code);
			else newItemsSet.delete(code);

			setField(['Items'], Array.from(newItemsSet), true);
		};

		useEffect(() => {
			if (screenReadOnlySelectRef.current) {
				try {
					const options = Array.from(
						(screenReadOnlySelectRef.current as HTMLSelectElement).options,
					);
					options.forEach(
						(option) => (option.selected = Items.includes(option.value)),
					);
				} catch {}
			}
		}, [Items]);

		const handleScreenReaderChange = (
			e: React.ChangeEvent<HTMLSelectElement>,
		) => {
			const SelItems = Array.from(e.target.selectedOptions).map(
				(option) => option.value,
			);
			setField(['Items'], SelItems, true);
		};

		return (
			<LabelWithError title="Selected Items" error="" hint="" htmlFor="">
				<StyledScreenReadOnlySelect
					name="selectedIcons"
					id="selectedIcons"
					multiple
					onChange={handleScreenReaderChange}
					ref={screenReadOnlySelectRef}
				>
					{config.map(({ code }, index) => (
						<option key={`${code}_${index}`} value={code}>
							{code}
						</option>
					))}
				</StyledScreenReadOnlySelect>
				<StyledFooterPromotedThirdPartiesEditor id="">
					{config.map((configItem) => {
						const { data, encoding, code, type } = configItem;
						const id = 'toogle_' + code;
						return (
							<StyledFooterIconContainer key={code}>
								<ImageFromConfig
									aspectRatio={0.5}
									data={data}
									encoding={encoding}
									type={type}
									w={80}
								/>
								<ToggleSwitch
									title={id}
									id={id}
									checked={Items.includes(code)}
									onChange={partialApply(toggleItemByCode, code)}
								/>
							</StyledFooterIconContainer>
						);
					})}
					{fillerEmptySlots}
				</StyledFooterPromotedThirdPartiesEditor>
			</LabelWithError>
		);
	};

	return Element;
};

export const PromotedPaymentMethodEditor = FooterPromotedThirdPartiesEditor(
	PaymentMethodsData,
);

export const PromotedWageringProvider = FooterPromotedThirdPartiesEditor(
	PartnerDataFull,
);
