import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { useTheme } from 'emotion-theming';

import Card from '../components/shared/Card';
import { connect } from 'react-redux';
import { Divider } from '../components/shared/FormElements';
import PageHeader from '../components/shared/PageHeader';
import { NavGroup, StyledButton } from '../components/shared/Navs';
import { ModalStyle } from '../common/modalStyle';
import { FiFile, FiHelpCircle } from 'react-icons/fi';
import Loading from 'react-loading-bar';
import { useBrandConfig } from '../context/brandConfig/brandConfigContext';

import {
	fetchAllHighlights,
	createHighlight,
	deleteHighlight,
	setEditingHighlight,
	clearEditingHighlight,
	updateHighlight,
} from '../store/highlights';

import HighlightsForm from '../components/highlights/HighlightsForm';
import HighlightItem from '../components/highlights/HighlightItem';
import HighlightsHelp from '../components/highlights/HighlightsHelp';

const Highlights = ({
	allHighlights,
	fetchHighlights,
	addHighlight,
	removeHighlight,
	isLoading,
	setHighlightEdit,
	clearHighlightEdit,
	editingHighlight,
	changeHighlight,
}) => {
	const [isModalOpen, setModalState] = useState(false);
	const [isHelpModalOpen, setHelpModal] = useState(false);
	const [fetchLoading, setFetchLoading] = useState(true);

	const theme = useTheme();
	const brandConfig = useBrandConfig();

	useEffect(() => {
		setFetchLoading(true);
		fetchHighlights().then(() => setFetchLoading(false));
	}, [fetchHighlights, brandConfig, setFetchLoading]);

	const closeModal = (type) => {
		if (type === 'form') {
			setModalState(false);
			clearHighlightEdit();
		} else if (type === 'help') {
			setHelpModal(false);
		}
	};

	const initialFormValues = editingHighlight || {
		title: '',
		expiryDate: new Date(),
		iconKey: '',
		link: '',
		visible: true,
		hasIcon: false,
	};

	const noItems = allHighlights === 'No items';

	return (
		<>
			<Card>
				<Loading
					show={isLoading}
					color={theme.brand.color_1}
					showSpinner={false}
				/>
				<PageHeader title="Highlighted Events">
					<NavGroup>
						<StyledButton
							isactive="false"
							onClick={(e) => {
								setHelpModal(true);
							}}
						>
							Help <FiHelpCircle />
						</StyledButton>
						<StyledButton
							isactive="true"
							onClick={(e) => {
								setModalState(true);
							}}
						>
							New Highlight <FiFile />
						</StyledButton>
					</NavGroup>
				</PageHeader>
				<Divider />

				{noItems && !fetchLoading && <p>No highlighted events created</p>}
				{!noItems &&
					!fetchLoading &&
					allHighlights &&
					allHighlights.map((h) => {
						return (
							<HighlightItem
								key={h.id}
								highlight={h}
								handleDelete={() => removeHighlight(h.id)}
								setHighlightEdit={setHighlightEdit}
								openModal={() => setModalState(true)}
								updateHighlight={changeHighlight}
							/>
						);
					})}

				<Modal
					isOpen={isModalOpen}
					onRequestClose={() => closeModal('form')}
					style={ModalStyle}
					shouldCloseOnOverlayClick={false}
				>
					<HighlightsForm
						closeModal={() => closeModal('form')}
						addHighlight={addHighlight}
						initialValues={initialFormValues}
						isEditing={editingHighlight ? true : false}
						updateHighlight={changeHighlight}
					/>
				</Modal>
				<Modal
					isOpen={isHelpModalOpen}
					onRequestClose={() => closeModal('help')}
					style={ModalStyle}
					shouldCloseOnOverlayClick={true}
				>
					<HighlightsHelp closeModal={() => closeModal('help')} />
				</Modal>
			</Card>
		</>
	);
};

const mapStateToProps = (state) => {
	return {
		allHighlights: state.highlights.allHighlights,
		isLoading: state.highlights.isFetching,
		editingHighlight: state.highlights.editingHighlight,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		fetchHighlights: () => dispatch(fetchAllHighlights()),
		addHighlight: (newHighlight) => dispatch(createHighlight(newHighlight)),
		removeHighlight: (id) => dispatch(deleteHighlight(id)),
		changeHighlight: (updatedHighlight) =>
			dispatch(updateHighlight(updatedHighlight)),
		setHighlightEdit: (highlight) => dispatch(setEditingHighlight(highlight)),
		clearHighlightEdit: () => dispatch(clearEditingHighlight()),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Highlights);
