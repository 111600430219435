import { AxiosResponse, AxiosInstance, AxiosError } from 'axios';
import { PromotionEngineItem } from './common';

export interface promotionsDeleteResponse {
	timestamp: string;
	http_status_code: number;
	data: PromotionEngineItem;
}

function promotionDelete(
	getConfig: () => {
		axiosInstance: AxiosInstance;
	},
	id: number,
): Promise<AxiosResponse<promotionsDeleteResponse> | AxiosError<any>> {
	return getConfig()
		.axiosInstance.delete(`/${id}`)
		.catch((e) => e);
}

export default promotionDelete;
