/**
 *
 * This was built to be compatible with React.useState, however the setState function can take
 * an optional 2nd parameter which if you send in a falsy value, it will save the state but wont cause
 * React to reRender because of it. This is useful if you have to mutate state rapidly and can't want the
 * page to reder many times. i.e. stepping functions working though a large file line by line.
 */

import { useRef } from 'react';
import useForceRender from './useForceRender';

type setStateInput<T> = T | ((input: T) => T);

const isUpdateFunc = <T extends any>(
	input: setStateInput<T>,
): input is (input: T) => T => typeof input === 'function';

const getUpdate = <T extends any>(input: setStateInput<T>, state?: T): T =>
	isUpdateFunc(input) ? input(state) : input;

const useStateOptionalDelayRender = <T extends any>(
	initial: T | (() => T),
): [T, (input: setStateInput<T>, reRender?: boolean) => void] => {
	const forceRender = useForceRender();

	const stateHolder = useRef(getUpdate(initial));

	const setState = (update: setStateInput<T>, reRender: boolean = true) => {
		stateHolder.current = getUpdate(update, stateHolder.current);

		if (reRender) forceRender();
	};
	const state = stateHolder.current;

	return [state, setState];
};

export default useStateOptionalDelayRender;
