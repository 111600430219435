import styled from '../../common/styled';

type textAlign = 'left' | 'center' | 'right';
type justifyContent = 'space-around' | 'space-between' | 'space-evenly';

export const Table = styled('table')`
	width: 100%;
	table-layout: auto;
`;

export const Thead = styled('thead')`
	color: #fff;
	background-color: ${(props) => props.theme.ui.color_black};
`;

export const Tbody = styled(`tbody`)``;

export const Tr = styled<'tr', { bgColor?: string }>('tr')`
	background-color: ${({ bgColor = (props) => props.theme.ui.color_3 }) =>
		bgColor};
`;

export const Th = styled<'th', { textAlign?: textAlign }>('th')`
	font-weight: bold;
	padding: ${(props) => props.theme.spacings.compact}px;
	text-align: ${({ textAlign = 'center' }) => textAlign};
`;

// Optionally hide on smaller displays
export const ThOptional = styled<'th', { textAlign?: textAlign }>('th')`
	font-weight: bold;
	padding: ${(props) => props.theme.spacings.compact}px;
	text-align: ${({ textAlign = 'center' }) => textAlign};

	@media screen and (max-width: 850px) {
		display: none;
	}
`;

export const Td = styled<'td', { textAlign?: textAlign, width?:string|number }>('td')`
	${({width})=>width?`width: ${typeof width === 'number' ? width+'px' : width};`:''}
	padding: ${(props) => props.theme.spacings.compact}px;
	text-align: ${({ textAlign = 'center' }) => textAlign};
`;

// Optionally hide on smaller displays
export const TdOptional = styled<'td', { textAlign?: textAlign }>('td')`
	padding: ${(props) => props.theme.spacings.compact}px;
	text-align: ${({ textAlign = 'center' }) => textAlign};

	@media screen and (max-width: 850px) {
		display: none;
	}
`;

export const Flex = styled<'div', { justifyContent?: justifyContent }>('div')`
	display: flex;
	justify-content: ${({ justifyContent = 'space-evenly' }) => justifyContent};

	@media screen and (max-width: 500px) {
		justify-content: center;
	}
`;

export const TableButton = styled<'button'>('button')`
	display: flex;
	align-items: center;
	justify-content: space-around;
	min-width: 80px;
	border: none;
	background-color: #fff;
	padding: ${(props) => props.theme.spacings.compact}px;
	cursor: pointer;
	width: 8em;
	margin-right: 2px;

	@media screen and (min-width: 499px) and (max-width: 850px) {
		min-width: 50px;
		width: 5em;
		justify-content: space-around;
	}

	@media screen and (max-width: 499px) {
		width: inherit;
		min-width: 35px;
		justify-content: center;
	}
`;
