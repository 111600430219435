import { AxiosResponse, AxiosInstance, AxiosError } from 'axios';
import { PromotionEngineItem } from './common';

export interface promotionsUpdateResponse<T = Record<string, unknown>> {
	timestamp: string;
	http_status_code: number;
	data: T;
}

export interface promotionsUpdateFailResponse<T = Record<string, unknown>> {
	timestamp: string;
	http_status_code: 422;
	errors?: {
		[K in keyof T]?: string[];
	};
}

function promotionUpdate(
	getConfig: () => {
		axiosInstance: AxiosInstance;
	},
	promotion: Partial<PromotionEngineItem>,
): Promise<
	| AxiosResponse<promotionsUpdateResponse>
	| AxiosError<promotionsUpdateFailResponse | any>
> {
	return getConfig()
		.axiosInstance.put(`/${promotion.id}`, promotion)
		.catch((e: AxiosError) => e);
}

export default promotionUpdate;
