import Axios from 'axios';
import * as React from 'react';
import { useBrandConfig } from '../../../context/brandConfig/brandConfigContext';
import { StyledButton } from '../../shared/Navs';
import { ColumnedLabeledArea } from '../DepositMatchFormComponents';
import MultipleSelector from './MultipleSelector';
interface EligibleTerritoriesProps {
	territoriesCallback: any;
	existingTerritories: string[];
	onClose: boolean;
}

const EligibleTerritories: React.FC<EligibleTerritoriesProps> = ({
	territoriesCallback,
	existingTerritories,
	onClose,
}) => {
	const mock = [
		{ label: 'Australia', code: 'AUS' },
		{ label: 'Australian Capital Territory', code: 'ACT' },
		{ label: 'New South Wales', code: 'NSW' },
		{ label: 'New Zealand', code: 'NZ' },
		{ label: 'Northern Territory', code: 'NT' },
		{ label: 'Queensland', code: 'QLD' },
		{ label: 'South Australia', code: 'SA' },
		{ label: 'Tasmania', code: 'TAS' },
		{ label: 'Victoria', code: 'VIC' },
		{ label: 'Western Australia', code: 'WA' },
	];

	const [data, setData] = React.useState(mock);
	const [showSummaryDisplay, setSummaryDisplay] = React.useState(false);
	const [territories, setTerritories] = React.useState<Array<any>>(
		existingTerritories || [],
	);

	const [countryList, setCountryList] = React.useState([]);

	const { brandConfig } = useBrandConfig();
	const { bettingEngineLegacyApi: BETTING_ENGINE_API } = brandConfig;

	const TERRITORIES_ENDPOINT =
		BETTING_ENGINE_API &&
		`${BETTING_ENGINE_API}/brandmanager/1/promotions/territories`;

	React.useEffect(() => {
		async function fetchData() {
			const response = await Axios(TERRITORIES_ENDPOINT);
			setData(response?.data?.data);
		}

		fetchData();
	}, [TERRITORIES_ENDPOINT]);

	React.useEffect(() => {
		async function showSummary() {
			if (territories.length > 0) setSummaryDisplay(true);
			else setSummaryDisplay(false);
		}

		showSummary();
	}, [territories]);

	const handleAddEvent = e => {
		e.preventDefault();

		let list = [];
		if (countryList.length !== 0) {
			countryList.forEach((cun: any) => {
				list.push(cun.code);
			});
		}

		// remove dupplicated values
		let newList = [...territories, ...list].filter(
			(v, i, a) => a.findIndex(v2 => v2 === v) === i,
		);

		setCountryList([]);
		setTerritories(newList);
		territoriesCallback(newList);
	};

	const handleEventRemove = territory => {
		const filterTerritories = territories.filter(e => e !== territory);

		setTerritories([...filterTerritories]);
		territoriesCallback([...filterTerritories]);
	};

	return (
		<div>
			<br />
			<ColumnedLabeledArea
				id="activeArea"
				snapWidth="250px"
				margin="4px"
				label="Select Territories"
			>
				{[
					{
						flexGrow: '1',
						child: (
							<MultipleSelector
								options={data}
								onSelect={items => setCountryList(items)}
								value={countryList}
								onClose={onClose}
								isRace={false}
								placeHolder={''}
							/>
						),
					},
					{
						flexGrow: '3',
						child: (
							<StyledButton onClick={handleAddEvent}>
								Add Territory
							</StyledButton>
						),
					},
				]}
			</ColumnedLabeledArea>

			{showSummaryDisplay &&
				territories?.map(territory => (
					<ColumnedLabeledArea
						id="activeArea"
						snapWidth="250px"
						margin="4px"
						label="Eligible Territory -"
					>
						{[
							{
								flexGrow: '1',
								child: <span>{territory}</span>,
							},
							{
								flexGrow: '3',
								child: (
									<StyledButton
										type="button"
										onClick={() => handleEventRemove(territory)}
									>
										Remove
									</StyledButton>
								),
							},
						]}
					</ColumnedLabeledArea>
				))}
		</div>
	);
};

export default EligibleTerritories;
