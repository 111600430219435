import Axios from 'axios';
import { useBrandConfig } from '../context/brandConfig/brandConfigContext';
import useAuthHeader from './useAuthHeader';

interface presignedReply {
	'Content-Type': string;
	Filename: string;
	Metadata: { [key: string]: boolean | number | string };
	Url: string;
	FutureAccessUrl: string;
}

const useImageUploadUrl = () => {
	const AuthHeader = useAuthHeader();
	const { brandConfig } = useBrandConfig();
	return async (
		folder: string,
		contentType: string,
		Metadata: { [key: string]: string | number | boolean },
	): Promise<presignedReply> => {
		const url = `${brandConfig.brandManagerEndpoint}/media/${folder}`;
		const getUrl = Axios.request({
			method: 'POST',
			url,
			headers: { ...AuthHeader },
			data: {
				'Content-Type': contentType,
				Metadata,
			},
		}).then((result) => {
			if (result.status !== 200) throw Error('Failed to get Presigned Url');

			return result.data.Item as presignedReply;
		});
		return await getUrl;
	};
};

export default useImageUploadUrl;
