import React from 'react';
import hdate from 'human-date';
import styled from '../../common/styled';
import { FaTrashAlt } from 'react-icons/fa';
import { FiEdit } from 'react-icons/fi';

import ToggleSwitch from '../shared/ToggleSwitch';
import { StyledButton } from '../shared/Navs';

const Container = styled('div')`
	label: HighlightItem__Container;
	border: 1px solid #eee;
	background: ${(props) => (props.disabled ? '#e1e1e1' : '#fcfcfc')};
	border-radius: 4px;
	margin-bottom: ${(props) => props.theme.spacings.compact}px;
	box-shadow: ${(props) => props.theme.application.box_shadow};
	transition: all 0.3s;
	padding: ${(props) => props.theme.spacings.cozy}px;
	display: flex;
	justify-content: space-between;
	align-items: center;

	@media screen and (max-width: 700px) {
		flex-direction: column;
	}
`;

const Title = styled('h3')`
	label: HighlightItem__Title;
	margin: 0 ${(props) => props.theme.spacings.cozy}px 0 0;
	display: inline-block;
`;

const DeleteIcon = styled('div')`
	label: HighlightItem__DeleteIcon;
	max-height: 30px;
	cursor: pointer;
	margin-left: ${(props) => props.theme.spacings.compact}px;
	margin-top: 3px;
	display: inline-block;

	:hover {
		transform: scale(1.05);
	}
`;

const ControlsContainer = styled('div')`
	label: HighlightItem__ControlsContainer;
	display: flex;
	align-items: center;

	@media screen and (max-width: 700px) {
		margin-top: ${(props) => props.theme.spacings.cozy}px;
	}
`;

const ToggleSwitchContainer = styled('div')`
	label: HighlightItem__ToggleSwitchContainer;
	margin: ${(props) => props.theme.spacings.tight}px
		${(props) => props.theme.spacings.cozy}px 0;
`;

const ExpiryContainer = styled('div')`
	label: HighlightItem__ExpiryContainer;
	display: flex;

	& div:first-of-type {
		margin-right: ${(props) => props.theme.spacings.tight}px;
	}

	@media screen and (max-width: 700px) {
		margin-top: ${(props) => props.theme.spacings.cozy}px;
	}
`;

const HighlightItem = ({
	handleDelete,
	highlight,
	setHighlightEdit,
	openModal,
	updateHighlight,
}) => {
	const { id, title, visible, expiryDate } = highlight;

	return (
		<Container disabled={!visible}>
			<Title>{title}</Title>
			<ExpiryContainer>
				<div>
					<strong>Expires:</strong>
				</div>
				<div>{hdate.prettyPrint(expiryDate, { showTime: true })}</div>
			</ExpiryContainer>
			<ControlsContainer>
				<StyledButton
					isactive="false"
					onClick={(_e) => {
						setHighlightEdit(highlight);
						openModal();
					}}
				>
					Edit <FiEdit />
				</StyledButton>
				<ToggleSwitchContainer>
					<ToggleSwitch
						checked={visible}
						onChange={() => {
							updateHighlight({ ...highlight, visible: !visible });
						}}
						id={id}
						title={'Enable or Disable'}
					/>
				</ToggleSwitchContainer>
				<DeleteIcon onClick={() => handleDelete(id)}>
					<FaTrashAlt title={'delete rubbish icon'} color="#000" size="16px" />
				</DeleteIcon>
			</ControlsContainer>
		</Container>
	);
};

export default HighlightItem;
