/* eslint-disable @typescript-eslint/no-unused-vars */
import { useFormik } from 'formik';
import React, { useState } from 'react';
import Modal from 'react-modal';
import { ToastsStore } from 'react-toasts';
import Tooltip from 'react-tooltip';
import { PromotionViewEditProps } from '.';
import { ModalStyle } from '../../../common/modalStyle';
import { manualPromoFormInterface } from '../../../helpers/promotionEngine/promotionEngineInterfaces';
import { partialApply } from '../../../helpers/pureFunc';
import { StyledButton } from '../../shared/Navs';
import ToggleSwitch from '../../shared/ToggleSwitch';
import {
	ColumnedArea,
	ColumnedLabeledArea,
	DepositFormLayout,
	DepositMatchFieldSet,
	InlineInput,
	InlineLabel,
} from '../DepositMatchFormComponents';
import ClientTags from './ClientTags';
import { handleChange, StyledErrorHint } from './Common';
import EligibleTerritories from './EligibleTerritories';
import RaceEventSearch from './RaceEventSearch';
import RestrictToClientArea from './RestrictToClientArea';

const initialValues = (): Omit<manualPromoFormInterface, 'id'> => {
	return {
		promotion_type_id: 3,
		description: '',
		internal_description: '',
		details: '',
		enabled: true,
		excluded_tags: [],
		eligible_events: [],
		eligible_territories: [],
		display_on_site: true,
		restrict_to_clients: false,
	};
};

const ManualPromoForm = ({
	item,
	saveChanges,
	setViewEditCloseDelete,
	mode,
	cachedPromotionEndpoints,
	setFormElement,
	formElement,
}: PromotionViewEditProps) => {
	const existingItems = mode === 'new' ? null : item;

	const formik = useFormik({
		initialValues: {
			...initialValues(),
			...existingItems,
		},
		validateOnBlur: false,
		validateOnMount: mode !== 'new',
		validateOnChange: false,
		onSubmit: async values => {
			const result = await saveChanges(values);

			if (result instanceof Error) {
				ToastsStore.error('Failed To Submit Form');
			} else {
				const successMessage = item?.id
					? 'Successfully Updated Item'
					: 'Successfully Created Item';
				ToastsStore.success(successMessage);
				setViewEditCloseDelete('close');
			}
		},
		enableReinitialize: true,
	});

	const [close, setClose] = useState(false);

	const [manageClientListOpen, setManageClientListOpen] = useState(false);

	const disableInputs = formik.isSubmitting || mode === 'view';

	let formErrorMessage = '';
	if (!formik.isValid) formErrorMessage = "See Error's below";
	if (formik.isValidating) formErrorMessage = 'Checking';

	const eligibleEventsCallback = events => {
		formik.setFieldValue('eligible_events', events);
	};

	const territoriesCallback = territories => {
		formik.setFieldValue('eligible_territories', territories);
	};

	const tagCallback = tags => {
		formik.setFieldValue('excluded_tags', tags);
	};

	return (
		<DepositFormLayout
			formError={mode !== 'view' ? formErrorMessage : ''}
			mode={mode}
			actionClose={partialApply(setViewEditCloseDelete, 'close')}
			actionDelete={partialApply(setViewEditCloseDelete, 'delete')}
			actionEdit={partialApply(setViewEditCloseDelete, 'edit')}
			actionView={partialApply(setViewEditCloseDelete, 'view')}
			actionSave={formik.submitForm}
			setFormElement={setFormElement}
			formElement={formElement}
		>
			<Modal
				isOpen={manageClientListOpen}
				style={ModalStyle}
				shouldCloseOnOverlayClick={false}
			>
				{mode === 'view' && (
					<RestrictToClientArea
						promotionId={item.id}
						closeMe={partialApply(setManageClientListOpen, false)}
						fetchPromotions={cachedPromotionEndpoints.promotionClientList}
						nukeClientList={cachedPromotionEndpoints.promotionClientListNuke}
						setClientList={cachedPromotionEndpoints.promotionClientListSet}
					/>
				)}
			</Modal>
			<form
				onClick={e => {
					e.stopPropagation();
					setClose(!close);
				}}
				onSubmit={formik.handleSubmit}
				style={{ width: '100%' }}
			>
				<div>
					<Tooltip id="tooltipManageCLientList">
						{mode !== 'view'
							? 'Save Promotion To Manage its Client List'
							: 'Manage Client List (restrict promotion to client list)'}
						Save Promotion To Edit Client List
					</Tooltip>
				</div>
				<DepositMatchFieldSet disabled={disableInputs}>
					<legend>General</legend>
					<ColumnedLabeledArea
						id="activeArea"
						snapWidth="250px"
						margin="4px"
						label="Promotion Active"
					>
						{[
							{
								flexGrow: '1',
								child: (
									<ToggleSwitch
										id="enabled"
										title="enabled"
										checked={formik.values.enabled}
										onChange={e => handleChange(e, formik)}
										onBlur={formik.handleBlur}
										displayNotAllowedOnDisabled
										tooltipContent="Enable/Disable Promotion"
									/>
								),
							},
							{
								flexGrow: '4',
								child: (
									<StyledErrorHint>
										{mode !== 'view' && formik.errors?.enabled
											? `(${formik.errors?.enabled})`
											: ''}
									</StyledErrorHint>
								),
							},
						]}
					</ColumnedLabeledArea>
					<ColumnedLabeledArea
						id="activeArea"
						snapWidth="250px"
						margin="4px"
						label="Display On Site"
					>
						{[
							{
								flexGrow: '1',
								child: (
									<ToggleSwitch
										id="display_on_site"
										title="display_on_site"
										checked={formik.values.display_on_site}
										onChange={e => handleChange(e, formik)}
										onBlur={formik.handleBlur}
										displayNotAllowedOnDisabled
										tooltipContent="Show/Hide"
									/>
								),
							},
							{
								flexGrow: '4',
								child: (
									<StyledErrorHint>
										{mode !== 'view' && formik.errors?.display_on_site
											? `(${formik.errors?.display_on_site})`
											: ''}
									</StyledErrorHint>
								),
							},
						]}
					</ColumnedLabeledArea>
					<ColumnedLabeledArea
						id="description"
						snapWidth="1000px"
						margin="4px"
						label="Description"
					>
						{{
							flexGrow: '4',
							child: (
								<InlineInput
									type="text"
									id="description"
									onChange={e => handleChange(e, formik)}
									value={formik.values.description}
									isError={!!formik.errors?.description}
									onBlur={formik.handleBlur}
								/>
							),
						}}
					</ColumnedLabeledArea>
					{mode !== 'view' && formik.errors.description && (
						<div>
							<StyledErrorHint>{formik.errors.description}</StyledErrorHint>
							<br />
							<br />
						</div>
					)}
					<ColumnedLabeledArea
						id="InternalDescription"
						snapWidth="1000px"
						margin="4px"
						label="Internal Description"
					>
						{{
							flexGrow: '4',
							child: (
								<InlineInput
									type="text"
									id="internal_description"
									onChange={e => handleChange(e, formik)}
									value={formik.values.internal_description}
									isError={!!formik.errors?.internal_description}
									onBlur={formik.handleBlur}
								/>
							),
						}}
					</ColumnedLabeledArea>
					{mode !== 'view' && formik.errors.description && (
						<div>
							<StyledErrorHint>
								{formik.errors.internal_description}
							</StyledErrorHint>
							<br />
						</div>
					)}
					<ColumnedLabeledArea
						id="Details"
						snapWidth="1000px"
						margin="4px"
						label="Details"
					>
						{{
							flexGrow: '4',
							child: (
								<InlineInput
									type="text"
									id="details"
									onChange={e => handleChange(e, formik)}
									value={formik.values.details}
									isError={!!formik.errors?.details}
									onBlur={formik.handleBlur}
								/>
							),
						}}
					</ColumnedLabeledArea>
					{mode !== 'view' && formik.errors.details && (
						<div>
							<StyledErrorHint>{formik.errors.details}</StyledErrorHint>
							<br />
						</div>
					)}
				</DepositMatchFieldSet>

				<DepositMatchFieldSet disabled={disableInputs}>
					<legend>Event Selection</legend>
					<RaceEventSearch
						onClose={close}
						eligibleEventsCallback={eligibleEventsCallback}
						existingEvents={formik.values.eligible_events}
					/>
				</DepositMatchFieldSet>

				<DepositMatchFieldSet showLocked={disableInputs}>
					<legend>Details</legend>
					<ColumnedArea id="RestrictToClient" snapWidth="350px" margin="4px">
						{[
							{
								flexGrow: '2',
								child: <InlineLabel>Client List</InlineLabel>,
							},
							{
								flexGrow: '1',
								child: (
									<ToggleSwitch
										disabled={disableInputs}
										title="restrict_to_clients"
										id="restrict_to_clients"
										checked={formik.values.restrict_to_clients}
										onBlur={formik.handleBlur}
										onChange={e => handleChange(e, formik)}
										displayNotAllowedOnDisabled
										tooltipContent="Restrict Promotion to client list"
									/>
								),
							},
							{
								flexGrow: '2',
								child: (
									<>
										{(mode === 'edit' || mode === 'new') &&
											formik.errors?.restrict_to_clients && (
												<StyledErrorHint>
													{formik.errors?.restrict_to_clients}
												</StyledErrorHint>
											)}
										{!(
											(mode === 'edit' || mode === 'new') &&
											formik.errors?.restrict_to_clients
										) &&
											formik.values.restrict_to_clients && (
												<div
													style={{
														width: '100%',
														display: 'flex',
														justifyContent: 'center',
													}}
												>
													<Tooltip id="tooltip_button_client_list">
														{mode !== 'view'
															? 'Save Promotion First'
															: 'Open Manage Client List Area'}
													</Tooltip>
													<div data-tip data-for="tooltip_button_client_list">
														<StyledButton
															disabled={
																mode === 'new' ||
																!(
																	mode === 'view' &&
																	!formik.errors?.restrict_to_clients
																)
															}
															form={'differentFormDontDisableWithParentForm'}
															onClick={partialApply(
																setManageClientListOpen,
																true,
															)}
															isactive={true}
															title={
																mode === 'new' ||
																!(
																	mode === 'view' &&
																	!formik.errors?.restrict_to_clients
																)
																	? 'Save Promotion First'
																	: null
															}
															data-testid="buttonRestrictClients"
														>
															Manage Client List
														</StyledButton>
													</div>
												</div>
											)}
									</>
								),
							},
						]}
					</ColumnedArea>
					<EligibleTerritories
						onClose={close}
						territoriesCallback={territoriesCallback}
						existingTerritories={formik.values.eligible_territories}
					/>
					<ClientTags
						tagCallback={tagCallback}
						existingTags={formik.values.excluded_tags}
					/>
				</DepositMatchFieldSet>
			</form>
		</DepositFormLayout>
	);
};

export default ManualPromoForm;
