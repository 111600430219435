import React from 'react';

import styled from '../../common/styled';

const Link = styled('p')`
	color: ${(props) => props.theme.brand.color_1};
	cursor: pointer;

	&:hover {
		color: ${(props) => props.theme.brand.color_2};
	}
`;
const NoBanners = ({ handleTabSelect }) => {
	return (
		<>
			<p>Currently no banners of this banner type.</p>
			<Link onClick={(e) => handleTabSelect(e, 'new')}>Create a Banner</Link>
		</>
	);
};

export default NoBanners;
