import { AxiosResponse, AxiosInstance, AxiosError } from 'axios';
import { PromotionEngineItem } from './common';

interface promotionViewResponse {
	timestamp: string;
	http_status_code: number;
	data: PromotionEngineItem;
}

function promotionView(
	getConfig: () => {
		axiosInstance: AxiosInstance;
	},
	id: number,
): Promise<AxiosResponse<promotionViewResponse> | AxiosError> {
	return getConfig()
		.axiosInstance.get(`/${id}`)
		.catch((e) => e);
}

export default promotionView;
