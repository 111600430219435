import React, { useEffect, useState, useRef } from 'react';
import { parsePhoneNumber } from 'libphonenumber-js';
import {
	FaFacebookF,
	FaInstagram,
	FaTwitter,
	FaWhatsapp,
	FaLinkedin,
	FaYoutube,
	FaAndroid,
	FaApple,
	FaMobileAlt,
} from 'react-icons/fa';
import { partialApply } from '../../../../helpers/pureFunc';
import { getErrorMessage, urlRequired } from '../FooterValidation';
import styled from '../../../../common/styled';
import {
	socialCodes,
	socialLinksInterface,
} from '../../../../helpers/footerEndpoints/TypesDefs';
import { StyledButton } from '../../../shared/Navs';
import { Input, LabelWithError } from '../../../shared/FormElements';

export const SocialLinkSettings = {
	facebook: { icon: FaFacebookF },
	twitter: { icon: FaTwitter },
	linkedin: { icon: FaLinkedin },
	whatsapp: { icon: FaWhatsapp },
	instagram: { icon: FaInstagram },
	youtube: { icon: FaYoutube },
	android: { icon: FaAndroid },
	apple: { icon: FaApple },
	phone: { icon: FaMobileAlt },
};

export const StyledFooterSocialItem = styled.div<{ visibility?: string }>`
	flex: 1 0 400px;
	margin: ${({ theme }) => theme.spacings.cozy}px;
	overflow: hidden;
	${({ visibility }) => (visibility ? `visibility: ${visibility};` : '')}
	border-radius: ${({ theme }) => theme.application.border_radius_2}px;
	background: ${({ theme }) => theme.ui.background};
`;

const StyledHeader = styled.nav`
	width: 100%;
	/* padding: ${({ theme }) => theme.spacings.cozy}px; */
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	align-items: baseline;
	background: ${({ theme }) => theme.brand.color_2};
	color: white;
`;

const StyledLogo = styled.div`
	flex: 0 0 50px;
	height: 50px;
	margin: ${({ theme }) => theme.spacings.cozy}px;
	background: white;
	color: ${({ theme }) => theme.brand.color_2};
	border-radius: 100%;
	border: 5px solid ${({ theme }) => 'white'};
	filter: drop-shadow(0px 1px 1px black);

	padding: ${({ theme }) => theme.spacings.tight}px;
	transform: translateY(80%);
	& > * {
		width: 100%;
		height: 100%;
		box-sizing: content-box;
	}
`;

const StyledTitle = styled.h5`
	flex: auto auto min-content;
	text-transform: uppercase;
`;

const StyledNavItem = styled.div<{ sendRight?: boolean }>`
	flex: 0 0 80px;
	display: flex;
	${({ sendRight }) => (sendRight ? 'margin-left: auto;' : '')}
	padding: ${({ theme }) => theme.spacings.cozy}px;
	& > * {
		margin-left: ${({ theme }) => theme.spacings.cozy}px;
		margin-right: ${({ theme }) => theme.spacings.cozy}px;
		margin-top: ${({ theme }) => 1.5 * theme.spacings.cozy}px;
	}
`;

const StyledBody = styled.div`
	box-sizing: content-box;
	width: calc(100% - 100px);
	padding-top: ${({ theme }) => theme.spacings.compact}px;
	padding-left: 100px;
`;

const StyledA = styled.a`
	width: min-content;
	max-width: min(90%, 50em);
	margin-right: ${({ theme }) => theme.spacings.tight}px;
	text-overflow: ellipsis;
	text-transform: lowercase;
	overflow: hidden;
`;

interface FooterSocialItemProps {
	code: socialCodes;
	href: string | null;
	setItem: (input: socialLinksInterface['data']['Items'][number]) => void;
	deleteItem: () => void;
	open: boolean;
	setOpen: (input: boolean) => void;
}

const FooterSocialItem = ({
	code,
	href,
	setItem,
	open,
	setOpen,
	deleteItem,
}: FooterSocialItemProps) => {
	const Icon = SocialLinkSettings?.[code]?.icon;
	const title = code !== socialCodes.phone ? 'Link' : 'Tel: Link';

	const InputRef = useRef(undefined);
	const [hrefError, setHrefError] = useState<null | string>('');
	const [newHref, setNewHref] = useState(href || null);

	// varables
	let hint = '';
	if (open && code === socialCodes.phone)
		hint = 'Enter a phone number and it will be saved as a tel: link';
	else if (open)
		hint = 'Enter a valid url link (example: https://www.google.com )';

	// update href state
	useEffect(() => void setNewHref(href || ''), [href]);

	// update href error state
	useEffect(() => {
		if (code !== socialCodes.phone)
			return void setHrefError(
				getErrorMessage(urlRequired.nullable(), newHref),
			);

		// should be a phone number
		try {
			// catch parsing errors or if invalid throw an error
			if (!parsePhoneNumber(newHref, 'AU').isValid()) throw new Error('');
			setHrefError('');
		} catch (e) {
			// set error message
			setHrefError('not a valid phone number: ' + e.message);
		}
	}, [newHref, code]);

	// set focus when detect open = true
	useEffect(() => {
		if (open && InputRef.current) InputRef.current?.focus?.();
	}, [open]);

	// on blur format newhref
	useEffect(() => {
		const theInput = InputRef.current;

		const onBlurHandler = (e) => {
			try {
				if (code === socialCodes.phone)
					setNewHref(parsePhoneNumber(e.target.value, 'AU').getURI() || '');
			} catch (error) {}
		};
		const onKeydownHandler = (e: KeyboardEvent) => {
			if (e.key === 'Enter') {
				e.preventDefault();
				if (!hrefError && newHref) saveMe();
			}
		};

		// add the listeners
		theInput?.addEventListener?.('blur', onBlurHandler);
		theInput?.addEventListener?.('keydown', onKeydownHandler);

		// remove the listeners on componentWillUnmount
		return () => {
			theInput?.removeEventListener?.('blur', onBlurHandler);
			theInput?.removeEventListener?.('keydown', onKeydownHandler);
		};
	});

	// handlers
	const onChange = (e) => void setNewHref(e.target.value);
	const editMe = partialApply(setOpen, true);
	const deleteMe = deleteItem;

	const closeMe = () => {
		// item didn't exist before so onclose delete it
		if (!href) deleteItem();
		// item did exist, close window and save item
		else void setOpen(false) || void setNewHref(href);
	};

	const saveMe = () => {
		if (code === socialCodes.phone && newHref) {
			const phoneNumber = parsePhoneNumber(newHref || '', 'AU');
			// don't do anything if not valid
			if (!phoneNumber.isValid()) return;

			setItem({ code, href: phoneNumber.getURI() });
		} else {
			// not a phone number
			setItem({ code, href: newHref });
		}
		// close the editor
		setOpen(false);
	};

	return (
		<StyledFooterSocialItem>
			<StyledHeader>
				<StyledLogo>
					<Icon />
				</StyledLogo>
				<StyledTitle>{code}</StyledTitle>
				<StyledNavItem sendRight>
					{open ? (
						<>
							<StyledButton
								isactive={true}
								onClick={saveMe}
								disabled={!!hrefError || newHref === href}
							>
								Save
							</StyledButton>
							<StyledButton isactive={true} onClick={closeMe}>
								Close
							</StyledButton>
						</>
					) : (
						<>
							<StyledButton isactive={true} onClick={editMe}>
								Edit
							</StyledButton>
							<StyledButton
								isactive={true}
								disabled={!newHref}
								onClick={deleteMe}
							>
								Remove
							</StyledButton>
						</>
					)}
				</StyledNavItem>
			</StyledHeader>
			<StyledBody>
				<LabelWithError
					error={open && hrefError}
					htmlFor={`${code}_href`}
					title={title}
					hint={hint}
				>
					<Input
						hidden={!open}
						name={`${code}_href`}
						value={newHref || ''}
						onChange={onChange}
						type="url"
						ref={InputRef}
					/>
					<StyledA
						hidden={open}
						href={newHref}
						target="_blank"
						rel="noopener noreferrer"
					>
						{href}
					</StyledA>
				</LabelWithError>
			</StyledBody>
		</StyledFooterSocialItem>
	);
};

export default FooterSocialItem;
