import { AxiosResponse, AxiosInstance, AxiosError } from 'axios';

interface clientList {
	user_id: number;
	email: string;
}

export interface promotionClientListResponse {
	timestamp: string;
	http_status_code: number;
	data: clientList[];
	current_page: number;
	per_page: number;
	last_page: number;
	total: number;
}

function promotionClientList(
	getConfig: () => {
		axiosInstance: AxiosInstance;
	},
	id: number,
	page: number = 1,
	items: number = 20,
): Promise<AxiosResponse<promotionClientListResponse> | AxiosError<any>> {
	return getConfig()
		.axiosInstance.get(`/${id}/clients`, {
			params: { page: page.toFixed(0), items: items.toFixed(0) },
		})
		.catch((e) => e);
}

export default promotionClientList;
