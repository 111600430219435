import { css } from '@emotion/core';
import MarkdownIt from 'markdown-it';
import React, { useState } from 'react';
import styled from '../../common/styled';
import CountdownTimer from '../shared/CountdownTimer';
import { StyledButton } from '../shared/Navs';

const Container = styled('div')(
	props => css`
		display: grid;
		grid-template-columns: ${props.type};
		grid-gap: 12px;
		width: 100%;

		@media only screen and (max-width: 600px) {
			grid-template-columns: repeat(1, 1fr);
		}
	`,
);

const Image = styled('img')`
	width: 100%;
	height: auto;
`;

const DetailsBox = styled('div')(
	props => css`
		padding: 16px;
		height: max-content;
		border: 1px solid #dddddd;
	`,
);

const Expiry = styled('div')(
	props => css`
		display: flex;
		border: 1px solid #dddddd;
		padding: 8px;
		margin-left: 12px;
		color: #666666;
	`,
);

const Row = styled('div')(
	props => css`
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-top: 8px;
	`,
);

const Text = styled('div')(
	props => css`
		margin-bottom: 8px;
	`,
);

const Link = styled('a')(
	props => css`
		text-decoration: underline;
		color: #666666;
		cursor: pointer;
	`,
);

const TermsAndConditions = styled('div')(
	props => css`
		margin-top: 8px;
		color: #666666;
		border: 1px solid #e2e2e2;
		overflow-y: scroll;
	`,
);

const PromoButton = styled(StyledButton)(
	props => css`
		display: block;
		width: 100%;
		margin-left: 0;
		text-align: center;
	`,
);

const handleBetClick = route => {
	if (!route) return () => {};

	alert(`clicked to ${route}`);
};

const PromotionsPreview = ({ promotions }) => {
	const [openTerms, toggleTerms] = useState(false);

	const handleTermsClick = key => {
		if (!openTerms) return toggleTerms(key);
		if (openTerms) return toggleTerms(false);
	};

	if (!promotions) return null;

	const mdParser = new MarkdownIt();

	// todo: replace with value from backend settings
	const DisplayType = true ? 'repeat(1, 1fr)' : 'repeat(2, 1fr)';

	const terms_and_conditions = {
		__html: mdParser.render(promotions.terms_and_conditions),
	};

	const externalLink = promotions.route.startsWith('http');
	const enableButton =
		promotions.route && !(promotions.hide_button || externalLink);

	const description = {
		__html: mdParser.render(promotions.description),
	};

	const tncEmpty = promotions.terms_and_conditions.length <= 0;

	return (
		<Container type={DisplayType}>
			<DetailsBox key={promotions.key}>
				{promotions.image && externalLink ? (
					<Link href={promotions.route} target="_blank" rel="noreferrer">
						<Image src={promotions.image} alt={promotions.title} />
					</Link>
				) : promotions.image ? (
					<Image src={promotions.image} alt={promotions.title} />
				) : (
					<Text strong size="2">
						{promotions.title}
					</Text>
				)}

				<Text>
					<div dangerouslySetInnerHTML={description} />
				</Text>

				{enableButton && (
					<PromoButton
						isactive="true"
						onClick={() => handleBetClick(promotions.route)}
						block
					>
						Bet Now
					</PromoButton>
				)}

				<Row>
					<Link onClick={() => handleTermsClick(promotions.key)}>
						Terms and Conditions
					</Link>
					<Expiry>
						Expires in: <CountdownTimer date={promotions.expiry} />
					</Expiry>
				</Row>

				{!tncEmpty && openTerms === promotions.key && (
					<TermsAndConditions>
						<div dangerouslySetInnerHTML={terms_and_conditions} />
					</TermsAndConditions>
				)}
			</DetailsBox>
		</Container>
	);
};

export default PromotionsPreview;
