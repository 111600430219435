import { createSlice } from '@reduxjs/toolkit';

export interface authInterface {
	isPending: boolean;
	isAuthenticated: boolean;
	idToken: string;
	accessToken: string;
	user: {
		sub?: string;
		name?: string;
		locale?: string;
		email?: string;
		preferred_username?: string;
		given_name?: string;
		family_name?: string;
		zoneinfo?: string;
		updated_at?: number;
		email_verified?: boolean;
		groups?: string[];
	};
}

const { actions, reducer } = createSlice({
	name: 'setAuth',
	initialState: {
		isPending: true,
		isAuthenticated: false,
		idToken: '',
		accessToken: '',
		user: {},
	},

	reducers: {
		setAuth(state, { payload }: { payload: Partial<authInterface> }) {
			state.accessToken = payload.accessToken;
			state.idToken = payload.idToken;
			state.isAuthenticated = payload.isAuthenticated;
			state.isPending = payload.isPending;
		},
		setAuthUser(state, { payload }: { payload: authInterface['user'] }) {
			state.user = payload;
		},
		setAuthLoading(state, { payload }: { payload: boolean }) {
			state.isPending = payload;
		},
	},
});

export const { setAuth, setAuthUser, setAuthLoading } = actions;

export default reducer;
