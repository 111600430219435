import { AxiosResponse, AxiosInstance, AxiosError } from 'axios';

export interface promotionTypeInfo {
	id: number;
	name: string;
	description: string;
}

interface promotionTypeListResponse {
	timestamp: string;
	http_status_code: number;
	data: promotionTypeInfo[];
}

function promotionsTypeList(
	getConfig: () => {
		axiosInstance: AxiosInstance;
	},
): Promise<AxiosResponse<promotionTypeListResponse> | AxiosError> {
	return getConfig()
		.axiosInstance.get('/types')
		.catch((e) => e);
}

export default promotionsTypeList;
