import React from 'react';
import styled, { css } from '../../common/styled';

interface styledCardProps {
	isModal: boolean;
	marginBottom: boolean;
}

interface cardProps {
	children: any;
	isModal?: boolean;
	marginBottom?: boolean;
}

const StyledCard = styled.div<styledCardProps>`
	label: Card;
	${({ isModal }) =>
		isModal
			? css``
			: css`
					height: 100%;
			  `}
	background-color: #fff;
	box-sizing: border-box;
	margin: ${(props) =>
		!props.isModal ? `${props.theme.spacings.cozy}px` : 'none'};
	padding: ${(props) => props.theme.spacings.cozy}px;
	margin-bottom: ${(props) =>
		props.marginBottom ? `${props.theme.spacings.cozy}px` : 'none'};
	border-radius: 4px;
	box-shadow: ${(props) => props.theme.application.box_shadow};
	@media (max-width: 1000px) {
		margin: ${(props) => props.theme.spacings.compact}px;
	}
`;

const Card = ({ children, isModal, marginBottom }: cardProps) => {
	return (
		<StyledCard isModal={isModal} marginBottom={marginBottom}>
			{children}
		</StyledCard>
	);
};

export default Card;
