import React, { ReactNode } from 'react';
import styled, { css } from '../../common/styled';
import Tooltip from 'react-tooltip';

const CheckBox = styled('input')`
	/* Below code hides the checkbox whist still letting it be visible to screen readers */
	position: absolute;
	width: 1px;
	height: 1px;
	padding: 0;
	margin: -1px;
	overflow: hidden;
	clip: rect(0, 0, 0, 0);
	white-space: nowrap;
	border-width: 0;
`;

const CheckBoxLabel = styled('label')<{ checked }>`
	position: relative;
	display: inline-block;
	width: 2em;
	height: 1em;
	background-color: ${(props) =>
		props.checked ? props.theme.brand.color_1 : props.theme.ui.color_5};
	border-radius: 1em;
	transition: all 0.3s;

	&::after {
		content: '';
		position: absolute;
		width: 1em;
		height: 1em;
		border-radius: 50%;
		background-color: ${(props) => props.theme.ui.color_1};
		top: 0px;
		/* have to overshoot each side a little so that there isn't a slither of colour from background showing around it */
		left: ${(props) => (props.checked ? 'calc(1em + 1px)' : '-1px')};
		transition: all 0.3s;
		box-sizing: border-box;
		border: 1px solid ${(props) => props.theme.ui.background};
	}
`;

const StyledToogleSwitch = styled.span<{
	displayNotAllowedOnDisabled?: boolean;
}>`
	padding-top: 3px;
	outline-offset: 4px;
	&:focus-within {
		outline: #f4f4f4 dotted 1px;
	}

	& > * {
		cursor: pointer;
	}

	${({ displayNotAllowedOnDisabled }) =>
		displayNotAllowedOnDisabled &&
		css`
			input:disabled ~ * {
				cursor: not-allowed;
			}
		`}
`;

const ToggleSwitch = ({
	checked,
	title,
	id,
	onClick,
	onBlur,
	displayNotAllowedOnDisabled,
	tooltipContent,
	...restOfProps
}: React.DetailedHTMLProps<
	React.InputHTMLAttributes<HTMLInputElement>,
	HTMLInputElement
> & { displayNotAllowedOnDisabled?: boolean; tooltipContent?: ReactNode }) => {
	const tooltipId = 'tooltip_' + id;
	return (
		<StyledToogleSwitch
			onClick={onClick}
			onBlur={onBlur}
			displayNotAllowedOnDisabled={displayNotAllowedOnDisabled}
			{...(tooltipContent ? { 'data-tip': true, 'data-for': tooltipId } : {})}
		>
			{tooltipContent && <Tooltip id={tooltipId}>{tooltipContent}</Tooltip>}
			<CheckBox
				type="checkbox"
				id={id}
				name="visible"
				checked={checked}
				{...restOfProps}
			/>
			<CheckBoxLabel
				htmlFor={id}
				checked={checked}
				aria-label={title}
				title={title}
			/>
		</StyledToogleSwitch>
	);
};

export default ToggleSwitch;
