import React from 'react';
import styled from '../../common/styled';
import { staticTheme } from '../../common/theme';

interface PillTypeInterface {
	ColBk: string;
	ColText: string;
	text: string;
}

type nameTypes = keyof typeof PillType;

const isMobile = window.innerWidth <= 500;

const PillType: {
	draft: PillTypeInterface;
	published: PillTypeInterface;
	racing: PillTypeInterface;
	sport: PillTypeInterface;
} = {
	published: {
		ColBk: '#339966',
		ColText: 'white',
		text: isMobile ? 'P' : 'Published',
	},
	draft: {
		ColBk: '#ff9900',
		ColText: 'white',
		text: isMobile ? 'D' : 'Draft',
	},
	racing: {
		ColBk: staticTheme.ui.color_black,
		ColText: 'white',
		text: 'Racing',
	},
	sport: {
		ColBk: staticTheme.ui.color_black,
		ColText: 'white',
		text: 'Sport',
	},
};

interface PillTypeInter {
	type: nameTypes;
	children?: React.ReactNode;
}

const Pill = styled<'span', PillTypeInter>('span')`
	display: flex;
	justify-content: space-evenly;
	align-items: baseline;
	color: ${({ type }) => PillType[type].ColText};
	background-color: ${({ type }) => PillType[type].ColBk};
	padding: 0.25em;
	border-radius: 5px;
`;

export default ({ type, children }: PillTypeInter) => (
	<Pill type={type}>
		<span>{PillType[type].text}</span>
		{children}
	</Pill>
);
