// @ts-nocheck

import React, { useLayoutEffect, useState } from 'react';
import { connect } from 'react-redux';
import { LightenDarkenColor } from '../../common/LightenDarkenColor';
import styled, { css } from '../../common/styled';
import {
	brandConfig as MasterBrandConfig,
	FALL_OVER_BRAND,
} from '../../context/brandConfig/brandConfig';
import { useBrandConfig } from '../../context/brandConfig/brandConfigContext';
import { setBrand } from '../../store/application';

interface brandSelectorProps {
	selectedBrand: string;
	handleBrandSet?: () => {};
}

interface hasSelectedProp {
	isSelected?: boolean;
}

const Container = styled.div(
	props => css`
		label: Container;

		display: flex;
		align-items: center;
		height: 100%;
		width: 100%;
		background-color: ${LightenDarkenColor(props.theme.brand.color_2, 0)};
		margin-bottom: ${props => props.theme.spacings.cozy}px;
	`,
);

const Content = styled.div(
	props => css`
		label: Content;

		position: relative;
		width: 100%;
		max-width: 1140px;
		margin: 0 auto;
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 10px;
	`,
);

const Selected = styled.div(
	props => css`
		user-select: none;
		color: ${props.theme.typography.color_1_alternate};
		cursor: pointer;
		display: flex;
		align-items: center;
		flex-direction: column;
	`,
);

const Logo = styled('img')`
	label: Header__Logo;

	height: 32px;
	z-index: 5;
`;

const Backboard = styled.div<hasSelectedProp>(
	props => css`
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		position: fixed;
		z-index: 99999;
	`,
);

const DropDown = styled.div<hasSelectedProp>(
	props => css`
		position: absolute;
		top: 145px;
		left: calc(50% - 160px);
		right: calc(50% - 160px);
		max-height: 500px;
		width: 320px;
		padding: 8px;
		background-color: ${LightenDarkenColor(props.theme.brand.color_2, -10)};
		border-radius: 4px;

		overscroll-behavior: contain;
		-webkit-overflow-scrolling: touch !important;

		// LEAVE THIS HERE
		overflow-y: auto !important;
	`,
);

const Option = styled.div<hasSelectedProp>(
	props => css`
		display: flex;
		align-items: center;

		cursor: pointer;
		margin-bottom: 4px;
		padding: 4px;
		background-color: ${props.isSelected
			? LightenDarkenColor(props.theme.brand.color_2, 20)
			: props.theme.brand.color_2};
		color: ${props.theme.typography.color_1_alternate};
		:hover {
			background-color: ${props.isSelected
				? LightenDarkenColor(props.theme.brand.color_2, 0)
				: LightenDarkenColor(props.theme.brand.color_2, 20)};
		}
	`,
);

const FaviconImg = styled.img(
	() => css`
		width: 24px;
		height: 24px;
		border-radius: 4px;
		margin-right: 8px;
	`,
);

const Text = styled.span(
	() => css`
		margin-top: 10px;
		font-size: 10px;
		font-weight: bold;
	`,
);

/**
 * Sorts the recieved brand list, adding the 'benchmark' and 'integration'
 * servers to the top, followed by the 'dev' environments and finially
 * all the client environments.
 *
 * @param {string[]} brandList
 * @returns {string[]} sorted brandList
 */
const sortBrandList = brandList => {
	if (!brandList || brandList.length <= 1) {
		return brandList;
	}

	// Identify unique branches to be sorted in this order from top
	const fixedBrandList = ['benchmark', 'integration'];
	return [
		...new Set([
			...fixedBrandList.filter(brand => brandList.includes(brand)),
			...brandList.filter(brand => brand.match(/^[dD]ev\d+$/)),
			...brandList.sort((a, b) =>
				MasterBrandConfig.displayName[a]?.toLowerCase() >
				MasterBrandConfig.displayName[b]?.toLowerCase()
					? 1
					: -1,
			),
		]),
	];
};

const BrandSelector = ({
	selectedBrand,
	handleBrandSet: _handleBrandSet,
}: brandSelectorProps) => {
	const { brandList } = useBrandConfig();

	const handleBrandSet = React.useCallback(_handleBrandSet, []);

	useLayoutEffect(() => {
		const selectedBrandIsValid =
			selectedBrand &&
			selectedBrand !== FALL_OVER_BRAND &&
			brandList.includes(selectedBrand);

		if (!selectedBrandIsValid && brandList.length) handleBrandSet(brandList[0]);
	}, [brandList, selectedBrand, handleBrandSet]);

	const [isDropDownOpen, setDropDown] = useState(false);

	return (
		<Container>
			<Content>
				<Selected onClick={() => setDropDown(!isDropDownOpen)}>
					<Logo
						src={MasterBrandConfig.logo[selectedBrand]}
						alt={MasterBrandConfig.displayName[selectedBrand]}
					/>
					<Text>{MasterBrandConfig.displayName[selectedBrand]}</Text>
				</Selected>

				{brandList?.length && isDropDownOpen && (
					<Backboard onClick={() => setDropDown(false)}>
						<DropDown>
							{sortBrandList(brandList).map((brand: Array) => (
								<Option
									key={brand}
									value={brand}
									onClick={() => {
										setDropDown(false);
										handleBrandSet(brand);
									}}
									isSelected={brand === selectedBrand}
								>
									<FaviconImg
										src={MasterBrandConfig.favicon[brand]}
										alt="logo"
									/>
									<span>{MasterBrandConfig.displayName[brand]}</span>
								</Option>
							))}
						</DropDown>
					</Backboard>
				)}

				{!brandList?.length && (
					<div>
						<p>
							You don't appear to have any brands authorised for your account.
						</p>
					</div>
				)}
			</Content>
		</Container>
	);
};

const mapStateToProps = state => {
	return {
		selectedBrand: state.application.brand,
	};
};

const mapDispatchToProps = dispatch => {
	return {
		handleBrandSet: brand => dispatch(setBrand(brand)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(BrandSelector);
