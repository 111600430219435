import React from 'react';
import { FiX } from 'react-icons/fi';
import styled, { css } from '../../common/styled';

import Card from '../shared/Card';
import PageHeader from '../shared/PageHeader';
import { StyledButton } from '../shared/Navs';

const StyledImage = styled('img')(
	(props) => css`
		label: BannerDesignInfo__Image;

		width: 95%;
		min-width: 350px;
		display: block;
		margin-bottom: ${props.theme.spacings.spacious}px;
	`,
);

const BannerDesignInfo = ({ closeModal }) => {
	return (
		<Card isModal>
			<PageHeader title="Banners">
				<StyledButton onClick={closeModal}>
					Close <FiX />
				</StyledButton>
			</PageHeader>
			<p>
				Banners provide an opportunity to make your app stand out, amplify your
				brand and promote specials or events.
			</p>
			<StyledImage src="/images/banner.png" alt="Banner example" />
			<strong>Banner Dimensions</strong>
			<ul>
				<li>Mobile: 1170 x 440px - exact</li>
				<li>Desktop: 1170 x 150px - exact</li>
			</ul>
			<strong>Banner Situations - who can see the banners</strong>
			<ul>
				<li>
					Banners can be shown to "logged out users" (guests), "logged in users"
					or "all users" (both logged in and guests)
				</li>
				<li>
					By law, banners shown to all users or guests cannot entice gambling.
					They must be generic "here's our features" sort of banners.
					<br />
					<a
						href="https://www.acma.gov.au/gambling-ads-during-live-sport-streamed-over-internet"
						target="_blank"
						rel="noopener noreferrer"
					>
						Learn more about rules for gambling ads
					</a>
				</li>
				<li>
					Banners that push specials or entice gambling have to be shown to
					logged in users only
				</li>
				<li>
					Banners can appear on the root homepage, racing homepage, sports
					homepage, single race events and sport events
				</li>
			</ul>
			<strong>Banner Locations - where the banners will display</strong>
			<ul>
				<li>
					Homepage: <em>/ or /home</em>
				</li>
				<li>
					Racing Homepage: <em>/racing</em>
				</li>
				<li>
					Race Meeting: <em>/racing/gallops/mackay/...</em>
				</li>
				<li>
					Single Race: <em>/racing/gallops/mackay/race-3...</em>
				</li>
				<li>
					Sports Homepage: <em>/sports</em>
				</li>
				<li>
					Sport category/type: <em>/sports/australian-rules/...</em>
				</li>
			</ul>
			<strong>
				Meeting, Race, Event ID's and Sport Names - how to target banners
				correctly
			</strong>
			<p>
				In order to show the banners in certain locations, an ID number or
				sports name is required. These can be found in the URL when viewing the
				desired race/event/sport. Examples below:
			</p>
			<ul>
				<li>
					Meeting ID: /racing/gallops/rockhampton/race-1-1950945-
					<strong>203519</strong>
				</li>
				<li>
					Race ID: /racing/gallops/rockhampton/race-1-<strong>1950945</strong>
					-203519
				</li>
				<li>
					Event ID: /sports/rugby-league/state-of-origin-final-
					<strong>1019541</strong>
				</li>
			</ul>
			<p>
				ID's are not required for Homepage, Racing Homepage or Sports Homepage
				banners.
			</p>
		</Card>
	);
};

export default BannerDesignInfo;
