import React, { useEffect, useState } from 'react';
import { differenceInMilliseconds } from 'date-fns';

function CountdownTimer({ date }) {
	const calculateTimeLeft = () => {
		const formatDate = new Date(date);
		const difference = differenceInMilliseconds(formatDate, new Date());
		let timeLeft = {};

		if (difference > 0) {
			timeLeft = {
				d: Math.floor(difference / (1000 * 60 * 60 * 24)),
				h: Math.floor((difference / (1000 * 60 * 60)) % 24),
				m: Math.floor((difference / 1000 / 60) % 60),
				s: Math.floor((difference / 1000) % 60),
			};
		}

		if (difference < 0) {
			timeLeft = {
				m: Math.floor((difference / 1000 / 60) % 60),
				s: Math.floor((difference / 1000) % 60),
			};
		}

		return timeLeft;
	};

	const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

	useEffect(() => {
		const timer = setTimeout(() => {
			setTimeLeft(calculateTimeLeft());
		}, 1000);
		return () => clearTimeout(timer);
	});

	const timerComponents = [];

	Object.keys(timeLeft).forEach((interval) => {
		if (!timeLeft[interval]) {
			return;
		}

		timerComponents.push(
			<span key={interval}>
				{timeLeft[interval]}
				{interval} {` `}
			</span>,
		);
	});

	return <div style={{ marginLeft: '4px' }}>{timerComponents}</div>;
}

export default CountdownTimer;
