import React, {useRef, useEffect} from 'react';


/**
 * This is stupid thing about react, 
 * * it doesn't support forwarding a ref and then using the same ref from within the component.
 * * it doesn't support multiple refs to the same html component
 * this is the best article I could find on the topic and credit to him for this solution
 * https://itnext.io/reusing-the-ref-from-forwardref-with-react-hooks-4ce9df693dd
 */


function useCombinedRefs<T>(...refs:Array<((instance: T) => void) | React.MutableRefObject<T>>) {
    const targetRef = useRef<T>()
  
    useEffect(() => {
      refs.forEach(ref => {
        if (!ref) return
  
        if (typeof ref === 'function') {
          ref(targetRef.current)
        } else {
          ref.current = targetRef.current
        }
      })
    }, [refs])
  
    return targetRef
  }

export default useCombinedRefs;