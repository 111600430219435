import React, { useEffect, useState } from 'react';
import { FiX } from 'react-icons/fi';
import styled from '../../../common/styled';
import Card from '../../shared/Card';
import { Divider } from '../../shared/FormElements';
import { StyledButton } from '../../shared/Navs';
import PageHeader from '../../shared/PageHeader';
import Pagination from '../Pagination';
import { ManageTable } from '../promotionEngineManageScreen';

const StyledCard = styled(Card)`
	overflow-y: scroll;
	max-height: 90vh;
`;

const StyledMenu = styled.div`
	margin-left: 'auto';
	display: flex;
	& > * {
		margin-left: ${({ theme }) => theme.spacings.compact}px;
	}
`;

const paginationItems = 20;

const PriceLadder = ({ promotionId, closeMe, fetchLadders }) => {
	const [paginationDetails, setPaginationDetails] = useState<{
		page: number;
		items: number;
	}>({ page: 1, items: paginationItems });

	const [fetchLoading, setFetchLoading] = useState(true);
	const [fetchedData, setFetchData] = useState(null);

	useEffect(() => {
		fetchLadders(promotionId, paginationDetails?.page).then(res => {
			setFetchLoading(false);
			setFetchData(res);
		});
	}, [fetchLadders, paginationDetails.page, promotionId]);

	const StickyThStyle = styled.th`
		position: sticky;
		top: 0;
		margin-top: -2px;
		background: white;
		border-bottom: 2px solid black;
		border-top: 2px solid black;
	`;

	return (
		<StyledCard isModal>
			<PageHeader title="Restrict To Clients">
				<StyledMenu>
					<StyledButton data-testid="buttonClose-top" onClick={() => closeMe()}>
						Close <FiX className="icon-only-mobile" />
					</StyledButton>
				</StyledMenu>
			</PageHeader>
			<Divider />
			<ManageTable underMutation={fetchLoading}>
				<thead>
					<tr>
						<StickyThStyle>Price</StickyThStyle>
					</tr>
				</thead>
				<tbody style={{ fontSize: '0.8em' }}>
					{fetchedData &&
						fetchedData?.data?.map(item => (
							<tr key={`${item}_fetched`}>
								<td align="center">${item.toFixed(2)}</td>
							</tr>
						))}
					{fetchedData && (
						<tr>
							<td colSpan={3} align="right">
								{paginationDetails?.page} of {fetchedData?.last_page} pages
							</td>
						</tr>
					)}
				</tbody>
			</ManageTable>
			{fetchedData && (
				<Pagination
					EitherSide={2}
					current={paginationDetails?.page}
					min={1}
					max={fetchedData?.last_page}
					pageRequest={page => {
						setPaginationDetails({ ...paginationDetails, page });
					}}
				/>
			)}
			<br />
		</StyledCard>
	);
};

export default PriceLadder;
