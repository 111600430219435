import React, { useRef } from 'react';
import { FiEdit, FiEye, FiTrash } from 'react-icons/fi';
import { connect } from 'react-redux';
import { useBrandConfig } from '../../context/brandConfig/brandConfigContext';
import { FieldsAltered } from '../../helpers/index';
import { contentItemType, ItemStatusType } from '../../helpers/newAPIClient';
import useElementSize from '../../hooks/useElementSize';
import { setAuthLoading } from '../../store/auth';
import {
	CreateDraftFrom,
	DeleteItem,
	setSelectedStatusID
} from '../../store/pages';
import { Flex, TableButton, Td, TdOptional, Tr } from '../shared/Table';
import PillWithHover, { PillHoverInfoGenerator } from './PillWithHover';

interface ContentRowProps {
	toggleModal: any;
	draft?: contentItemType;
	published?: contentItemType;
	item?: contentItemType;
	setSelectedStatusID: ({
		status,
		id,
	}: {
		status: ItemStatusType;
		id: string;
	}) => void;
	DeleteItem: ({
		status,
		id,
	}: {
		status: ItemStatusType;
		id: string;
	}) => Promise<any>;
	CreateDraftFrom: (item: contentItemType) => Promise<any>;
}

const bitMe = (input: any): 1 | 0 => (input ? 1 : 0);

const ContentTableRow = ({
	toggleModal,
	published,
	draft,
	setSelectedStatusID,
	DeleteItem,
	CreateDraftFrom,
}: ContentRowProps) => {
	const { brandConfig } = useBrandConfig();
	const featurePage = brandConfig.featureFlags?.pages;
	const windowRef = useRef(window);
	const windowSize = useElementSize<Window>(windowRef);
	const isMobile = windowSize.width <= 600;

	const appUrl = brandConfig.appUrl;

	const AlteredFields = draft && published && FieldsAltered(published, draft);
	const fieldsToIgnore: Array<keyof contentItemType> = [
		'status',
		'date_created',
		'date_updated',
		'user_created',
		'user_updated',
	];
	const AnyAlteredFields =
		typeof AlteredFields === 'object' &&
		Object.entries(AlteredFields).some(
			([key, changed]) =>
				!fieldsToIgnore.includes(key as keyof contentItemType) && changed,
		);
	const bkCol = draft ? '#ececeb' : '#dddddd';

	// using bitwise operators be careful if you haven't used before
	// logically equivalent to the below
	// if (draft && !published) || (!draft && published) || (draft && published && not AnyAlteredFields)
	const TreatAsSingleItem: boolean = !!(
		(bitMe(draft) ^ bitMe(published)) |
		bitMe(!AnyAlteredFields)
	);

	const handleEditClick = async () => {
		setAuthLoading(true);
		if (!draft) {
			await CreateDraftFrom(published);
		}
		await setSelectedStatusID({
			status: 'draft',
			id: draft?.id || published?.id,
		});
		setAuthLoading(false);
		toggleModal(true);
	};

	const handleViewClick = () => {
		return window.open(`${appUrl}/content/${published.slug}`);
	};

	const handleDeleteClick = () => {
		// TreatAsSingleItem
		const sure = window.confirm(
			`Are you sure you want to delete this ${
				TreatAsSingleItem && published ? 'Page' : 'Draft'
			}?`,
		);
		if (sure) {
			const id = published?.id || draft?.id;
			if (TreatAsSingleItem && published && draft) {
				DeleteItem({ status: 'draft', id });
				DeleteItem({ status: 'published', id });
			} else {
				DeleteItem({
					status: draft ? 'draft' : 'published',
					id: draft?.id || published?.id,
				});
			}
		} else {
			return null;
		}
	};

	return (
		<Tr bgColor={bkCol}>
			<TdOptional>
				{AlteredFields?.slug
					? `${draft.slug}*`
					: draft?.slug || published?.slug}
			</TdOptional>
			{featurePage?.version &&
				<TdOptional>
					{AlteredFields?.version
						? `${draft.version}*`
						: draft?.version || published?.version}
				</TdOptional>
			}
			<Td>
				{AlteredFields?.title
					? `${draft.title}*`
					: draft?.title || published?.title}
			</Td>
			<Td>
				<Flex>
					{published && (
						<PillWithHover
							type="published"
							uniqueKey={published?.id + 'published'}
							bgCol="#5d97fb"
						>
							{(published.user_created || published.user_updated) && (
								<PillHoverInfoGenerator Published={published} />
							)}
						</PillWithHover>
					)}
					{draft && !(published && TreatAsSingleItem) && (
						<PillWithHover
							type="draft"
							uniqueKey={draft?.id + '_draft'}
							bgCol="#5d97fb"
						>
							{(draft?.user_created || draft?.user_updated) && (
								<PillHoverInfoGenerator Draft={draft} />
							)}
						</PillWithHover>
					)}
				</Flex>
			</Td>

			<TdOptional>
				{AlteredFields?.publish_date
					? `${draft.publish_date}*`
					: draft?.publish_date || published?.publish_date}
			</TdOptional>
			<Td>
				<Flex>
					<TableButton
						disabled={!published || !appUrl}
						onClick={handleViewClick}
					>
						{!isMobile && 'View'}
						<FiEye />
					</TableButton>

					<TableButton onClick={handleEditClick}>
						{!isMobile && 'Edit'}
						<FiEdit />
					</TableButton>
					<TableButton onClick={handleDeleteClick}>
						{!isMobile && 'Delete'}
						<FiTrash />
					</TableButton>
				</Flex>
			</Td>
		</Tr>
	);
};

const mapStateToProps = () => ({});

export default connect(mapStateToProps, {
	DeleteItem,
	setSelectedStatusID,
	CreateDraftFrom,
})(ContentTableRow);
