import React from 'react';

import { SearchBox } from '../shared/FormElements';
import PageHeader from '../shared/PageHeader';

interface props {
	searchFieldOnChange: (input: string) => void;
	searchFieldValue: string;
}

const PromotionHeader = ({ searchFieldOnChange, searchFieldValue }: props) => (
	<PageHeader title="Promotions Engine">
		<SearchBox
			type="text"
			onChange={(e) => searchFieldOnChange(e.currentTarget.value)}
			value={searchFieldValue}
			placeholder="Search"
		></SearchBox>
	</PageHeader>
);

export default PromotionHeader;
