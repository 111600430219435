import { useFormik } from 'formik';
import React, { HTMLProps, useCallback, useEffect, useState } from 'react';
import { FaEdit, FaTrashAlt } from 'react-icons/fa';
import { Prompt } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import styled from '../../common/styled';
import { bannersValidation } from '../../common/validations';
import { useBrandConfig } from '../../context/brandConfig/brandConfigContext';
import { AuthenticationStatusType } from '../../helpers/bannerEndpoints/TypesDefs';
import {
	mobileRouteLegacyObject,
	mobileRouteObject
} from '../../helpers/mobileRouteInterfaces';
import MobileRouteEditor, {
	cleanMobileRoute
} from '../mobileRoute/mobileRouteEditor';
import { Divider, Input, LabelWithError, Select } from '../shared/FormElements';
import { StyledButtonInverse } from '../shared/Navs';
import ToggleSwitch from '../shared/ToggleSwitch';
import BannerTagInput from './BannerTagInput';

interface isEnabled {
	enabled: boolean;
	isFullWidth?: boolean;
}

interface DivWithDisabled extends HTMLProps<HTMLDivElement> {
	disabled?: boolean;
}

interface DivWithImgWidth extends HTMLProps<HTMLDivElement> {
	isFullWidth?: boolean;
}

const Container = styled.div<DivWithDisabled>`
	label: BannerForm__Container;
	border: 1px solid #eee;
	background: ${props => (props.disabled ? '#e1e1e1' : '#fcfcfc')};
	border-radius: 4px;
	margin-bottom: ${props => props.theme.spacings.compact}px;
	box-shadow: ${props => props.theme.application.box_shadow};
	overflow: hidden;
	transition: all 0.3s;
`;

const BannerCardControlBar = styled('div')`
	label: BannerForm__BannerCardControlBar;
	padding: ${props => props.theme.spacings.cozy}px;
	background: ${props => props.theme.brand.color_2};
	display: flex;
	justify-content: space-between;
`;

const PositionContainer = styled('div')`
	label: BannerForm__PositionContainer;
	display: flex;
	align-items: center;
	cursor: default;
`;

const NumContainer = styled('div')`
	label: BannerForm__NumContainer;
	color: ${props => props.theme.ui.color_4};
	font-size: 14px;
`;

const ControlContainer = styled('div')`
	label: BannerForm__ControlContainer;
	display: flex;
	justify-content: flex-end;
	align-items: center;
`;

const EditIcon = styled('div')`
	label: BannerForm__EditIcon;
	max-height: 30px;
	cursor: pointer;
	margin-left: ${props => props.theme.spacings.compact}px;
	margin-top: 3px;

	:hover {
		transform: scale(1.05);
	}
`;

const DeleteIcon = styled('div')`
	label: BannerForm__DeleteIcon;
	max-height: 30px;
	cursor: pointer;
	margin-left: ${props => props.theme.spacings.compact}px;
	margin-top: 3px;

	:hover {
		transform: scale(1.05);
	}
`;

const BannerCardFormBody = styled('div')<DivWithImgWidth>`
	label: BannerForm__BannerCardFormBody;
	display: flex;
	flex-direction: ${props => (props.isFullWidth ? 'column' : 'row')};
`;

const ImageContainer = styled('div')`
	label: BannerForm__ImageContainer;
	display: flex;
	flex-direction: column;
	width: 100%;
`;

const ImageTitle = styled('div')`
	label: BannerForm__ImageTitle;
	color: ${props => props.theme.ui.color_5};
	font-size: 14px;
	padding-top: ${props => props.theme.spacings.compact}px;
	padding-left: ${props => props.theme.spacings.compact}px;
`;

const ImageHolder = styled('div')<isEnabled>`
	label: BannerForm__ImageHolder;
	box-sizing: border-box;
	padding: ${props => props.theme.spacings.compact}px;
	padding-top: ${props => props.theme.spacings.tight}px;
	height: auto;
	opacity: ${props => (props.enabled ? '100%' : '30%')};
	transition: opacity 0.3s;
	cursor: pointer;
`;

const BannerImage = styled('img')`
	label: BannerForm__BannerImage;
	max-width: 100%;
	height: auto;
`;

const FieldContainer = styled('div')`
	label: BannerForm__FieldContainer;
	width: 100%;
	padding: ${props => props.theme.spacings.tight}px;
	padding-bottom: 0;
`;

const InputContainer = styled('div')`
	label: BannerForm__InputContainer;
	box-sizing: border-box;
	padding: ${props => props.theme.spacings.tight}px;
	display: flex;
	width: 100%;
`;

const Thinputs = styled('div')`
	label: BannerForm__Thinputs;
	display: flex;
`;

const LeftMarginContainer = styled('div')`
	label: BannerForm__LeftMarginContainer;
	margin-left: ${props => props.theme.spacings.roomy}px;
`;

interface BannerTag {
	id: number;
	name: string;
}

interface bannerObject {
	visible: boolean;
	authentication_status: string;
	link?: string;
	mobileRoute?: null | mobileRouteObject | mobileRouteLegacyObject;
	image_alt?: string;
	image_src: string;
	image_src_mobile: string;
	image_src_web_device: string;
	image_width: number | null;
	id: string;
	position?: Number;
	context_id?: string;
	banner_type?: string;
	selected_tags?: BannerTag[] | null;
}

interface FormProps {
	banner?: bannerObject;
	formKey?: String;
	handleDelete?: Function;
	handleVisibleToggle?: Function;
	handleSave?: Function;
	setDirty?: Function;
	handleBannerEdit?: Function;
}

const BannerForm = ({
	banner,
	formKey,
	handleDelete,
	handleSave,
	setDirty,
	handleBannerEdit,
}: FormProps) => {
	const { brandConfig } = useBrandConfig();
	const featureBanners = brandConfig.featureFlags.banners;

	const initialAuthenticationStatus =
		featureBanners?.tags &&
		banner.authentication_status === AuthenticationStatusType.TAGS
			? null
			: banner.authentication_status;

	const initialValues = {
		visible: banner.visible || false,
		authentication_status:
			initialAuthenticationStatus || AuthenticationStatusType.SIGNED_IN,
		link: banner.link || '',
		mobileRoute: banner.mobileRoute || null,
		image_alt: banner.image_alt || '',
		image_src: banner.image_src || '',
		image_src_mobile: banner.image_src_mobile || '',
		image_src_web_device: banner.image_src_web_device || '',
		image_width: banner.image_width || null,
		id: banner.id,
		position: banner.position || 1,
		banner_type: banner.banner_type || '',
		context_id: banner.context_id || '',
		selected_tags: banner.selected_tags || null,
	};

	const formik = useFormik({
		initialValues,
		enableReinitialize: true,
		validateOnChange: true,
		validateOnBlur: true,
		validationSchema: bannersValidation,
		onSubmit: (values, { setSubmitting, resetForm }) => {
			const cleanedValues: bannerObject = {
				...values,
				mobileRoute:
					(values.mobileRoute && cleanMobileRoute(values.mobileRoute)) || null,
			};

			setSubmitting(false);
			handleSave(formKey, cleanedValues);
			resetForm();
		},
	});

	// Find and set image width for correct form layout
	const [isFullWidth, setIsFullWidth] = useState(false);
	const imgElement = React.createRef<HTMLImageElement>();
	const getImageSize = () => {
		const { naturalWidth, naturalHeight } = imgElement.current;
		const aspectRatio = naturalWidth / naturalHeight;

		setIsFullWidth(aspectRatio >= 5);
	};

	const { banner_type } = formik.values;
	const formChanged = Object.keys(formik.values).some(
		key => formik.values[key] !== formik.initialValues[key],
	);
	const needsContextId = ['race', 'event'].includes(banner_type);
	// Titles for contextId field
	const bannerTypeTitles = {
		race: 'Race or Meeting ID',
		event: 'Event ID',
	};

	const toolTipConfig = {
		backgroundColor: '#000000',
		delayShow: 100,
		multiLine: true,
		delayHide: 1000,
	};

	// ** How we're protecting unfinished forms for banners **
	// react-router's Prompt handles route change (promotions, pages)
	// beforeunload eventListener handles closed browser, refreshed or closed tab
	// setDirty handles bannerNav changes. Quite hacky. Sends dirty state up to BannerEdit where setDirty
	// is set for all banners of this type. i.e. are any banners on the page dirty? Then prompt on bannerNav change.

	const onWindowOrTabClose = event => {
		let message;
		if (!formik.dirty) {
			return;
		}

		if (typeof event == 'undefined') event = window.event;
		if (event) event.returnValue = message;

		return message;
	};

	useEffect(() => {
		setDirty(banner.id, formik.dirty);
		window.addEventListener('beforeunload', onWindowOrTabClose);

		return function cleanup() {
			window.removeEventListener('beforeunload', onWindowOrTabClose);
		};
	});

	const mobileRouteRouterKey = formik.values?.mobileRoute?.routerKey || null;

	const selectedTagsLabel =
		AuthenticationStatusType.TAGS === formik.values.authentication_status
			? 'Included Tags'
			: 'Excluded Tags';

	const selectedTagsEnabled =
		featureBanners?.tags &&
		[
			AuthenticationStatusType.ANY,
			AuthenticationStatusType.SIGNED_IN,
			AuthenticationStatusType.TAGS,
		].includes(formik.values.authentication_status as AuthenticationStatusType);

	const validateField = formik.validateField;

	const reValidateMobileRoute = useCallback(
		// we want to revalidate the mobileRoute object when mobileRouteRouterKey changes
		() => validateField('mobileRoute') || mobileRouteRouterKey,
		[mobileRouteRouterKey, validateField],
	);

	useEffect(() => {
		reValidateMobileRoute();
	}, [reValidateMobileRoute]);

	return (
		<form onSubmit={formik.handleSubmit} key={banner.id}>
			<Prompt
				when={formik.dirty}
				message={'Leave page? Unpublished changes will be lost'}
			/>
			<Container disabled={!formik.values.visible}>
				<BannerCardControlBar>
					<PositionContainer>
						<NumContainer>Position {banner.position}</NumContainer>
					</PositionContainer>
					<ControlContainer>
						<ToggleSwitch
							checked={formik.values.visible}
							onChange={formik.handleChange}
							title={'Banner visible'}
							id={banner.id}
						/>
						<EditIcon onClick={() => handleBannerEdit(banner)}>
							<FaEdit title={'Click to edit'} color="#fff" size="24px" />
						</EditIcon>
						<DeleteIcon onClick={() => handleDelete(banner, formKey)}>
							<FaTrashAlt
								title={'delete rubbish icon'}
								color="#fff"
								size="24px"
							/>
						</DeleteIcon>
						<StyledButtonInverse
							type="submit"
							disabled={!formChanged}
							isactive={true}
						>
							Publish
						</StyledButtonInverse>
					</ControlContainer>
				</BannerCardControlBar>
				<BannerCardFormBody isFullWidth={isFullWidth}>
					<ImageContainer>
						{formik.values.image_src && (
							<>
								<ImageTitle>Desktop</ImageTitle>
								<ImageHolder
									enabled={formik.values.visible}
									isFullWidth={isFullWidth}
									onClick={() => handleBannerEdit(banner)}
									title={'Click to edit'}
								>
									<BannerImage
										src={formik.values.image_src}
										alt={`banner-${formik.values.image_src}`}
										ref={imgElement}
										onLoad={getImageSize}
									/>
								</ImageHolder>
							</>
						)}
						{formik.values.image_src_mobile && (
							<>
								<ImageTitle>Mobile</ImageTitle>
								<ImageHolder
									enabled={formik.values.visible}
									isFullWidth={isFullWidth}
									onClick={() => handleBannerEdit(banner)}
									title={'Click to edit'}
								>
									<BannerImage
										src={formik.values.image_src_mobile}
										alt={`banner-${formik.values.image_src_mobile}`}
										ref={imgElement}
										onLoad={getImageSize}
									/>
								</ImageHolder>
							</>
						)}
						{formik.values.image_src_web_device && (
							<>
								<ImageTitle>Mobile Website</ImageTitle>
								<ImageHolder
									enabled={formik.values.visible}
									isFullWidth={isFullWidth}
									onClick={() => handleBannerEdit(banner)}
									title={'Click to edit'}
								>
									<BannerImage
										src={formik.values.image_src_web_device}
										alt={`banner-${formik.values.image_src_web_device}`}
										ref={imgElement}
										onLoad={getImageSize}
									/>
								</ImageHolder>
							</>
						)}
					</ImageContainer>

					<InputContainer>
						<FieldContainer>
							<LabelWithError
								htmlFor="link"
								title="URL banner link"
								error={formik.errors.link}
								hint={'Optional'}
							>
								<Input
									name="link"
									value={formik.values.link}
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									isError={false}
									type="url"
									disabled={!formik.values.visible}
								/>
							</LabelWithError>

							<Divider />
							<MobileRouteEditor
								values={formik.values.mobileRoute}
								errors={null}
								setField={(fieldName: string, value) =>
									formik.setFieldValue('mobileRoute.' + fieldName, value)
								}
								initialValues={formik.initialValues}
								disabled={false}
							/>
							<Divider />

							<LabelWithError
								htmlFor="image_alt"
								title="Banner alternate text"
								error={formik.errors.image_alt}
								hint={'Brief banner description'}
							>
								<Input
									name="image_alt"
									value={formik.values.image_alt}
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									isError={false}
									type="text"
									disabled={!formik.values.visible}
								/>
							</LabelWithError>

							<Thinputs>
								<LabelWithError
									htmlFor="authentication_status"
									title="Display"
									error={null}
									hint={'Who can see the banner'}
									hasToolTip={true}
									toolTipFor={'bannerDisplayTip'}
								>
									<Select
										name="authentication_status"
										className="thin"
										value={formik.values.authentication_status}
										onChange={formik.handleChange}
										onBlur={formik.handleBlur}
										isError={false}
										disabled={!formik.values.visible}
									>
										<option value={AuthenticationStatusType.ANY} label="All" />
										<option
											value={AuthenticationStatusType.SIGNED_OUT}
											label="Guests Only"
										/>
										<option
											value={AuthenticationStatusType.SIGNED_IN}
											label="Logged in Users"
										/>
										{featureBanners?.tags && (
											<option
												value={AuthenticationStatusType.TAGS}
												label="Selected Tags"
											/>
										)}
									</Select>
								</LabelWithError>
								{selectedTagsEnabled && (
									<React.Fragment>
										<LabelWithError
											htmlFor="selected_tags"
											title={selectedTagsLabel}
											error={null}
											hint={'Tags who can see the banner'}
											hasToolTip={true}
											toolTipFor={'bannerTagsTip'}
										>
											<BannerTagInput
												onChange={tags =>
													formik.setFieldValue('selected_tags', tags)
												}
												selectedTags={formik.values.selected_tags}
											/>
										</LabelWithError>
										<ReactTooltip
											id="bannerTagsTip"
											effect="solid"
											{...toolTipConfig}
											getContent={() => (
												<div>
													Tags only apply to registered members and determine
													who can see, or not see, the banner.
													<br />
													When including, will show the banner if any of the
													member's tags match any of the tags here.
													<br />
													Otherwise if excluding, will hide the banner if any
													tags here match the member's.
												</div>
											)}
										></ReactTooltip>
									</React.Fragment>
								)}
								{needsContextId && (
									<LeftMarginContainer>
										<LabelWithError
											htmlFor="context_id"
											title={bannerTypeTitles[formik.values.banner_type]}
											error={formik.errors.context_id}
											hint="Required for banner to be visible"
											hasToolTip={true}
											toolTipFor={
												formik.values.banner_type === 'race'
													? 'bannerRaceContextIdTip'
													: 'bannerSportContextIdTip'
											}
										>
											<Input
												name="context_id"
												className="thin"
												value={formik.values.context_id}
												onChange={formik.handleChange}
												onBlur={formik.handleBlur}
												isError={false}
												disabled={!formik.values.visible}
											/>
										</LabelWithError>
									</LeftMarginContainer>
								)}
							</Thinputs>
						</FieldContainer>
					</InputContainer>
				</BannerCardFormBody>
				<ReactTooltip id="bannerSourceTip" effect="solid" {...toolTipConfig}>
					Banner dimensions must be: <br />
					1170 x 440 (mobile)
					<br />
					1170 x 150 (desktop)
				</ReactTooltip>
				<ReactTooltip
					id="bannerDisplayTip"
					effect="solid"
					{...toolTipConfig}
					getContent={() => (
						<div>
							Due to gambling regulation laws, some banners <br />
							must only be visible to signed in users.
							<br />
							<a
								style={{ color: '#000' }}
								href="https://www.acma.gov.au/gambling-ads-during-live-sport-streamed-over-internet"
							>
								Learn more
							</a>
						</div>
					)}
				></ReactTooltip>
				<ReactTooltip
					id="bannerRaceContextIdTip"
					effect="solid"
					{...toolTipConfig}
				>
					Banners can display for single races or entire race meetings.
					<br />
					i.e. Meeting banners will show for every race in that meeting.
				</ReactTooltip>
				<ReactTooltip
					id="bannerSportContextIdTip"
					effect="solid"
					{...toolTipConfig}
				>
					Banners can display for sport events.
					<br />
					Add event ID for single events.
					<br />
				</ReactTooltip>
			</Container>
		</form>
	);
};

export default BannerForm;
