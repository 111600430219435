import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import styled from '../../../../common/styled';
import { Holder, Input, LabelWithError } from '../../../shared/FormElements';
import { partialApply } from '../../../../helpers/pureFunc';
import { getErrorMessage, urlRequired } from '../FooterValidation';

const StyledFooterMobileAppEditor = styled.div`
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
`;

const platforms = ['android', 'ios'] as const;

interface FooterMobileItem {
	code: string;
	href: string;
}

interface FooterMobileAppEditorProps {
	Items: { [K in typeof platforms[number]]?: FooterMobileItem };
	setField: (paths: string[], value: any, validate: boolean) => void;
}

export const FooterMobileAppsEditor = ({
	Items,
	setField,
}: FooterMobileAppEditorProps) => {
	const setItem = (
		address: typeof platforms[number],
		href: FooterMobileItem['href'] | null,
	) => setField(['Items', address], href ? { href } : null, true);

	return (
		<StyledFooterMobileAppEditor>
			{platforms.map((platform) => (
				<FooterMobileApp
					key={platform}
					title={platform}
					code={platform}
					href={Items?.[platform]?.href || null}
					onEdit={partialApply(setItem, platform)}
				/>
			))}
		</StyledFooterMobileAppEditor>
	);
};

const StyledFooterMobileAppActions = styled.div`
	display: flex;
	justify-content: space-between;
	background: ${({ theme }) => theme.brand.color_2};
	align-items: baseline;
	& > * {
		margin: ${({ theme }) => theme.spacings.comfortable}px;
		color: white;
	}
`;

const StyledMoveRight = styled.div`
	margin-left: auto;
`;

const StyledDeleteLink = styled(Link)<{ visibility?: string; to: string }>`
	visibility: ${({ visibility }) => visibility || 'unset'};
	color: white;
`;

interface FooterMobileAppProps {
	title: string;
	code: string;
	href: string | null;
	onEdit: (input: string) => void;
};

const FooterMobileApp = ({
	title,
	code,
	href,
	onEdit,
}: FooterMobileAppProps) => {
	const [formError, setFormError] = useState('');

	useEffect(() => {
		if (!href) return void setFormError('');

		setFormError('pending');
		setFormError(getErrorMessage(urlRequired, href));
	}, [href]);

	const selfDestruct = () => onEdit(null);
	const headingLookup = {ios:'iOS',android:'Android'};
	return (
		<Holder width="100%">
			<StyledFooterMobileAppActions>
				<span>{headingLookup[title]||title}</span>
				<StyledMoveRight />
				<StyledDeleteLink
					to="#"
					visibility={!!href ? 'unset' : 'hidden'}
					onClick={(e) => void e.preventDefault() || selfDestruct()}
				>
					Remove
				</StyledDeleteLink>
			</StyledFooterMobileAppActions>
			<Holder width="100%">
				<LabelWithError
					title="href"
					hint="URL / Destination for App Download"
					htmlFor={'mobileHref' + code}
					error={formError}
				>
					<Input
						name={'mobileHref' + code}
						value={href || ''}
						onChange={(e) => onEdit(e.target.value)}
					/>
				</LabelWithError>
			</Holder>
		</Holder>
	);
};
