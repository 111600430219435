import { AxiosError, AxiosInstance, AxiosResponse } from 'axios';

interface promotionRedemptionType {
	user_id: number;
	email: string;
	redeemed_at: string;
	username: string;
}

export interface promotionRedemptionsResponseType {
	timestamp: string;
	data: promotionRedemptionType[];
	http_status_code: number;
	total: number;
	per_page: number;
	current_page: number;
	last_page: 1;
	next_page_url: null | string;
	pre_page_url: null | string;
	from: number;
	to: number;
}

export function promotionRedemptions(
	getConfig: () => {
		axiosInstance: AxiosInstance;
	},
	promoId: number,
	page?: number,
	items?: number,
): Promise<AxiosResponse<promotionRedemptionsResponseType> | AxiosError> {
	const params = {};
	if (page) params['page'] = page.toFixed(0);
	if (items) params['items'] = items.toFixed(0);

	return getConfig()
		.axiosInstance.get(`/${promoId}/redemptions`, {
			params: params,
		})
		.catch((e) => e);
}

export function promotionRedemptionExport(
	getConfig: () => {
		axiosInstance: AxiosInstance;
	},
	promoId: number,
): Promise<AxiosResponse | AxiosError> {
	const { axiosInstance } = getConfig();
	const buildUrl = `/${promoId}/redemptions/export`;

	return axiosInstance.get(buildUrl).catch((e) => e);
}
