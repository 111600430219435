import {useState, useEffect, useCallback, RefObject} from 'react';

interface Size {
    width: number,
    height: number,
}

/**
 * you pass it a React ref to either a HTMLElement or the Window itself 
 * returns back its size, and will recalculate on resize
 * 
 * inspired by the usehooks examples see https://usehooks-typescript.com/use-element-size/
 */
const useElementSize = <T extends HTMLElement | Window>(elementRef: RefObject<T>):Size => {
    const [size, setSize] = useState<Size>({width: 0, height: 0});
    const updateSize = useCallback(() => {
        const node = elementRef?.current;
        if (node && node instanceof Window)
            setSize({width: node.innerWidth, height: node.innerHeight});
        else if (node && node instanceof HTMLElement)
            setSize({width: node.offsetWidth, height: node.offsetHeight})
    }, [elementRef])

    useEffect(() => {
        // run updateSize initially
        updateSize();
        // set up listener && listener remover
        const node = elementRef.current;
        node?.addEventListener?.('resize', updateSize);
        return () => node?.removeEventListener?.('resize',updateSize);
    },[elementRef, updateSize])

    return size;
}

export default useElementSize;