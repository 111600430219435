import { AxiosResponse, AxiosInstance, AxiosError } from 'axios';
import { PromotionEngineItem } from './common';

export interface promotionValidateResponseSuccessful {
	timestamp: string;
	http_status_code: 200;
}

export interface promotionValidateResponseFail {
	timestamp: string;
	http_status_code: 422;
	errors: {
		[K in keyof PromotionEngineItem]?: string[];
	};
}

export type promotionValidateResponse =
	| promotionValidateResponseSuccessful
	| promotionValidateResponseFail;

function promotionValidate(
	getConfig: () => {
		axiosInstance: AxiosInstance;
	},
	promotion: Partial<PromotionEngineItem>,
	id?: number,
): Promise<
	| AxiosResponse<promotionValidateResponse>
	| AxiosError<promotionValidateResponseFail | any>
> {
	const { axiosInstance } = getConfig();
	return axiosInstance
		.post(`/validate${id ? `/${id.toFixed(0)}` : ''}`, promotion)
		.catch((e) => e);
}

export default promotionValidate;
