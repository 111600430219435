const isValidUrl = (s) => {
	try {
		new URL(s);
	} catch (_) {
		return false;
	}
	return true;
};

export default isValidUrl;
