import React, { useState, useEffect } from 'react';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import arrayMove from 'array-move';
import styled from '../../common/styled';

import { Divider, Hint } from '../shared/FormElements';
import { StyledButton } from '../shared/Navs';
import BannerSortView from './BannerSortView';
import NoBanners from '../shared/NoBanners';

const TitleContainer = styled('div')`
	display: flex;
	justify-content: space-between;
	margin-bottom: -${(props) => props.theme.spacings.compact}px;
`;

const SubmitContainer = styled('div')`
	display: flex;
	justify-content: flex-end;
	align-self: center;
	margin-top: ${(props) => props.theme.spacings.cozy}px;
`;

const SortContainer = styled('div')`
	display: flex;
	justify-content: center;
`;

const BannerSort = ({
	banners,
	formKey,
	handleToggle,
	handleSortPublish,
	handleTabSelect,
	buildMutableBannersArray,
	sortByPosition,
	title,
	isLoading,
}) => {
	const [stateBanners, setBanners] = useState(banners[formKey]);

	useEffect(() => {
		let newBanners = banners[formKey];
		if (banners[formKey] !== 'No items') {
			newBanners = sortByPosition(banners[formKey]);
		}
		setBanners(newBanners);
	}, [banners, formKey, sortByPosition]);

	const SortableItem = SortableElement(({ banner, index }) => {
		return <BannerSortView banner={banner} key={index} />;
	});

	const SortableList = SortableContainer(() => {
		return (
			<SortContainer>
				{stateBanners.map((banner, i) => (
					<SortableItem banner={banner} index={i} key={i} />
				))}
			</SortContainer>
		);
	});

	const handleSort = ({ oldIndex, newIndex }) => {
		// Restores document cursor style
		document.body.style.cursor = 'default';

		// Sorts array index & makes it immutable
		const newBanners = arrayMove(stateBanners, oldIndex, newIndex);
		let updatedBanners = buildMutableBannersArray(newBanners, true);

		setBanners(updatedBanners);
	};

	const onSortStart = () => (document.body.style.cursor = 'grabbing');
	const onSortEnd = ({ oldIndex, newIndex, collection }) =>
		handleSort({ oldIndex, newIndex, collection });

	return (
		<>
			<TitleContainer>
				<h2>Sort {title} Banners</h2>
				<StyledButton onClick={() => handleToggle()}>Edit</StyledButton>
			</TitleContainer>
			<Hint>Click and drag horizontally to sort</Hint>
			<Divider />

			{!isLoading &&
				(stateBanners === 'No items' ? (
					<NoBanners handleTabSelect={handleTabSelect} />
				) : (
					<>
						<SortableList
							onSortStart={onSortStart}
							onSortEnd={onSortEnd}
							useDragHandle={false}
							axis={'x'}
							lockAxis={'x'}
						/>
						<SubmitContainer>
							<StyledButton
								onClick={() => handleSortPublish(stateBanners, formKey)}
							>
								Publish
							</StyledButton>
						</SubmitContainer>
					</>
				))}
		</>
	);
};

export default BannerSort;
