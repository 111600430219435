import styled, { css } from '../../../common/styled';

export const FooterItemMenu = styled.h4<{ open: boolean }>(
	({ theme, open }) => css`
		label: FooterConfigForm_ControlBar;
		padding: ${theme.spacings.cozy}px;
		background: ${open ? 'inherit' : theme.brand.color_2};
		display: flex;
		justify-content: space-between;
		align-items: baseline;
		color: ${open ? 'inherit' : 'white'};
		margin: 0px;
	`,
);

export const FooterTopLabel = styled.div`
	display: flex;
	display: flex;
	justify-content: space-between;
	align-items: baseline;
	padding-right: 40px;
	margin-bottom: ${({theme})=>theme.spacings.compact}px;
`;

export const FooterItemMenuSendRight = styled.span`
	margin-inline-start: auto;
	& > * {
		margin-right: ${({ theme }) => theme.spacings.cozy}px;
	}
`;

export const FooterItemContentArea = styled.div<{ open: boolean }>`
	padding: ${({ theme }) => theme.spacings.cozy}px;
	transition: all 0.3s;
`;

export const StyledFooterGroupItemTop = styled.div`
	z-index: 2;
	display: flex;
	padding: ${({ theme }) => theme.spacings.cozy}px;
	background: ${({ theme }) => theme.brand.color_2};
	color: white;
	justify-content: space-between;
	align-items: baseline;
	cursor: pointer;
	&:hover{
		filter: brightness(130%) hue-rotate(30deg);
	}
`;
