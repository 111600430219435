import React, { useEffect, useState } from 'react';
import { configItemProp } from '../index';
import styled from '../../../../common/styled';
import {
	footerGroupInterface,
	footerItemInterface,
} from './FooterContentGroups';
import { footerGroupItem } from '../../../../helpers/footerEndpoints/TypesDefs';
import { LabelWithError, Holder, Select } from '../../../shared/FormElements';
import FooterContentGroupEditor from './FooterContentGroupsEditor';
import FooterContentItemsEditor from './FooterContentItemsEditor';

interface contentItem {
	title: string;
	id?: string;
	slug?: string;
	href: string;
}

interface contentGroup {
	groupName: string;
	Items: contentItem[];
}

interface props extends configItemProp {
	compactLayout: boolean;
	Items: contentGroup[];
	ContentPages: (footerItemInterface & { id: string })[];
}

const StyledCompactLayout = styled.div`
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
`;

export const ContentFormPart = ({
	compactLayout,
	Items = [],
	setField,
	ContentPages,
}: props) => {
	const [pendingGroupUpdate, updateGroups] = useState<null | footerGroupItem[]>(
		null,
	);

	useEffect(() => {
		if (pendingGroupUpdate) {
			const temp = pendingGroupUpdate;
			updateGroups(null);
			setField(['Items'], temp, true);
		}
	}, [pendingGroupUpdate, setField]);

	let compactLayoutHint = 'Hint: ';
	const footerItemsCount = Items.reduce(
		(acc, nextGroup) => acc + nextGroup.Items.length,
		0,
	);

	const bitwiseCompactLayoutAndFooterItemCountGreaterThan6 =
		((compactLayout ? 1 : 0) << 1) ^ (footerItemsCount > 6 ? 1 : 0);

	switch (bitwiseCompactLayoutAndFooterItemCountGreaterThan6) {
		case 0: // !compactLayout && footerItemsCount <= 6
			compactLayoutHint = '';
			break;
		case 1: //!compactLayout && footerItemsCount > 6
		case 2: // compactLayout && footerItems <= 6
			compactLayoutHint = '';
			break;
		case 3: // compactLayout && footerItemCount > 6
			compactLayoutHint = '';
			break;
		default:
			compactLayoutHint = '';
	}

	return (
		<StyledCompactLayout>
			<Holder width="100%">
				<LabelWithError
					title="Compact Layout"
					htmlFor="compactLayout"
					error=""
					hint={compactLayoutHint}
				>
					<Select
						value={compactLayout ?? false ? 'true' : 'false'}
						id="compactLayout"
						onChange={(e) => {
							const newSetting = e.target.value === 'true';
							if (newSetting === true) {
								const result =
									Items.length === 1 ||
									window.confirm(
										'This will remove all Footer Groups whist preserving footer Items, Do you want to continue?',
									);
								if (result) {
									setField(['compactLayout'], newSetting, true);
									const masterGroup: footerGroupInterface = {
										groupName: 'Links',
										Items: Items.flatMap((group) => group.Items),
									};
									setField(['Items'], [masterGroup], true);
									return;
								} else return;
							}

							// newSetting === false
							setField(['compactLayout'], e.target.value === 'true', true);
						}}
					>
						<option value="true">True</option>
						<option value="false">False</option>
					</Select>
				</LabelWithError>
			</Holder>

			<FooterContentGroupEditor setGroups={updateGroups}>
				{{
					groups: Items,
					compactLayout,
				}}
			</FooterContentGroupEditor>
			<FooterContentItemsEditor setGroups={updateGroups}>
				{{
					compactLayout,
					contentPages: ContentPages,
					groups: Items,
				}}
			</FooterContentItemsEditor>
		</StyledCompactLayout>
	);
};

export default ContentFormPart;
