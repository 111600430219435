import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useOktaAuth } from '@okta/okta-react';

import { setAuth, setAuthUser, setAuthLoading } from '../store/auth';

const AuthHandler = () => {
	const { authService, authState } = useOktaAuth();
	const location = useLocation();
	const dispatch = useDispatch();

	useEffect(() => {
		if (location.pathname === '/login') authService.login('/');
		if (location.pathname === '/logout') authService.logout('/');
	}, [authService, location.pathname, authState]);

	useEffect(() => {
		const fetchUserDetails = async () => {
			if (authState.isAuthenticated) {
				dispatch(setAuthLoading(true));
				await authService.getUser().then((user) => dispatch(setAuthUser(user)));
				dispatch(setAuthLoading(false));
			} else dispatch(setAuthUser(null));
		};
		dispatch(setAuth(authState));
		fetchUserDetails();
	}, [authState, authService, dispatch]);

	return <></>;
};

export default AuthHandler;
