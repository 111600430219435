import React from 'react';
import styled, { css } from '../../common/styled';
import { FiX } from 'react-icons/fi';

import Card from '../shared/Card';
import { StyledButton } from '../shared/Navs';
import PageHeader from '../shared/PageHeader';

const StyledImage = styled('img')(
	(props) => css`
		label: QuickLinksHelp__Image;

		width: ${props.shrink ? '50%' : '95%'};
		min-width: 350px;
		display: block;
		margin-bottom: ${props.theme.spacings.spacious}px;
	`,
);

const StyledList = styled('ul')(
	(props) => css`
		label: QuickLinksHelp__List;

		margin-bottom: ${props.theme.spacings.spacious}px;
	`,
);

const QuickLinksHelp = ({ closeModal }) => {
	return (
		<Card>
			<PageHeader title="Quicklinks Help">
				<StyledButton onClick={closeModal}>
					Close <FiX />
				</StyledButton>
			</PageHeader>
			<p>
				Quicklinks are modifiable and easily accessible navigation items for
				common and popular tasks/events.
			</p>
			<StyledImage src="/images/quicklinks.png" alt="Quicklinks example" />
			<p>
				Quicklinks appear near the top of the display, and can be selectively
				shown on any or all of the following locations:
			</p>
			<StyledList>
				<li>Homepage</li>
				<li>Racing</li>
				<li>Sports</li>
				<li>Side Navigation Panel</li>
			</StyledList>
			<strong>
				Mobile and Web Quicklinks targets - where a quicklink takes the user
			</strong>
			<p>
				For a quicklink to appear on the web application, a link to the desired
				target must be provided in the <em>Link To Target Event/Page</em> field.
			</p>
			<p>
				For a quicklink to appear on the mobile app, the <em>RouterKey</em>{' '}
				location must be selected, and the subsequent ID's provided if the{' '}
				<em>Racing</em> or <em>Sports</em> RouterKey is selected.
			</p>
		</Card>
	);
};

export default QuickLinksHelp;
