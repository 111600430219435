import { combineReducers } from 'redux';

import auth, { authInterface } from './auth';
import pages, { pagesInterface } from './pages';
import promotions, { promotionsInterface } from './promotions';
import banners, { bannersInterface } from './banners';
import highlights, { HighlightStateInterface } from './highlights';
import footer, { footerConfigInterface } from './footers';
import quickLinks, { QuicklinksState } from './quicklinks';
import application, { ApplicationInterface } from './application';

export interface MasterState {
	auth: authInterface;
	pages: pagesInterface;
	promotions: promotionsInterface;
	banners: bannersInterface;
	highlights: HighlightStateInterface;
	footer: { loading: boolean; config: footerConfigInterface };
	quickLinks: QuicklinksState;
	application: ApplicationInterface;
}

export default combineReducers<MasterState>({
	auth,
	pages,
	promotions,
	banners,
	highlights,
	footer,
	quickLinks,
	application,
});
