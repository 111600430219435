export type TupleMissingHead<T extends any[]> = T extends [
	head: any,
	...tail: infer Tail_
]
	? Tail_
	: never;

export type FuncMissingFirstParam<F extends (...args: any[]) => any> = (
	...args: TupleMissingHead<Parameters<F>>
) => ReturnType<F>;

export const partialApply = <A extends unknown[], B extends unknown[], O>(
	func: (...args: [...A, ...B]) => O,
	...argsA: A
) => (...argsB: B) => func(...argsA, ...argsB);
