import Axios from 'axios';
import React, { useState } from 'react';
import { MdCancel } from 'react-icons/md';
import styled from '../../common/styled';
import { useBrandConfig } from '../../context/brandConfig/brandConfigContext';
import { Input, TagInput, TagRow, TagSelectItem } from '../shared/FormElements';

const Container = styled.div`
	display: flex;
	flex-direction: column;
`;

interface Tags {
	id: number;
	name: string;
}

interface Props {
	onChange?: Function;
	selectedTags?: Tags[];
}

const BannerTagInput = ({ onChange, selectedTags }: Props) => {
	const { brandConfig } = useBrandConfig();
	const { bettingEngineApi: BETTING_ENGINE_API } = brandConfig;
	const [text, setText] = useState('');
	const [tags, setTags] = useState([]);
	const [selectedList, setSelectedList] = useState(selectedTags);

	const onHandleChange = async (text: string) => {
		setText(text);
		const TAG_ENDPOINT =
			BETTING_ENGINE_API && `${BETTING_ENGINE_API}/tags?q=${text}`;
		const response = await Axios(TAG_ENDPOINT);
		setTags(response.data);
	};

	const onItemnSelect = (item: any) => {
		const isItem = selectedList.find((s: any) => s.id === item.id);
		if (!isItem) {
			const items = [...selectedList, ...[item]];
			setSelectedList(items);
			onChange(items);
		}
	};

	const onItemnRemove = (item: any) => {
		const isItem = selectedList.find((s: any) => s.id === item.id);
		const items = selectedList.filter((s: any) => s.id !== isItem.id);
		setSelectedList(items);
		onChange(items);
	};

	return (
		<Container>
			<TagInput>
				<Input
					value={text}
					onChange={e => onHandleChange(e.target.value)}
					isError={false}
					type="text"
				/>
				{text && tags && tags.length > 0 && (
					<div onClick={e => e.stopPropagation()}>
						{tags.map((item: any) => {
							const isSelect: any =
								selectedTags && selectedTags.find(s => s.id === item.id);
							return (
								<TagSelectItem
									key={item.id}
									isSelected={isSelect}
									onClick={() => onItemnSelect(item)}
								>
									{item.name}
									{isSelect && (
										<MdCancel
											onClick={() => onItemnRemove(item)}
											color="#000"
											size="24px"
										/>
									)}
								</TagSelectItem>
							);
						})}
					</div>
				)}
			</TagInput>
			{selectedTags && selectedTags.length > 0 ? (
				<TagRow>
					{selectedTags.map((item: any) => {
						return (
							<div key={item.id}>
								{item.name}
								<MdCancel
									onClick={() => onItemnRemove(item)}
									color="#000"
									size="16px"
								/>
							</div>
						);
					})}
				</TagRow>
			) : (
				<div />
			)}
		</Container>
	);
};
export default BannerTagInput;
