import styled from '../../common/styled';

export const TabGroup = styled('div')`
	label: Tab__Group;
	padding-bottom: ${(props) => props.theme.spacings.compact}px;
	display: flex;
	@media only screen and (max-width: 600px) {
		flex-direction: column;
		padding-bottom: ${(props) => props.theme.spacings.tight}px;
	}
`;

interface isactiveProp {
	isactive?: string;
}

export const TabItem = styled.span<isactiveProp>`
	label: Tab__Item;

	margin-right: ${(props) => props.theme.spacings.cozy}px;
	text-decoration: none;
	color: ${(props) =>
		props.isactive === 'true'
			? props.theme.brand.color_1
			: props.theme.brand.color_2_light};
	padding: ${(props) => props.theme.spacings.cozy}px 0;
	border-bottom: ${(props) =>
		props.isactive === 'true'
			? '2px solid ' + props.theme.brand.color_1
			: '0 solid ' + props.theme.brand.color_2_light};
	display: flex;
	text-align: center;
	cursor: pointer;
	@media only screen and (max-width: 600px) {
		text-align: left;
		display: inline-block;
		align-self: flex-start;
		padding: ${(props) => props.theme.spacings.cozy}px 0
			${(props) => props.theme.spacings.tight}px;
		border-bottom: ${(props) =>
			props.isactive === 'true'
				? '2px solid ' + props.theme.brand.color_1
				: '2px solid white'};
	}
`;
