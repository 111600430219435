import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ToastsStore } from 'react-toasts';
import { configurationLoader } from '../context/brandConfig/brandConfig';
import BannerEndpoints from '../helpers/bannerEndpoints';
import {
	AuthenticationStatusType,
	BannerItemType,
	banner_types,
} from '../helpers/bannerEndpoints/TypesDefs';

export type bannerObject = BannerItemType;

export interface bannersInterface {
	homepage?: Array<bannerObject>;
	racing?: Array<bannerObject>;
	race?: Array<bannerObject>;
	sports?: Array<bannerObject>;
	event?: Array<bannerObject>;
	isFetching?: Boolean;
}

const initialState: bannersInterface = {
	homepage: [],
	racing: [],
	sports: [],
	race: [],
	event: [],
	isFetching: false,
};

const bannerApiFactory = state => {
	//@ts-ignore
	const authToken = state?.auth?.accessToken;
	//@ts-ignore
	const brand = state?.application?.brand;
	const brandManagerEndpoint = configurationLoader(brand).brandManagerEndpoint;

	const BannerApi = new BannerEndpoints(`${brandManagerEndpoint}/banner`);
	BannerApi.useAuthCode(authToken);
	return BannerApi;
};

export const fetchAllBanners = createAsyncThunk(
	'banner/fetchAllbanners',
	async (banner_type: banner_types, thunkAPI) => {
		const BannerApi = bannerApiFactory(thunkAPI.getState());

		return {
			banner_type,
			items: await BannerApi.get(AuthenticationStatusType.ANY, banner_type),
		};
	},
);

export const fetchHomepageBanners = createAsyncThunk(
	'banner/fetchHomepageBanners',
	async (_, thunkAPI) => {
		const BannerApi = bannerApiFactory(thunkAPI.getState());

		return await BannerApi.fetchHomepageBanners(AuthenticationStatusType.ANY);
	},
);

export const fetchRacingBanners = createAsyncThunk(
	'banner/fetchRacingBanners',
	async (_, thunkAPI) => {
		const BannerApi = bannerApiFactory(thunkAPI.getState());

		return await BannerApi.fetchRacingBanners(AuthenticationStatusType.ANY);
	},
);

export const fetchRaceBanners = createAsyncThunk(
	'banner/fetchRaceBanners',
	async (_, thunkAPI) => {
		const BannerApi = bannerApiFactory(thunkAPI.getState());

		return await BannerApi.fetchRaceBanners(AuthenticationStatusType.ANY);
	},
);

export const fetchSportsHomeBanners = createAsyncThunk(
	'banner/fetchSportsHomeBanners',
	async (_, thunkAPI) => {
		const BannerApi = bannerApiFactory(thunkAPI.getState());

		return await BannerApi.fetchSportsHomeBanners(AuthenticationStatusType.ANY);
	},
);

export const fetchEventBanners = createAsyncThunk(
	'banner/fetchEventBanners',
	async (_, thunkAPI) => {
		const BannerApi = bannerApiFactory(thunkAPI.getState());

		return await BannerApi.fetchEventBanners(AuthenticationStatusType.ANY);
	},
);

export const updateBanner = createAsyncThunk(
	'banner/updateBanner',
	async (banner: bannerObject, thunkAPI) => {
		const BannerApi = bannerApiFactory(thunkAPI.getState());

		return await BannerApi.updateBanner(banner);
	},
);

// Duplicate of `updateBanner` to avoid multiple success toasts on banner sort
export const updateBanners = createAsyncThunk(
	'banner/updateBanners',
	async (banner: bannerObject, thunkAPI) => {
		const BannerApi = bannerApiFactory(thunkAPI.getState());
		return await BannerApi.updateBanner(banner);
	},
);

export const createBanner = createAsyncThunk(
	'banner/createBanner',
	async (banner: bannerObject, thunkAPI) => {
		const BannerApi = bannerApiFactory(thunkAPI.getState());
		return await BannerApi.createBanner(banner);
	},
);

export const deleteBanner = createAsyncThunk(
	'banner/deleteBanner',
	async (banner: bannerObject, thunkAPI) => {
		const BannerApi = bannerApiFactory(thunkAPI.getState());

		return await BannerApi.deleteBanner(banner);
	},
);

const { actions, reducer } = createSlice({
	name: 'loadBanners',
	initialState,
	reducers: {
		setBanners(state, { payload }: { payload: bannersInterface }) {
			state = payload;
		},
	},
	extraReducers: {
		// deleteBanner
		[deleteBanner.fulfilled as any]: () => {
			ToastsStore.success('Banner deleted', 2000);
		},
		[deleteBanner.rejected as any]: () => {
			ToastsStore.error(
				'There was a problem deleting this banner. Please try again',
				3000,
			);
		},
		// createBanner
		[createBanner.fulfilled as any]: state => {
			ToastsStore.success('Banner successfully created', 2000);
			state.isFetching = false;
		},
		[createBanner.rejected as any]: state => {
			state.isFetching = false;
			ToastsStore.error(
				'There was a problem creating this banner. Please try again',
				3000,
			);
		},
		// updateBanner
		[updateBanner.fulfilled as any]: () => {
			ToastsStore.success('Banner updated', 2000);
		},
		[updateBanner.rejected as any]: () => {
			ToastsStore.error(
				'There was a problem updating this banner. Please try again',
				3000,
			);
		},
		// updateBanners (multi for sorting)
		[updateBanners.fulfilled as any]: () => {},
		[updateBanners.rejected as any]: () => {
			ToastsStore.error(
				'There was a problem updating this banner. Please try again',
				3000,
			);
		},
		// Homepage
		[fetchHomepageBanners.pending as any]: state => {
			state.isFetching = true;
		},
		[fetchHomepageBanners.fulfilled as any]: (state, action) => {
			state.homepage = action.payload;
			state.isFetching = false;
		},
		// Racing
		[fetchRacingBanners.pending as any]: state => {
			state.isFetching = true;
		},
		[fetchRacingBanners.fulfilled as any]: (state, action) => {
			state.racing = action.payload;
			state.isFetching = false;
		},
		// Race
		[fetchRaceBanners.pending as any]: state => {
			state.isFetching = true;
		},
		[fetchRaceBanners.fulfilled as any]: (state, action) => {
			state.race = action.payload;
			state.isFetching = false;
		},
		// SportsHome
		[fetchSportsHomeBanners.pending as any]: state => {
			state.isFetching = true;
		},
		[fetchSportsHomeBanners.fulfilled as any]: (state, action) => {
			state.sports = action.payload;
			state.isFetching = false;
		},
		// Event
		[fetchEventBanners.pending as any]: state => {
			state.isFetching = true;
		},
		[fetchEventBanners.fulfilled as any]: (state, action) => {
			state.event = action.payload;
			state.isFetching = false;
		},
		// All
		[fetchAllBanners.pending as any]: state => {
			state.isFetching = true;
		},
		[fetchAllBanners.fulfilled as any]: (state, action) => {
			state[action.payload.banner_type] = action.payload.items;
			state.isFetching = false;
		},
	},
});

export const { setBanners } = actions;
export default reducer;
