import { AxiosError, AxiosResponse } from 'axios';
import * as React from 'react';
import { ToastsStore } from 'react-toasts';
import {
	manualPromoFormInterface,
	promotionItemInterface,
	racingBoostFormInterface,
	runnerFormInterface,
	sportBoostFormInterface,
} from '../../helpers/promotionEngine/promotionEngineInterfaces';
import { promotionsCreateResponse } from '../../helpers/promotionEngine/rawEndpointFunc/promotionCreate';
import { promotionsUpdateResponse } from '../../helpers/promotionEngine/rawEndpointFunc/promotionUpdate';
import { promotionValidateResponseFail } from '../../helpers/promotionEngine/rawEndpointFunc/promotionValidate';
import { partialApply } from '../../helpers/pureFunc';
import { getModalContentElement } from './PromotionEngineForms';
import usePromotionEngineAPI from './usePromotionEngineAPI';

interface ModalContentProps {
	ItemOpenDetail: promotionItemInterface | null;
	mode: 'view' | 'edit' | 'new' | 'redeem';
	id: number | null;
	viewEditCloseDeleteById: (
		id: number,
		promotion_type_id: number,
		mode: 'view' | 'edit' | 'close' | 'delete' | 'redeem',
	) => void;
	APIHandler: ReturnType<typeof usePromotionEngineAPI>['APIHandler'];
	formElement: number;
	setFormElement: unknown;
}

const ModalContent: React.SFC<ModalContentProps> = ({
	ItemOpenDetail,
	mode,
	id,
	viewEditCloseDeleteById,
	APIHandler,
	formElement,
	setFormElement,
}) => {
	const ModalContentElement = getModalContentElement(
		formElement,
		mode,
		setFormElement,
	);

	const validatePromoItem = async (
		promo: promotionItemInterface,
	): Promise<{ [K in keyof promotionItemInterface]?: string }> => {
		const joinFieldErrors = (input: promotionValidateResponseFail) =>
			Object.entries(input?.errors || {})
				.map(([key, value]) => [key, value.join('/n')])
				.reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});

		const id = promo?.id || undefined;

		const result = await APIHandler.validationPromotion(promo, id);

		if (result instanceof Error) {
			const isValidationError = (
				input: AxiosError,
			): input is AxiosError<promotionValidateResponseFail> =>
				input?.response?.status === 422;
			if (isValidationError(result))
				return joinFieldErrors(result?.response?.data);

			// no error
			return {};
		}
	};
	const handleSave = async (
		input: Omit<
			| promotionItemInterface
			| runnerFormInterface
			| manualPromoFormInterface
			| sportBoostFormInterface
			| racingBoostFormInterface,
			'id'
		> & {
			id?: number;
		},
	): Promise<
		| AxiosError<any>
		| AxiosResponse<promotionsUpdateResponse | promotionsCreateResponse>
	> => {
		const isFullItem = (input): input is promotionItemInterface => !!input.id;

		const results = isFullItem(input)
			? await APIHandler.editPromotion(input)
			: await APIHandler.createPromotion(input);

		if (results instanceof Error) {
			if (results.response.status === 404) {
				ToastsStore.error('Failed To Save, as Item no longer exists', 2000);
				viewEditCloseDeleteById(
					input?.id,
					input?.promotion_type_id || 1,
					'close',
				);
			} else {
				ToastsStore.error('Failed To Save Item');
			}
		}
		APIHandler.endpoints.clearCache();
		APIHandler.executeFetchPromotions();

		return results;
	};

	const promotionTypeId = ItemOpenDetail?.promotion_type_id || 1;

	return (
		<>
			<ModalContentElement
				item={ItemOpenDetail}
				saveChanges={handleSave}
				validate={validatePromoItem}
				setViewEditCloseDelete={partialApply(
					viewEditCloseDeleteById,
					id,
					promotionTypeId,
				)}
				formElement={formElement}
				setFormElement={setFormElement}
				mode={mode}
				cachedPromotionEndpoints={APIHandler.endpoints}
			/>
		</>
	);
};

export default ModalContent;
