import { LoginCallback, SecureRoute } from '@okta/okta-react';
import { ThemeProvider } from 'emotion-theming';
import { connect } from 'react-redux';
import React, { useMemo } from 'react';
import { Helmet } from 'react-helmet';
import { Route, Switch } from 'react-router-dom';
import {
	ToastsContainer,
	ToastsContainerPosition,
	ToastsStore,
} from 'react-toasts';
import { dynamicTheme } from '../common/theme';
import Page from '../components/shared/Page';
import { setBrand } from '../store/application';
import BannerContainer from './Banners';
import BrandManager from './BrandManager';
import ContentContainer from './Content';
import FooterContainer from './Footer';
import Highlights from './Highlights';
import PromotionEngineContainer from './PromotionEngine';
import PromotionsContainer from './Promotions';
import QuickLinks from './QuickLinks';
import SettingsContainer from './Settings';
import { BrandConfigProvider } from '../context/brandConfig/brandConfigContext';
import { brandConfig as MasterBrandConfig } from '../context/brandConfig/brandConfig';

function Root({ selectedBrand }) {
	const dynTheme = useMemo(() => dynamicTheme(selectedBrand), [selectedBrand]);

	return (
		<BrandConfigProvider brandName={selectedBrand}>
			<ThemeProvider theme={dynTheme}>
				{selectedBrand !== 'NOT_LOGGED_IN' && (
					<Helmet>
						<title>BM - {MasterBrandConfig.displayName[selectedBrand]}</title>
						<link
							rel="icon"
							type="image/png"
							href={MasterBrandConfig.favicon[selectedBrand]}
						/>
					</Helmet>
				)}
				<Page>
					<ToastsContainer
						position={ToastsContainerPosition.TOP_CENTER}
						store={ToastsStore}
						lightBackground
					/>
					<Switch>
						<Route path="/implicit/callback" component={LoginCallback} />
						<SecureRoute exact path="/" component={BrandManager} />
						<SecureRoute exact path="/login" component={BrandManager} />
						<SecureRoute exact path="/logout" component={BrandManager} />
						<SecureRoute exact path="/banners" component={BannerContainer} />
						<SecureRoute
							exact
							path="/promotions"
							component={PromotionsContainer}
						/>
						<SecureRoute exact path="/content" component={ContentContainer} />
						<SecureRoute exact path="/highlights" component={Highlights} />
						<SecureRoute exact path="/quicklinks" component={QuickLinks} />
						<SecureRoute exact path="/footer" component={FooterContainer} />
						<SecureRoute exact path="/settings" component={SettingsContainer} />
						<SecureRoute
							path="/promotion-engine"
							component={PromotionEngineContainer}
						/>
						<Route path="*">
							<h2>404 - Not Found</h2>
						</Route>
					</Switch>
				</Page>
			</ThemeProvider>
		</BrandConfigProvider>
	);
}

const mapStateToProps = (state) => {
	return {
		selectedBrand: state.application.brand,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		handleBrandSet: (brand) => dispatch(setBrand(brand)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Root);
