import { useCallback, useState } from 'react';

const useForceRender = () => {
	const [renderCount, setRenderCount] = useState(1);

	const updateFunc = () => {
		setRenderCount((state) => state + 1);
		return renderCount;
	};
	return useCallback(updateFunc, []);
};

export default useForceRender;
