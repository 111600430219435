import React from 'react';
import styled from '../../common/styled';

const FilterContainer = styled('div')`
	label: BannerFilter__FilterContainer;

	display: flex;
	justify-content: flex-end;
	margin-bottom: ${(props) => props.theme.spacings.cozy}px;
	color: #838383;
	font-size: 12px;
`;

const FilterOption = styled('span')`
	label: BannerFilter__FilterOption;

	cursor: pointer;
	color: ${(props) => (props.active ? props.theme.brand.color_1 : 'inherit')};

	&:hover {
		color: ${(props) => props.theme.typography.base_color};
	}
`;

const BannerFilter = ({ handleSelection, currentFilter }) => {
	return (
		<FilterContainer>
			<div>
				Visible to:
				<FilterOption
					onClick={() => handleSelection('logged-in')}
					active={currentFilter === 'logged-in'}
				>
					{' '}
					Logged in{' '}
				</FilterOption>
				|{' '}
				<FilterOption
					onClick={() => handleSelection('guests')}
					active={currentFilter === 'guests'}
				>
					{' '}
					Guests
				</FilterOption>
			</div>
		</FilterContainer>
	);
};

export default BannerFilter;
