import React from 'react';
import { Link } from 'react-router-dom';
import styled from '../../common/styled';
import { LightenDarkenColor } from '../../common/LightenDarkenColor';

interface isactiveProp {
	isactive?: boolean;
}

interface isMobileProp extends isactiveProp {
	ismobile?: boolean;
	to: string;
}

export const MainNavGroup = styled('nav')`
	display: flex;
	width: 100%;
	height: 100%;
	overflow: hidden;
	align-items: center;
	justify-content: space-between;

	@media only screen and (max-width: 750px) {
		display: none;
	}
`;

export const Flex = styled('div')`
	display: flex;
	height: 100%;
	overflow: hidden;
	align-items: center;
`;

export const MobileMainNavGroup = styled('nav')<isactiveProp>`
	label: Navs__MobileMainNavGroup;

	display: ${(props) => (props.isactive ? 'inline-flex' : 'none')};
	flex-direction: column;
	overflow: hidden;
	position: absolute;
	top: 40px;
	z-index: 100;
	background: ${(props) => props.theme.brand.color_2};
	width: 100%;

	@media only screen and (min-width: 750px) {
		display: none;
	}
`;

export const BurgerContainer = styled('div')`
	@media only screen and (min-width: 750px) {
		display: none;
	}
`;

export const NavGroup = styled('nav')`
	display: flex;
	height: 100%;
	overflow: hidden;
`;

export const LinkButton = styled(Link)<isMobileProp>`
	box-sizing: border-box;
	margin-left: ${(props) => (props.ismobile ? '0' : '10')}px;
	margin: 8px;
	padding: 10px 20px;
	text-align: center;
	font-weight: bold;
	border-radius: 4px;
	border-bottom: 2px solid ${(props) => '#181818'};
	background-color: ${(props) =>
		props.isactive
			? props.theme.brand.color_1
			: LightenDarkenColor(props.theme.brand.color_2, 30)};
	color: ${(props) => props.theme.typography.color_1_alternate};
	cursor: pointer;
	outline: none;
	text-decoration: none;

	&:hover {
		background-color: ${(props) =>
			props.isactive
				? LightenDarkenColor(props.theme.brand.color_1, -20)
				: LightenDarkenColor(props.theme.brand.color_2, 0)};
	}
`;

export const StyledButton = styled.button<isactiveProp>`
	label: StyledButton;

	display: flex;
	align-items: center;
	box-sizing: border-box;
	border: none;
	margin-left: ${(props) => props.theme.spacings.compact}px;
	padding: ${(props) => props.theme.spacings.compact}px
		${(props) => props.theme.spacings.cozy}px;
	text-align: center;
	font-size: 14px;
	border-radius: 4px;
	border-bottom: 1px solid ${(props) => props.theme.ui.color_4};
	background-color: ${(props) =>
		props.isactive ? props.theme.brand.color_1 : props.theme.brand.color_2};
	color: ${(props) => props.theme.typography.color_1_alternate};
	cursor: pointer;
	outline: none;
	text-decoration: none;

	svg {
		margin-left: ${(props) => props.theme.spacings.compact}px;
	}

	&:hover {
		background-color: ${(props) =>
			props.isactive
				? LightenDarkenColor(props.theme.brand.color_1, -25)
				: LightenDarkenColor(props.theme.brand.color_2, -25)};
	}

	&[disabled] {
		background-color: #ccc;
	}

	&[disabled]:hover {
		cursor: default;
	}

	@media screen and (max-width: 500px) {
		.icon-only-mobile {
			margin-left: 0;
		}
	}
`;

interface styledButtonWithIconProps
	extends React.ComponentProps<typeof StyledButton> {
	icon: React.ReactNode;
}
export const StyledButtonWithIcon = ({
	icon,
	children,
	...props
}: styledButtonWithIconProps) => (
	<StyledButton {...props}>
		{children && (
			<>
				<span>{children}</span>
			</>
		)}
		{icon}
	</StyledButton>
);

export const StyledButtonInverse = styled.button<isactiveProp>`
	display: flex;
	align-items: center;
	box-sizing: border-box;
	border: none;
	margin-left: ${(props) => props.theme.spacings.compact}px;
	padding: ${(props) => props.theme.spacings.compact}px
		${(props) => props.theme.spacings.cozy}px;
	text-align: center;
	font-size: 14px;
	border-radius: 4px;
	border-bottom: 1px solid ${(props) => props.theme.ui.color_4};
	background-color: ${(props) =>
		props.isactive ? props.theme.brand.color_1 : props.theme.brand.color_2};
	color: ${(props) => props.theme.typography.color_1_alternate};
	cursor: pointer;
	outline: none;
	text-decoration: none;

	svg {
		margin-left: ${(props) => props.theme.spacings.compact}px;
	}

	&:hover {
		background-color: ${(props) =>
			props.isactive
				? LightenDarkenColor(props.theme.brand.color_1, -25)
				: LightenDarkenColor(props.theme.brand.color_2, -25)};
	}

	&[disabled] {
		background-color: #ccc;
		color: #aaa;
	}

	&[disabled]:hover {
		cursor: default;
	}
`;

export const Loading = styled.div`
	color: ${(props) => props.theme.typography.color_1_alternate};
`;
