import React from 'react';
import Card from '../components/shared/Card';
import {
	LabelWithError,
	Holder,
	Select,
	Divider,
} from '../components/shared/FormElements';

function Settings() {
	return (
		<>
			<Card>
				<h2>App Settings</h2>

				<Divider />
			</Card>
			<Card>
				<h2>Feature Toggles</h2>
				<Divider />

				<Holder width="100%">
					<LabelWithError
						htmlFor="homepage-banners"
						title="Homepage Banners"
						hint="Enable/Disable banners on the homepage"
						error={null}
					>
						<Select name="homepage-banners" id="homepage-banners">
							<option value="true">True</option>
							<option value="false">False</option>
						</Select>
					</LabelWithError>
				</Holder>

				<Holder width="100%">
					<LabelWithError
						htmlFor="racing-banners"
						title="Racing Banners"
						hint="Enable/Disable banners on the racing page"
						error={null}
					>
						<Select name="racing-banners" id="racing-banners">
							<option value="true">True</option>
							<option value="false">False</option>
						</Select>
					</LabelWithError>
				</Holder>
			</Card>
		</>
	);
}

export default Settings;
