import {
	number as yupNumber,
	object as yupObject,
	string as yupString,
} from 'yup';
import { partialApply } from './pureFunc';

export type mobileRouteObject =
	| mobileRouteRacesObject
	| mobileRouteSportsObject
	| mobileRouteUserObject
	| mobileRoutePromotions
	| mobileRouteRacingFutures
	| mobileRouteSportsBaseCompetition
	| mobileRouteRegistration;

export interface mobileRouteLegacyObject {
	routerKey?: string | null;
	raceId?: number | null;
	meetingId?: number | null;
	eventId?: number | null;
	competitionId?: number | null;
}

type mobileRouteWithKey<RK> = {
	routerKey: RK;
};

export const legacyRouterKeys = ['Racing', 'Sport', 'User'];
export const supportedRouterKeys = [
	'today_racing',
	'race',
	'racing_futures',
	'meeting',
	'event',
	'sport',
	'my_account',
	'my_bets',
	'promotions',
	'base_competition',
	'registration',
] as const;

// quicklinkMobileRouteRacesObject definitions
type mobileRouteRacesObject =
	| mobileRouteRacesToday
	| mobileRouteRacesSpecificMeeting
	| mobileRouteRacesSpecificRace;

type mobileRouteRacesToday = mobileRouteWithKey<'today_racing'>;

type mobileRouteRacesSpecificRace = mobileRouteWithKey<'race'> & {
	raceId: number;
	meetingId: number;
};

type mobileRouteRacesSpecificMeeting = mobileRouteWithKey<'meeting'> & {
	meetingId: number;
};

// quicklinkMobileRouteSportsObject definitions
type mobileRouteSportsObject =
	| mobileRouteSportsEvent
	| mobileRouteSportsSport
//| mobileRouteSportsCompetition;

type mobileRouteSportsEvent = mobileRouteWithKey<'event'> & {
	eventId: number;
	competitionId: number;
};
type mobileRouteSportsSport = mobileRouteWithKey<'sport'> & {
	sportId: number;
};


// quicklinkMobileRouteUserObject definitions
type mobileRouteUserObject =
	| mobileRouteUserMyAccount
	| mobileRouteUserMyBets
	| mobileRoutePromotions
	| mobileRouteSportsBaseCompetition;

type mobileRouteUserMyAccount = mobileRouteWithKey<'my_account'>;
type mobileRouteUserMyBets = mobileRouteWithKey<'my_bets'>;
type mobileRouteRegistration = mobileRouteWithKey<'registration'>;
type mobileRoutePromotions = mobileRouteWithKey<'promotions'>;
type mobileRouteRacingFutures = mobileRouteWithKey<'racing_futures'>;
type mobileRouteSportsBaseCompetition = mobileRouteWithKey<
	'base_competition'
> & { baseCompetitionId: number };

function hasOwnProperty<X extends {}, Y extends PropertyKey>(
	obj: X,
	prop: Y,
): obj is X & Record<Y, unknown> {
	return obj.hasOwnProperty(prop);
}

export const upgradeLegacyMobileRouteObject = (
	input: mobileRouteLegacyObject | mobileRouteObject | null,
): mobileRouteObject | null => {
	if (!input) return null;

	const routerKey = input?.routerKey;
	// handle non legacy Racing
	//@ts-ignore
	if (!routerKey || supportedRouterKeys.includes(routerKey))
		return input as mobileRouteObject;

	// handle legacy Racing
	if (routerKey === 'Racing') {
		if (hasOwnProperty(input, 'raceId') && hasOwnProperty(input, 'meetingId'))
			return {
				routerKey: 'race',
				meetingId: input.meetingId,
				raceId: input.meetingId,
			};
		else if (hasOwnProperty(input, 'meetingId'))
			return {
				routerKey: 'meeting',
				meetingId: input.meetingId,
			};
		else return { routerKey: 'today_racing' };
	}

	// handle legacy sport
	if (routerKey === 'Sport') {
		if (
			hasOwnProperty(input, 'eventId') &&
			hasOwnProperty(input, 'competitionId')
		)
			return {
				routerKey: 'event',
				eventId: input.eventId,
				competitionId: input.competitionId,
			};

		if (hasOwnProperty(input, 'sportId'))
			return { routerKey: 'sport', sportId: input.sportId };

	}

	// handle legacy User
	if (routerKey === 'User') {
		return { routerKey: 'my_account' };
	}

	// fall over if a no legacy or current value found
	return null;
};

const requiredPositiveNumber = yupNumber()
	.nullable()
	.required()
	.integer()
	.positive();

export const mobileRouteValidator = yupObject()
	.shape({
		routerKey: yupString()
			.nullable()
			.oneOf([...supportedRouterKeys, ...legacyRouterKeys]),
		raceId: yupNumber()
			.nullable()
			.notRequired()
			.when('routerKey', {
				is: 'race',
				then: requiredPositiveNumber,
			}),
		meetingId: yupNumber()
			.nullable()
			.notRequired()
			.when('routerKey', {
				is: routerKey => ['race', 'meeting'].includes(routerKey),
				then: requiredPositiveNumber,
			}),
		eventId: yupNumber()
			.nullable()
			.notRequired()
			.when('routerKey', {
				is: 'event',
				then: requiredPositiveNumber,
			}),
		competitionId: yupNumber()
			.nullable()
			.notRequired()
			.when('routerKey', {
				is: routerKey => ['event', 'competition'].includes(routerKey),
				then: requiredPositiveNumber,
			}),
		sportId: yupNumber()
			.nullable()
			.notRequired()
			.when('routerKey', {
				is: 'sport',
				then: requiredPositiveNumber,
			}),
		baseCompetitionId: yupNumber()
			.nullable()
			.notRequired()
			.when('routerKey', {
				is: 'base_competition',
				then: requiredPositiveNumber,
			}),
	})
	.nullable();

const isMobileRouteRacesToday = (
	input: mobileRouteLegacyObject | mobileRouteObject | null,
): input is mobileRouteRacesToday => input?.routerKey === 'today_racing';

const isMobileRouteRacesSpecificRace = (
	input: mobileRouteLegacyObject | mobileRouteObject | null,
): input is mobileRouteRacesSpecificRace =>
	input.routerKey === 'race' &&
	['raceId', 'meetingId'].every(partialApply(hasOwnProperty, input));

const isMobileRouteRacesSpecificMeeting = (
	input: mobileRouteLegacyObject | mobileRouteObject | null,
): input is mobileRouteRacesSpecificMeeting =>
	input.routerKey === 'meeting' && hasOwnProperty(input, 'meetingId');

const isMobileRouteSportsEvent = (
	input: mobileRouteLegacyObject | mobileRouteObject | null,
): input is mobileRouteSportsEvent =>
	input.routerKey === 'event' &&
	['eventId', 'competitionId'].every(partialApply(hasOwnProperty, input));

const isMobileRouteSportsSport = (
	input: mobileRouteLegacyObject | mobileRouteObject | null,
): input is mobileRouteSportsSport =>
	input.routerKey === 'sport' && hasOwnProperty(input, 'sportId');

const isMobileRouteUserMyAccount = (
	input: mobileRouteLegacyObject | mobileRouteObject | null,
): input is mobileRouteUserMyAccount => input.routerKey === 'my_account';

const isMobileRoutePromotions = (
	input: mobileRouteLegacyObject | mobileRouteObject | null,
): input is mobileRoutePromotions => input?.routerKey === 'promotions';

const isMobileRouteRacingFutures = (
	input: mobileRouteLegacyObject | mobileRouteObject | null,
): input is mobileRouteRacingFutures => input?.routerKey === 'racing_futures';

const isMobileRouteBaseCompetition = (
	input: mobileRouteLegacyObject | mobileRouteObject | null,
): input is mobileRouteSportsBaseCompetition =>
	input?.routerKey === 'base_competition';

const isMobileRouteUserMyBets = (
	input: mobileRouteLegacyObject | mobileRouteObject | null,
): input is mobileRouteUserMyBets => input.routerKey === 'my_bets';

const isMobileRouteRegistration = (
	input: mobileRouteLegacyObject | mobileRouteObject | null,
): input is mobileRouteRegistration => input.routerKey === 'registration';

export const cleanMobileRoute = (
	input: mobileRouteLegacyObject | mobileRouteObject | null,
): mobileRouteLegacyObject | mobileRouteObject | null => {
	if (!input || !input.routerKey) return null;
	else if (isMobileRouteRacesToday(input))
		return { routerKey: 'today_racing' } as mobileRouteRacesToday;
	else if (isMobileRouteRacesSpecificRace(input))
		return {
			routerKey: 'race',
			raceId: input.raceId,
			meetingId: input.meetingId,
		} as mobileRouteRacesSpecificRace;
	else if (isMobileRouteRacesSpecificMeeting(input))
		return {
			routerKey: 'meeting',
			meetingId: input.meetingId,
		} as mobileRouteRacesSpecificMeeting;
	else if (isMobileRouteSportsEvent(input))
		return {
			routerKey: 'event',
			competitionId: input.competitionId,
			eventId: input.eventId,
		} as mobileRouteSportsEvent;
	else if (isMobileRouteSportsSport(input))
		return {
			routerKey: 'sport',
			sportId: input.sportId,
		} as mobileRouteSportsSport;

	else if (isMobileRouteUserMyAccount(input))
		return { routerKey: 'my_account' } as mobileRouteUserMyAccount;
	else if (isMobileRoutePromotions(input))
		return { routerKey: 'promotions' } as mobileRoutePromotions;
	else if (isMobileRouteRacingFutures(input))
		return { routerKey: 'racing_futures' } as mobileRouteRacingFutures;
	else if (isMobileRouteBaseCompetition(input))
		return {
			routerKey: 'base_competition',
			baseCompetitionId: input.baseCompetitionId,
		} as mobileRouteSportsBaseCompetition;
	else if (isMobileRouteUserMyBets(input)) return { routerKey: 'my_bets' };
	else if (isMobileRouteRegistration(input))
		return { routerKey: 'registration' };
	// unable to nail down just let though
	else return input;
};
