import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
	contentItemType as CIT,
	ContentItemsByStatus as CIsT,
} from '../helpers/newAPIClient';
import { ItemStatusType } from '../helpers/contentEndpoints';
import { ToastsStore } from 'react-toasts';
import { configurationLoader } from '../context/brandConfig/brandConfig';
import ContentEndpoints from '../helpers/contentEndpoints';

export interface pagesInterface {
	data: ContentItemsByStatus;
	selectedStatusID: string;
	isFetching: Boolean;
}

const initialState: pagesInterface = {
	data: {},
	selectedStatusID: '',
	isFetching: false,
};

export type contentItemType = CIT;
export type ContentItemsByStatus = CIsT;

const contentApiFactory = (state, updateAuth: boolean) => {
	const authToken = state?.auth?.accessToken;
	const brand = state?.application?.brand;
	const brandManagerEndpoint = configurationLoader(brand).brandManagerEndpoint;
	const ContentApi = new ContentEndpoints(
		`${brandManagerEndpoint}/content`,
		'-private',
	);
	if (updateAuth) ContentApi.useAuthCode(authToken);
	return ContentApi;
};

export const fetchAllContent = createAsyncThunk(
	'content/fetchAllContent',
	async (_, thunkAPI) =>
		await contentApiFactory(thunkAPI.getState(), true).fetchAll(),
);

export const DeleteItem = createAsyncThunk(
	'content/deleteItem',
	async ({ status, id }: { status: ItemStatusType; id: string }, thunkAPI) => {
		const contentApi = contentApiFactory(thunkAPI.getState(), true);
		await contentApi.deleteItem(status, id);

		await thunkAPI.dispatch(fetchAllContent());
		await thunkAPI.dispatch(
			setSelectedStatusID({
				status: 'draft',
				id: '',
			}),
		);
	},
);

export const UpdateDraft = createAsyncThunk(
	'content/updateDraft',
	async (item: CIT, thunkAPI) => {
		const contentApi = contentApiFactory(thunkAPI.getState(), true);
		const result = await contentApi.updateDraft(item);
		await thunkAPI.dispatch(fetchAllContent());
		await thunkAPI.dispatch(
			setSelectedStatusID({
				status: 'draft',
				id: result.data?.Item?.id || '',
			}),
		);
		return result;
	},
);

export const CreateDraft = createAsyncThunk(
	'content/createDraft',
	async (item: CIT, thunkAPI) => {
		const contentApi = contentApiFactory(thunkAPI.getState(), true);
		const result = await contentApi.createDraft(item);
		await thunkAPI.dispatch(fetchAllContent());
		await thunkAPI.dispatch(
			setSelectedStatusID({
				status: 'draft',
				id: result.data?.Item?.id || '',
			}),
		);
		return result;
	},
);

export const PublishDraft = createAsyncThunk(
	'content/publishDraft',
	async (item: CIT, thunkAPI) => {
		const contentApi = contentApiFactory(thunkAPI.getState(), true);
		const result = await contentApi.publishDraft(item);
		await thunkAPI.dispatch(fetchAllContent());
		await thunkAPI.dispatch(
			setSelectedStatusID({
				status: 'draft',
				id: '',
			}),
		);
		return result;
	},
);

export const CreateDraftFrom = createAsyncThunk(
	'content/createDraftFrom',
	async (item: CIT, thunkAPI) => {
		const contentApi = contentApiFactory(thunkAPI.getState(), true);
		await contentApi.createDraftFrom(item);
		await thunkAPI.dispatch(fetchAllContent());
		await thunkAPI.dispatch(
			setSelectedStatusID({
				status: 'draft',
				id: item.id,
			}),
		);
	},
);

const { actions, reducer } = createSlice({
	name: 'loadPages',
	initialState: initialState,
	reducers: {
		setPages(state, { payload }: { payload: ContentItemsByStatus }) {
			state.data = payload;
			state.selectedStatusID = '';
		},
		setSelectedStatusID(
			state,
			{ payload }: { payload: { status: ItemStatusType; id: string } },
		) {
			state.selectedStatusID = `${payload.status}_${payload.id}`;
		},
	},
	extraReducers: {
		// FetchAll
		[fetchAllContent.pending as any]: (state) => {
			state.isFetching = true;
		},
		[fetchAllContent.fulfilled as any]: (state, action) => {
			state.data = action.payload;
			state.isFetching = false;
		},
		// Create
		[CreateDraft.pending as any]: (state) => {
			state.isFetching = true;
		},
		[CreateDraft.fulfilled as any]: (state) => {
			state.isFetching = false;
			ToastsStore.success('Draft created', 2000);
		},
		[CreateDraft.rejected as any]: (state) => {
			state.isFetching = false;
			ToastsStore.error(
				'There was a problem creating a draft. Please try again',
				3000,
			);
		},
		// CreateDraft
		[CreateDraftFrom.pending as any]: (state) => {
			state.isFetching = true;
		},
		[CreateDraftFrom.fulfilled as any]: (state) => {
			state.isFetching = false;
		},
		[CreateDraftFrom.rejected as any]: (state) => {
			state.isFetching = false;
			ToastsStore.error(
				'There was a problem creating a draft from this page. Please try again',
				3000,
			);
		},
		// Delete
		[DeleteItem.pending as any]: (state) => {
			state.isFetching = true;
		},
		[DeleteItem.fulfilled as any]: (state) => {
			state.isFetching = false;
			ToastsStore.success('Page deleted', 2000);
		},
		[DeleteItem.rejected as any]: (state) => {
			state.isFetching = false;
			ToastsStore.error(
				'There was a problem deleting this page. Please try again',
				3000,
			);
		},
		// Update
		[UpdateDraft.pending as any]: (state) => {
			state.isFetching = true;
		},
		[UpdateDraft.fulfilled as any]: (state) => {
			state.isFetching = false;
			ToastsStore.success('Page updated', 2000);
		},
		[UpdateDraft.rejected as any]: (state) => {
			state.isFetching = false;
			ToastsStore.error(
				'There was a problem updating this draft. Please try again',
				3000,
			);
		},
		// Publish
		[PublishDraft.pending as any]: (state) => {
			state.isFetching = true;
		},
		[PublishDraft.fulfilled as any]: (state) => {
			state.isFetching = false;
			ToastsStore.success('Page published', 2000);
		},
		[PublishDraft.rejected as any]: (state) => {
			state.isFetching = false;
			ToastsStore.error(
				'There was a problem publishing this page. Please try again',
				3000,
			);
		},
	},
});

export const { setPages, setSelectedStatusID } = actions;
export default reducer;
