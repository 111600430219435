import React from 'react';
import { Global, css } from '@emotion/core';
import { connect } from 'react-redux';
import Loading from 'react-loading-bar';
import 'react-loading-bar/dist/index.css';

import styled from '../../common/styled';
import { staticTheme } from '../../common/theme';

import { setAuth } from '../../store/auth';

import Header from './Header';
import Footer from './Footer';

const style = css`
	body {
		box-sizing: border-box;
		background-color: ${staticTheme.ui.background};
		margin: 0;
		padding: 0;
		overflow-y: scroll;
		font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
			Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
			'Segoe UI Symbol';
	}
	h2 {
		margin: 0;
	}
	li {
		margin-bottom: 0.4em;
		line-height: 1.4em;
	}
	p {
		line-height: 1.4em;
	}
	/* Overwrite react-loading-bar */
	.Loading__bar___21yOt {
		height: 4px;
	}
	/* Overwrite alert-confirm-button*/
	.alert-confirm-button {
		border: 0;
		padding: 0 16px;
		line-height: 32px;
		cursor: pointer;
		color: #5c5c5c;
		border-radius: 3px;
		outline: none;
		background-color: #fff;
		transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
	}
	.alert-confirm-button.primary-button {
		background: ${staticTheme.brand.color_1};
		transition: all 0.3s;
	}
	.alert-confirm-button.primary-button:hover {
		box-shadow: 0 1px 10px 0 #ccc, 0 2px 4px 0 #ccc, 0 4px 5px 0 #ccc;
		transition: all 0.3s;
	}
	.alert-confirm-button.default-button:hover {
		box-shadow: 0 1px 10px 0 #ccc, 0 2px 4px 0 #ccc, 0 4px 5px 0 #ccc;
		color: ${staticTheme.brand.color_1};
	}
	/* Required for clickable links in tooltips. Refer BannerForm component */
	.__react_component_tooltip {
		pointer-events: auto !important;
	}

	/* React-datepicker styles */
	.react-datepicker__input-container {
		width: 180px;
	}
	.react-datepicker__close-icon::after {
		background: ${staticTheme.brand.color_1};
	}

	/* Removes scrollbar track on image upload fields (react-dropzone-uploader) */
	.dzu-dropzone {
		overflow: hidden;
	}
`;

const Container = styled('div')`
	label: Page__Container;

	height: auto;
	max-width: 1170px;
	margin: 0 auto;
	color: ${(props) => props.theme.typography.color_1};
`;

const Page = ({ children, loading, authUser, isAuthenticated }) => {
	// if (isAuthenticated && loading)
	// 	return (
	// 		<>
	// 			<Global styles={style} />
	// 			<Loading
	// 				show={true}
	// 				color={staticTheme.brand.color_1}
	// 				showSpinner={false}
	// 			/>
	// 			<p style={{ marginLeft: '10px' }}>Signing In...</p>
	// 		</>
	// 	);

	return (
		<>
			<Global styles={style} />
			<Loading
				show={loading}
				color={staticTheme.brand.color_1}
				showSpinner={false}
			/>
			<Header
				loading={!!loading}
				authUser={authUser}
				isAuthenticated={isAuthenticated}
			/>
			<Container>
				{children}
				<Footer />
			</Container>
		</>
	);
};

const mapStateToProps = (state) => ({
	token: state.auth.token,
	authUser: state.auth.user,
	loading: state.auth.isPending,
	isAuthenticated: state.auth.isAuthenticated,
});

const mapDispatchToProps = { setAuth };

export default connect(mapStateToProps, mapDispatchToProps)(Page);
