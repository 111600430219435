import React from 'react';
import styled from '../../common/styled';

const Container = styled('div')`
	h2 {
		word-wrap: break-word;
	}
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: ${(props) => props.theme.spacings.tight}px;
	flex-wrap: wrap;
`;

interface PageHeaderProps {
	title: string;
	children?: React.ReactNode;
}

const PageHeader = ({ title, children }: PageHeaderProps) => {
	return (
		<Container>
			<h2>{title}</h2>
			{children}
		</Container>
	);
};

export default PageHeader;
