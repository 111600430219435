export const icons = [
	{ value: 'alarm', label: 'Alarm' },
	{ value: 'american-football', label: 'American Football' },
	{ value: 'arrow-down', label: 'Arrow Down' },
	{ value: 'athletics', label: 'Athletics' },
	{ value: 'baseball', label: 'Baseball' },
	{ value: 'basketball', label: 'Basketball' },
	{ value: 'boxing', label: 'Boxing' },
	{ value: 'calendar', label: 'Calendar' },
	{ value: 'commonwealth-games', label: 'Commonwealth Games' },
	{ value: 'cricket', label: 'Cricket' },
	{ value: 'cycling', label: 'Cycling' },
	{ value: 'darts', label: 'Darts' },
	{ value: 'dogs', label: 'Dogs' },
	{ value: 'esports', label: 'E-Sports' },
	{ value: 'equestrian', label: 'Equestrian' },
	{ value: 'extreme-sports', label: 'Extreme Sports' },
	{ value: 'feature-racing', label: 'Feature Racing' },
	{ value: 'football', label: 'Football' },
	{ value: 'gaelic-sports', label: 'Gaelic Sports' },
	{ value: 'gallops', label: 'Gallops' },
	{ value: 'gallops-specials', label: 'Gallops Specials' },
	{ value: 'golf', label: 'Golf' },
	{ value: 'greyhounds', label: 'Greyhounds' },
	{ value: 'harness', label: 'Harness' },
	{ value: 'hockey', label: 'Hockey' },
	{ value: 'ice-hockey', label: 'Ice Hockey' },
	{ value: 'jockeys-challenge', label: "Jockey's Challenge" },
	{ value: 'laurel', label: 'Laurel' },
	{ value: 'lawn-bowls', label: 'Lawn Bowls' },
	{ value: 'martial-arts', label: 'Martial Arts' },
	{ value: 'mma', label: 'MMA' },
	{ value: 'motor-racing', label: 'Motor Racing' },
	{ value: 'motor-sport', label: 'Motor Sport' },
	{ value: 'motorcycling', label: 'Motorcycling' },
	{ value: 'multi-specials', label: 'Multi-Specials' },
	{ value: 'my-bets', label: 'My Bets' },
	{ value: 'netball', label: 'Netball' },
	{ value: 'olympics', label: 'Olympics' },
	{ value: 'racing', label: 'Racing' },
	{ value: 'racing-specials', label: 'Racing Specials' },
	{ value: 'rugby-league', label: 'Rugby League' },
	{ value: 'rugby-union', label: 'Rugby Union' },
	{ value: 'sailing', label: 'Sailing' },
	{ value: 'signup-help', label: 'Help' },
	{ value: 'signup-info', label: 'Info' },
	{ value: 'soccer', label: 'Soccer' },
	{ value: 'sports', label: 'Sports' },
	{ value: 'sports-future', label: 'Sports Future' },
	{ value: 'star', label: 'Star' },
	{ value: 'star-full', label: 'Star Full' },
	{ value: 'surfing', label: 'Surfing' },
	{ value: 'trophy', label: 'Trophy' },
	{ value: 'user', label: 'User' },
	{ value: 'volley-ball', label: 'Volleyball' },
	{ value: 'withdrawal', label: 'Withdrawal' },
	{ value: 'tennis', label: 'Tennis' },
	{ value: 'australian-rules', label: 'Australian Rules' },
	{ value: 'handball', label: 'Handball' },
	{ value: 'promo', label: 'Handball' },
	{ value: 'promotion', label: 'Promotion' },
	{ value: 'dollarCircle', label: 'Doller' },
].sort((a, b) => a.label.localeCompare(b.label));
