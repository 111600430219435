import React from 'react';
import styled, { css } from '../../common/styled';
import { FiX } from 'react-icons/fi';

import Card from '../shared/Card';
import { StyledButton } from '../shared/Navs';
import PageHeader from '../shared/PageHeader';

const StyledImage = styled('img')(
	(props) => css`
		label: HighlightsHelp__Image;

		width: ${props.shrink ? '50%' : '95%'};
		min-width: 350px;
		display: block;
		margin-bottom: ${props.theme.spacings.spacious}px;
	`,
);

const HighlightsHelp = ({ closeModal }) => {
	return (
		<Card isModal>
			<PageHeader title="Highlights">
				<StyledButton onClick={closeModal}>
					Close <FiX />
				</StyledButton>
			</PageHeader>
			<p>
				Highlights can be used to feature a race or event, and are displayed
				just under the main navigation bar.
			</p>
			<StyledImage src="/images/highlights.png" alt="Highlights example" />
			<p>
				A highlighted race will show larger than a normal (unhighlighted) race,
				with other content removed.
			</p>
			<StyledImage
				src="/images/highlighted-race.png"
				alt="Highlighted race example"
				shrink
			/>
			<strong>About Highlights</strong>
			<p>
				A maximum of two highlights will show at a time on desktop/large
				displays, sorted by expiry date with the earliest expiry displaying
				first.
			</p>
			<p>
				Mobile displays will only show one highlight, while 'in between' sizes
				will show two.
			</p>
			<p>Highlights can be removed, disabled or updated at any time.</p>
		</Card>
	);
};

export default HighlightsHelp;
