export type Pair<T> = [string, T];
export type PromiseResolved<T> = T extends Promise<infer U> ? U : T;

export function zeroPad(length: number, input: string): string {
	return input.length >= length ? input : zeroPad(length, `0${input}`);
}

export const objToPairs = <T>(obj: Record<string, T>): Pair<T>[] =>
	Object.keys(obj) && Object.keys(obj).map((key) => [key, obj[key]]);

export const pairsToObj = <T>(pairs: Pair<T>[]): Record<string, T> =>
	pairs.reduce<Record<string, T>>(
		(acc, [key, value]) => ({ ...acc, [key]: value }),
		{},
	);

export const filterObj = <T>(
	fn: (value: T, key: string) => boolean,
	obj: Record<string, T>,
) =>
	pairsToObj(
		objToPairs(obj) && objToPairs(obj).filter(([key, value]) => fn(value, key)),
	);

export const fieldAltered = <T, K extends keyof T>(
	fieldName: K,
	prev: T,
	next: T,
): boolean => prev[fieldName] !== next[fieldName];

type booleanValuesOfType<T> = { [key in keyof T]: boolean };

export const FieldsAltered = <T>(
	values: T,
	initialValues: T,
): booleanValuesOfType<T> =>
	Object.keys(values) &&
	Object.keys(values)
		.map<[keyof T, boolean]>((key) => [
			key as keyof T,
			initialValues[key] !== values[key],
		])
		.reduce<booleanValuesOfType<T>>(
			(acc, [key, value]) => ({ ...acc, [key as keyof T]: value }),
			{} as booleanValuesOfType<T>,
		);

const windowDefined = typeof window !== 'undefined';

export const debounce = <F extends (...args: any) => any>(
	func: F,
	waitFor: number,
) => {
	let timeout;

	const debounced = (...args: any) => {
		clearTimeout(timeout);
		timeout = (windowDefined ? window : global).setTimeout(
			func,
			waitFor,
			...args,
		);
	};

	return debounced as (...args: Parameters<F>) => ReturnType<F>;
};

export const debounceWithDelayedPromises = <
	F extends (...args: unknown[]) => unknown
>(
	func: F,
	waitFor,
): ((...args: Parameters<F>) => Promise<ReturnType<F>>) => {
	let timeout;

	const debounced = (...args: Parameters<F>): Promise<ReturnType<F>> => {
		return new Promise((resolve, reject) => {
			clearTimeout(timeout);
			timeout = (windowDefined ? window : global).setTimeout(
				() => {
					try {
						resolve(func(...args) as ReturnType<F>);
					} catch (e) {
						reject(e);
					}
				},
				waitFor,
				...args,
			);
		});
	};
	return debounced;
};
