import Axios from 'axios';
import { useFormik } from 'formik';
import * as React from 'react';
import RACE_CODE_LABEL from '../../../common/raceCodeLabels';
import { useBrandConfig } from '../../../context/brandConfig/brandConfigContext';
import { Select, SelectBox } from '../../shared/FormElements';
import { StyledButton } from '../../shared/Navs';
import {
	ColumnedLabeledArea,
	InlineInput
} from '../DepositMatchFormComponents';
import {
	dateTimeToHTMLSafe,
	handleChange,
	handleFormikBlur,
	handleFormikChange, handleRaceSelectBlur,
	handleRaceSelectChange
} from './Common';
import MultipleSelector from './MultipleSelector';

interface formValues {
	date: string;
	code: string;
	venue: string;
	race_number: string;
	race_id: string;
}

interface RaceEventSearchProps {
	eligibleEventsCallback: any;
	existingEvents: any;
	onClose: boolean;
}

const genInitialValues = (): Omit<formValues, 'id'> => {
	const now = new Date();
	const year = now
		.getFullYear()
		.toFixed(0)
		.padStart(4, '0');
	const month = (now.getMonth() + 1).toFixed(0).padStart(2, '0');
	const day = now
		.getDate()
		.toFixed(0)
		.padStart(2, '0');

	const todayDate = `${year}-${month}-${day}`;

	return {
		date: todayDate,
		code: 'R',
		venue: '',
		race_number: '',
		race_id: '',
	};
};

const RaceEventSearch: React.FC<RaceEventSearchProps> = ({
	eligibleEventsCallback,
	existingEvents,
	onClose,
}) => {
	const [data, setData] = React.useState([]);
	const [isFetching, setFetching] = React.useState(false);
	const [showEventSelectionSummary, setShowSelectionSummary] = React.useState(
		false,
	);
	const [eligibleEvents, setEligibleEvents] = React.useState(
		existingEvents || [],
	);

	const [raceIdList, setIdList] = React.useState([]);

	const { brandConfig } = useBrandConfig();
	const { bettingEngineApi: BETTING_ENGINE_API } = brandConfig;

	const formik = useFormik({
		initialValues: {
			...genInitialValues(),
		},
		validateOnBlur: false,
		validateOnChange: false,
		onSubmit: async () => {},
		enableReinitialize: false,
	});

	React.useEffect(() => {
		async function fetchData() {
			const MEETINGS_ENDPOINT =
				BETTING_ENGINE_API &&
				`${BETTING_ENGINE_API}/combined/meetings/races?date=${formik.values.date}`;
			await setFetching(true);
			const response = await Axios(MEETINGS_ENDPOINT);
			setData(response.data.data);
			setFetching(false);
		}
		fetchData();
	}, [BETTING_ENGINE_API, formik.values.date]);

	React.useEffect(() => {
		async function showSummary() {
			if (eligibleEvents.length > 0) setShowSelectionSummary(true);
			else setShowSelectionSummary(false);
		}

		showSummary();
	}, [eligibleEvents]);

	const meetingsGallops = data?.filter(e => e.type === 'R');
	const meetingsGreyhounds = data?.filter(e => e.type === 'G');
	const meetingsHarness = data?.filter(e => e.type === 'H');
	const meetingLookup = {
		R: meetingsGallops,
		G: meetingsGreyhounds,
		H: meetingsHarness,
	};
	const venues = meetingLookup[formik.values.code];
	const races =
		formik.values.venue && venues?.filter(v => v.name === formik.values.venue);

	const disableButton =
		(formik.values.venue === '' && raceIdList.length === 0) || raceIdList.length === 0;

	const handleAddEvent = e => {
		e.preventDefault();

		if (disableButton) return null;

		const code = formik.values.code;
		const venue = formik.values.venue;
		const filteredRace = races.filter(race => race?.name === venue);
		const startDate = filteredRace[0].start_date;

		let eventList = [];

		raceIdList.forEach((race: any) => {
			const event = {
				code: code,
				venue: venue,
				race_number: race.number,
				race_id: race.id,
				start_date: startDate,
			};
			eventList.push(event);
		})

		setEligibleEvents([...eligibleEvents, ...eventList]);
		eligibleEventsCallback([...eligibleEvents, ...eventList]);

		setIdList([]);
		formik.setFieldValue('venue', '');
		formik.setFieldValue('race_number', '');
		formik.setFieldValue('race_id', '');
	};

	const handleEventRemove = event => {
		const race_id = event?.race_id;
		const filterEvent = eligibleEvents.filter(e => e?.race_id !== race_id);

		setEligibleEvents([...filterEvent]);
		eligibleEventsCallback([...filterEvent]);
	};

	const raceOrder = races[0]?.races.sort((a, b) => {return a.number-b.number});

	return (
		<div>
			<ColumnedLabeledArea
				id="activeArea"
				snapWidth="1000px"
				margin="4px"
				label="Race Date"
			>
				{[
					{
						flexGrow: '4',
						child: (
							<InlineInput
								id="date"
								type="date"
								onChange={e => handleChange(e, formik)}
								value={dateTimeToHTMLSafe(formik.values.date)}
								onBlur={formik.handleBlur}
							/>
						),
					},
				]}
			</ColumnedLabeledArea>
			{isFetching ? (
				<ColumnedLabeledArea
					id="activeArea"
					snapWidth="250px"
					margin="4px"
					label="Race Selector"
				>
					{[
						{
							flexGrow: '1',
							child: <div>Fetching race events...</div>,
						},
					]}
				</ColumnedLabeledArea>
			) : (
				<ColumnedLabeledArea
					id="activeArea"
					snapWidth="250px"
					margin="4px"
					label="Race Selector"
				>
					{[
						{
							flexGrow: '1',
							child: (
								<SelectBox>
									<Select
										name="code"
										value={formik.values.code}
										onChange={e => handleRaceSelectChange(e, formik)}
										onBlur={e => handleRaceSelectBlur(e, formik)}
									>
										<option value="R">{RACE_CODE_LABEL['R']}</option>
										<option value="G">{RACE_CODE_LABEL['G']}</option>
										<option value="H">{RACE_CODE_LABEL['H']}</option>
									</Select>
								</SelectBox>
							),
						},
						{
							flexGrow: '1',
							child: (
								<SelectBox>
									<Select
										name="venue"
										value={formik.values.venue}
										onChange={e => {
											handleFormikChange(e, formik);
											setIdList([]);
										}}
										onBlur={e => {
											handleFormikBlur(e, formik);
											setIdList([]);
										}}
									>
										<option value="" disabled selected>
											Venue
										</option>
										{venues.map(m => (
											<option value={m.name}>{m.name}</option>
										))}
									</Select>
								</SelectBox>
							),
						},
						{
							flexGrow: '1',
							child: (
								<MultipleSelector
									options={raceOrder}
									value={raceIdList}
									onSelect={(items) => setIdList(items)}
									onClose={onClose}
									isRace={true}
									placeHolder={'Race'}
								/>
							),
						},
						{
							flexGrow: '1',
							child: (
								<StyledButton disabled={disableButton} onClick={handleAddEvent}>
									Add Event
								</StyledButton>
							),
						},
					]}
				</ColumnedLabeledArea>
			)}
			{showEventSelectionSummary && (
				<ColumnedLabeledArea
					id="activeArea"
					snapWidth="250px"
					margin="4px"
					label="eligible Events"
				>
					{[
						{
							flexGrow: '1',
							child: <p>Code:</p>,
						},
						{
							flexGrow: '1',
							child: <p>Venue:</p>,
						},
						{
							flexGrow: '1',
							child: <p>Event:</p>,
						},
						{
							flexGrow: '1',
							child: <p>Event Date:</p>,
						},
						{
							flexGrow: '1',
							child: <div></div>,
						},
					]}
				</ColumnedLabeledArea>
			)}
			{showEventSelectionSummary &&
				eligibleEvents?.map(event => (
					<ColumnedLabeledArea
						id="activeArea"
						snapWidth="250px"
						margin="4px"
						label=""
					>
						{[
							{
								flexGrow: '1',
								child: <span>{RACE_CODE_LABEL[event.code || event.type]}</span>,
							},
							{
								flexGrow: '1',
								child: <span>{event.venue}</span>,
							},
							{
								flexGrow: '1',
								child: (
									<span>
										{event.race_id} (R{event.race_number})
									</span>
								),
							},
							{
								flexGrow: '1',
								child: (
									<span>
										{event.start_date}
									</span>
								),
							},
							{
								flexGrow: '1',
								child: (
									<StyledButton
										type="button"
										onClick={() => handleEventRemove(event)}
									>
										Remove
									</StyledButton>
								),
							},
						]}
					</ColumnedLabeledArea>
				))}
		</div>
	);
};

export default RaceEventSearch;
