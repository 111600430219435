import styled from '../../common/styled';
import { LightenDarkenColor } from '../../common/LightenDarkenColor';

export const BannerPreviewWrapper = styled('div')`
	flex: auto;
	overflow: hidden;
	text-align: left;
	background: #fff;
	padding: ${({ theme: { spacings } }) => spacings.cozy}px;
	margin-bottom: ${({ theme: { spacings } }) => spacings.compact}px;
	color: ${({ theme: { typography } }) => typography.color_1};

	.markdown > * {
		color: ${({ theme: { typography } }) => typography.color_1_alternate};
	}

	p {
		font-size: 14px;
	}

	ol,
	ul {
		padding-left: ${({ theme: { spacings } }) => spacings.spacious}px;
		font-size: 14px;
	}

	table {
		width: 80%;
		max-width: 800px;
		margin: ${({ theme: { spacings } }) => spacings.tight}px auto;
	}

	tbody > :nth-child(even) {
		background-color: ${({ theme: { ui } }) => ui.color_3};
	}
	tbody > :nth-child(odd) {
		background-color: ${({ theme: { ui } }) =>
			LightenDarkenColor(ui.color_3, 30)};
	}
	td {
		padding: ${({ theme: { spacings } }) => spacings.cozy}px;
	}
	.PrimaryTableCell {
		background-color: ${({ theme: { brand } }) =>
			LightenDarkenColor(brand.color_2, 100)};
	}
`;
