import { useFormik } from 'formik';
import React, { useCallback, useEffect } from 'react';
import alertConfirm from 'react-alert-confirm';
import 'react-alert-confirm/dist/index.css';
import { FiSave, FiTrash, FiX } from 'react-icons/fi';
import { connect } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import { promotionsValidation } from '../../common/validations';
import { useBrandConfig } from '../../context/brandConfig/brandConfigContext';
import {
	promotionItemType as formValuesInterface,
	promotion_categories,
} from '../../helpers/promotionEndpoints';
import { createPromotion, updatePromotion } from '../../store/promotions';
import MobileRouteEditor, {
	cleanMobileRoute,
} from '../mobileRoute/mobileRouteEditor';
import Card from '../shared/Card';
import {
	Divider,
	Form,
	Holder,
	Input,
	LabelWithError,
	LeftColumn,
	RightColumn,
	Select,
	SplitView,
} from '../shared/FormElements';
import MarkdownEditor from '../shared/MarkdownEditor';
import { NavGroup, StyledButton } from '../shared/Navs';
import PageHeader from '../shared/PageHeader';
import ToggleSwitch from '../shared/ToggleSwitch';
import UserImageUpload from '../shared/UserImageUpload';
import PromotionsPreview from './PromotionsPreview';

interface FormProps {
	toggleModal: any;
	selectedPromotion: string;
	initialValues: formValuesInterface;
	formTitle: string;
	buttonLabel: string;
	handleCreate: (Object) => void;
	handleUpdate: (Object) => void;
	handleDelete: (Object) => void;
}

const PromotionsForm = ({
	toggleModal,
	initialValues,
	formTitle,
	buttonLabel,
	handleCreate,
	handleUpdate,
	handleDelete,
}: FormProps) => {
	const { brandConfig } = useBrandConfig();
	const formik = useFormik<formValuesInterface>({
		initialValues,
		enableReinitialize: true,
		validateOnChange: true,
		validateOnBlur: true,
		validationSchema: promotionsValidation,
		onSubmit: async (
			values: formValuesInterface,
			{ setSubmitting, resetForm },
		) => {
			const forceRelativeUrl = formik.values.route.startsWith(
				`${brandConfig.appUrl}/`,
			)
				? formik.values.route.replace(`${brandConfig.appUrl}/`, '')
				: formik.values.route.replace(/^\/+/, '');

			const cleanedValues: formValuesInterface = {
				...values,
				mobileRoute:
					(values.mobileRoute && cleanMobileRoute(values.mobileRoute)) || null,
				route: forceRelativeUrl,
			};

			values.id !== ''
				? await handleUpdate(cleanedValues)
				: await handleCreate(cleanedValues);

			setSubmitting(false);
			toggleModal(false);
			resetForm();
		},
	});

	const handleClose = e => {
		if (formik.dirty) {
			alertConfirm({
				title: 'Unsaved content',
				content: 'Leave page? Unpublished changes will be lost',
				okText: 'Leave without saving',
				cancelText: 'Go back to form',
				onOk: () => {
					toggleModal(false);
				},
				onCancel: () => {},
			});
		} else {
			toggleModal(false);
		}
	};

	const toolTipConfig = {
		backgroundColor: '#000000',
		delayShow: 100,
		multiLine: true,
		delayHide: 1000,
	};

	const externalRoute = formik.values.route.startsWith('http');
	const routePreview = externalRoute
		? `external link: ${formik.values.route}`
		: `link preview: ${brandConfig.appUrl}/${
				formik.dirty
					? formik.values.route.replace(/^\/+/, '')
					: formik.values.route
		  }`;

	const isMobile = window.innerWidth <= 760;
	const isSmallMobile = window.innerWidth <= 500;

	const mobileRouteRouterKey = formik.values?.mobileRoute?.routerKey || null;

	const validateField = formik.validateField;

	const reValidateMobileRoute = useCallback(
		// we want to revalidate the mobileRoute object when mobileRouteRouterKey changes
		() => validateField('mobileRoute') || mobileRouteRouterKey,
		[mobileRouteRouterKey, validateField],
	);

	useEffect(() => {
		reValidateMobileRoute();
	}, [reValidateMobileRoute]);

	return (
		<Card isModal>
			<PageHeader title={formTitle}>
				<NavGroup>
					<StyledButton
						isactive={true}
						style={{ marginLeft: 0 }}
						disabled={!formik.dirty || formik.isSubmitting || !formik.isValid}
						onClick={() => formik.submitForm()}
					>
						{!isSmallMobile && buttonLabel}{' '}
						<FiSave className="icon-only-mobile" />
					</StyledButton>
					{formik.values.id && (
						<StyledButton
							isactive={false}
							onClick={() => handleDelete(formik.values.id)}
							disabled={formik.isSubmitting || !formik.isValid}
						>
							{!isSmallMobile && 'Delete'}{' '}
							<FiTrash className="icon-only-mobile" />
						</StyledButton>
					)}
					<StyledButton onClick={handleClose}>
						{!isSmallMobile && 'Close'} <FiX className="icon-only-mobile" />
					</StyledButton>
				</NavGroup>
			</PageHeader>
			<Divider />

			<Form onSubmit={formik.handleSubmit}>
				<SplitView disabled={isMobile}>
					<LeftColumn disabled={isMobile}>
						<div>
							<Holder width="100%">
								<LabelWithError
									htmlFor="title"
									title="Title"
									error={formik.errors.title}
								>
									<Input
										id="title"
										value={formik.values.title}
										onChange={formik.handleChange}
										isError={!!formik.errors.title}
									/>
								</LabelWithError>
							</Holder>
							<Holder width="100%">
								<LabelWithError
									htmlFor="category"
									title="Category"
									error={formik.errors.category}
								>
									<Select
										name="category"
										value={formik.values.category}
										onChange={formik.handleChange}
										onBlur={formik.handleBlur}
										isError={!!formik.errors.category}
										style={{ display: 'block' }}
									>
										<option value="" label="Select a category" />
										{Object.values(promotion_categories).map(category => (
											<option
												key={category}
												value={category}
												label={[
													category.slice(0, 1).toUpperCase(),
													category.slice(1),
												].join('')}
											/>
										))}
									</Select>
								</LabelWithError>
							</Holder>
							<Holder width="100%">
								<LabelWithError
									htmlFor="route"
									title="Link"
									error={formik.errors.route}
									hint={routePreview}
									hasToolTip={true}
									toolTipFor="routeToolTip"
								>
									<Input
										id="route"
										value={formik.values.route}
										onChange={formik.handleChange}
										isError={!!formik.errors.route}
									/>
								</LabelWithError>
							</Holder>
							{!externalRoute && (
								<Holder width="100%">
									<MobileRouteEditor
										values={formik.values.mobileRoute}
										errors={formik.errors.mobileRoute}
										setField={(fieldName: string, value) =>
											formik.setFieldValue('mobileRoute.' + fieldName, value)
										}
										initialValues={formik.initialValues}
										disabled={false}
									/>
								</Holder>
							)}
							<Holder width="100%">
								<LabelWithError
									htmlFor="expiry"
									title="Expiry"
									error={formik.errors.expiry}
								>
									<Input
										id="expiry"
										type="datetime-local"
										step="1"
										placeholder="YYYY-MM-DD HH:MM:SS"
										value={formik.values.expiry ?? ''}
										onChange={formik.handleChange}
										isError={!!formik.errors.expiry}
									/>
								</LabelWithError>
							</Holder>
							<Holder width="100%">
								<LabelWithError
									htmlFor="image"
									title="Image"
									error={formik.errors.image}
								>
									<UserImageUpload
										folder="promotion"
										selectedImage={formik.values.image}
										setSelectedImage={ImgSrc =>
											formik.setFieldValue('image', ImgSrc, true)
										}
									/>
								</LabelWithError>
							</Holder>
							<Holder width="100%">
								<LabelWithError
									htmlFor="description"
									title="Promotion Description"
									error={formik.errors.description}
								>
									<MarkdownEditor
										value={formik.values.description ?? ''}
										handleChange={({ html, text }) => {
											formik.handleChange({
												target: { id: 'description', value: text },
											} as React.ChangeEvent<HTMLInputElement>);
										}}
									/>
								</LabelWithError>
							</Holder>
							<Holder width="100%">
								<LabelWithError
									htmlFor="terms_and_conditions"
									title="Terms And Conditions"
									error={formik.errors.terms_and_conditions}
								>
									<MarkdownEditor
										value={formik.values.terms_and_conditions ?? ''}
										handleChange={({ html, text }) => {
											formik.handleChange({
												target: { id: 'terms_and_conditions', value: text },
											} as React.ChangeEvent<HTMLInputElement>);
										}}
									/>
								</LabelWithError>
							</Holder>
							<Holder width="100%">
								<LabelWithError
									htmlFor="hide_button"
									title="Hide/Show Bet Now Button"
									error={formik.errors.hide_button}
								>
									<ToggleSwitch
										id="hide_button"
										title="hide_button"
										checked={formik.values.hide_button}
										onChange={() => {
											formik.handleChange({
												target: {
													id: 'hide_button',
													value: !formik.values.hide_button,
												},
											});
										}}
										onBlur={formik.handleBlur}
										tooltipContent="Hide/Show 'Bet Now' Button"
									/>
								</LabelWithError>
							</Holder>
						</div>
					</LeftColumn>

					<RightColumn disabled={isMobile}>
						<PromotionsPreview promotions={formik.values} />
					</RightColumn>
				</SplitView>
				<ReactTooltip
					id="routeToolTip"
					effect="solid"
					{...toolTipConfig}
					getContent={() => (
						<div>
							External link will remove the Bet Now button and
							<br />
							will apply to both the mobile app and website.
						</div>
					)}
				></ReactTooltip>
			</Form>
		</Card>
	);
};

const promotionsToInitialformValue = (
	promotions: formValuesInterface[],
	id: string,
): formValuesInterface => {
	const defaultValues: formValuesInterface = {
		id: '',
		title: '',
		image: '',
		category: 'racing',
		route: '',
		expiry: '',
		description: '',
		terms_and_conditions: '',
		hide_button: false,
		mobileRoute: null,
	};

	return (
		(id !== null && promotions.find(promotion => promotion.id === id)) ||
		defaultValues
	);
};

const mapStateToProps = state => ({
	selectedPromotion: state.promotions.selectedPromotion,
	formTitle: state.promotions.selectedPromotion?.toString()
		? 'Edit Promotion'
		: 'New Promotion',
	buttonLabel: state.promotions.selectedPromotion?.toString()
		? 'Update'
		: 'Publish',
	initialValues: promotionsToInitialformValue(
		state.promotions.data,
		state.promotions.selectedPromotion?.toString(),
	),
});

const mapDispatchToProps = dispatch => {
	return {
		handleCreate: item => dispatch(createPromotion(item)),
		handleUpdate: item => dispatch(updatePromotion(item)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(PromotionsForm);
