import axios from 'axios';

/**
 * this request boolean values for restrict_by_address,restrict_by_address for block functions
 *
 * @param AuthHeader
 * @param brandConfig
 * @param brandId
 * @returns restrict_by_address: boolean restrict_by_address: boolean
 */
export const getIpAddressConfig = (AuthHeader, brandConfig, brandId) => {
	const builtURL = `${brandConfig.bettingEngineLegacyApi}/brandmanager/${brandId}/promotion/config`;
	return axios
		.get(builtURL, {
			headers: { 'content-type': 'application/json', ...AuthHeader },
		})
		.then(response => response.data)
		.catch(error => {
			throw error;
		});
};

/**
 * this update boolean values for restrict_by_address,restrict_by_address for block functions
 *
 * @param config
 * @param AuthHeader
 * @param brandConfig
 * @param brandId
 * @returns
 */
export const createIpAddressConfig = (
	config,
	AuthHeader,
	brandConfig,
	brandId,
) => {
	const builtURL = `${brandConfig.bettingEngineLegacyApi}/brandmanager/${brandId}/promotion/config`;
	const bodyJSON = JSON.stringify(config);

	return axios
		.post(builtURL, bodyJSON, {
			headers: { 'content-type': 'application/json', ...AuthHeader },
		})
		.then(response => response.data)
		.catch(error => {
			throw error;
		});
};
