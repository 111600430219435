import { Security } from '@okta/okta-react';
import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import reduxThunk from 'redux-thunk';
import AuthHandler from './components/AuthHandler';
import RootPages from './containers/Root';
import reducers from './store';

// configure Redux store
const store = createStore(
	reducers,
	composeWithDevTools(applyMiddleware(reduxThunk)),
);

// setup okta
const oktaConfig = {
	issuer: `${process.env.REACT_APP_OKTA_ORG_URL}/oauth2/default`,
	redirect_uri: `${window.location.origin}/implicit/callback`,
	client_id: process.env.REACT_APP_OKTA_CLIENT_ID,
};

const HasRequirements = () => {
	return (
		<Security {...oktaConfig}>
			<AuthHandler />
			<RootPages />
		</Security>
	);
};

const App = () => {
	return (
		<Provider store={store}>
			<BrowserRouter>
				<HasRequirements />
			</BrowserRouter>
		</Provider>
	);
};

export default App;
