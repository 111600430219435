import React from 'react';
import { TabGroup, TabItem } from '../../components/shared/Tabs';

function BannerNav({ handleTabSelect, currentView }) {
	const isActive = (name) => (name === currentView ? 'true' : 'false');
	return (
		<TabGroup>
			<TabItem
				onClick={(e) => handleTabSelect(e, 'homepage')}
				isactive={isActive('homepage')}
			>
				Homepage
			</TabItem>
			<TabItem
				onClick={(e) => handleTabSelect(e, 'racing')}
				isactive={isActive('racing')}
			>
				Racing Homepage
			</TabItem>
			<TabItem
				onClick={(e) => handleTabSelect(e, 'race')}
				isactive={isActive('race')}
			>
				Races &amp; Meetings
			</TabItem>
			<TabItem
				onClick={(e) => handleTabSelect(e, 'sports')}
				isactive={isActive('sports')}
			>
				Sports Homepage
			</TabItem>
			<TabItem
				onClick={(e) => handleTabSelect(e, 'event')}
				isactive={isActive('event')}
			>
				Sports &amp; Events
			</TabItem>
		</TabGroup>
	);
}

export default BannerNav;
