import Cache from './CacheClass';

type PromiseResolved<T> = T extends Promise<infer U> ? U : T;

const neededHashStuffAvailable =
	typeof TextEncoder !== 'undefined' && typeof crypto.subtle !== undefined;

export const hashOfString = async (input: string) => {
	/**
	 * if the crypto api is avaliable we will return a Promise< a SHA-256 Hex string> otherwise we just return the Promise<string>
	 */

	if (!neededHashStuffAvailable) {
		return input;
	}

	const buffer = new TextEncoder().encode(input);
	const hashBuffer = crypto.subtle.digest('SHA-256', buffer);

	const hashResult = new Uint8Array(await hashBuffer);

	let hexString = '';
	hashResult.forEach((b) => (hexString += b.toString(16).padStart(2, '0')));

	return hexString;
};

export const hashOfArgs = (...input: any[]) =>
	hashOfString(JSON.stringify(input));

class MemorizeCachedFunction<F extends (...args: any[]) => any> {
	func: F;
	argsIgnoreList: number[];
	private cache: Cache<PromiseResolved<ReturnType<F>>>;

	constructor(
		func: F,
		argsIgnoreList: number[] = [],
		ttl: number = 60000,
		maxItems: number = 50,
		cleanUpInterval: number = 30000,
	) {
		this.func = func;
		this.argsIgnoreList = argsIgnoreList;
		this.cache = new Cache(ttl, maxItems, cleanUpInterval);
		return this;
	}

	private async keyFromArgs(...args: Parameters<F>): Promise<string> {
		const argsListToUseAsKey = args.map((value, index) =>
			this.argsIgnoreList.includes(index) ? '__IGNORED__' : value,
		);
		return await hashOfArgs(...argsListToUseAsKey);
	}

	async remove(...args: Parameters<F>): Promise<void> {
		const hashKey = await this.keyFromArgs(...args);
		this.cache.remove(hashKey);
	}

	async execute(
		...args: Parameters<F>
	): Promise<PromiseResolved<ReturnType<F>>> {
		const hashKey = await this.keyFromArgs(...args);
		const cachedItem = this.cache.get(hashKey);

		// cache hit
		if (cachedItem) return cachedItem[1];

		// cache miss
		const [timestamp, result] = [Date.now(), await this.func(...args)];
		this.cache.set(hashKey, result, timestamp);

		return result;
	}

	clearCache() {
		this.cache.clearCache();
	}

	get maxItems() {
		return this.cache.maxItems;
	}

	get ttl() {
		return this.cache.ttl;
	}

	get cleanUpInterval() {
		return this.cache.cleanUpInterval;
	}

	get length() {
		return this.cache.length;
	}
}

export default MemorizeCachedFunction;
