import React, { useEffect, useState } from 'react';
import { AiFillDelete } from 'react-icons/ai';
import { GoArrowSmallDown, GoArrowSmallUp } from 'react-icons/go';
import { partialApply } from '../../../../helpers/pureFunc';
import styled from '../../../../common/styled';
import { StyledFooterGroupItemTop } from '../../styledAssets/footerConfigItemAssets';

export interface footerItemInterface {
	title: string;
	slug?: string;
	href?: string;
}

export interface footerGroupInterface {
	groupName: string;
	Items: footerItemInterface[];
}

const SendRight = styled.div`
	margin-left: auto;
	margin-right: ${({ theme }) => theme.spacings.cozy}px;
`;

const DeleteWrapper = styled.div`
	margin-left: ${({ theme }) => theme.spacings.cozy}px;
	&:hover {
		color: red;
	}
`;

const StyledMoveWrapper = styled.div`
	column-gap: ${({ theme }) => theme.spacings.tight}px;
	margin-left: ${({ theme }) => theme.spacings.cozy}px;
	margin-right: ${({ theme }) => theme.spacings.cozy}px;
	display: flex;
	justify-items: baseline;

	& > div {
		padding: ${({ theme }) => theme.spacings.constrictive}px;
		border-radius: 5%;
		color: black;
		background: white;
	}
	& > div:hover,
	& > div:focus {
		color: red;
		background: grey;
	}
`;

interface FooterGroupProps {
	groupName: string;
	Items: footerItemInterface[];
	selected: boolean;
	setSelected: () => void;
	updateGroupItems: (update: footerItemInterface[]) => void;
	deleteSelf: () => void;
	moveGroup: (direction: 'up' | 'down') => void;
}

export const FooterGroup = ({
	groupName,
	Items,
	selected,
	setSelected,
	updateGroupItems,
	deleteSelf,
	moveGroup,
}: FooterGroupProps) => {
	const [pendingUpdateGroupItems, setPendingUpdateGroupItems] = useState<
		null | footerItemInterface[]
	>(null);
	useEffect(() => {
		if (pendingUpdateGroupItems) {
			const temp = [...pendingUpdateGroupItems];
			setPendingUpdateGroupItems(null);
			updateGroupItems(temp);
		}
	}, [pendingUpdateGroupItems, updateGroupItems]);
	return (
		<div>
			<StyledFooterGroupItemTop onClick={partialApply(setSelected)}>
				{/* menu */}
				<span>{groupName}</span>

				<SendRight>
					{selected && (
						<StyledMoveWrapper>
							<span>Move</span>

							<div
								onClick={(e) => {
									e.preventDefault();
									moveGroup('up');
								}}
							>
								<GoArrowSmallUp />
							</div>
							<div
								onClick={(e) => {
									e.preventDefault();
									moveGroup('down');
								}}
							>
								<GoArrowSmallDown />
							</div>
						</StyledMoveWrapper>
					)}
				</SendRight>

				<span>{Items.length} Items</span>
				<DeleteWrapper onClick={deleteSelf}>
					<AiFillDelete />
				</DeleteWrapper>
			</StyledFooterGroupItemTop>
		</div>
	);
};
