import React from 'react';
import { connect } from 'react-redux';
import { useBrandConfig } from '../../context/brandConfig/brandConfigContext';
import { ContentItemsByStatus } from '../../helpers/newAPIClient';
import { contentItemType } from '../../store/pages';
import { Table, Tbody, Th, Thead, ThOptional } from '../shared/Table';
import ContentTableRow from './ContentTableRow';

interface ContentTableProps {
	content: ContentItemsByStatus;
	toggleModal: any;
}

const ContentTable = ({ content, toggleModal }: ContentTableProps) => {
	const { brandConfig } = useBrandConfig();
	const featurePage = brandConfig.featureFlags?.pages;

	const items = Object.keys(content)
		.map<{
			id: string;
			sortDate: string;
			draft?: contentItemType;
			published?: contentItemType;
		}>((key) => {
			const item = content[key];
			return {
				id: key,
				sortDate: item?.draft?.publish_date || item?.published?.publish_date,
				...item,
			};
		})
		.sort((a, b) => a.sortDate.localeCompare(b.sortDate));

	return (
		<Table>
			<Thead>
				<tr>
					<ThOptional>Slug</ThOptional>
					{featurePage?.version && 
						<ThOptional>Version</ThOptional>
					}
					<Th>Title</Th>
					<Th>Status</Th>
					<ThOptional>Date</ThOptional>
					<Th>Actions</Th>
				</tr>
			</Thead>
			<Tbody>
				{items.map(({ id, draft, published }) => (
					<ContentTableRow
						key={`published_${id}`}
						draft={draft}
						published={published}
						toggleModal={toggleModal}
					/>
				))}
			</Tbody>
		</Table>
	);
};

const mapStateToProps = (state) => ({
	content: state.pages.data,
});

export default connect(mapStateToProps)(ContentTable);
