import { useFormik } from 'formik';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { ToastsStore } from 'react-toasts';
import Tooltip from 'react-tooltip';
import { PromotionViewEditProps } from '.';
import { ModalStyle } from '../../../common/modalStyle';
import {
	promotionItemInterface,
	promotionRewardTypes,
} from '../../../helpers/promotionEngine/promotionEngineInterfaces';
import { partialApply } from '../../../helpers/pureFunc';
import { StyledButton } from '../../shared/Navs';
import ToggleSwitch from '../../shared/ToggleSwitch';
import {
	ColumnedArea,
	ColumnedLabeledArea,
	DepositFormLayout,
	DepositMatchFieldSet,
	InlineInput,
	InlineLabel,
	StyledLeadingI,
	StyledTrailingI,
} from '../DepositMatchFormComponents';
import ClientTags from './ClientTags';
import {
	dateTimeToHTMLSafe,
	formattedDateValue,
	handleChange,
	StyledErrorHint,
} from './Common';
import EligibleTerritories from './EligibleTerritories';
import RestrictToClientArea from './RestrictToClientArea';

const initialValues = (): Omit<promotionItemInterface, 'id'> => {
	const now = new Date();

	return {
		code: '',
		enabled: true,
		start_date: `${now
			.getFullYear()
			.toFixed(0)
			.padStart(4, '0')}-${(now
			.getMonth() + 1)
			.toFixed(0)
			.padStart(2, '0')}-${now
			.getDate()
			.toFixed(0)
			.padStart(2, '0')}`,
		end_date: null,
		description: '',
		internal_description: '',
		max_deposit: 5000,
		percent_of_deposit: 100,
		winning_turnover_factor: 0,
		restrict_to_clients: true,
		sign_up_offer: false,
		redemptions_per_customer: 1,
		promotion_type_id: 1,
		reward_type: promotionRewardTypes.BONUS_BETS,
		excluded_tags: [],
		eligible_territories: [],
	};
};

const DepositMatchForm = ({
	item,
	saveChanges,
	setViewEditCloseDelete,
	mode,
	cachedPromotionEndpoints,
	formElement,
	setFormElement,
}: PromotionViewEditProps) => {
	const existingItems = mode === 'new' ? null : item;

	const formik = useFormik({
		initialValues: {
			...initialValues(),
			...existingItems,
		},
		validateOnBlur: false,
		validateOnMount: mode !== 'new',
		validateOnChange: false,
		onSubmit: async values => {
			const result = await saveChanges(values);

			if (result instanceof Error) {
				ToastsStore.error('Failed To Submit Form');
			} else {
				const successMessage = item?.id
					? 'Successfully Updated Item'
					: 'Successfully Created Item';
				ToastsStore.success(successMessage);
				setViewEditCloseDelete('close');
			}
		},
		enableReinitialize: true,
	});

	const [close, setClose] = useState(false);

	const [manageClientListOpen, setManageClientListOpen] = useState(false);
	const [maxDepositFieldValue, setMaxDepositFieldValue] = useState(
		formik.values.max_deposit / 100,
	);
	const setFieldValueFunction = formik.setFieldValue;

	useEffect(() => {
		setFieldValueFunction(
			'max_deposit',
			Math.round(maxDepositFieldValue * 100),
		);
	}, [item, maxDepositFieldValue, setFieldValueFunction]);

	const disableInputs = formik.isSubmitting || mode === 'view';

	const territoriesCallback = territories => {
		formik.setFieldValue('eligible_territories', territories);
	};

	const tagCallback = tags => {
		formik.setFieldValue('excluded_tags', tags);
	};

	let formErrorMessage = '';
	if (!formik.isValid) formErrorMessage = "See Error's below";
	if (formik.isValidating) formErrorMessage = 'Checking';

	return (
		<DepositFormLayout
			formError={mode !== 'view' ? formErrorMessage : ''}
			mode={mode}
			actionClose={partialApply(setViewEditCloseDelete, 'close')}
			actionDelete={partialApply(setViewEditCloseDelete, 'delete')}
			actionEdit={partialApply(setViewEditCloseDelete, 'edit')}
			actionView={partialApply(setViewEditCloseDelete, 'view')}
			actionSave={formik.submitForm}
			formElement={formElement}
			setFormElement={setFormElement}
		>
			<Modal
				isOpen={manageClientListOpen}
				style={ModalStyle}
				shouldCloseOnOverlayClick={false}
			>
				{mode === 'view' && (
					<RestrictToClientArea
						promotionId={item?.id}
						closeMe={partialApply(setManageClientListOpen, false)}
						fetchPromotions={cachedPromotionEndpoints.promotionClientList}
						nukeClientList={cachedPromotionEndpoints.promotionClientListNuke}
						setClientList={cachedPromotionEndpoints.promotionClientListSet}
					/>
				)}
			</Modal>
			<form
				onClick={e => {
					e.stopPropagation();
					setClose(!close);
				}}
				onSubmit={formik.handleSubmit}
				style={{ width: '100%' }}
			>
				<div>
					<Tooltip id="tooltipManageCLientList">
						{mode !== 'view'
							? 'Save Promotion To Manage its Client List'
							: 'Manage Client List (restrict promotion to client list)'}
						Save Promotion To Edit Client List
					</Tooltip>
				</div>
				<DepositMatchFieldSet disabled={disableInputs}>
					<legend>General</legend>
					<ColumnedLabeledArea
						id="promoCode"
						snapWidth="500px"
						margin="4px"
						label="Promo Code"
					>
						{[
							{
								flexGrow: '1',
								child: (
									<InlineInput
										id="code"
										onChange={e => handleChange(e, formik)}
										value={formik.values.code}
										isError={!!formik.errors.code}
										onBlur={formik.handleBlur}
									/>
								),
							},
							{
								flexGrow: '4',
								child: (
									<StyledErrorHint>
										{mode !== 'view' ? formik.errors?.code : null}
									</StyledErrorHint>
								),
							},
						]}
					</ColumnedLabeledArea>
					<ColumnedLabeledArea
						id="activeArea"
						snapWidth="250px"
						margin="4px"
						label="Active"
					>
						{[
							{
								flexGrow: '1',
								child: (
									<ToggleSwitch
										id="enabled"
										checked={formik.values.enabled}
										onChange={e => handleChange(e, formik)}
										onBlur={formik.handleBlur}
										title="active"
										displayNotAllowedOnDisabled
										tooltipContent="Enable/Disable Promotion"
									/>
								),
							},
							{
								flexGrow: '4',
								child: (
									<StyledErrorHint>
										{mode !== 'view' && formik.errors?.enabled
											? `(${formik.errors?.enabled})`
											: ''}
									</StyledErrorHint>
								),
							},
						]}
					</ColumnedLabeledArea>
					<br />

					<ColumnedArea id="activeControlArea" snapWidth="550px" margin="4px">
						{[
							{
								flexGrow: '1',
								child: <InlineLabel htmlFor="start_date">Start</InlineLabel>,
							},
							{
								flexGrow: '1',
								child: (
									<InlineInput
										id="start_date"
										type="date"
										onChange={e => handleChange(e, formik)}
										value={dateTimeToHTMLSafe(formik.values.start_date)}
										onBlur={formik.handleBlur}
										max={formattedDateValue(formik.values.end_date)}
									/>
								),
							},
							{
								flexGrow: '1',
								child: <></>,
							},
							{
								flexGrow: '1',
								child: <InlineLabel htmlFor="dateFinish">Finish</InlineLabel>,
							},
							{
								flexGrow: '1',
								child: (
									<InlineInput
										id="end_date"
										type="date"
										min={
											formik.values.start_date
												? moment(formik.values.start_date, 'yyyy-MM-DD')
														.add('1', 'd')
														.format('yyyy-MM-DD')
												: undefined
										}
										onChange={e => handleChange(e, formik)}
										value={dateTimeToHTMLSafe(formik.values.end_date)}
										onBlur={formik.handleBlur}
									/>
								),
							},
							{
								flexGrow: '1',
								child: <div></div>,
							},
						]}
					</ColumnedArea>
					{(formik.errors.start_date || formik.errors.end_date) && (
						<StyledErrorHint>
							{mode !== 'view' &&
								Object.entries(formik.errors)
									.filter(
										([field, error]) =>
											field === 'start_date' || field === 'end_date',
									)
									.map(([_, value]) => <div>({value})</div>)}
							<br />
						</StyledErrorHint>
					)}
					<br />
					<br />
					<ColumnedLabeledArea
						id="description"
						snapWidth="1000px"
						margin="4px"
						label="Description"
					>
						{{
							flexGrow: '4',
							child: (
								<InlineInput
									type="text"
									id="description"
									onChange={e => handleChange(e, formik)}
									value={formik.values.description}
									isError={!!formik.errors?.description}
									onBlur={formik.handleBlur}
								/>
							),
						}}
					</ColumnedLabeledArea>
					{mode !== 'view' && formik.errors.description && (
						<div>
							<StyledErrorHint>{formik.errors.description}</StyledErrorHint>
							<br />
							<br />
						</div>
					)}
					<ColumnedLabeledArea
						id="InternalDescription"
						snapWidth="1000px"
						margin="4px"
						label="Internal Description"
					>
						{{
							flexGrow: '4',
							child: (
								<InlineInput
									type="text"
									id="internal_description"
									onChange={e => handleChange(e, formik)}
									value={formik.values.internal_description}
									isError={!!formik.errors?.internal_description}
									onBlur={formik.handleBlur}
								/>
							),
						}}
					</ColumnedLabeledArea>
					{mode !== 'view' && formik.errors.description && (
						<div>
							<StyledErrorHint>
								{formik.errors.internal_description}
							</StyledErrorHint>
							<br />
						</div>
					)}
				</DepositMatchFieldSet>
				<DepositMatchFieldSet showLocked={disableInputs}>
					<legend>Details</legend>
					<ColumnedArea id="transactionArea" snapWidth="350px" margin="4px">
						{[
							{
								flexGrow: '2',
								child: (
									<InlineLabel htmlFor="max_deposit">Max Deposit</InlineLabel>
								),
							},
							{
								flexGrow: '1',
								child: (
									<div style={{ position: 'relative' }}>
										<InlineInput
											disabled={disableInputs}
											id="max_deposit"
											type="number"
											min={0}
											step={1}
											value={maxDepositFieldValue}
											inputMode="decimal"
											onChange={e =>
												handleChange(e, formik, setMaxDepositFieldValue)
											}
											onBlur={formik.handleBlur}
											isError={!!formik.errors.max_deposit}
											required
										/>
										<StyledLeadingI>$</StyledLeadingI>
									</div>
								),
							},
							{
								flexGrow: '2',
								child: (
									<StyledErrorHint>
										{mode !== 'view' && formik.errors.max_deposit}
									</StyledErrorHint>
								),
							},
						]}
					</ColumnedArea>
					<ColumnedArea id="transactionArea" snapWidth="350px" margin="4px">
						{[
							{
								flexGrow: '2',
								child: (
									<InlineLabel htmlFor="percent_of_deposit">
										% of Deposit
									</InlineLabel>
								),
							},

							{
								flexGrow: '1',
								child: (
									<div style={{ position: 'relative' }}>
										<InlineInput
											disabled={disableInputs}
											id="percent_of_deposit"
											type="number"
											min={0}
											step={1}
											value={formik.values.percent_of_deposit}
											onChange={e => handleChange(e, formik)}
											onBlur={formik.handleBlur}
											isError={!!formik.errors.percent_of_deposit}
										/>
										<StyledTrailingI>%</StyledTrailingI>
									</div>
								),
							},
							{
								flexGrow: '2',
								child: (
									<StyledErrorHint>
										{mode !== 'view' && formik.errors.percent_of_deposit}
									</StyledErrorHint>
								),
							},
						]}
					</ColumnedArea>
					<ColumnedArea id="turnoverarea" snapWidth="350px" margin="4px">
						{[
							{
								flexGrow: '2',
								child: (
									<InlineLabel htmlFor="winning_turnover_factor">
										Winning Turnover Factor
									</InlineLabel>
								),
							},

							{
								flexGrow: '1',
								child: (
									<InlineInput
										disabled={disableInputs}
										id="winning_turnover_factor"
										type="number"
										min={0}
										step={1}
										value={formik.values.winning_turnover_factor}
										onChange={e => handleChange(e, formik)}
										onBlur={formik.handleBlur}
										isError={!!formik.errors.winning_turnover_factor}
									/>
								),
							},
							{
								flexGrow: '2',
								child: (
									<StyledErrorHint>
										{mode !== 'view' && formik.errors.winning_turnover_factor}
									</StyledErrorHint>
								),
							},
						]}
					</ColumnedArea>

					<br />

					<ColumnedArea id="RestrictToClient" snapWidth="350px" margin="4px">
						{[
							{
								flexGrow: '2',
								child: <InlineLabel>Client List</InlineLabel>,
							},
							{
								flexGrow: '1',
								child: (
									<ToggleSwitch
										disabled={disableInputs}
										title="restrict_to_clients"
										id="restrict_to_clients"
										checked={formik.values.restrict_to_clients}
										onBlur={formik.handleBlur}
										onChange={e => handleChange(e, formik)}
										displayNotAllowedOnDisabled
										tooltipContent="Restrict Promotion to client list"
									/>
								),
							},
							{
								flexGrow: '2',
								child: (
									<>
										{(mode === 'edit' || mode === 'new') &&
											formik.errors?.restrict_to_clients && (
												<StyledErrorHint>
													{formik.errors?.restrict_to_clients}
												</StyledErrorHint>
											)}
										{!(
											(mode === 'edit' || mode === 'new') &&
											formik.errors?.restrict_to_clients
										) &&
											formik.values.restrict_to_clients && (
												<div
													style={{
														width: '100%',
														display: 'flex',
														justifyContent: 'center',
													}}
												>
													<Tooltip id="tooltip_button_client_list">
														{mode !== 'view'
															? 'Save Promotion First'
															: 'Open Manage Client List Area'}
													</Tooltip>
													<div data-tip data-for="tooltip_button_client_list">
														<StyledButton
															disabled={
																mode === 'new' ||
																!(
																	mode === 'view' &&
																	!formik.errors?.restrict_to_clients
																)
															}
															form={'differentFormDontDisableWithParentForm'}
															onClick={partialApply(
																setManageClientListOpen,
																true,
															)}
															isactive={true}
															title={
																mode === 'new' ||
																!(
																	mode === 'view' &&
																	!formik.errors?.restrict_to_clients
																)
																	? 'Save Promotion First'
																	: null
															}
															data-testid="buttonRestrictClients"
														>
															Manage Client List
														</StyledButton>
													</div>
												</div>
											)}
									</>
								),
								// mode==='edit' &&
								// mode === 'view' ? (
								// 	<div
								// 		style={{
								// 			width: '100%',
								// 			display: 'flex',
								// 			justifyContent: 'center',
								// 		}}
								// 	>
								// 		<StyledButton
								// 			disabled={false}
								// 			form={'differentFormDontDisableWithParentForm'}
								// 			onClick={partialApply(setManageClientListOpen, true)}
								// 			isactive={'true'}
								// 		>
								// 			Manage Client List
								// 		</StyledButton>
								// 	</div>
								// ) : (
								// 	<StyledErrorHint>
								// 		{formik.errors?.restrict_to_clients}
								// 	</StyledErrorHint>
								// ),
							},
						]}
					</ColumnedArea>
					<ColumnedArea id="signupofferarea" snapWidth="350px" margin="4px">
						{[
							{
								flexGrow: '2',
								child: <InlineLabel>Sign Up Offer</InlineLabel>,
							},
							{
								flexGrow: '1',
								child: (
									<ToggleSwitch
										disabled={disableInputs}
										title=""
										id="sign_up_offer"
										checked={!!formik.values.sign_up_offer}
										onChange={e => handleChange(e, formik)}
										onBlur={formik.handleBlur}
										displayNotAllowedOnDisabled
										tooltipContent="Promotion Available to users on first Sign Up"
									/>
								),
							},
							{
								flexGrow: '2',
								child: (
									<StyledErrorHint>
										{mode !== 'view' && formik.errors?.sign_up_offer}
									</StyledErrorHint>
								),
							},
						]}
					</ColumnedArea>

					<ColumnedArea
						id="Max_Usage_per_Client"
						snapWidth="350px"
						margin="4px"
					>
						{[
							{
								flexGrow: '2',
								child: (
									<InlineLabel htmlFor="redemptions_per_customer">
										Usage Per Client
									</InlineLabel>
								),
							},
							{
								flexGrow: '1',
								child: (
									<InlineInput
										id="redemptions_per_customer"
										disabled={disableInputs}
										type="number"
										min="1"
										step="1"
										value={formik.values.redemptions_per_customer}
										onChange={e => handleChange(e, formik)}
										isError={!!formik.errors.redemptions_per_customer}
										onBlur={formik.handleBlur}
									/>
								),
							},
							{
								flexGrow: '2',
								child: (
									<StyledErrorHint>
										{mode !== 'view' && formik.errors.redemptions_per_customer}
									</StyledErrorHint>
								),
							},
						]}
					</ColumnedArea>
					<EligibleTerritories
						onClose={close}
						territoriesCallback={territoriesCallback}
						existingTerritories={formik.values.eligible_territories}
					/>
					<ClientTags
						tagCallback={tagCallback}
						existingTags={formik.values.excluded_tags}
					/>
				</DepositMatchFieldSet>
			</form>
		</DepositFormLayout>
	);
};

export default DepositMatchForm;
