import axios, { AxiosInstance } from 'axios';
import MemorizeCachedFunction from '../../helpers/MemorizeCachedFunction';
import { partialApply, FuncMissingFirstParam } from '../pureFunc';
import { generateHeadersFromAuthCode } from './rawEndpointFunc/common';
import promotionsTypeList from './rawEndpointFunc/promotionsTypeList';
import promotionsList from './rawEndpointFunc/promotionsList';
import promotionCreate from './rawEndpointFunc/promotionCreate';
import promotionValidate from './rawEndpointFunc/promotionValidate';
import promotionView from './rawEndpointFunc/promotionView';
import promotionUpdate from './rawEndpointFunc/promotionUpdate';
import promotionDelete from './rawEndpointFunc/promotionDelete';
import {
	promotionRedemptions,
	promotionRedemptionExport,
} from './rawEndpointFunc/promotionRedemptions';
import promotionClientList from './rawEndpointFunc/promotionClientList';
import promotionClientListSet from './rawEndpointFunc/promotionClientListSet';
import promotionClientListNuke from './rawEndpointFunc/promotionClientListNuke';

class PromotionEngineEndpoints {
	private axiosCreate: typeof axios.create;
	private baseUrl;
	private authToken: string;
	private axiosInstance: AxiosInstance;
	private getConfig() {
		return {
			axiosInstance: this.axiosInstance,
		};
	}

	private memorizedFunctions: {
		promotionsTypeList: MemorizeCachedFunction<
			FuncMissingFirstParam<typeof promotionsTypeList>
		>;
		promotionsList: MemorizeCachedFunction<
			FuncMissingFirstParam<typeof promotionsList>
		>;
		promotionView: MemorizeCachedFunction<
			FuncMissingFirstParam<typeof promotionView>
		>;
		promotionUpdate: MemorizeCachedFunction<
			FuncMissingFirstParam<typeof promotionUpdate>
		>;
		promotionRedemptions: MemorizeCachedFunction<
			FuncMissingFirstParam<typeof promotionRedemptions>
		>;
		promotionRedemptionExport: MemorizeCachedFunction<
			FuncMissingFirstParam<typeof promotionRedemptionExport>
		>;
		promotionClientList: MemorizeCachedFunction<
			FuncMissingFirstParam<typeof promotionClientList>
		>;
	};
	updateConfig(baseUrl: string, authToken?: string) {
		this.baseUrl = baseUrl;
		this.authToken = authToken;
		this.axiosInstance = this.axiosCreate({
			baseURL: this.baseUrl,
			headers: generateHeadersFromAuthCode(this.authToken),
		});
	}

	constructor(
		baseUrl: string,
		axiosCreate: typeof axios.create = axios.create,
	) {
		this.axiosCreate = axiosCreate;
		this.updateConfig(baseUrl);
		this.memorizedFunctions = {
			promotionsList: new MemorizeCachedFunction(
				partialApply(promotionsList, this.getConfig.bind(this)),
				[],
				60000 * 5, // 5 min
				50,
				5000,
			),
			promotionsTypeList: new MemorizeCachedFunction(
				partialApply(promotionsTypeList, this.getConfig.bind(this)),
				[],
				600000, // 10 mins
				50,
				5000,
			),
			promotionView: new MemorizeCachedFunction(
				partialApply(promotionView, this.getConfig.bind(this)),
				[],
				10000,
				50,
				5000,
			),
			promotionUpdate: new MemorizeCachedFunction(
				partialApply(promotionUpdate, this.getConfig.bind(this)),
				[],
				10000,
				50,
				5000,
			),
			promotionRedemptions: new MemorizeCachedFunction(
				partialApply(promotionRedemptions, this.getConfig.bind(this)),
				[],
				10000,
				5,
				5000,
			),
			promotionRedemptionExport: new MemorizeCachedFunction(
				partialApply(promotionRedemptionExport, this.getConfig.bind(this)),
				[],
				30000,
				2,
				5000,
			),
			promotionClientList: new MemorizeCachedFunction(
				partialApply(promotionClientList, this.getConfig.bind(this)),
				[],
				60000,
				2,
				5000,
			),
		};
	}

	public updateAuthToken(authToken: string) {
		this.updateConfig(this.baseUrl, authToken);
		return this;
	}

	public clearCache() {
		Object.values(this.memorizedFunctions).forEach((func) => func.clearCache());
		return this;
	}

	private async clearCacheForPromoId(promoId?: number) {
		this.promotionView.clearCache();
		if (promoId) await this.promotionView.remove(promoId);
		return this;
	}

	get promotionsTypeList() {
		return this.memorizedFunctions.promotionsTypeList;
	}

	get promotionsList() {
		return this.memorizedFunctions.promotionsList;
	}

	get promotionView() {
		return this.memorizedFunctions.promotionView;
	}

	get promotionRedemptions() {
		return this.memorizedFunctions.promotionRedemptions;
	}

	get promotionRedemptionExport() {
		return this.memorizedFunctions.promotionRedemptionExport;
	}
	get promotionClientList() {
		return this.memorizedFunctions.promotionClientList;
	}

	get promotionCreate() {
		const funcInner = partialApply(promotionCreate, this.getConfig.bind(this));

		const funcToReturn = (
			...[promotion, ...args]: Parameters<typeof funcInner>
		) =>
			funcInner(promotion, ...args).then(async (result) => {
				const _id = promotion?.id;
				if (_id) await this.clearCacheForPromoId(_id);
				return result;
			});
		return funcToReturn;
	}

	get promotionUpdate() {
		const funcInner = partialApply(promotionUpdate, this.getConfig.bind(this));

		const funcToReturn = (
			...[promotion, ...args]: Parameters<typeof funcInner>
		) =>
			funcInner(promotion, ...args).then(async (result) => {
				const _id = promotion?.id;
				if (_id) await this.clearCacheForPromoId(_id);
				return result;
			});
		return funcToReturn;
	}
	get promotionDelete() {
		const funcInner = partialApply(promotionDelete, this.getConfig.bind(this));

		const funcToReturn = (...[id, ...args]: Parameters<typeof funcInner>) =>
			funcInner(id, ...args).then(async (result) => {
				await this.clearCacheForPromoId(id);
				return result;
			});

		return funcToReturn;
	}
	get promotionValidate() {
		return partialApply(promotionValidate, this.getConfig.bind(this));
	}

	get promotionClientListNuke() {
		const funcInner = partialApply(
			promotionClientListNuke,
			this.getConfig.bind(this),
		);

		const funcToReturn = (...args: Parameters<typeof funcInner>) =>
			funcInner(...args).then(async (result) => {
				await this.promotionClientList.clearCache();
				return result;
			});
		return funcToReturn;
	}
	get promotionClientListSet() {
		const funcInner = partialApply(
			promotionClientListSet,
			this.getConfig.bind(this),
		);

		const funcToReturn = (...args: Parameters<typeof funcInner>) =>
			funcInner(...args).then(async (result) => {
				await this.promotionClientList.clearCache();
				return result;
			});
		return funcToReturn;
	}
}

export default PromotionEngineEndpoints;
