import { useFormik } from 'formik';
import * as React from 'react';
import { StyledButton } from '../../shared/Navs';
import {
	ColumnedLabeledArea,
	InlineInput,
} from '../DepositMatchFormComponents';
import { handleChange } from './Common';

interface formValues {
	tags: string[];
}

interface ClientTagsProps {
	tagCallback: any;
	existingTags: string[];
	label?: string;
}

const genInitialValues = (): Omit<formValues, 'id'> => {
	return {
		tags: [],
	};
};

const ClientTags: React.FC<ClientTagsProps> = ({
	tagCallback,
	existingTags,
	label,
}) => {
	const [showSummaryDisplay, setSummaryDisplay] = React.useState(false);
	const [tags, setTags] = React.useState<Array<any>>(existingTags || []);

	const formik = useFormik({
		initialValues: {
			...genInitialValues(),
		},
		validateOnBlur: false,
		validateOnChange: false,
		onSubmit: async () => {},
		enableReinitialize: false,
	});

	React.useEffect(() => {
		async function showSummary() {
			if (tags.length > 0) setSummaryDisplay(true);
			else setSummaryDisplay(false);
		}

		showSummary();
	}, [tags]);

	const handleAddTag = e => {
		e.preventDefault();

		const tag = formik.values.tags;

		if (tags.includes(tag)) return null;

		setTags([...tags, tag]);
		tagCallback([...tags, tag]);

		formik.setFieldValue('tags', '');
	};

	const handleEventRemove = territory => {
		const filtertags = tags.filter(e => e !== territory);

		setTags([...filtertags]);
		tagCallback([...filtertags]);
	};

	return (
		<div>
			<br />
			<ColumnedLabeledArea
				id="activeArea"
				snapWidth="250px"
				margin="4px"
				label={label || 'Excluded Tags'}
			>
				{[
					{
						flexGrow: '1',
						child: (
							<InlineInput
								type="text"
								id="tags"
								onChange={e => handleChange(e, formik)}
								value={formik.values.tags}
								isError={!!formik.errors?.tags}
								onBlur={formik.handleBlur}
							/>
						),
					},
					{
						flexGrow: '3',
						child: <StyledButton onClick={handleAddTag}>Add Tag</StyledButton>,
					},
				]}
			</ColumnedLabeledArea>

			{showSummaryDisplay &&
				tags?.map(tag => (
					<ColumnedLabeledArea
						id="activeArea"
						snapWidth="250px"
						margin="4px"
						label="Tags -"
					>
						{[
							{
								flexGrow: '1',
								child: <span>{tag}</span>,
							},
							{
								flexGrow: '3',
								child: (
									<StyledButton
										type="button"
										onClick={() => handleEventRemove(tag)}
									>
										Remove
									</StyledButton>
								),
							},
						]}
					</ColumnedLabeledArea>
				))}
		</div>
	);
};

export default ClientTags;
