import * as React from 'react';
import { useBrandConfig } from '../../context/brandConfig/brandConfigContext';
import {
	promotionTriggerTypes,
	promotionTypeLabels,
} from '../../helpers/promotionEngine/promotionEngineInterfaces';
import { TabGroup, TabItem } from '../shared/Tabs';

export interface FormSwitcherProps {
	formElement: number;
	setFormElement: (number) => void;
}

const FormSwitcher: React.SFC<FormSwitcherProps> = ({
	formElement,
	setFormElement,
}) => {
	const localBrandConfig = useBrandConfig()?.brandConfig;
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [activeTab, setTab] = React.useState<promotionTriggerTypes>(
		formElement,
	);

	const handleTabSelect = async name => {
		return [setTab(name), setFormElement(name)];
	};

	const runnerFormEnabled =
		localBrandConfig?.featureFlags?.promotionEngine?.runnerForm || false;

	// Disable the feature according to config
	const sportsBoostEnabled =
		localBrandConfig?.featureFlags?.promotionEngine?.sportsBoost || false;

	const racingBoostEnabled =
		localBrandConfig?.featureFlags?.promotionEngine?.racingBoost || false;

	return (
		<TabGroup>
			<TabItem
				onClick={e => handleTabSelect(promotionTriggerTypes.DEPOSIT)}
				isactive={(formElement === promotionTriggerTypes.DEPOSIT).toString()}
			>
				{promotionTypeLabels[promotionTriggerTypes.DEPOSIT]}
			</TabItem>
			{runnerFormEnabled && (
				<TabItem
					onClick={e => handleTabSelect(promotionTriggerTypes.RUNNER)}
					isactive={(formElement === promotionTriggerTypes.RUNNER).toString()}
				>
					{promotionTypeLabels[promotionTriggerTypes.RUNNER]}
				</TabItem>
			)}
			<TabItem
				onClick={e => handleTabSelect(promotionTriggerTypes.PROMO)}
				isactive={(formElement === promotionTriggerTypes.PROMO).toString()}
			>
				{promotionTypeLabels[promotionTriggerTypes.PROMO]}
			</TabItem>
			{sportsBoostEnabled && (
				<TabItem
					onClick={e => handleTabSelect(promotionTriggerTypes.SPORT)}
					isactive={(formElement === promotionTriggerTypes.SPORT).toString()}
				>
					{promotionTypeLabels[promotionTriggerTypes.SPORT]}
				</TabItem>
			)}
			{racingBoostEnabled && (
				<TabItem
					onClick={e => handleTabSelect(promotionTriggerTypes.RACING)}
					isactive={(formElement === promotionTriggerTypes.RACING).toString()}
				>
					{promotionTypeLabels[promotionTriggerTypes.RACING]}
				</TabItem>
			)}
		</TabGroup>
	);
};

export default FormSwitcher;
