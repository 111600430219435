import axios from 'axios';

import { filterObj } from '../helpers/index';

const ItemPksList = ['content', 'banner', 'promotion'] as const;
export type ItemPkType = typeof ItemPksList[number];

const ItemStatusList = ['draft', 'published'] as const;
export type ItemStatusType = typeof ItemStatusList[number];

export interface commonItemEntry {
	readonly pk: ItemPkType;
	readonly status_id: string;
	readonly id: string; // not this should follow pattern '${status}_${id}
	readonly status: ItemStatusType;
	publish_date: string; // note should follow pattern YYYY-MM-DD HH:MM:SS
}

export interface ItemByStatus<T> {
	draft?: T;
	published?: T;
}

export interface ItemsByStatus<T> {
	[id: string]: ItemByStatus<T>;
}

class APIClientEndpoint<T extends commonItemEntry> {
	protected url: string;
	protected authCode: string;
	private PrivGetPostPrefix:string;

	authHeader() {
		return { Authorization: `Bearer ${this.authCode}` };
	}

	constructor(url: string, PrivGetPostPrefix:string='') {
		this.url = url;
		this.PrivGetPostPrefix=PrivGetPostPrefix;
	}
	useAuthCode(code: string): APIClientEndpoint<T> {
		this.authCode = code;
		return this;
	}
	static mapArrayOfItemsToItemsByStatus<T extends commonItemEntry>(Items: T[]): ItemsByStatus<T> {
		return Items.reduce<ItemsByStatus<T>>(
			(acc, next) => ({
				...acc,
				[next.id]: { ...acc[next.id], [next.status]: { ...next } },
			}),
			{},
		);
	}

	async fetchAll(): Promise<ItemsByStatus<T>> {
		const Items = await this.fetchByStatus('all');
		return APIClientEndpoint.mapArrayOfItemsToItemsByStatus<T>(Items);
	}

	async fetchByStatus(status: ItemStatusType | 'all'): Promise<T[]> {
		const builtURL = `${this.url}${this.PrivGetPostPrefix}/${status}`;
		const builtUrlFallback = `${this.url}/${status}`;
		
		try {
			const request = axios.get(builtURL, {
				withCredentials: false,
				headers: { ...this.authHeader() },
			}).catch(() => {
				// try fallback url ( non private routes )
				return axios.get(builtUrlFallback, {
					withCredentials: false,
					headers: { ...this.authHeader() },
				})
			});

			const response = await request;
			if (response.status !== 200) throw new Error('cant download content')
			const Items = (response.data.Items || []) as T[];
			return Items;
		} catch (e) {
			console.log(e);
			return [];
		}
	}

	async deleteItem(status: ItemStatusType, id: string) {
		const builtURL = `${this.url}/${id}/delete`;
		try {
			await axios.post(
				builtURL,
				{},
				{
					params: { status },
					headers: { ...this.authHeader() },
				},
			);
		} catch (e) {
			console.log(e);
		}
	}

	async updateDraft(Item: T) {
		const builtURL = `${this.url}/${Item.id}/update`;
		const bodyJSON = JSON.stringify({ Item });
		const result = await axios.post(builtURL, bodyJSON, {
			headers: { ...this.authHeader() },
		});

		return result;
	}

	async createDraft(Item: T) {
		const builtURL = `${this.url}/create`;
		// filtering out ID as its a new Item we want the server to generate one
		const filterItem = filterObj<any>((_, key) => key !== 'id', Item);
		const bodyJSON = JSON.stringify({ Item: filterItem });
		const result = await axios.post(builtURL, bodyJSON, {
			headers: { ...this.authHeader() },
		});

		return result;
	}

	async publishDraft(Item: T) {
		const builtURL = `${this.url}/${Item.id}/publish`;
		const result = await axios.post(
			builtURL,
			{},
			{
				headers: { ...this.authHeader() },
			},
		);

		return result;
	}

	async createDraftFrom(Item: T) {
		const builtURL = `${this.url}/${Item.id}/create_draft_from`;
		const result = await axios.post(
			builtURL,
			{},
			{
				headers: { ...this.authHeader() },
			},
		);

		return result;
	}
}

export default APIClientEndpoint;
