import Axios from 'axios';
import {
	approvedPartnersCodes,
	footerConfigInterface,
	paymentMethodsCodes,
	TextColorThemeCodes,
} from './footerEndpoints/TypesDefs';

export const initialSettings: footerConfigInterface = {
	compactLayout: true,
	textColorTheme: TextColorThemeCodes.alternate,
	Items: {
		Content: {
			enabled: false,
			data: {
				Items: [],
			},
		},
		ApprovedPartners: {
			enabled: true,
			data: {
				Items: Object.values(approvedPartnersCodes),
			},
		},
		ResponsibleGambling: {
			enabled: true,
			data: {
				title: 'Must Be 18+',
				body:
					'Think About Your Choices Call Gambling Help 1800 858 858 Responsible Gamblinge',
				url: 'https://www.gamblinghelponline.org.au/',
			},
		},
		Social: {
			enabled: true,
			data: {
				Items: [], //Object.values(socialCodes).map((code) => ({ code, href: '#' })),
			},
		},
		MobileApps: {
			enabled: true,
			data: {
				Items: {},
			},
		},
		PaymentMethods: {
			enabled: true,
			data: {
				Items: Object.values(paymentMethodsCodes),
			},
		},
	},
};

class FooterEndpoints {
	url: string;
	authCode: string = '';

	constructor(url: string) {
		this.url = url;
	}
	useAuthCode(code: string) {
		this.authCode = code;
	}
	authHeader() {
		return { Authorization: `Bearer ${this.authCode}` };
	}
	async getFooterConfig() {
		const RequestResult = await Axios.get(this.url, { withCredentials: false });
		if (RequestResult.status !== 200)
			throw new Error('Error Contacting Footer API');

		const fetchedFooterConfig = RequestResult.data?.Item;
		//merge in default settings so when api doesn't return a value we still have defaults in the UI.
		const mergedFooterConfig: footerConfigInterface = {
			...fetchedFooterConfig,
			Items: {
				...initialSettings.Items,
				...fetchedFooterConfig?.Items,
			},
		};

		return mergedFooterConfig;
	}
	async setFooterConfig(config: footerConfigInterface) {
		const req = await Axios.post(
			this.url,
			{ Item: config },
			{
				headers: { Authorization: 'Bearer ' + this.authCode },
				withCredentials: false,
			},
		);
		if (req.status !== 200) throw new Error('Error Contacting Footer API');
		return req.data?.Item;
	}
}

export default FooterEndpoints;
