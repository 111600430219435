type CacheObject<O> = {
	[key: string]: [number, O];
};

function cacheItemExpired(timestamp: number, ttl: number, now: number) {
	return now - timestamp - ttl > 0;
}

const findOldestCacheKey = <O>(input: CacheObject<O>) => {
	const oldestItem = Object.entries(input).reduce((oldEntry, nextEntry) => {
		const oldTimestamp = oldEntry[1][0];
		const nextTimestamp = nextEntry[1][0];
		return oldTimestamp < nextTimestamp ? oldEntry : nextEntry;
	});
	return oldestItem[0];
};

class CacheClass<O> {
	readonly ttl: number;
	readonly maxItems: number;
	readonly cleanUpInterval: number;
	private cache: CacheObject<O>;
	private cleanUpTimer: number | undefined;

	constructor(
		ttl: number = 60000,
		maxItems: number = 50,
		cleanUpInterval: number = 10000,
	) {
		this.ttl = ttl;
		this.maxItems = maxItems;
		this.cleanUpInterval = cleanUpInterval;
		this.cache = {};
	}

	cleanUp(now: number = Date.now()) {
		clearTimeout(this?.cleanUpTimer);

		Object.entries(this.cache).forEach(([key, [timestamp, _value]]) => {
			if (cacheItemExpired(timestamp, this.ttl, now)) delete this.cache[key];
		});
		// delete items until at maxItems
		while (Object.keys(this.cache).length > this.maxItems) {
			const oldestItemKey = findOldestCacheKey(this.cache);
			delete this.cache[oldestItemKey];
		}
		this.cleanUpTimer = window.setTimeout(
			this.cleanUp.bind(this),
			this.cleanUpInterval,
		);
	}

	set(key: string, value: O, now: number = Date.now()) {
		this.cache[key] = [now, value];
		this.cleanUp(now);
	}

	get(key: string, now: number = Date.now()): [number, O] | undefined {
		const item = this.cache[key];
		const itemTimestamp = item?.[0] || 0;
		if (cacheItemExpired(itemTimestamp, this.ttl, now)) delete this.cache[key];

		return this.cache[key];
	}

	remove(key: string) {
		delete this.cache[key];
	}

	clearCache() {
		// no need to start the clearInterval again as the this.set will call cleanup which restarts the interval
		clearTimeout(this.cleanUpTimer);
		this.cache = {};
	}

	get length() {
		return Object.keys(this.cache).length;
	}
}

export default CacheClass;
