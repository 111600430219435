import axios from 'axios';

class HighlightEndpoints {
	constructor(url) {
		this.url = url;
	}
	useAuthCode(code) {
		this.authCode = code;
	}
	authHeader() {
		return { Authorization: 'Bearer ' + this.authCode };
	}
	async get(authStatus) {
		const builtURL = `${this.url}`;
		try {
			const response = await axios.get(builtURL, {
				withCredentials: false,
				params: {
					authentication_status: authStatus,
				},
			});
			const Items =
				response.data.Items.length > 0 ? response.data.Items : 'No items';
			return Items;
		} catch (e) {
			console.log(e);
			return [];
		}
	}

	async fetchAllHighlights(authStatus) {
		return await this.get(authStatus);
	}

	async createHighlight(highlightItem) {
		const builtURL = `${this.url}/create`;
		const bodyJSON = JSON.stringify({ Item: highlightItem });

		return await axios.post(builtURL, bodyJSON, {
			headers: { 'content-type': 'application/json', ...this.authHeader() },
		});
	}

	async deleteHighlight(id) {
		const builtURL = `${this.url}/${id}/delete`;

		return await axios.post(
			builtURL,
			{},
			{ headers: { ...this.authHeader() } },
		);
	}

	async updateHighlight(highlightItem) {
		const builtURL = `${this.url}/${highlightItem.id}/update`;
		const bodyJSON = JSON.stringify({ Item: highlightItem });

		return await axios.post(builtURL, bodyJSON, {
			headers: { 'content-type': 'application/json', ...this.authHeader() },
		});
	}
}

export default HighlightEndpoints;
