import React from 'react';
import { AiFillDelete } from 'react-icons/ai';
import { UserImageHoverDeleteStyled } from './UserImageHoverDelete.styled';
interface props {
	imageSrc: string;
	onClick: () => void;
}
const UserImageHoverDeleteButton = ({ imageSrc, onClick }: props) => (
	<UserImageHoverDeleteStyled className="container">
		<img alt="chosen" className="image" src={imageSrc} onClick={onClick} />
		<div className="menu">
			<span>
				<AiFillDelete />
			</span>
		</div>
	</UserImageHoverDeleteStyled>
);

export default UserImageHoverDeleteButton;
