import { AxiosError, AxiosResponse } from 'axios';
import React from 'react';
import PromotionEngineEndpoints from '../../../helpers/promotionEngine/PromotionEngineEndpoints';
import {
	manualPromoFormInterface,
	promotionItemInterface,
	promotionTriggerTypes,
	racingBoostFormInterface,
	runnerFormInterface,
	sportBoostFormInterface,
} from '../../../helpers/promotionEngine/promotionEngineInterfaces';
import {
	promotionsCreateFailResponse,
	promotionsCreateResponse,
} from '../../../helpers/promotionEngine/rawEndpointFunc/promotionCreate';
import {
	promotionsUpdateFailResponse,
	promotionsUpdateResponse,
} from '../../../helpers/promotionEngine/rawEndpointFunc/promotionUpdate';
import { partialApply } from '../../../helpers/pureFunc';
import { DepositFormLayout } from '../DepositMatchFormComponents';
import PromotionEngineRedeemedAreaDialog from '../PromotionEngineRedeemedAreaDialog';
import DepositMatchForm from './DepositMatchForm';
import ManualPromoForm from './ManualPromoForm';
import RacingBoostForm from './RacingBoostForm';
import RunnerForm from './RunnerForm';
import SportsBoostForm from './SportsBoostForm';

export type PromotionViewEditProps<T = Record<string, unknown>> = {
	item:
		| promotionItemInterface
		| runnerFormInterface
		| manualPromoFormInterface
		| sportBoostFormInterface
		| racingBoostFormInterface;
	setViewEditCloseDelete: (input: 'view' | 'edit' | 'close' | 'delete') => void;
	saveChanges: (
		input: T,
	) => Promise<
		| AxiosResponse<promotionsCreateResponse<T> | promotionsUpdateResponse<T>>
		| AxiosError<
				promotionsCreateFailResponse<T> | promotionsUpdateFailResponse<T> | any
		  >
	>;
	mode: 'view' | 'edit' | 'new' | 'redeem';
	validate: (
		promotionItemInterface,
	) => Promise<{ [K in keyof promotionItemInterface]?: string }>;
	cachedPromotionEndpoints: PromotionEngineEndpoints;
	formElement: any;
	setFormElement: any;
};

type modalContentElementType<T = unknown> = (
	props: PromotionViewEditProps<T>,
) => React.ReactElement;

const FallbackModalContent: modalContentElementType = ({
	setViewEditCloseDelete,
	setFormElement,
	formElement,
}) => (
	<DepositFormLayout
		actionClose={partialApply(setViewEditCloseDelete, 'close')}
		mode="unsupported"
		actionDelete={partialApply(setViewEditCloseDelete, 'delete')}
		formElement={formElement}
		setFormElement={setFormElement}
	>
		<div>Sorry, this promotion type cannot be viewed.</div>
	</DepositFormLayout>
);

export const PromotionTypeComponentLookup: {
	[K in promotionTriggerTypes]?: {
		[K in 'view' | 'edit' | 'new' | 'redeem']?: modalContentElementType;
	};
} = {
	[promotionTriggerTypes.DEPOSIT]: {
		edit: DepositMatchForm,
		view: DepositMatchForm,
		new: DepositMatchForm,
	},
	[promotionTriggerTypes.RUNNER]: {
		edit: RunnerForm,
		view: RunnerForm,
		new: RunnerForm,
	},
	[promotionTriggerTypes.PROMO]: {
		edit: ManualPromoForm,
		view: ManualPromoForm,
		new: ManualPromoForm,
	},
	[promotionTriggerTypes.SPORT]: {
		edit: SportsBoostForm,
		view: SportsBoostForm,
		new: SportsBoostForm,
	},
	[promotionTriggerTypes.RACING]: {
		edit: RacingBoostForm,
		view: RacingBoostForm,
		new: RacingBoostForm,
	},
};

export const getModalContentElement = (
	triggerType: number,
	mode,
	setFormElement: any,
): modalContentElementType => {
	if (mode === 'redeem') return PromotionEngineRedeemedAreaDialog;
	return (
		PromotionTypeComponentLookup?.[triggerType]?.[mode] || FallbackModalContent
	);
};
