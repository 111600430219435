import React, { useEffect, useState } from 'react';
import { FiX, FiDelete } from 'react-icons/fi';
import Papa from 'papaparse';
import { CSVReader } from 'react-papaparse';
import { ToastsStore } from 'react-toasts';

import styled, { css } from '../../../common/styled';
import PromotionEngineEndpoints from '../../../helpers/promotionEngine/PromotionEngineEndpoints';

import useStateOptionalDelayRender from '../../../hooks/useStateOptionalDelayRender';
import { ManageTable } from '../promotionEngineManageScreen';
import PageHeader from '../../shared/PageHeader';
import Card from '../../shared/Card';
import { Divider } from '../../shared/FormElements';
import { StyledButton } from '../../shared/Navs';
import { promotionClientListResponse } from '../../../helpers/promotionEngine/rawEndpointFunc/promotionClientList';
import Pagination from '../Pagination';

type fileCsvRowErrorType = { code: string; message: string; type: string };

type fileCsvRowMeta = {
	aborted: boolean;
	cursor: number;
	delimiter: string;
	linebreak: string;
	truncated: boolean;
};

type fileCsvRowType = {
	data: Array<string | number | boolean>;
	errors: Array<fileCsvRowErrorType>;
	meta: fileCsvRowMeta;
};

type importedFileType = {
	file: File;
	rows: { field: string | number | boolean; errors: string[] }[];
	isErrors: boolean;
};

const StyledCard = styled(Card)`
	overflow-y: scroll;
	max-height: 90vh;
`;

const StyledMenu = styled.div`
	margin-left: 'auto';
	display: flex;
	& > * {
		margin-left: ${({ theme }) => theme.spacings.compact}px;
	}
`;

const ScrollBox = styled.div<{ maxHeight?: string }>(
	({ maxHeight }) =>
		maxHeight &&
		css`
			overflow-y: scroll;
			max-height: ${maxHeight};
		`,
);

const FlexSplitView = styled.div`
	display: flex;
	justify-content: space-around;
	align-items: flex-start;
	flex-wrap: wrap;
	width: 100%;
	margin: 10px;
	background: grey;
	& > * {
		flex: 1 0 300px;
		background: white;
		border-radius: 5px;
		margin: 15px;
		padding: 15px;
		box-sizing: border-box;
	}
`;

const validCsvRow = (
	row: any,
	index: number,
): typeof row extends fileCsvRowType ? [true] : [false, Error] => {
	const isCSVRowError = (input: any): input is fileCsvRowErrorType =>
		typeof input === 'object' &&
		['code', 'message', 'type'].every((key) =>
			['string', 'number'].includes(typeof input[key]),
		);

	if (
		typeof row !== 'object' || // row isn't a object
		!['data', 'errors', 'meta'].every((key) =>
			Object.keys(row).includes(key),
		) || // missing expected fields
		!['data', 'errors'].every((key) => Array.isArray(row[key])) || // missing expected fields
		!row.errors.every(isCSVRowError) || // errors in unexpected shape
		!Array.isArray(row.data) // row isn't an array of fields
	)
		return [
			false,
			new Error(`unable to parse line in file (line#: ${index + 1})`),
		];

	return [true];
};

const isCSVFileType = (
	data: any,
	file: File,
): typeof data extends fileCsvRowType[] ? [true] : [false, Error[]] => {
	if (
		!file.name.toUpperCase().endsWith('.CSV') ||
		typeof data !== 'object' ||
		!Array.isArray(data)
	)
		return [
			false,
			[new Error('unable to parse file, please check is valid csv file')],
		];

	const errors = data.flatMap((row, index) => {
		const [valid, error] = validCsvRow(row, index);

		return !valid || error ? [error] : [];
	});

	return errors.length ? [false, errors] : [true];
};

const exampleJSON = [23, 48, 59].map((item) => ({
	userId: item,
}));

const exampleCSVString = Papa.unparse(exampleJSON);

const exampleCSVHref =
	'data:text/plain;charset=utf-8,' + encodeURIComponent(exampleCSVString);

interface props {
	promotionId: number;
	closeMe: () => void;
	fetchPromotions: PromotionEngineEndpoints['promotionClientList'];
	nukeClientList: PromotionEngineEndpoints['promotionClientListNuke'];
	setClientList: PromotionEngineEndpoints['promotionClientListSet'];
}

const setLoadingFetchReducer = (value) => (state) => ({
	...state,
	fetchLoading: value,
});
const setLoadingUploadReducer = (value) => (state) => ({
	...state,
	uploadLoading: value,
});

const paginationItems = 20;

const RestrictToClientArea = ({
	promotionId,
	closeMe,
	fetchPromotions: fetchClientList,
	nukeClientList,
	setClientList,
}: props) => {
	const [paginationDetails, setPaginationDetails] = useState<{
		page: number;
		items: number;
	}>({ page: 1, items: paginationItems });

	const [{ fetchLoading, uploadLoading }, setLoadingState] = useState({
		fetchLoading: true,
		uploadLoading: false,
	});

	const [
		fetchedClientList,
		setFetchedClientList,
	] = useState<promotionClientListResponse>(null);

	const [
		importedFile,
		setImportedFile,
	] = useStateOptionalDelayRender<importedFileType | null>(null);

	const [isRestForCSVComponent, SetIsRestForCSVComponent] = useState(false);
	const resetCSVImport = () => {
		SetIsRestForCSVComponent((state) => !state);
		setPaginationDetails({ page: 1, items: paginationItems });
	};

	const importedFileMissing = !importedFile;

	useEffect(() => {
		setLoadingState(setLoadingFetchReducer(true));
		fetchClientList
			.execute(promotionId, paginationDetails.page, paginationDetails.items)
			.then((result) => {
				setLoadingState(setLoadingFetchReducer(false));
				if (result instanceof Error)
					if (result.response.status === 404)
						ToastsStore.error(
							'Failed To Fetch client list, This Promotion May no longer exist',
						);
					else
						ToastsStore.error('Failed To Fetch Client List for this promotion');
				else setFetchedClientList(result.data);
			});
	}, [fetchClientList, promotionId, importedFileMissing, paginationDetails]);

	const onCloseHandler = () => {
		if (
			!importedFile ||
			window.confirm('Are you sure you want to exit without saving')
		)
			closeMe();
	};

	const onNukeHandler = async () => {
		if (window.confirm('Are you sure you want to delete whole Client List')) {
			const nukeResult = await nukeClientList(promotionId);

			if (nukeResult instanceof Error) {
				ToastsStore.error('Failed To Delete Client List');
			} else {
				ToastsStore.success('Client List Deleted');
				setFetchedClientList(null);
				resetCSVImport();
				setImportedFile(() => null);

				const newFetchResult = await fetchClientList.execute(promotionId);
				if (newFetchResult instanceof Error) {
					ToastsStore.error('failed to refetch client list');
				} else {
					setFetchedClientList(newFetchResult.data);
				}
			}
		}
	};

	const onImportFile = (data: any, file: File) => {
		setImportedFile(() => null);

		const [csvValid, csvImportErrors] = isCSVFileType(data, file);

		if (!csvValid) {
			console.log('error parsing csv file', csvImportErrors);

			const userErrorReport = (
				<div>
					Error Importing File:
					<ul>
						{csvImportErrors.map((error, i) =>
							csvImportErrors.length > 1 ? (
								<li key={i + error.message}>{error.message}</li>
							) : (
								<div key={i + error.message}>{error.message}</div>
							),
						)}
					</ul>
				</div>
			);
			//@ts-expect-error this can removed after I make a PR to the libary we are using as its been typed incorrectly see issue here https://github.com/Vashnak/react-toasts/issues/23
			return void ToastsStore.error(userErrorReport);
		} else {
			const validHeaderFields = ['USERID', 'USERIDS', 'USER_ID', 'USER_IDS'];

			// const isNumber = (input:any):input is number => typeof input === 'number';
			const isUnique = (input: any, index: number, list: any[]) =>
				list.every((item, i) => item !== input || i === index);
			const isString = (input: any): input is string =>
				typeof input === 'string';
			const isValidHeaderName = (input: string) =>
				validHeaderFields.includes(input);

			const firstRow = data?.[0];
			const isHeaderRow = firstRow.data.every(
				(...args: [item: any, index: number, Array: Array<any>]) =>
					isString(args[0]) && isUnique(...args),
			);
			const foundColumnNumber = isHeaderRow
				? firstRow.data.findIndex((head: string) =>
						isValidHeaderName(head.toUpperCase()),
				  )
				: -1;
			const columnNumber = foundColumnNumber >= 0 ? foundColumnNumber : 0;

			const csvBody = data.slice(isHeaderRow ? 1 : 0);

			const itemData: {
				field: string | number | boolean;
				errors: string[];
			}[] = csvBody
				.filter(
					(row) =>
						row.data[columnNumber] !== null &&
						typeof row.data[columnNumber] !== 'undefined',
				)
				.map((row, index) => {
					const userId = row.data[columnNumber];
					const errors = row.errors
						.filter((error) =>
							index === 0 && row === firstRow
								? error.code !== 'UndetectableDelimiter'
								: true,
						)
						.map((error) => error.message);
					if (
						typeof userId !== 'number' ||
						!Number.isInteger(userId) ||
						userId <= 0
					)
						errors.push('userId must be a positive Integer');

					return { field: userId, errors };
				});

			const isErrors = itemData.some((row) => row.errors.length > 0);
			setImportedFile(() => ({
				file,
				isErrors,
				rows: itemData,
			}));

			if (isErrors)
				return void ToastsStore.error(
					'Failed To Import, (review errors in table)',
				);

			const prevIds = fetchedClientList.data.map((item) => item.user_id);
			const newIds = itemData.map((item) => item.field) as number[];
			setLoadingState(setLoadingUploadReducer(true));
			setClientList(promotionId, [...prevIds, ...newIds])
				.then((result) => {
					setLoadingState(setLoadingUploadReducer(false));
					if (result instanceof Error) {
						ToastsStore.error('Failed To Save Users See Errors Below');
						const userIdsWithErrors = result?.response?.data?.errors?.userIds;
						if (userIdsWithErrors) {
							setImportedFile((state) => ({
								...state,
								isErrors: true,
								rows: state.rows.map((item) => {
									const itemId = item.field as number | string;
									return {
										...item,
										errors:
											itemId in userIdsWithErrors
												? [...item.errors, userIdsWithErrors[itemId]]
												: item.errors,
									};
								}),
							}));
						}
					} else {
						ToastsStore.success('Successfully Updated Client List');
						fetchClientList.clearCache();
						setImportedFile(() => null);
						resetCSVImport();
					}
				})
				.catch(() => ToastsStore.error('Failed To Save Client List'));
		}
	};

	const StickyThStyle = styled.th`
		position: sticky;
		top: 0;
		margin-top: -2px;
		background: white;
		border-bottom: 2px solid black;
		border-top: 2px solid black;
	`;

	const uploadStatus = (() => {
		if (!importedFile) return null;
		if (uploadLoading) return 'Uploading';
		if (importedFile.isErrors) return "Error'/s In File";
	})();

	return (
		<StyledCard isModal>
			<PageHeader title="Restrict To Clients">
				<StyledMenu>
					<StyledButton onClick={onNukeHandler}>
						Delete Client List <FiDelete />
					</StyledButton>
					<StyledButton onClick={onCloseHandler} data-testid="buttonClose-top">
						Close <FiX className="icon-only-mobile" />
					</StyledButton>
				</StyledMenu>
			</PageHeader>
			<Divider />
			<ManageTable underMutation={fetchLoading}>
				<thead>
					<tr>
						<StickyThStyle>userId</StickyThStyle>
						<StickyThStyle>email</StickyThStyle>
						<StickyThStyle>status</StickyThStyle>
					</tr>
				</thead>
				<tbody style={{ fontSize: '0.8em' }}>
					{fetchedClientList &&
						fetchedClientList.data.map((item) => (
							<tr key={`${item.user_id}_fetched`}>
								<td align="center">{item.user_id}</td>
								<td align="center">{item.email}</td>
								<td align="center">saved</td>
							</tr>
						))}
					{fetchedClientList && (
						<tr>
							<td colSpan={3} align="right">
								{paginationDetails.page} of {fetchedClientList.last_page} pages
							</td>
						</tr>
					)}
				</tbody>
			</ManageTable>
			{fetchedClientList && (
				<Pagination
					EitherSide={2}
					current={paginationDetails.page}
					min={1}
					max={fetchedClientList?.last_page}
					pageRequest={(page) => {
						setPaginationDetails({ ...paginationDetails, page });
					}}
				/>
			)}
			<br />

			<h3>Import a CSV File (Append To Client List)</h3>
			<div
				style={{
					height: '200px',
					padding: '20px',
					margin: '20px',
					marginBottom: '30px',
				}}
			>
				<CSVReader
					accept="text/csv"
					onFileLoad={onImportFile}
					addRemoveButton
					config={{ dynamicTyping: true, delimiter: ',' }}
					onRemoveFile={() => setImportedFile(null)}
					isReset={isRestForCSVComponent}
				>
					<span>Drop CSV file here or click to upload.</span>
				</CSVReader>
			</div>
			<FlexSplitView>
				<div>
					<div style={{ fontSize: '.8em' }}>
						{importedFile && (
							<>
								<h3>Pending File Details</h3>
								Status: {uploadStatus}
								{importedFile.isErrors &&
									` (${importedFile.rows.reduce(
										(acc, next) => acc + (next.errors.length ? 1 : 0),
										0,
									)} Errors in File)`}
							</>
						)}
						<h3>Instructions</h3>
						<p>
							Download and edit csv template file{' '}
							<a href={exampleCSVHref} download="example.csv">
								here
							</a>{' '}
							<br />
							<br />
							Requirements for csv file
						</p>
						<ul>
							<li>
								Single Column csv files with/without header
								<ul>
									<li>
										Column to be valid list of usersIds (positive integers)
									</li>
								</ul>
							</li>
							<li>
								Multi column csv files must have headers
								<ul>
									<li>
										named column with either of the following names (case
										insensitive)
										<ul>
											<li>USERID</li>
											<li>USERIDS</li>
											<li>USER_ID</li>
											<li>USER_IDS</li>
										</ul>
									</li>
								</ul>
							</li>
						</ul>
					</div>
				</div>
				{importedFile && (
					<div>
						<ScrollBox maxHeight="90vh">
							<ManageTable underMutation={uploadLoading}>
								<thead>
									<tr>
										<StickyThStyle>userId</StickyThStyle>
										<StickyThStyle>email</StickyThStyle>
										<StickyThStyle>status</StickyThStyle>
									</tr>
								</thead>
								<tbody>
									{importedFile?.rows
										?.sort((a, b) => b.errors.length - a.errors.length)
										.map(({ field, errors }, i) => (
											<tr key={`imported_${field}_i${i}`}>
												<td align="center">{field}</td>
												<td align="center">n/a</td>
												<td
													align="center"
													style={{ color: errors.length ? 'red' : 'unset' }}
												>
													{!errors.length ? (
														<span>Pending</span>
													) : (
														<span>Error's: {errors.join(',')}</span>
													)}
												</td>
											</tr>
										))}
								</tbody>
							</ManageTable>
						</ScrollBox>
					</div>
				)}
			</FlexSplitView>
		</StyledCard>
	);
};

export default RestrictToClientArea;
