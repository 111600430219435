import React, { useState } from 'react';
import Burger from 'react-css-burger';
import { Link } from 'react-router-dom';
import { LightenDarkenColor } from '../../common/LightenDarkenColor';
import styled, { css } from '../../common/styled';
import { useBrandConfig } from '../../context/brandConfig/brandConfigContext';
import { partialApply } from '../../helpers/pureFunc';
import BrandSelector from './BrandSelector';
import LoginButton from './LoginButton';
import {
	BurgerContainer,
	Flex,
	LinkButton,
	MainNavGroup,
	MobileMainNavGroup,
} from './Navs';

const Container = styled.div(
	(props) => css`
		label: Header__Container;

		height: auto;
		max-width: 100%;
		background-color: ${LightenDarkenColor(props.theme.brand.color_2, 10)};

		@media only screen and (max-width: 600px) {
			margin-bottom: 0;
		}
	`,
);

const StaffWarning = styled.div(
	(props) => css`
		label: Header__StaffWarning;

		height: auto;
		max-width: 100%;
		color: white;
		font-size: 10px;
		font-weight: bold;
		padding: 8px;
		text-align: center;
		background-color: ${LightenDarkenColor(props.theme.brand.color_2, 0)};
	`,
);

const Content = styled('header')`
	label: Header__Content;

	max-width: 1140px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin: 0 auto;
`;

const Logo = styled('img')`
	label: Header__Logo;

	height: 60px;
`;

interface HeaderProps {
	loading: boolean;
	authUser: object;
	isAuthenticated: boolean;
}

const Header = ({ loading, authUser, isAuthenticated }: HeaderProps) => {
	const bmLogo =
		'https://bm-brand-assets.s3-ap-southeast-2.amazonaws.com/betmakers/logo.png';
	const [isMenuOpen, setIsMenuOpen] = useState(false);
	const { brandConfig, isStaffMember } = useBrandConfig();

	// use partialApply to delay execution
	const menuToggle = partialApply(setIsMenuOpen, !isMenuOpen);

	if (loading) return null;

	return (
		<>
			{isAuthenticated && isStaffMember && (
				<StaffWarning>
					You are in the <i>BETMAKERS_STAFF</i> group. All actions will effect
					live client production environments.
				</StaffWarning>
			)}
			<Container>
				<Content>
					{!isAuthenticated && (
						<Link to="/">
							<Logo
								src={brandConfig.logo || bmLogo}
								alt={brandConfig.displayName}
							/>
						</Link>
					)}

					<MainNavGroup>
						<Link to="/" style={{ textDecoration: 'none' }}>
							<Flex>
								<img
									src={bmLogo}
									alt="BetMakers logo"
									style={{ width: 'auto', height: '50px', margin: '10px' }}
								/>
								<h3
									style={{
										color: 'white',
										marginRight: '8px',
										textDecoration: 'none',
									}}
								>
									- Betting App Manager
								</h3>
							</Flex>
						</Link>
						<Flex>
							<LinkButton
								to="/"
								isactive={false}
								ismobile={true}
								onClick={() => setIsMenuOpen(false)}
							>
								Dashboard
							</LinkButton>
							<LoginButton isAuth={!!authUser} />
						</Flex>
					</MainNavGroup>
					<MobileMainNavGroup isactive={isMenuOpen}>
						{isAuthenticated && (
							<LinkButton
								to="/"
								isactive={false}
								ismobile={true}
								onClick={() => setIsMenuOpen(false)}
							>
								Dashboard
							</LinkButton>
						)}
						<LoginButton isAuth={!!authUser} isMobile={isMenuOpen} />
					</MobileMainNavGroup>

					<BurgerContainer>
						<Burger
							onClick={menuToggle}
							active={isMenuOpen}
							burger="collapse"
							color="white"
							hoverOpacity={0.9}
							marginTop="2px"
							marginLeft="0px"
							scale={0.6}
						/>
					</BurgerContainer>
				</Content>
			</Container>
			{isAuthenticated && <BrandSelector />}
		</>
	);
};

export default Header;
