import { AxiosResponse, AxiosInstance, AxiosError } from 'axios';
import { PromotionEngineItem } from './common';

type sortByKey<Fields extends string> = { key: Fields; dir: 'asc' | 'desc' };
export const listPromotionSortableFields = [
	'id',
	'code',
	'max_deposit',
	'description',
	'start_date',
	'end_date',
	'promotion_type_id',
	'redemption_count',
	'enabled',
] as const;
type listPromotionSortableFieldType = typeof listPromotionSortableFields[number];

export type PromotionEngineSortByKeysArray = sortByKey<listPromotionSortableFieldType>[];

export interface ListPromotionResponse {
	timestamp: string;
	http_status_code: number;
	total: number;
	per_page: number;
	current_page: number;
	last_page: number;
	next_page_url: string | null;
	prev_page_url: string | null;
	from: number;
	to: number;
	data: PromotionEngineItem[];
}

function PromotionsList(
	getConfig: () => {
		axiosInstance: AxiosInstance;
	},
	page: number = 1,
	items: number = 20,
	sortBy: PromotionEngineSortByKeysArray,
	searchPhrase: string = '',
): Promise<AxiosResponse<ListPromotionResponse> | AxiosError> {
	const params = {};
	if (page) params['page'] = page.toFixed(0);
	if (items) params['items'] = items.toFixed(0);
	if (sortBy && sortBy.length > 0)
		params['sort'] = sortBy.map(({ key, dir }) => `${key},${dir}`).join('|');
	if (searchPhrase && searchPhrase.length > 0) params['search'] = searchPhrase;

	return getConfig()
		.axiosInstance.get('', { params })
		.catch((e) => e);
}

export default PromotionsList;
