import React from 'react';
import styled from '../../common/styled';

const FilterWrapper = styled('div')`
	label: QuickLinksFilter__FilterWrapper;

	margin-bottom: ${(props) => props.theme.spacings.compact}px;
`;

const FilterContainer = styled('div')`
	label: QuickLinksFilter__FilterContainer;

	display: flex;
	justify-content: flex-end;
	color: #838383;
	font-size: 12px;
	margin-bottom: ${(props) => props.theme.spacings.tight}px;

	&:last-of-type {
		margin-bottom: none;
	}
`;

const FilterOption = styled('div')`
	label: QuickLinksFilter__FilterOption;

	cursor: pointer;
	display: inline;
	color: ${(props) => (props.active ? props.theme.brand.color_1 : 'inherit')};
	text-decoration: ${(props) => (props.active ? 'underline' : 'none')};

	&:hover {
		color: ${(props) => props.theme.typography.base_color};
	}
`;

const SelectionText = styled('span')`
	label: QuickLinksFilter__SelectionText;

	margin-left: 6px;
	margin-right: ${(props) => (props.noRightMargin ? '0' : '6')}px;
`;

const QuickLinksFilter = ({
	handleSelection,
	currentAuthFilter,
	currentLocationFilter,
}) => {
	return (
		<FilterWrapper>
			<FilterContainer>
				Visible to:
				<FilterOption
					onClick={() => handleSelection('logged-in', 'auth')}
					active={currentAuthFilter === 'logged-in'}
				>
					<SelectionText>Logged in</SelectionText>
				</FilterOption>
				|
				<FilterOption
					onClick={() => handleSelection('guests', 'auth')}
					active={currentAuthFilter === 'guests'}
				>
					<SelectionText noRightMargin>Guests</SelectionText>
				</FilterOption>
			</FilterContainer>
			<FilterContainer>
				Locations:
				<FilterOption
					onClick={() => handleSelection('homepage-racing', 'location')}
					active={
						currentLocationFilter ===
						('homepage-racing' || 'homepage-sports' || 'homepage-watch')
					}
				>
					<SelectionText>Homepage</SelectionText>
				</FilterOption>
				|
				<FilterOption
					onClick={() => handleSelection('racing', 'location')}
					active={currentLocationFilter === 'racing'}
				>
					<SelectionText>Racing</SelectionText>
				</FilterOption>
				|
				<FilterOption
					onClick={() => handleSelection('sports', 'location')}
					active={currentLocationFilter === 'sports'}
				>
					<SelectionText noRightMargin>Sports</SelectionText>
				</FilterOption>
			</FilterContainer>
		</FilterWrapper>
	);
};

export default QuickLinksFilter;
