import styled from '../../common/styled';
import { css } from '@emotion/core';

export const UserImageHoverDeleteStyled = styled('div')(
	(props) => css`
		position: relative;
		display: inline-block;
		:hover .menu {
			opacity: 1;
		}
		.image {
			transition: all 0.5s ease-in-out;
			width: 100%;
			height: auto;
			opacity: 1;
		}
		.image:hover {
			opacity: 0.3;
		}
		.menu {
			transition: all 0.5s ease-in-out;
			position: absolute;
			top: 10px;
			right: 10px;
			cursor: pointer;
			font-size: 3em;
			color: red;
			pointer-events: none;
			z-index: 2;
			opacity: 0;
		}
	`,
);
