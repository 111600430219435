import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ToastsStore } from 'react-toasts';
import { configurationLoader } from '../context/brandConfig/brandConfig';
import { AuthenticationStatusType } from '../helpers/bannerEndpoints/TypesDefs';
import QuickLinkEndpoints, {
	quicklinkInterface,
} from '../helpers/quickLinkEndpoints';

export interface QuicklinksState {
	// note this was previously infered by ts
	allQuickLinks: any[];
	isFetching: boolean;
	editingQuickLink: any;
}

const initialState = {
	allQuickLinks: [],
	isFetching: false,
	editingQuickLink: null,
};

const quickLinkApiFactory = state => {
	const authToken = state?.auth?.accessToken;
	const brand = state?.application?.brand;
	const brandManagerEndpoint = configurationLoader(brand).brandManagerEndpoint;
	const quickLinkApi = new QuickLinkEndpoints(
		`${brandManagerEndpoint}/quicklinks`,
	);
	quickLinkApi.useAuthCode(authToken);
	return quickLinkApi;
};

export const fetchAllQuickLinks = createAsyncThunk(
	'quickLinks/fetchAllQuickLinks',
	async (_, thunkAPI) => {
		return {
			items: await quickLinkApiFactory(thunkAPI.getState()).get(
				AuthenticationStatusType.ANY,
			),
		};
	},
);

export const createQuickLink = createAsyncThunk(
	'quickLinks/createQuickLink',
	async (quickLinkItem: quicklinkInterface, thunkAPI) => {
		try {
			const result = await quickLinkApiFactory(
				thunkAPI.getState(),
			).createQuickLink(quickLinkItem);
			thunkAPI.dispatch(fetchAllQuickLinks());

			return result;
		} catch (error) {
			return thunkAPI.rejectWithValue(error?.response || error);
		}
	},
);

export const deleteQuickLink = createAsyncThunk(
	'quickLinks/deleteQuickLink',
	async (id, thunkAPI) => {
		try {
			const result = await quickLinkApiFactory(
				thunkAPI.getState(),
			).deleteQuickLink(id);
			thunkAPI.dispatch(fetchAllQuickLinks());

			return result;
		} catch (error) {
			return thunkAPI.rejectWithValue(error?.response || error);
		}
	},
);

export const updateQuickLink = createAsyncThunk(
	'quickLinks/updateQuickLink',
	async (quickLinkItem: quicklinkInterface, thunkAPI) => {
		try {
			const result = await quickLinkApiFactory(
				thunkAPI.getState(),
			).updateQuickLink(quickLinkItem);
			thunkAPI.dispatch(fetchAllQuickLinks());
			return result;
		} catch (error) {
			return thunkAPI.rejectWithValue(error);
		}
	},
);

export const updateQuickLinks = createAsyncThunk(
	'quickLinks/updateQuickLinks',
	async (quickLinkItem: quicklinkInterface, thunkAPI) => {
		const result = await quickLinkApiFactory(
			thunkAPI.getState(),
		).updateQuickLink(quickLinkItem);
		thunkAPI.dispatch(fetchAllQuickLinks());

		return result;
	},
);

const { actions, reducer } = createSlice({
	name: 'loadQuickLinks',
	initialState,
	reducers: {
		setQuickLinks(state, { payload }) {
			state = payload;
		},
		setEditingQuickLink(state, { payload }) {
			// react-timepicker in create/edit form doesn't accept date format as is.
			const payloadNewTimeFormat = {
				...payload,
				expiry: payload.expiry ? new Date(payload.expiry) : null,
			};

			state.editingQuickLink = payloadNewTimeFormat;
		},
		clearEditingQuickLink(state) {
			state.editingQuickLink = null;
		},
	},
	extraReducers: {
		// fetchAllQuickLinks
		[fetchAllQuickLinks.pending as any]: state => {
			state.isFetching = true;
		},
		[fetchAllQuickLinks.fulfilled as any]: (state, action) => {
			state.allQuickLinks = action.payload.items;
			state.isFetching = false;
		},
		// createQuickLink
		[createQuickLink.pending as any]: state => {
			state.isFetching = true;
		},
		[createQuickLink.fulfilled as any]: state => {
			ToastsStore.success('QuickLink successfully created', 2000);
			state.isFetching = false;
		},
		[createQuickLink.rejected as any]: (state, event) => {
			state.isFetching = false;
			const validationError = getValidationErrorOutofError(
				event?.payload || {},
			);
			console.log('There was a problem creating Quicklink', event);
			ToastsStore.error(
				validationError
					? `Validation Error: ${validationError}`
					: 'There was a problem creating Quicklink Item',
				3000,
			);
		},
		// deleteQuickLink
		[deleteQuickLink.pending as any]: state => {
			state.isFetching = true;
		},
		[deleteQuickLink.fulfilled as any]: state => {
			ToastsStore.success('QuickLink deleted', 2000);
			state.isFetching = false;
		},
		[deleteQuickLink.rejected as any]: state => {
			ToastsStore.error('There was a problem Deleting Quicklink Item', 3000);
			state.isFetching = false;
		},
		// updateQuickLink
		[updateQuickLink.pending as any]: state => {
			state.isFetching = true;
		},
		[updateQuickLink.fulfilled as any]: state => {
			ToastsStore.success('QuickLink updated', 2000);
			state.isFetching = false;
		},
		[updateQuickLink.rejected as any]: (state, event) => {
			const validationError = getValidationErrorOutofError(
				event?.payload || {},
			);
			console.log('problem updating quicklink', event);
			ToastsStore.error(
				validationError
					? `Validation Error: ${validationError}`
					: 'There was a problem updating this quicklink',
				3000,
			);

			state.isFetching = false;
		},
		// updateQuickLinks (multi for sorting)
		[updateQuickLinks.fulfilled as any]: () => {},
		[updateQuickLinks.rejected as any]: e => {
			ToastsStore.error(
				'There was a problem updating this banner. Please try again',
				3000,
			);
		},
	},
});

const getValidationErrorOutofError = (error: Error): null | String => {
	//@ts-ignore
	return error?.response?.data?.error_validation || null;
};

export const {
	setQuickLinks,
	setEditingQuickLink,
	clearEditingQuickLink,
} = actions;
export default reducer;
