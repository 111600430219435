import { useFormik } from 'formik';
import moment from 'moment';
import styled from '../../../common/styled';
import { debounceWithDelayedPromises } from '../../../helpers';
import { partialApply } from '../../../helpers/pureFunc';

export const formattedDateValue = (value: string) =>
	value
		? moment(value, 'yyyy-MM-DD')
				.add('1', 'd')
				.format('yyyy-MM-DD')
		: undefined;

export const dateTimeToHTMLSafe = (input: string) =>
	input ? moment(input, 'yyyy-MM-DD hh:mm:ss').format('yyyy-MM-DD') : undefined;
export const dateTimeToNormalized = (input: string) =>
	moment(input, 'yyyy-MM-DD').format('yyyy-MM-DD');

export const handleDateChange = (
	e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
	formik: ReturnType<typeof useFormik>,
	setData: any,
) => {
	const el = e.target;

	setData([]);
	formik.setFieldValue(formik.values.venue, '');
	formik.setFieldValue(formik.values.race, '');
	formik.setFieldValue(
		el.id,
		e.target.value ? dateTimeToNormalized(e.target.value) : null,
	);
};

export const handleFormikChange = (
	e: React.ChangeEvent<
		HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
	>,
	formik: ReturnType<typeof useFormik>,
) => {
	formik.handleChange(e);
};

export const handleRaceSelectChange = (
	e: React.ChangeEvent<
		HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
	>,
	formik: ReturnType<typeof useFormik>,
) => {
	formik.handleChange(e);
	formik.values.venue = '';
	formik.values.race = '';
};

export const handleRaceSelectBlur = (
	e: React.ChangeEvent<
		HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
	>,
	formik: ReturnType<typeof useFormik>,
) => {
	formik.handleBlur(e);
	formik.values.venue = '';
	formik.values.race = '';
};

export const handleFormikBlur = (
	e: React.ChangeEvent<
		HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
	>,
	formik: ReturnType<typeof useFormik>,
) => {
	formik.handleBlur(e);
};

const validateForm = debounceWithDelayedPromises(
	(formik: ReturnType<typeof useFormik>) => formik.validateForm(),
	400,
);

export const handleChange = (
	e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
	formik: ReturnType<typeof useFormik>,
	setFieldValue?: (field: any) => void,
) => {
	const setValue =
		setFieldValue || partialApply(formik.setFieldValue, e.target.id);

	const isTextArea = (
		input: HTMLInputElement | HTMLTextAreaElement,
	): input is HTMLTextAreaElement => e.target.tagName === 'textarea';

	const isBoolEl = (
		input: HTMLInputElement,
	): input is HTMLInputElement & { type: 'checkbox'; checked: boolean } =>
		input.type === 'checkbox';

	const isDateEl = (
		input: HTMLInputElement,
	): input is HTMLInputElement & { type: 'date' } => input.type === 'date';

	const el = e.target;
	if (e.target.id === 'max_deposit')
		setValue(Number.parseFloat(e.target.value));
	else if (e.target.id === 'winning_turnover_factor')
		setValue(Number.parseFloat(e.target.value));
	else if (e.target.id === 'redemptions_per_customer') setValue(e.target.value);
	else if (isTextArea(el)) formik.handleChange(e);
	else if (el.id === 'max_deposit') {
		el.value = (parseFloat(e.target.value) * 100).toFixed(0);
		formik.handleChange(e);
	} else if (isBoolEl(el)) formik.setFieldValue(el.id, el.checked);
	else if (isDateEl(el))
		formik.setFieldValue(
			el.id,
			e.target.value ? dateTimeToNormalized(e.target.value) : null,
		);
	else if (e.target.id === 'details' && e.target.value.length > 300)
		formik.setErrors({ details: 'Must be 300 characters or less' });
	else formik.handleChange(e);
	validateForm(formik);
};

export const StyledErrorHint = styled.span`
	color: red;
`;

export const StyledItalicParagraph = styled('p')`
	font-size: 12px;
	font-style: italic;
	color: #838383;
	user-select: none;
	margin-left: 5px;
`;

export const boostPercentValues = [
	1,
	2,
	3,
	4,
	5,
	6,
	7,
	8,
	9,
	10,
	11,
	12,
	13,
	14,
	15,
	16,
	17,
	18,
	19,
	20,
];
