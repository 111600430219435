import { css } from '@emotion/core';
import React, { useEffect, useState } from 'react';
import styled from '../../common/styled';
import { ColumnedLabeledArea } from '../promotionEngine/DepositMatchFormComponents';
import { StyledItalicParagraph } from '../promotionEngine/PromotionEngineForms/Common';
import MultipleSelector from '../promotionEngine/PromotionEngineForms/MultipleSelector';
import { StyledButton } from '../shared/Navs';

interface DaysSelectionsI {
	daysCallback: any;
	existingDays: any[];
	onClose: boolean;
	label?: string;
}

const StyledMultipleSelect = styled(MultipleSelector)(
	() => css`
		.div: nth-of-type(2) {
			margin-top: 0px important;
		}
	`,
);
const StyledButtonContainer = styled('div')(
	() => css`
		display: flex;
		gap: 10px;
		height: 33px;
	`,
);

const DaysSelections: React.FC<DaysSelectionsI> = ({
	daysCallback,
	existingDays,
	onClose,
	label,
}) => {
	const weekDays = [
		{ label: 'Monday', code: 1 },
		{ label: 'Tuesday', code: 2 },
		{ label: 'Wednesday', code: 3 },
		{ label: 'Thursday', code: 4 },
		{ label: 'Friday', code: 5 },
		{ label: 'Saturday', code: 6 },
		{ label: 'Sunday', code: 7 },
	];

	const getDate = (value: number) => {
		return weekDays.filter((day: any) => day.code === value)[0]?.label;
	};
	const [data] = useState(weekDays);
	const [showSummaryDisplay, setSummaryDisplay] = useState(false);
	const [dates, setDates] = useState<Array<any>>(existingDays || []);

	const [daysList, setDaysList] = useState([]);

	useEffect(() => {
		async function showSummary() {
			if (dates.length > 0) setSummaryDisplay(true);
			else setSummaryDisplay(false);
		}

		showSummary();
	}, [dates]);

	const handleAddEvent = e => {
		e.preventDefault();

		let list = [];
		if (daysList.length !== 0) {
			daysList.forEach((cun: any) => {
				list.push(cun.code);
			});
		}
		// remove dupplicated values
		let newList = [...dates, ...list].filter(
			(v, i, a) => a.findIndex(v2 => v2 === v) === i,
		);

		setDaysList([]);

		setDates(newList);
		daysCallback(newList);
	};

	const handleEventRemove = day => {
		const filterDays = dates.filter(e => e !== day);

		setDates([...filterDays]);
		daysCallback([...filterDays]);
	};

	return (
		<div>
			<br />
			<ColumnedLabeledArea
				id="activeArea"
				snapWidth="250px"
				margin="4px"
				label={label}
			>
				{[
					{
						flexGrow: '1',
						child: (
							<StyledMultipleSelect
								options={data}
								onSelect={items => setDaysList(items)}
								value={daysList}
								onClose={onClose}
								isRace={false}
								placeHolder={''}
								overrideDefault={true}
								multipleSelectText={'Daily'}
							/>
						),
					},
					{
						flexGrow: '3',
						child: (
							<StyledButtonContainer>
								<StyledButton onClick={handleAddEvent}>Add Days</StyledButton>
								<StyledItalicParagraph>
									All Boosts will be uploaded at Midnight
								</StyledItalicParagraph>
							</StyledButtonContainer>
						),
					},
				]}
			</ColumnedLabeledArea>

			{showSummaryDisplay &&
				dates?.map(day => (
					<ColumnedLabeledArea
						id="activeArea"
						snapWidth="250px"
						margin="4px"
						label="Day -"
					>
						{[
							{
								flexGrow: '1',
								child: <span>{getDate(day)}</span>,
							},
							{
								flexGrow: '3',
								child: (
									<StyledButton
										type="button"
										onClick={() => handleEventRemove(day)}
									>
										Remove
									</StyledButton>
								),
							},
						]}
					</ColumnedLabeledArea>
				))}
		</div>
	);
};

export default DaysSelections;
