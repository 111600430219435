import React from 'react';
import styled from '../../common/styled';
import { FaTrashAlt } from 'react-icons/fa';
import { FiEdit } from 'react-icons/fi';

import ToggleSwitch from '../shared/ToggleSwitch';
import { StyledButton } from '../shared/Navs';

const Container = styled('div')`
	label: QuickLinks__Container;

	border: 1px solid #eee;
	background: ${(props) => (props.disabled ? '#e1e1e1' : '#fcfcfc')};
	border-radius: 4px;
	transition: all 0.3s;
	padding: ${(props) => props.theme.spacings.cozy}px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	white-space: pre;
	flex: 3;
	box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1), 0 2px 2px rgba(0, 0, 0, 0.1),
		0 4px 4px rgba(0, 0, 0, 0.1);
`;

const Title = styled('h4')`
	label: QuickLinks__Title;
	margin: 0 ${(props) => props.theme.spacings.cozy}px 0 0;
	display: inline-block;
	width: 135px;
	overflow: hidden;
	text-overflow: ellipsis;
`;

const DeleteIcon = styled('div')`
	label: QuickLinks__DeleteIcon;
	max-height: 30px;
	cursor: pointer;
	margin-left: ${(props) => props.theme.spacings.compact}px;
	margin-top: 3px;
	display: inline-block;

	:hover {
		transform: scale(1.05);
	}
`;

const ToggleSwitchContainer = styled('div')`
	label: QuickLinks__ToggleSwitchContainer;
	margin: ${(props) => props.theme.spacings.tight}px
		${(props) => props.theme.spacings.cozy}px 0;
`;

const SoftButton = styled(StyledButton)`
	label: QuickLinks__SoftButton;

	color: ${(props) => props.theme.ui.color_black};
	background-color: #f1f1f1;
	margin-left: none;

	&:hover {
		background-color: ${(props) => props.theme.brand.color_2};
		color: ${(props) => props.theme.typography.color_1_alternate};
	}
`;

const QuickLinksItem = ({
	handleDelete,
	quickLink,
	setQuickLinkEdit,
	openModal,
	updateQuickLink,
}) => {
	const { id, title, disabled } = quickLink;

	return (
		<Container disabled={disabled}>
			<Title>{title}</Title>
			<div
				style={{
					display: 'flex',
					justifyContent: 'flex-end',
					alignItems: 'center',
				}}
			>
				<SoftButton
					isactive="false"
					onClick={(_e) => {
						setQuickLinkEdit(quickLink);
						openModal();
					}}
				>
					Edit <FiEdit />
				</SoftButton>
				<ToggleSwitchContainer>
					<ToggleSwitch
						checked={!disabled}
						onChange={() => {
							updateQuickLink({ ...quickLink, disabled: !disabled });
						}}
						id={id}
						title={'Enable or Disable'}
					/>
				</ToggleSwitchContainer>
				<DeleteIcon onClick={() => handleDelete(id)}>
					<FaTrashAlt title={'delete rubbish icon'} color="#000" size="16px" />
				</DeleteIcon>
			</div>
		</Container>
	);
};

export default QuickLinksItem;
