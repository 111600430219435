import axios from 'axios';
import {
	AuthenticationStatusType,
	BannerItemType,
	banner_types,
} from './bannerEndpoints/TypesDefs';

class BannerEndpoints {
	url: string;
	authCode: string = '';

	constructor(url: string) {
		this.url = !url.match?.('undefined') ? url : null;
	}

	useAuthCode(code: string) {
		this.authCode = code;
	}

	authHeader() {
		return { Authorization: 'Bearer ' + this.authCode };
	}

	async get(
		authStatus: AuthenticationStatusType,
		banner_type: banner_types | 'all',
	): Promise<BannerItemType[]> {
		const builtURL = `${this.url}`;
		if (!this.url) return [];

		try {
			const response = await axios.get(builtURL, {
				withCredentials: false,
				params: {
					authentication_status: authStatus,
					banner_type,
				},
			});
			const Items = (response.data.Items.length > 0
				? response.data.Items
				: 'No items') as BannerItemType[];
			return Items;
		} catch (e) {
			console.log(e);
			return [];
		}
	}

	async fetchHomepageBanners(
		authStatus: AuthenticationStatusType,
	): Promise<BannerItemType[]> {
		return await this.get(authStatus, banner_types.homepage);
	}

	async fetchRacingBanners(
		authStatus: AuthenticationStatusType,
	): Promise<BannerItemType[]> {
		return await this.get(authStatus, banner_types.racing);
	}

	async fetchRaceBanners(
		authStatus: AuthenticationStatusType,
	): Promise<BannerItemType[]> {
		return await this.get(authStatus, banner_types.race);
	}

	async fetchSportsHomeBanners(
		authStatus: AuthenticationStatusType,
	): Promise<BannerItemType[]> {
		return await this.get(authStatus, banner_types.sports);
	}

	async fetchEventBanners(
		authStatus: AuthenticationStatusType,
	): Promise<BannerItemType[]> {
		return await this.get(authStatus, banner_types.event);
	}

	async updateBanner(banner): Promise<BannerItemType[]> {
		const builtURL = `${this.url}/${banner.id}/update`;
		const bodyJSON = JSON.stringify({ Item: banner });

		return await axios.post(builtURL, bodyJSON, {
			headers: {
				'content-type': 'application/json',
				...this.authHeader(),
			},
		});
	}

	async createBanner(banner): Promise<BannerItemType[]> {
		const builtURL = `${this.url}/create`;
		const bodyJSON = JSON.stringify({ Item: banner });

		return await axios.post(builtURL, bodyJSON, {
			headers: { 'content-type': 'application/json', ...this.authHeader() },
		});
	}

	async deleteBanner(banner): Promise<BannerItemType[]> {
		const builtURL = `${this.url}/${banner.id}/delete`;

		return await axios.post(
			builtURL,
			{},
			{
				headers: { ...this.authHeader() },
			},
		);
	}
}

export default BannerEndpoints;
