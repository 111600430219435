import React from 'react';
import propTypes from 'prop-types';
import styled, { css } from '../../../../common/styled';

const StyledAspexBox = styled.div<{
	w: string | number;
	aspectRatio: number;
	maxWidth: string | number;
}>(
	({ w, aspectRatio, maxWidth }) => css`
		position: relative;
		flex: 1 auto;
		width: ${typeof w === 'number' ? `${w}px` : w};
		max-width: ${typeof maxWidth === 'number' ? `${maxWidth}px` : maxWidth};
		object-fit: contain;

		&::before {
			content: '';
			display: block;
			padding-bottom: calc(${aspectRatio} * 100%);
		}

		img,
		svg {
			position: absolute;
			height: 100%;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			height: 100%;
			width: auto;
			max-width: 100%;
			object-fit: contain;
		}
	`,
);

/**
 *
 * @param {{
 *  type: string, encoding: 'component'|'base64', data: React.ReactNode
 * }} props
 */
const ImageFromConfig = ({
	type,
	encoding,
	data,
	w,
	aspectRatio,
	maxWidth,
}) => (
	<StyledAspexBox w={w} aspectRatio={aspectRatio} maxWidth={maxWidth}>
		{type === 'component' || encoding === 'component' ? (
			data
		) : (
			<img
				src={type === 'url' ? data : `data:${type};${encoding},${data}`}
				alt="icon"
			/>
		)}
	</StyledAspexBox>
);

ImageFromConfig.propTypes = {
	type: propTypes.string.isRequired,
	encoding: propTypes.oneOf(['component', 'base64', 'url']).isRequired,
	data: propTypes.node.isRequired,
	w: propTypes.oneOfType([propTypes.number, propTypes.string]).isRequired,
	maxWidth: propTypes.oneOfType([propTypes.number, propTypes.string]),
	aspectRatio: propTypes.number,
};

ImageFromConfig.defaultProps = { maxWidth: '100%', aspectRatio: 1 };

export default ImageFromConfig;
