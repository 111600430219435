import React, { ComponentPropsWithoutRef } from 'react';
import { FiInfo } from 'react-icons/fi';
import ReactTooltip from 'react-tooltip';

import { contentItemType } from '../../store/pages';
import styled from '../../common/styled';
import Pill from '../shared/Pill';

const StyledInfoIcon = styled(FiInfo)`
	height: 0.75em;
	width: 0.75em;
	margin-left: auto;
	padding-left: 4px;
`;

type PillWithHoverProps = ComponentPropsWithoutRef<typeof Pill> & {
	children?: React.ReactNode;
	uniqueKey: string;
	bgCol?: string;
	txCol?: string;
};

const PillWithHover = (props: PillWithHoverProps) => (
	<>
		<span data-tip data-for={props.uniqueKey}>
			<Pill {...props}>{props.children && <StyledInfoIcon />}</Pill>
		</span>
		{props.children && (
			<ReactTooltip
				id={props.uniqueKey}
				type="info"
				place="bottom"
				backgroundColor={props.bgCol}
				textColor={props.txCol}
			>
				{props.children}
			</ReactTooltip>
		)}
	</>
);

const BlameDiv = styled.div``;

interface PhillHoverInfoGeneratorProps {
	Published?: contentItemType;
	Draft?: contentItemType;
}

export const PillHoverInfoGenerator = ({
	Published,
	Draft,
}: PhillHoverInfoGeneratorProps) => {
	const ItemRender = ({
		Item,
		status,
		hideTitle = true,
	}: {
		Item?: contentItemType;
		status: 'published' | 'draft';
		hideTitle?: boolean;
	}) => {
		if (!Item) return null;
		const anyField = [
			'user_created',
			'user_updated',
			'date_created',
			'date_updated',
		].some((key) => !!Item[key]);
		return (
			<>
				{anyField && !hideTitle && (
					<tr>
						<td colSpan={2}>{status}</td>
					</tr>
				)}
				{Item.user_created && new Date(Item.date_created).valueOf() > 0 ? (
					<tr>
						<td align="right">Created</td>
						<td>
							{Item.date_created || ''}{' '}
							{Item.user_created ? `(${Item.user_created})` : ''}
						</td>
					</tr>
				) : null}
				{Item.user_updated && new Date(Item.date_updated).valueOf() > 0 ? (
					<tr>
						<td align="right">Updated</td>
						<td>
							{Item.date_updated || ''}{' '}
							{Item.user_updated ? `(${Item.user_updated})` : ''}
						</td>
					</tr>
				) : null}
			</>
		);
	};

	return (
		<BlameDiv>
			<table>
				<thead>
					<tr hidden aria-hidden="false">
						<th>property</th>
						<th>value</th>
					</tr>
				</thead>
				<tbody>
					<ItemRender Item={Published} status="published" />
					<ItemRender Item={Draft} status="draft" />
				</tbody>
			</table>
		</BlameDiv>
	);
};

export default PillWithHover;
