import React, { useEffect, useRef, useState } from 'react';
import ReactTooltip from 'react-tooltip';
import styled from '../../../../common/styled';
import { Holder, Input, LabelWithError } from '../../../shared/FormElements';
import MarkdownEditor from '../../../shared/MarkdownEditor';
import {
	getErrorMessage,
	ResponsibleGamblingFields,
} from '../FooterValidation';
import { configItemProp } from '../index';

const toolTipConfig = {
	backgroundColor: '#000',
	delayShow: 100,
	multiLine: true,
};

interface props extends configItemProp {
	title: string;
	body: string;
	url: string;
}

export const FooterResponsibleGamblingEditor = ({
	title,
	body,
	url,
	setField,
}: props) => {
	const [errors, setErrors] = useState(null);
	useEffect(() => {
		setErrors({
			title: getErrorMessage(ResponsibleGamblingFields.title, title),
			body: getErrorMessage(ResponsibleGamblingFields.body, body),
			url: getErrorMessage(ResponsibleGamblingFields.url, url),
		});
	}, [title, body, url]);
	const isMobile = window.innerWidth <= 600;
	const contentTextArea = useRef();

	const handleChange = e => {
		setField(['body'], e.target.value, true);
	};

	return (
		<StyledFooterResponsibleGamblingEditor>
			<Holder width="100%">
				<LabelWithError
					title="Title"
					htmlFor="title"
					error={errors?.title || ''}
					hint="Example: Must Be 18+"
					hasToolTip={true}
					toolTipFor="ResponsibleTitleTip"
				>
					<Input
						type="text"
						id="title"
						name="title"
						value={title}
						onChange={e => setField(['title'], e.target.value, true)}
						placeholder="Enter A Title"
					/>
					<ReactTooltip
						id="ResponsibleTitleTip"
						effect="solid"
						{...toolTipConfig}
					>
						This is the Title of the Responsible Gambling Message that appears
						within the Footer.
					</ReactTooltip>
				</LabelWithError>
			</Holder>
			<Holder width="100%">
				<LabelWithError
					title="Link"
					hint="Example: https://www.gamblinghelponline.org.au/"
					htmlFor="link"
					error={errors?.url || ''}
					hasToolTip={true}
					toolTipFor="ResponsibleUrlTip"
				>
					<Input
						name="link"
						value={url}
						onChange={e => setField(['url'], e.target.value, true)}
					/>
					<ReactTooltip
						id="ResponsibleUrlTip"
						effect="solid"
						{...toolTipConfig}
					>
						When clicking the Responsible Gambling 18+ icon redirect to this url
						within the Footer.
					</ReactTooltip>
				</LabelWithError>
			</Holder>
			<Holder width="100%">
				<LabelWithError
					title="Body"
					htmlFor="body"
					error={errors?.body || ''}
					hasToolTip={true}
					toolTipFor="ResponsibleBodyTip"
				>
					<textarea
						hidden
						readOnly
						ref={contentTextArea}
						name="body"
						id="body"
						value={body}
						onInput={handleChange}
					/>
					<MarkdownEditor
						value={body ?? ''}
						height="400px"
						handleChange={({ html, text }) => {
							const contentElement = contentTextArea.current as HTMLTextAreaElement;
							contentElement.value = text;
							contentElement.dispatchEvent(
								new Event('input', { bubbles: true }),
							);
						}}
						config={{
							view: { menu: true, md: true, html: isMobile ? false : true },
						}}
						styleType="banner"
					/>
					<ReactTooltip
						id="ResponsibleBodyTip"
						effect="solid"
						{...toolTipConfig}
					>
						This is the Content of the Responsible Gambling Message that appears
						within the Footer.
					</ReactTooltip>
				</LabelWithError>
			</Holder>
		</StyledFooterResponsibleGamblingEditor>
	);
};

const StyledFooterResponsibleGamblingEditor = styled.div`
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
`;

export default FooterResponsibleGamblingEditor;
