import React from 'react';
import { Formik } from 'formik';
import DatePicker from 'react-datepicker';
import { FiSave, FiX } from 'react-icons/fi';
import moment from 'moment';

import { highlightsValidation } from '../../common/validations';
import { icons } from '../../common/icons';
import isValidUrl from '../../common/UrlChecker';

import { LabelWithError, Input, Select } from '../shared/FormElements';
import { StyledButton, NavGroup } from '../shared/Navs';
import PageHeader from '../shared/PageHeader';
import Card from '../shared/Card';

import 'react-datepicker/dist/react-datepicker.css';

const HighlightsForm = ({
	closeModal,
	addHighlight,
	initialValues,
	isEditing,
	updateHighlight,
}) => {
	const handleOnSubmit = (values) => {
		const newValues = {
			...values,
			// URL constructor craps out if given a string already trimmed to pathname
			link: isValidUrl(values.link)
				? new URL(values.link).pathname
				: values.link,
			expiryDate: moment(values.expiryDate).format('YYYY-MM-DD HH:mm'),
		};
		if (isEditing) {
			updateHighlight(newValues);
		} else {
			addHighlight(newValues);
		}
		closeModal();
	};

	const handleDateSubmit = (date, setFieldValue) => {
		setFieldValue('expiryDate', date);
	};

	return (
		<Card isModal>
			<Formik
				initialValues={initialValues}
				validationSchema={highlightsValidation}
				onSubmit={handleOnSubmit}
			>
				{({
					values,
					setFieldValue,
					handleChange,
					handleBlur,
					handleSubmit,
					errors,
				}) => (
					<>
						<PageHeader
							title={isEditing ? 'Edit Highlight' : 'Create Highlight'}
						>
							<NavGroup>
								<StyledButton
									isactive="true"
									type="submit"
									onClick={handleSubmit}
								>
									Save <FiSave />
								</StyledButton>
								<StyledButton onClick={closeModal}>
									Close <FiX />
								</StyledButton>
							</NavGroup>
						</PageHeader>
						<LabelWithError htmlFor="title" title="Title" error={errors.title}>
							<Input
								name="title"
								value={values.title}
								onChange={handleChange}
								onBlur={handleBlur}
								isError={false}
								type="text"
								disabled={!values.visible}
							/>
						</LabelWithError>
						<LabelWithError
							htmlFor="link"
							title="Link to race/event"
							error={errors.link}
						>
							<Input
								name="link"
								value={values.link}
								onChange={handleChange}
								onBlur={handleBlur}
								isError={false}
								type="url"
								disabled={!values.visible}
								placeholder={
									'e.g. https://yoursite.com.au/racing/gallops/randwick/race-4-1350539-1234670'
								}
							/>
						</LabelWithError>
						<LabelWithError
							htmlFor="expiryDate"
							title="Expiry Date"
							error={errors.expiryDate}
						>
							<DatePicker
								selected={values.expiryDate}
								onChange={(date) => handleDateSubmit(date, setFieldValue)}
								showTimeSelect
								popperPlacement="top-start"
								timeFormat="HH:mm"
								timeIntervals={15}
								timeCaption="time"
								dateFormat="MMM d, yyyy h:mm aa"
								showPopperArrow={false}
							/>
						</LabelWithError>
						<LabelWithError
							htmlFor="iconKey"
							title="Icon"
							error={errors.iconKey}
							hint={'Optional'}
						>
							<Select
								name="iconKey"
								value={values.iconKey}
								onChange={handleChange}
								onBlur={handleBlur}
								isError={false}
								type="text"
								disabled={!values.visible}
							>
								<option value="" label="None" />
								{icons.map((icon) => (
									<option
										key={icon.value}
										value={icon.value}
										label={icon.label}
									/>
								))}
							</Select>
						</LabelWithError>
					</>
				)}
			</Formik>
		</Card>
	);
};

export default HighlightsForm;
