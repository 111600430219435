import React from 'react';
import {
	cleanMobileRoute,
	mobileRouteLegacyObject,
	mobileRouteObject,
	supportedRouterKeys,
} from '../../helpers/mobileRouteInterfaces';
import { Input, LabelWithError, Select } from '../shared/FormElements';

type valuesInterface = mobileRouteObject | mobileRouteLegacyObject | null;

const RouterKeyTitles: { [K in mobileRouteObject['routerKey']]: string } = {
	today_racing: 'Racing (Today)',
	race: 'Racing (Specific Race)',
	racing_futures: 'Racing Futures',
	meeting: 'Racing (Specific Meeting)',
	event: 'Sport (Event)',
	sport: 'Sport (Specific Sport)',
	my_account: 'User (Account)',
	my_bets: 'User (Bets)',
	promotions: 'Promotions',
	base_competition: 'Sport (Base Competition)',
	registration: 'Registration',
};

interface MobileRouterEditorProps {
	initialValues?: Object;
	disabled: boolean;
	values?: valuesInterface;
	errors?: Partial<Record<keyof valuesInterface, string>>;
	touched?: Partial<Record<keyof valuesInterface, boolean>>;
	setField: <K extends keyof valuesInterface>(
		fieldName: K,
		value: valuesInterface[K],
	) => void;
}

const ExtraFieldWrap = ({ children }) => {
	const isChild = React.Children.count(children);
	return (
		<>
			{isChild ? <h5>Extra Fields For RouteKey</h5> : undefined}
			{isChild ? children : undefined}
		</>
	);
};

const MobileExtraFields = ({
	initialValues = {},
	values = null,
	errors = null,
	setField,
	disabled = false,
}: MobileRouterEditorProps) => {
	const genField = ({ fieldName, title }) => {
		const handleChange = e => setField?.(fieldName, e.target.value);
		return (
			<LabelWithError
				htmlFor={`mobileRoute.${fieldName}`}
				title={title}
				error={errors?.[fieldName] || ''}
			>
				<Input
					id={fieldName}
					name={`mobileRoute.${fieldName}`}
					value={values?.[fieldName] || ''}
					onChange={handleChange}
					isError={!!errors?.[fieldName]}
					type="number"
					min={1}
					step={1}
					disabled={disabled}
				/>
			</LabelWithError>
		);
	};

	const routerKey = values?.routerKey;

	// no routerKey or isn't a supported router key
	if (!routerKey) return null;
	//@ts-ignore
	if (!supportedRouterKeys.includes(routerKey))
		return (
			<div
				style={{
					display: 'flex',
					width: '100%',
					flexDirection: 'column',
					alignItems: 'center',
				}}
			>
				<span style={{}}>Legacy Data Object:</span>
				<pre style={{ padding: '8px', background: 'lightgrey', margin: '8px' }}>
					{JSON.stringify(
						// @ts-ignore
						initialValues?.mobileRoute || {},
						null,
						1,
					)}
				</pre>
			</div>
		);

	const fieldTitles = {
		meetingId: 'Meeting ID',
		competitionId: 'Competition ID',
		raceId: 'Race ID',
		eventId: 'Event ID',
		sportId: 'Sport ID',
		baseCompetitionId: 'Base Competition ID',
	};

	const renderedFields = Object.entries(fieldTitles)
		.map<[string, React.ReactNode]>(([fieldName, title]) => [
			fieldName,
			genField({ fieldName, title }),
		])
		.reduce<{ [K in keyof typeof fieldTitles]: React.ReactNode }>(
			(acc, [fieldName, field]) => ({ ...acc, [fieldName]: field }),
			{} as { [K in keyof typeof fieldTitles]: React.ReactNode },
		);

	return (
		<ExtraFieldWrap>
			{(() => {
				switch (routerKey) {
					case 'race':
						return (
							<>
								{renderedFields.meetingId}
								{renderedFields.raceId}
							</>
						);
					case 'event':
						return (
							<>
								{renderedFields.eventId}
								{renderedFields.competitionId}
							</>
						);
					case 'meeting':
					case 'racing_futures':
					case 'competition':
					case 'sport':
						return renderedFields[`${routerKey}Id`];
					case 'base_competition':
						return renderedFields.baseCompetitionId;
					// router keys that don't require extra fields
					case 'today_racing':
					case 'my_account':
					case 'my_bets':
					case 'registration':
					default:
						return undefined;
				}
			})()}
		</ExtraFieldWrap>
	);
};

const MobileRouteEditor = ({
	values = null,
	errors = null,
	touched = null,
	disabled,
	initialValues,
	setField,
}: MobileRouterEditorProps) => {
	const handleChange = (
		fieldName: keyof valuesInterface,
	): React.ChangeEventHandler<HTMLInputElement | HTMLSelectElement> => e =>
		void setField(fieldName, e.target.value);

	return (
		<>
			<LabelWithError
				htmlFor="mobileRoute.routerKey"
				title="Mobile App Route Key"
				error={touched?.routerKey && errors?.routerKey}
				hint={'Which mobile app page clicking on this will navigate the user'}
			>
				<Select
					name="mobileRoute.routerKey"
					value={values?.routerKey}
					onChange={handleChange('routerKey')}
					isError={!!(touched?.routerKey && errors?.routerKey)}
					disabled={disabled}
				>
					<option value="" label="None" />
					{Object.entries(RouterKeyTitles).map(([routerKey, title]) => {
						return <option key={routerKey} value={routerKey} label={title} />;
					})}
					{values?.routerKey &&
						// @ts-ignore
						!supportedRouterKeys.includes(values.routerKey) && (
							<option
								key={values.routerKey}
								value={values.routerKey}
								label={`${values.routerKey} (Legacy)`}
							/>
						)}
				</Select>
			</LabelWithError>
			<MobileExtraFields
				values={values}
				errors={errors}
				setField={setField}
				disabled={disabled}
				initialValues={initialValues}
				touched={touched}
			/>
		</>
	);
};

export { MobileRouteEditor as default, cleanMobileRoute };
