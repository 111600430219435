import styled from '../../common/styled';
import React from 'react';

const StyledWrapper = styled.div`
	display: flex;
	flex-wrap: wrap;
	justify-content: space-evenly;
	& > * {
		margin: ${({ theme }) => theme.spacings.tight}px;
		flex: 0 1 auto;
	}
`;

interface PromotionEngineManageActionRowProps {
	children: React.ReactNode;
}

const PromotionEngineManageActionRow = ({
	children,
}: PromotionEngineManageActionRowProps) => {
	return <StyledWrapper>{children}</StyledWrapper>;
};

export default PromotionEngineManageActionRow;
