import { css } from '@emotion/core';
import * as React from 'react';
import { MdCancel } from 'react-icons/md';
import styled from '../../../common/styled';
import {
	HeaderText,
	MultipleSelect,
	MultiSelectItem,
} from '../../shared/FormElements';

interface SelectorProps {
	options: any;
	onSelect: (items: any) => void;
	value: any;
	onClose: boolean;
	isRace: boolean;
	placeHolder: string;
	overrideDefault?: boolean;
	multipleSelectText?: string;
}

const StyledMultipleContainer = styled('div')(
	(props: any) => css`
		${props?.overrideDefault &&
			css`
				margin-top: 0px !important;
			`};
	`,
);

const RaceMultipleSelector: React.FC<SelectorProps> = ({
	options,
	onSelect,
	value,
	onClose,
	isRace,
	placeHolder,
	overrideDefault,
	multipleSelectText,
}) => {
	const [show, setShow] = React.useState(false);
	const [isAll, setAll] = React.useState(false);

	React.useEffect(() => {
		setShow(false);
	}, [onClose]);

	const onItemnSelect = (item: any) => {
		if (isRace) {
			const isItem = value.find((s: any) => s.id === item.id);
			if (!isItem) {
				const items = [...value, ...[{ id: item.id, number: item.number }]];
				onSelect(items);
			}
		} else {
			const isItem = value.find((s: any) => s.code === item.code);
			if (!isItem) {
				const items = [...value, ...[item]];
				onSelect(items);
			}
		}
	};

	const onItemnRemove = (item: any) => {
		if (isRace) {
			const isItem = value.find((s: any) => s.id === item.id);
			const items = value.filter((s: any) => s.id !== isItem.id);
			onSelect(items);
		} else {
			const isItem = value.find((s: any) => s.code === item.code);
			const items = value.filter((s: any) => s.code !== isItem.code);
			onSelect(items);
		}
	};

	const onSelectAll = () => {
		if (isRace) {
			const items = options.map((s: any) => {
				return { id: s.id, number: s.number };
			});
			onSelect(items);
		} else {
			onSelect(options);
		}
		setAll(true);
	};

	const onRemoveAll = () => {
		onSelect([]);
		setAll(false);
	};

	const renderName = () => {
		const name = value.map(T => {
			return `${T.number || T.label}, `;
		});
		return <HeaderText>{value.length === 0 ? placeHolder : name}</HeaderText>;
	};

	return (
		<MultipleSelect
			isRace={isRace}
			onClick={e => {
				e.stopPropagation();
				setShow(!show);
			}}
		>
			{renderName()}
			{show && (
				<StyledMultipleContainer
					onClick={e => e.stopPropagation()}
					overrideDefault={overrideDefault}
				>
					{options &&
						options.map((item: any) => {
							const isSelect: boolean = value.find(s =>
								isRace ? s.id === item.id : s.code === item.code,
							);
							return (
								<MultiSelectItem
									key={item.code || item.id}
									isSelected={isSelect}
									onBlur={() => onItemnSelect(item)}
									onClick={() => onItemnSelect(item)}
								>
									{item.number || item.label}
									{isSelect && (
										<MdCancel
											onClick={() => onItemnRemove(item)}
											color="#000"
											size="24px"
										/>
									)}
								</MultiSelectItem>
							);
						})}
					{options && options.length > 0 && (
						<MultiSelectItem
							isSelected={isAll}
							onBlur={() => onSelectAll()}
							onClick={() => onSelectAll()}
						>
							{multipleSelectText || 'All'}
							{isAll && (
								<MdCancel
									onClick={e => {
										e.stopPropagation();
										onRemoveAll();
									}}
									color="#000"
									size="24px"
								/>
							)}
						</MultiSelectItem>
					)}
				</StyledMultipleContainer>
			)}
		</MultipleSelect>
	);
};
export default RaceMultipleSelector;
