import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Modal from 'react-modal';
import { FiFile, FiHelpCircle, FiEdit } from 'react-icons/fi';
import { BsArrowLeftRight } from 'react-icons/bs';
import Loading from 'react-loading-bar';
import { useTheme } from 'emotion-theming';
import { useBrandConfig } from '../context/brandConfig/brandConfigContext';

import styled from '../common/styled';
import {
	createQuickLink,
	deleteQuickLink,
	fetchAllQuickLinks,
	setEditingQuickLink,
	clearEditingQuickLink,
	updateQuickLink,
	updateQuickLinks,
} from '../store/quicklinks';
import { ModalStyle } from '../common/modalStyle';

import Card from '../components/shared/Card';
import PageHeader from '../components/shared/PageHeader';
import { NavGroup, StyledButton } from '../components/shared/Navs';
import { Divider, Hint } from '../components/shared/FormElements';
import QuickLinksForm from '../components/quickLinks/QuickLinksForm';
import QuickLinksItem from '../components/quickLinks/QuickLinksItem';
import QuickLinksSort from '../components/quickLinks/QuickLinksSort';
import { ToastsStore } from 'react-toasts';
import QuickLinksHelp from '../components/quickLinks/QuickLinksHelp';
import QuickLinksFilter from '../components/quickLinks/QuickLinksFilter';

const QuickLinksList = styled.div`
	display: inline-flex;
	flex-wrap: wrap;
	gap: ${(props) => props.theme.spacings.compact}px;
	margin-top: ${(props) => props.theme.spacings.cozy}px;
	margin-bottom: ${(props) => props.theme.spacings.compact}px;
`;

const SortButtonWrapper = styled.div`
	display: flex;
	width: 100%;
	justify-content: space-between;
`;

const StyledHint = styled(Hint)`
	display: block;
	margin-bottom: ${(props) => props.theme.spacings.cozy}px;
`;

const SimpleLink = styled.span`
	color: ${(props) => props.theme.brand.color_1};
	cursor: pointer;
`;

const QuickLinks = ({
	editingQuickLink,
	fetchQuickLinks,
	allQuickLinks,
	setQuickLinkEdit,
	clearQuickLinkEdit,
	addQuickLink,
	removeQuickLink,
	changeQuickLink,
	multiChangeQuickLinks,
	isLoading,
}) => {
	const [isFormModalOpen, setFormModalState] = useState(false);
	const [isHelpModalOpen, setHelpModal] = useState(false);
	const [isSort, setSortToggle] = useState(false);
	const [authFilterStatus, setAuthFilterStatus] = useState(null);
	const [locationFilterStatus, setLocationFilterStatus] = useState(null);
	const theme = useTheme();

	const [fetchLoading, setFetchLoading] = useState(true);
	const brandConfig = useBrandConfig();

	useEffect(() => {
		setFetchLoading(true);
		fetchQuickLinks().then(() => setFetchLoading(false));
	}, [fetchQuickLinks, brandConfig, setFetchLoading]);

	let initialFormValues;

	if (editingQuickLink) {
		initialFormValues = {
			...editingQuickLink,
			// Required for temp period where some existing quicklinks don't have auth status
			authentication_status:
				editingQuickLink?.authentication_status || 'signed_in',
		};
	} else {
		initialFormValues = {
			title: '',
			expiry: null,
			icon: '',
			route: '',
			disabled: false,
			location: [],
			authentication_status: 'signed_in',
			order: allQuickLinks.length + 1,
			mobileRoute: {
				routerKey: '',
				raceId: '',
				meetingId: '',
				eventId: '',
				competitionId: '',
			},
		};
	}

	const authFilter = (q) => {
		if (!authFilterStatus) {
			return true;
		} else if (authFilterStatus === 'logged-in') {
			return (
				q.authentication_status === 'signed_in' ||
				q.authentication_status === 'any'
			);
		} else if (authFilterStatus === 'guests') {
			return (
				q.authentication_status === 'signed_out' ||
				q.authentication_status === 'any'
			);
		}
	};

	const locationFilter = (q) =>
		!locationFilterStatus || q.location.includes(locationFilterStatus)
			? true
			: false;

	const buildMutableQuickLinksArray = (qlArr) => {
		const newQuickLinks = qlArr.map((i) => {
			return {
				id: i.id,
				title: i.title,
				disabled: i.disabled,
				expiry: i.expiry,
				location: i.location,
				order: i.order,
				route: i.route,
				icon: i.icon,
				authentication_status: i.authentication_status,
				mobileRoute: { ...i.mobileRoute },
			};
		});
		return newQuickLinks;
	};

	const combinedFilter = (q) => authFilter(q) && locationFilter(q);

	const filteredQuickLinks =
		typeof allQuickLinks === 'string'
			? allQuickLinks
			: allQuickLinks?.filter(combinedFilter);

	const sortedQuickLinks =
		filteredQuickLinks.length > 0 && filteredQuickLinks !== 'No items'
			? buildMutableQuickLinksArray(filteredQuickLinks).sort(
					(a, b) => a.order - b.order,
			  )
			: 'No items';

	const isQuickLinks =
		sortedQuickLinks.length > 0 && sortedQuickLinks !== 'No items';

	const handleSelection = (selection, type) => {
		if (type === 'auth') {
			if (!authFilterStatus || authFilterStatus !== selection) {
				setAuthFilterStatus(selection);
			} else if (authFilterStatus === selection) {
				setAuthFilterStatus(null);
			}
		} else if (type === 'location') {
			if (!locationFilterStatus || locationFilterStatus !== selection) {
				setLocationFilterStatus(selection);
			} else if (locationFilterStatus === selection) {
				setLocationFilterStatus(null);
			}
		}
	};

	const handleSortPublish = async (sortedQuickLinks) => {
		await Promise.all(sortedQuickLinks.map(multiChangeQuickLinks));
		ToastsStore.success('QuickLinks positions updated', 2000);
	};

	const handleCloseModal = () => {
		setFormModalState(false);
		clearQuickLinkEdit();
	};

	const handleClearAllFilters = () => {
		setAuthFilterStatus(null);
		setLocationFilterStatus(null);
	};

	const handleDelete = (id) => {
		const sure = window.confirm(
			'Are you sure you want to delete this quicklink?',
		);
		if (sure) {
			removeQuickLink(id);
		}
	};

	const NoQuickLinks = () => {
		return authFilterStatus || locationFilterStatus ? (
			<div>
				There are currently no Quicklinks for this filter.{' '}
				<SimpleLink onClick={handleClearAllFilters}>
					Clear all filters{' '}
				</SimpleLink>
				or{' '}
				<SimpleLink onClick={() => setFormModalState(true)}>
					Create a Quicklink
				</SimpleLink>
			</div>
		) : (
			<div>
				<p>
					There are currently no Quicklinks.{' '}
					<SimpleLink onClick={() => setFormModalState(true)}>
						Create a Quicklink
					</SimpleLink>
				</p>
			</div>
		);
	};
	return (
		<>
			<Card>
				<Loading
					show={isLoading}
					color={theme.brand.color_1}
					showSpinner={false}
				/>
				<PageHeader title="Quicklinks">
					<NavGroup>
						<StyledButton
							isactive="false"
							onClick={(e) => {
								setHelpModal(true);
							}}
						>
							Help <FiHelpCircle />
						</StyledButton>
						<StyledButton
							isactive="true"
							onClick={(e) => {
								setFormModalState(true);
							}}
						>
							New Quicklink <FiFile />
						</StyledButton>
					</NavGroup>
				</PageHeader>
			</Card>
			<Card>
				<SortButtonWrapper>
					<h2>{isSort ? 'Sort' : 'Edit'} QuickLinks</h2>
					{isSort ? (
						<StyledButton onClick={() => setSortToggle(false)}>
							Edit View <FiEdit />
						</StyledButton>
					) : (
						<StyledButton onClick={() => setSortToggle(true)}>
							Sort View <BsArrowLeftRight />
						</StyledButton>
					)}
				</SortButtonWrapper>
				{isSort && <StyledHint>Click and drag horizontally to sort</StyledHint>}
				{!isSort && <Divider />}

				{!isQuickLinks && !fetchLoading && <NoQuickLinks />}

				{!isSort && (
					<QuickLinksFilter
						handleSelection={handleSelection}
						currentAuthFilter={authFilterStatus}
						currentLocationFilter={locationFilterStatus}
					/>
				)}

				{!fetchLoading && (
					<>
						{isQuickLinks && isSort && (
							<QuickLinksSort
								quickLinks={sortedQuickLinks}
								handleSortPublish={handleSortPublish}
								buildMutableQuickLinksArray={buildMutableQuickLinksArray}
							/>
						)}

						{isQuickLinks && !isSort && (
							<QuickLinksList>
								{sortedQuickLinks.map((ql) => (
									<QuickLinksItem
										key={ql.id}
										quickLink={ql}
										handleDelete={() => handleDelete(ql.id)}
										setQuickLinkEdit={() => setQuickLinkEdit(ql)}
										openModal={() => setFormModalState(true)}
										updateQuickLink={changeQuickLink}
									/>
								))}
							</QuickLinksList>
						)}
					</>
				)}

				<Modal
					isOpen={isFormModalOpen}
					onRequestClose={() => setFormModalState(false)}
					style={ModalStyle}
					shouldCloseOnOverlayClick={false}
				>
					<QuickLinksForm
						closeModal={() => handleCloseModal()}
						initialValues={initialFormValues}
						isEditing={editingQuickLink ? true : false}
						addQuickLink={addQuickLink}
						updateQuickLink={changeQuickLink}
					/>
				</Modal>
				<Modal
					isOpen={isHelpModalOpen}
					onRequestClose={() => setHelpModal(false)}
					style={ModalStyle}
					shouldCloseOnOverlayClick={true}
				>
					<QuickLinksHelp closeModal={() => setHelpModal(false)} />
				</Modal>
			</Card>
		</>
	);
};

const mapStateToProps = (state) => {
	return {
		allQuickLinks: state.quickLinks.allQuickLinks,
		isLoading: state.quickLinks.isFetching,
		editingQuickLink: state.quickLinks.editingQuickLink,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		fetchQuickLinks: () => dispatch(fetchAllQuickLinks()),
		addQuickLink: (newQuickLink) => dispatch(createQuickLink(newQuickLink)),
		removeQuickLink: (id) => dispatch(deleteQuickLink(id)),
		setQuickLinkEdit: (quickLink) => dispatch(setEditingQuickLink(quickLink)),
		clearQuickLinkEdit: () => dispatch(clearEditingQuickLink()),
		changeQuickLink: (quickLink) => dispatch(updateQuickLink(quickLink)),
		multiChangeQuickLinks: (quickLink) => dispatch(updateQuickLinks(quickLink)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(QuickLinks);
