import React from 'react';
import { FaTrashAlt } from 'react-icons/fa';
import styled from '../../common/styled';
import { StyledItalicParagraph } from '../promotionEngine/PromotionEngineForms/Common';

const StyledInput = styled.input`
	opacity: 0;
	z-index: -1;
`;
const StyledLabel = styled.label`
	position: relative;
	width: 205px;
	height: 33px;
	float: left;
	border: 2px solid black;
	line-height: 1.9;
	text-align: center;
	margin-right: 10px;
`;

const DeleteIcon = styled('div')`
	label: BannerForm__DeleteIcon;
	max-height: 30px;
	cursor: pointer;
	margin-left: ${props => props.theme.spacings.compact}px;
	margin-top: 3px;
	padding-top: 10px;
	padding-left: 10px;
	:hover {
		transform: scale(1.05);
	}
`;
const StyledMessage = styled(StyledItalicParagraph)`
	margin: 10px;
`;

const FileUploadContainer = styled('div')`
	display: flex;
`;
const FileUploader = ({ onChange, fileName, removeFile, message }) => {
	const labelText = fileName || 'Upload Price Ladder CSV ';
	return (
		<FileUploadContainer>
			<StyledLabel>
				{labelText}
				<StyledInput type="file" onChange={onChange} accept="csv" />
			</StyledLabel>
			{fileName && (
				<DeleteIcon onClick={() => removeFile()}>
					<FaTrashAlt />
				</DeleteIcon>
			)}
			{message && <StyledMessage>{message}</StyledMessage>}
		</FileUploadContainer>
	);
};

export default FileUploader;
