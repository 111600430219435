export enum AuthenticationStatusType {
	ANY = 'any',
	SIGNED_OUT = 'signed_out',
	SIGNED_IN = 'signed_in',
	TAGS = 'tags',
}

export enum banner_types {
	homepage = 'homepage',
	racing = 'racing',
	sports = 'sports',
	race = 'race',
	event = 'event',
}

export interface BannerItemType {
	id: string;
	authentication_status: AuthenticationStatusType;
	image_src: string;
	link: string;
	visible: boolean;
	position: number;
	banner_type: banner_types;
}
