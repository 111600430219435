import { AxiosResponse, AxiosInstance, AxiosError } from 'axios';

interface clientList {
	user_id: number;
	email: string;
}

export interface promotionClientListSetResponse {
	timestamp: string;
	http_status_code: 200;
	data: clientList[];
}

export interface promotionClientListSetFailResponse {
	http_status_code: 422;
	timestamp: string;
	user_message: string;
	errors?: {
		userIds?: {
			[K in number]: string;
		};
	};
}

function promotionClientListSet(
	getConfig: () => {
		axiosInstance: AxiosInstance;
	},
	promoId: number,
	userIds: number[],
): Promise<
	| AxiosResponse<promotionClientListSetResponse>
	| AxiosError<promotionClientListSetFailResponse | any>
> {
	return getConfig()
		.axiosInstance.post(`/${promoId}/clients`, {
			userIds: userIds,
		})
		.catch((e: AxiosError) => e);
}

export default promotionClientListSet;
