import React from 'react';
import Modal from 'react-modal';
import { footerItemInterface } from './FooterMenuItems/FooterContentEditor/FooterContentGroups';
import styled from '../../common/styled';
import { Divider, Form } from '../shared/FormElements';
import ToggleSwitch from '../shared/ToggleSwitch';
import { StyledButtonInverse } from '../shared/Navs';
import {
	FooterItemMenu,
	FooterItemContentArea,
	FooterItemMenuSendRight,
	FooterTopLabel,
} from './styledAssets/footerConfigItemAssets';
import { ModalStyle } from '../../common/modalStyle';

import {
	ContentFormPart,
	FooterMobileAppsEditor,
	PromotedWageringProvider,
	PromotedPaymentMethodEditor,
	FooterResponsibleGamblingEditor,
	FooterSocialLinksEditor,
} from './FooterMenuItems/index';

import { partialApply } from '../../helpers/pureFunc';
import PageHeader from '../shared/PageHeader';
import Card from '../shared/Card';
import { FiCheck, FiEdit, FiX } from 'react-icons/fi';

export const FormPartLookup = {
	Content: { Element: ContentFormPart, title: 'Pages and Links' },
	Social: { Element: FooterSocialLinksEditor, title: 'Social Links' },
	MobileApps: { Element: FooterMobileAppsEditor, title: 'Mobile App Links' },
	ApprovedPartners: {
		Element: PromotedWageringProvider,
		title: 'Approved Partners',
	},
	PaymentMethods: {
		Element: PromotedPaymentMethodEditor,
		title: 'Payment Methods',
	},
	ResponsibleGambling: {
		Element: FooterResponsibleGamblingEditor,
		title: 'Responsible Gambling',
	},
};

interface footerConfigItemProps {
	title: string;
	open: boolean;
	dirty?: boolean;
	isFormError: boolean;
	enablePublish: boolean;
	setOpen: () => void;
	updateGroups: (paths: string[], value: any, validate?: boolean) => void;
	topLabel: boolean;
	children: {
		enabled: boolean;
		data: object;
		ContentPages: (footerItemInterface & { id: string })[];
		code: string;
	};
	submitForm: () => void;
	errors;
}

export const FooterConfigItem = ({
	open,
	dirty,
	enablePublish,
	updateGroups,
	setOpen,
	topLabel,
	children: { enabled, data, ContentPages, code },
	submitForm,
	isFormError,
	errors,
}: footerConfigItemProps) => {
	const EditorElement = FormPartLookup?.[code]?.Element;
	const title = FormPartLookup?.[code]?.title || code;
	const editorElementProps = {
		...data,
		setField: (paths: string[], value: any, validate: boolean = true) =>
			updateGroups(['data', ...paths], value, validate),
		ContentPages,
	};

	const StyledTitle = styled.div`
		&:hover {
			cursor: pointer;
		}
	`;
	const LocalErrorCount = Object.keys(errors || {}).length;
	const handleVisualToggle = (e) => {
		const proceed =
			e.target.checked ||
			window.confirm(`Are you sure you want to hide ${title}`);
		if (proceed) updateGroups(['enabled'], e.target.checked, true);
	};
	const handleCloseOrEdit = (e) => {
		e.preventDefault();
		setOpen();
	};
	const renderedMenuItems = (
		<>
			<FooterItemMenuSendRight>
				{!open && LocalErrorCount > 0 && (
					<span>* Edit to fix error{LocalErrorCount > 1 ? 's' : ''}</span>
				)}
				{dirty && LocalErrorCount === 0 && <span>* unpublished changes</span>}
				{!open && (
					<ToggleSwitch
						checked={enabled}
						onChange={handleVisualToggle}
						id={`${title}_enabled`}
						title="enabled"
					/>
				)}
			</FooterItemMenuSendRight>
			{open && (
				<StyledButtonInverse
					type="button"
					disabled={!enablePublish || isFormError}
					onClick={partialApply(submitForm)}
					isactive={true}
				>
					Publish
					<FiCheck />
				</StyledButtonInverse>
			)}

			<StyledButtonInverse
				type="button"
				onClick={handleCloseOrEdit}
				isactive={true}
				disabled={false}
			>
				{open ? (
					<>
						Close <FiX />
					</>
				) : (
					<>
						Edit <FiEdit />
					</>
				)}
				{/* import { FiCheck, FiSave, FiX } from 'react-icons/fi'; */}
			</StyledButtonInverse>
		</>
	);
	const renderedMenu = (
		<FooterItemMenu open={open}>
			<StyledTitle onClick={setOpen}>{title}</StyledTitle>
			{renderedMenuItems}
		</FooterItemMenu>
	);
	return (
		<StyledFooterConfigItem open={open}>
			{topLabel && (
				<FooterTopLabel>
					<span>Footer Sections</span>
					<FooterItemMenuSendRight>
						<span>Visible</span>
					</FooterItemMenuSendRight>
					<span>Edit</span>
				</FooterTopLabel>
			)}
			{renderedMenu}
			<Modal
				isOpen={open}
				onRequestClose={setOpen}
				style={ModalStyle}
				shouldCloseOnOverlayClick={false}
			>
				<Card isModal>
					<PageHeader title={title}>{renderedMenuItems}</PageHeader>
					<Divider />
					<FooterItemContentArea open={open}>
						{EditorElement && <EditorElement {...editorElementProps} />}
					</FooterItemContentArea>
				</Card>
			</Modal>
		</StyledFooterConfigItem>
	);
};

const StyledFooterConfigItem = styled.div<{ open: boolean }>`
	width: 100%;
	min-width: 200px;
	margin-bottom: ${({ theme: { spacings } }) => spacings.compact}px;
`;

export const FooterConfigContainer = styled(Form)``;
