import React from 'react';
import styled, { css } from '../../common/styled';

const Container = styled.div(
	(props) => css`
		label: Footer__Container;

		display: flex;
		justify-content: center;
		font-size: 10px;
		color: ${props.theme.ui.color_5};
		margin-top: ${props.theme.spacings.cozy}px;
		margin-bottom: ${props.theme.spacings.cozy}px;
		user-select: none;
	`,
);

const Footer = () => {
	const getVersionNumber = () => {
		const tag = process.env.REACT_APP_TAG;
		const sha = process.env.REACT_APP_COMMIT;
		const isTag = tag ? `v${tag}` : false;
		const isSha = sha ? `#${sha}` : false;

		return isTag || isSha;
	};

	return (
		<Container>
			{getVersionNumber() || '#development'} - Copyright
			{` `}
			{new Date().getFullYear()}, BetMakers Technology Group
		</Container>
	);
};

export default Footer;
