import React from 'react';
import styled from '../../common/styled';
import { SortableHandle } from 'react-sortable-hoc';
import { MdDragHandle } from 'react-icons/md';

const Container = styled('div')`
	cursor: grab;
	justify-content: center;
	align-items: center;
	display: flex;
	flex-direction: column;
	margin-bottom: ${(props) => props.theme.spacings.compact}px;
`;

const BannerCardImage = styled.img`
	padding: ${(props) => props.theme.spacings.tight}px;
	max-width: 75%;
	max-height: 300px;
	height: auto;
	opacity: ${(props) => (props.enabled ? '100%' : '30%')};
	transition: opacity 0.3s linear;
`;

const HorizContainer = styled('div')`
	display: flex;
	align-items: center;
`;

const NumContainer = styled('div')`
	margin-right: ${(props) => props.theme.spacings.tight}px;
	color: ${(props) => props.theme.ui.color_4};
	font-size: 14px;
`;

const DragHandle = SortableHandle(() => (
	<MdDragHandle color="#666666" size="16px" />
));

const BannerSortView = ({ banner, key }) => {
	const { position, image_src, image_src_mobile, visible } = banner;
	const isMobile = window.innerWidth <= 500;
	return (
		<Container key={key}>
			<HorizContainer>
				<NumContainer>
					{!isMobile && 'Position'} {position}
				</NumContainer>
			</HorizContainer>
			<BannerCardImage
				src={image_src || image_src_mobile}
				alt={image_src ? `banner-${image_src}` : `banner-${image_src_mobile}`}
				enabled={visible}
			/>
			<DragHandle />
		</Container>
	);
};

export default BannerSortView;
