import { AxiosInstance, AxiosResponse } from 'axios';

export const generateHeadersFromAuthCode = (
	authCode?: string,
	otherHeaders: { [key: string]: string } = {},
) => ({
	...otherHeaders,

	Authorization: `Bearer ${authCode}`,
});

export interface handlerConfig {
	baseUrl: string;
	headers: object;
}

export interface PromotionEngineDepositBonus {
	brand_id?: number;
	promotion_type_id: number;
	code?: string;
	name?: string;
	description: string;
	internal_description: string;
	enabled: boolean;
	start_date: string;
	end_date?: string;
	expiry_date?: string | null;
	expiry_days_after_issued?: number | null;
	restrict_to_clients: boolean;
	sign_up_offer: boolean;
	winning_turnover_factor: number;
	max_deposit: number;
	redemptions_per_customer?: number;
	redemption_count?: number;
	created_by?: number;
	created_at?: string;
	updated_at?: string;
	deleted_at?: null | string;
	percent_of_deposit: number;
	excluded_tags: Array<string>;
	eligible_territories: Array<any>;
	price_rolls?: number;
	boost_status?: number;
	boost_type_id?: number;
}

export type PromotionEngineItem = {
	id: number;
} & PromotionEngineDepositBonus;

export function AxiosInstanceMock(
	listener: (
		method: keyof AxiosInstance,
		...args: unknown[]
	) => void | AxiosResponse,
	ListenerProvidesResponse: boolean = false,
): AxiosInstance {
	const emptyResponse = {
		config: {},
		data: {} as any,
		status: 200,
		headers: {},
		statusText: 'all good',
	};
	const giveBackResponse = <K extends keyof AxiosInstance>(method: K) => async <
		T = any,
		R = AxiosResponse<T>
	>(
		...args: unknown[]
	) => {
		if (ListenerProvidesResponse) return listener(method, ...args);
		else
			return (
				void listener(method, ...args) || ((emptyResponse as unknown) as R)
			);
	};

	return {
		get: giveBackResponse('get'),
		post: giveBackResponse('post'),
		delete: giveBackResponse('delete'),
		put: giveBackResponse('put'),
	} as AxiosInstance;
}
