import React from 'react';
import MarkdownIt from 'markdown-it';
import MdEditor from 'react-markdown-editor-lite';
import { EditorConfig } from 'react-markdown-editor-lite/share/var';
import markdownMultiTable from 'markdown-it-multimd-table';
import 'react-markdown-editor-lite/lib/index.css';
import { BannerPreviewWrapper } from './MarkdownEditor.styled';

interface MarkdownEditorProps {
	value: string;
	height?: string;
	handleChange: ({ html, text }: { html: string; text: string }) => void;
	config?: Partial<EditorConfig>;
	styleType?: 'promotion' | 'banner';
	HEAD?: React.ReactNode;
}

const renderIt = (markdown, styleType = 'promotion', HEAD = undefined) => {
	const MdRender = new MarkdownIt().enable('linkify').use(markdownMultiTable, {
		multiline: true,
		rowspan: true,
		headerless: true,
	});

	const htmlString = MdRender.render(markdown);
	const wrapper = document.createElement('DIV');
	wrapper.className = 'markdown';
	wrapper.innerHTML = htmlString;
	wrapper.querySelectorAll('.markdown td').forEach((el) => {
		if (el.children.length === 1 && el.children[0].tagName === 'STRONG')
			el.classList.add('PrimaryTableCell');
	});
	const Content = ({ innerHTML }) =>
		styleType === 'banner' ? (
			<BannerPreviewWrapper dangerouslySetInnerHTML={{ __html: innerHTML }} />
		) : (
			<div dangerouslySetInnerHTML={{ __html: innerHTML }} />
		);
	return (
		<div>
			{HEAD}
			<Content innerHTML={wrapper.innerHTML} />
		</div>
	);
};

export default ({
	value,
	height,
	handleChange,
	config,
	styleType,
	HEAD = undefined,
	...args
}: MarkdownEditorProps &
	Omit<React.ComponentProps<typeof MdEditor>, 'renderHTML'>) => (
	<MdEditor
		value={value}
		config={{
			view: { menu: true, md: true, html: false },
			htmlClass: 'markdown',
			...config,
		}}
		renderHTML={(markdown) =>
			renderIt(markdown, styleType || 'promotion', HEAD)
		}
		style={{ height }}
		onChange={handleChange}
		{...args}
	/>
);
