import { configurationLoader } from '../context/brandConfig/brandConfig';

export const dynamicTheme = (brand) => ({
	application: {
		min: 320,
		max: 1170,
		bet_slip_width: 320,
		size: {
			mobile: 620,
			tablet: 780,
			desktop: 1024,
		},
		form_height_small: 23,
		form_height_medium: 32,
		form_height_large: 56,
		border_radius_1: 2,
		border_radius_2: 4,
		border_radius_3: 12,
		box_shadow: '0 2px 1px 0 rgba(0, 0, 0, 0.08)',
		text_shadow: '1px 1px 1px rgba(0, 0, 0, 0.3)',
		shadow: '0 0 10px rgba(#000, .25)',
		number_pad_button_size: 75,
		racing: {
			races_row_height: 36,
			ntj_height: 69,
			ntj_item_width: 140,
			odds_button_width: 72,
			odds_button_width_small: 52,
			flucs_width: 60,
			button_width: 55,
			button_width_expanded: 90,
		},
	},
	brand: {
		color_1: configurationLoader(brand).primaryColor,
		color_1_light: configurationLoader(brand).primaryColor,
		color_1_dark: configurationLoader(brand).primaryColor,
		color_2: configurationLoader(brand).secondaryColor,
		color_2_light: configurationLoader(brand).secondaryColor,
		color_2_dark: configurationLoader(brand).secondaryColor,
		color_3: '#32be60',
		color_3_light: '#3cc86a',
		color_3_dark: '#28b456',
		color_4: '#666666',
		color_4_light: '#707070',
		color_4_dark: '#5c5c5c',
	},
	ui: {
		color_1: '#f9f9f9',
		color_2: '#ffffff',
		color_3: '#ececeb',
		color_4: '#dddddd',
		color_5: '#666666',
		color_success: '#32be60',
		color_danger: '#cf1b41',
		color_warning: '#ee9c15',
		color_info: '#5d97fb',
		color_neutral: '#ececeb',
		color_yellow: '#F8DC1E',
		color_black: '#222222',
		color_transparent: 'rgba(255,255,255,0)',
		global_tote: '#2dafe6',
		leader: '#FF0080',
		offpace: '#FF33B3',
		midfield: '#ff56d6',
		backmarker: '#ff6aea',
		social: {
			facebook: '#4e71a7',
			linkedin: '#1685b0',
			twitter: '#1cb6ea',
			instagram: '#D93175',
		},
		background: '#e7e7e7',
	},
	spacings: {
		constrictive: 1,
		tight: 4,
		compact: 8,
		cozy: 16,
		comfortable: 24,
		spacious: 32,
		roomy: 40,
	},
	typography: {
		base_font: '"proximanova-regular", "Helvetica", "Arial", sans-serif',
		base_heading_font:
			'"proximanova-regular", "Helvetica", "Arial", sans-serif',
		base_size: '16px',
		base_line_height: 1.3,
		base_color: '#222222',
		weight: {
			light: 100,
			regular: 400,
			bold: 700,
		},
		'size_-5': '8.878863316906298px',
		'size_-4': '9.988721231519586px',
		'size_-3': '11.237311385459533px',
		'size_-2': '12.641975308641975px',
		'size_-1': '14.222222222222221px',
		size_0: '16px',
		size_1: '18px',
		size_2: '20.25px',
		size_3: '22.78125px',
		size_4: '25.62890625px',
		size_5: '28.83251953125px',
		size_6: '32.43658447265625px',
		color_1: '#222222',
		color_1_alternate: '#ffffff',
		color_2: '#666666',
		color_3: '#dddddd',
	},
});

export const staticTheme = {
	application: {
		min: 320,
		max: 1170,
		bet_slip_width: 320,
		size: {
			mobile: 620,
			tablet: 780,
			desktop: 1024,
		},
		form_height_small: 23,
		form_height_medium: 32,
		form_height_large: 56,
		border_radius_1: 2,
		border_radius_2: 4,
		border_radius_3: 12,
		box_shadow: '0 2px 1px 0 rgba(0, 0, 0, 0.08)',
		text_shadow: '1px 1px 1px rgba(0, 0, 0, 0.3)',
		shadow: '0 0 10px rgba(#000, .25)',
		number_pad_button_size: 75,
		racing: {
			races_row_height: 36,
			ntj_height: 69,
			ntj_item_width: 140,
			odds_button_width: 72,
			odds_button_width_small: 52,
			flucs_width: 60,
			button_width: 55,
			button_width_expanded: 90,
		},
	},
	brand: {
		color_1: '#000',
		color_1_light: '#000',
		color_1_dark: '#000',
		color_2: '#fff',
		color_2_light: '#fff',
		color_2_dark: '#fff',
		color_3: '#32be60',
		color_3_light: '#3cc86a',
		color_3_dark: '#28b456',
		color_4: '#666666',
		color_4_light: '#707070',
		color_4_dark: '#5c5c5c',
	},
	ui: {
		color_1: '#f9f9f9',
		color_2: '#ffffff',
		color_3: '#ececeb',
		color_4: '#dddddd',
		color_5: '#666666',
		color_success: '#32be60',
		color_danger: '#cf1b41',
		color_warning: '#ee9c15',
		color_info: '#5d97fb',
		color_neutral: '#ececeb',
		color_yellow: '#F8DC1E',
		color_black: '#222222',
		color_transparent: 'rgba(255,255,255,0)',
		global_tote: '#2dafe6',
		leader: '#FF0080',
		offpace: '#FF33B3',
		midfield: '#ff56d6',
		backmarker: '#ff6aea',
		social: {
			facebook: '#4e71a7',
			linkedin: '#1685b0',
			twitter: '#1cb6ea',
			instagram: '#D93175',
		},
		background: '#e7e7e7',
	},
	spacings: {
		constrictive: 1,
		tight: 4,
		compact: 8,
		cozy: 16,
		comfortable: 24,
		spacious: 32,
		roomy: 40,
	},
	typography: {
		base_font: '"proximanova-regular", "Helvetica", "Arial", sans-serif',
		base_heading_font:
			'"proximanova-regular", "Helvetica", "Arial", sans-serif',
		base_size: '16px',
		base_line_height: 1.3,
		base_color: '#222222',
		weight: {
			light: 100,
			regular: 400,
			bold: 700,
		},
		'size_-5': '8.878863316906298px',
		'size_-4': '9.988721231519586px',
		'size_-3': '11.237311385459533px',
		'size_-2': '12.641975308641975px',
		'size_-1': '14.222222222222221px',
		size_0: '16px',
		size_1: '18px',
		size_2: '20.25px',
		size_3: '22.78125px',
		size_4: '25.62890625px',
		size_5: '28.83251953125px',
		size_6: '32.43658447265625px',
		color_1: '#222222',
		color_1_alternate: '#ffffff',
		color_2: '#666666',
		color_3: '#dddddd',
	},
};
