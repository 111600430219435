import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { FiEye, FiFile } from 'react-icons/fi';
import Modal from 'react-modal';
import Loading from 'react-loading-bar';
import 'react-loading-bar/dist/index.css';

import { setPages, setSelectedStatusID, fetchAllContent } from '../store/pages';
import { ItemStatusType } from '../helpers/newAPIClient';
import { ContentItemsByStatus } from '../helpers/newAPIClient';

import { NavGroup, StyledButton } from '../components/shared/Navs';
import PageHeader from '../components/shared/PageHeader';
import Card from '../components/shared/Card';

import ContentTable from '../components/content/ContentTable';
import ContentForm from '../components/content/ContentForm';
import { ModalStyle } from '../common/modalStyle';
import { staticTheme } from '../common/theme';
import { useBrandConfig } from '../context/brandConfig/brandConfigContext';

interface PagesInterface {
	setPages: (input: ContentItemsByStatus) => void;
	fetchAllContent: () => Promise<any>;
	setSelectedStatusID: (payload: {
		status: ItemStatusType;
		id: string;
	}) => void;
	isLoading: Boolean;
}

function Pages({
	setPages,
	setSelectedStatusID,
	fetchAllContent,
	isLoading,
}: PagesInterface) {
	const brandConfig = useBrandConfig();
	const appUrl = brandConfig.brandConfig.appUrl;

	const [fetchLoading, setFetchLoading] = useState(true);

	useEffect(() => {
		setFetchLoading(true);
		fetchAllContent().then(() => setFetchLoading(false));
	}, [fetchAllContent, brandConfig]);

	const [isModalOpen, setModalState] = useState(false);
	const isMobile = window.innerWidth <= 500;

	const toggleModal = (state) => {
		return setModalState(state);
	};

	return (
		<>
			<Card>
				<Loading
					show={isLoading || fetchLoading}
					color={staticTheme.brand.color_1}
					showSpinner={false}
				/>
				<PageHeader title="Pages">
					<NavGroup>
						<StyledButton
							isactive={false}
							disabled={!appUrl}
							onClick={() => window.open(`${appUrl}/content`)}
						>
							{!isMobile && 'Pages'}
							<FiEye className="icon-only-mobile" />
						</StyledButton>
						<StyledButton
							isactive={true}
							onClick={async (e: any) => {
								e.preventDefault();
								await setSelectedStatusID({ status: 'draft', id: '' });
								toggleModal(true);
							}}
						>
							{!isMobile && 'New Page'} <FiFile className="icon-only-mobile" />
						</StyledButton>
					</NavGroup>
				</PageHeader>
				{!fetchLoading && <ContentTable toggleModal={toggleModal} />}
			</Card>
			<Modal
				isOpen={isModalOpen}
				onRequestClose={() => toggleModal(false)}
				style={ModalStyle}
				shouldCloseOnOverlayClick={false}
			>
				<ContentForm toggleModal={toggleModal} />
			</Modal>
		</>
	);
}

const mapStateToProps = (state) => ({
	isLoading: state.pages.isFetching,
});

export default connect(mapStateToProps, {
	setPages,
	setSelectedStatusID,
	fetchAllContent,
})(Pages);
