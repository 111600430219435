/* eslint-disable @typescript-eslint/no-unused-vars */
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { ToastsStore } from 'react-toasts';
import Tooltip from 'react-tooltip';
import { PromotionViewEditProps } from '.';
import { ModalStyle } from '../../../common/modalStyle';
import { useBrandConfig } from '../../../context/brandConfig/brandConfigContext';
import { runnerFormInterface } from '../../../helpers/promotionEngine/promotionEngineInterfaces';
import { partialApply } from '../../../helpers/pureFunc';
import { Select, SelectBox } from '../../shared/FormElements';
import { StyledButton } from '../../shared/Navs';
import ToggleSwitch from '../../shared/ToggleSwitch';
import {
	ColumnedArea,
	ColumnedLabeledArea,
	DepositFormLayout,
	DepositMatchFieldSet,
	InlineInput,
	InlineLabel,
	StyledLeadingI,
} from '../DepositMatchFormComponents';
import ClientTags from './ClientTags';
import {
	handleChange,
	handleFormikBlur,
	handleFormikChange,
	StyledErrorHint,
} from './Common';
import EligibleTerritories from './EligibleTerritories';
import RaceEventSearch from './RaceEventSearch';
import RestrictToClientArea from './RestrictToClientArea';

const initialValues = (): Omit<runnerFormInterface, 'id'> => {
	return {
		promotion_type_id: 2,
		criteria: 1,
		name: 'Free credits if your runner comes second',
		description: '',
		internal_description: '',
		enabled: true,
		max_deposit: 5000,
		winning_turnover_factor: 0,
		redemptions_per_customer: 1,
		reward_type: 1,
		first_bet_only: true,
		excluded_tags: [],
		eligible_events: [],
		eligible_territories: [],
		display_on_site: true,
		fixed_odds_only: true,
		restrict_to_clients: false,
	};
};

const RunnerForm = ({
	item,
	saveChanges,
	setViewEditCloseDelete,
	mode,
	cachedPromotionEndpoints,
	setFormElement,
	formElement,
}: PromotionViewEditProps) => {
	const localBrandConfig = useBrandConfig()?.brandConfig;

	const existingItems = mode === 'new' ? null : item;

	const formik = useFormik({
		initialValues: {
			...initialValues(),
			...existingItems,
		},
		validateOnBlur: false,
		validateOnMount: mode !== 'new',
		validateOnChange: false,
		onSubmit: async values => {
			const result = await saveChanges(values);

			if (result instanceof Error) {
				ToastsStore.error('Failed To Submit Form');
			} else {
				const successMessage = item?.id
					? 'Successfully Updated Item'
					: 'Successfully Created Item';
				ToastsStore.success(successMessage);
				setViewEditCloseDelete('close');
			}
		},
		enableReinitialize: true,
	});

	const [close, setClose] = useState(false);

	const [manageClientListOpen, setManageClientListOpen] = useState(false);

	const [maxDepositFieldValue, setMaxDepositFieldValue] = useState(
		formik.values.max_deposit / 100,
	);

	const [turnOverFieldValue, setTurnOverFieldValue] = useState(
		formik.values.winning_turnover_factor,
	);

	const [redemptionsPerCustomer, setRedemptionsPerCustomer] = useState(
		formik.values.redemptions_per_customer,
	);

	const [rewardTypeToggle, setRewardTypeToggle] = useState(
		formik.values.reward_type === 1,
	);

	const setFieldValueFunction = formik.setFieldValue;

	useEffect(() => {
		setFieldValueFunction(
			'max_deposit',
			Math.round(maxDepositFieldValue * 100),
		);
		setFieldValueFunction(
			'winning_turnover_factor',
			Math.round(turnOverFieldValue * 100),
		);
		setFieldValueFunction('redemptions_per_customer', +redemptionsPerCustomer);
		setFieldValueFunction('reward_type', rewardTypeToggle ? 1 : 2);
	}, [
		item,
		maxDepositFieldValue,
		turnOverFieldValue,
		rewardTypeToggle,
		redemptionsPerCustomer,
		setFieldValueFunction,
	]);

	const disableInputs = formik.isSubmitting || mode === 'view';

	let formErrorMessage = '';
	if (!formik.isValid) formErrorMessage = "See Error's below";
	if (formik.isValidating) formErrorMessage = 'Checking';

	const eligibleEventsCallback = events => {
		formik.setFieldValue('eligible_events', events);
	};

	const territoriesCallback = territories => {
		formik.setFieldValue('eligible_territories', territories);
	};

	const tagCallback = tags => {
		formik.setFieldValue('excluded_tags', tags);
	};

	const runnerFormEnabled =
		localBrandConfig?.featureFlags?.promotionEngine?.runnerForm || false;

	return (
		<DepositFormLayout
			formError={mode !== 'view' ? formErrorMessage : ''}
			mode={mode}
			actionClose={partialApply(setViewEditCloseDelete, 'close')}
			actionDelete={partialApply(setViewEditCloseDelete, 'delete')}
			actionEdit={partialApply(setViewEditCloseDelete, 'edit')}
			actionView={partialApply(setViewEditCloseDelete, 'view')}
			actionSave={formik.submitForm}
			setFormElement={setFormElement}
			formElement={formElement}
		>
			{!runnerFormEnabled ? (
				<p>Please contact the product team to enable this feature.</p>
			) : (
				<>
					<Modal
						isOpen={manageClientListOpen}
						style={ModalStyle}
						shouldCloseOnOverlayClick={false}
					>
						{mode === 'view' && (
							<RestrictToClientArea
								promotionId={item.id}
								closeMe={partialApply(setManageClientListOpen, false)}
								fetchPromotions={cachedPromotionEndpoints.promotionClientList}
								nukeClientList={
									cachedPromotionEndpoints.promotionClientListNuke
								}
								setClientList={cachedPromotionEndpoints.promotionClientListSet}
							/>
						)}
					</Modal>
					<form
						onClick={e => {
							e.stopPropagation();
							setClose(!close);
						}}
						onSubmit={formik.handleSubmit}
						style={{ width: '100%' }}
					>
						<div>
							<Tooltip id="tooltipManageCLientList">
								{mode !== 'view'
									? 'Save Promotion To Manage its Client List'
									: 'Manage Client List (restrict promotion to client list)'}
								Save Promotion To Edit Client List
							</Tooltip>
						</div>
						<DepositMatchFieldSet disabled={disableInputs}>
							<legend>General</legend>
							<ColumnedLabeledArea
								id="activeArea"
								snapWidth="250px"
								margin="4px"
								label="Criteria"
							>
								{[
									{
										flexGrow: '1',
										child: (
											<SelectBox>
												<Select
													name="criteria"
													value={formik.values.criteria}
													onChange={e => handleFormikChange(e, formik)}
													onBlur={e => handleFormikBlur(e, formik)}
												>
													<option value={1}>Runs 2nd</option>
													<option value={2}>Runs 2nd / 3rd</option>
													<option value={3}>Runs 2nd / 3rd / 4th</option>
												</Select>
											</SelectBox>
										),
									},
									{
										flexGrow: '4',
										child: (
											<StyledErrorHint>
												{mode !== 'view' && formik.errors?.criteria
													? `(${formik.errors?.criteria})`
													: ''}
											</StyledErrorHint>
										),
									},
								]}
							</ColumnedLabeledArea>
							<ColumnedLabeledArea
								id="activeArea"
								snapWidth="250px"
								margin="4px"
								label="Promotion Active"
							>
								{[
									{
										flexGrow: '1',
										child: (
											<ToggleSwitch
												id="enabled"
												title="enabled"
												checked={formik.values.enabled}
												onChange={e => handleChange(e, formik)}
												onBlur={formik.handleBlur}
												displayNotAllowedOnDisabled
												tooltipContent="Enable/Disable Promotion"
											/>
										),
									},
									{
										flexGrow: '4',
										child: (
											<StyledErrorHint>
												{mode !== 'view' && formik.errors?.enabled
													? `(${formik.errors?.enabled})`
													: ''}
											</StyledErrorHint>
										),
									},
								]}
							</ColumnedLabeledArea>
							<ColumnedLabeledArea
								id="activeArea"
								snapWidth="250px"
								margin="4px"
								label="Display On Site"
							>
								{[
									{
										flexGrow: '1',
										child: (
											<ToggleSwitch
												id="display_on_site"
												title="display_on_site"
												checked={formik.values.display_on_site}
												onChange={e => handleChange(e, formik)}
												onBlur={formik.handleBlur}
												displayNotAllowedOnDisabled
												tooltipContent="Show/Hide"
											/>
										),
									},
									{
										flexGrow: '4',
										child: (
											<StyledErrorHint>
												{mode !== 'view' && formik.errors?.display_on_site
													? `(${formik.errors?.display_on_site})`
													: ''}
											</StyledErrorHint>
										),
									},
								]}
							</ColumnedLabeledArea>
							<ColumnedLabeledArea
								id="description"
								snapWidth="1000px"
								margin="4px"
								label="Description"
							>
								{{
									flexGrow: '4',
									child: (
										<InlineInput
											type="text"
											id="description"
											onChange={e => handleChange(e, formik)}
											value={formik.values.description}
											isError={!!formik.errors?.description}
											onBlur={formik.handleBlur}
										/>
									),
								}}
							</ColumnedLabeledArea>
							{mode !== 'view' && formik.errors.description && (
								<div>
									<StyledErrorHint>{formik.errors.description}</StyledErrorHint>
									<br />
									<br />
								</div>
							)}
							<ColumnedLabeledArea
								id="InternalDescription"
								snapWidth="1000px"
								margin="4px"
								label="Internal Description"
							>
								{{
									flexGrow: '4',
									child: (
										<InlineInput
											type="text"
											id="internal_description"
											onChange={e => handleChange(e, formik)}
											value={formik.values.internal_description}
											isError={!!formik.errors?.internal_description}
											onBlur={formik.handleBlur}
										/>
									),
								}}
							</ColumnedLabeledArea>
							{mode !== 'view' && formik.errors.description && (
								<div>
									<StyledErrorHint>
										{formik.errors.internal_description}
									</StyledErrorHint>
									<br />
								</div>
							)}
						</DepositMatchFieldSet>

						<DepositMatchFieldSet disabled={disableInputs}>
							<legend>Event Selection</legend>
							<RaceEventSearch
								onClose={close}
								eligibleEventsCallback={eligibleEventsCallback}
								existingEvents={formik.values.eligible_events}
							/>
						</DepositMatchFieldSet>

						<DepositMatchFieldSet showLocked={disableInputs}>
							<legend>Details</legend>
							<ColumnedLabeledArea
								id="activeArea"
								snapWidth="250px"
								margin="4px"
								label="Max Stake"
							>
								{[
									{
										flexGrow: '1',
										child: (
											<div style={{ position: 'relative' }}>
												<InlineInput
													disabled={disableInputs}
													id="max_deposit"
													type="number"
													min={0}
													step={1}
													value={maxDepositFieldValue}
													inputMode="decimal"
													onChange={e =>
														handleChange(e, formik, setMaxDepositFieldValue)
													}
													onBlur={formik.handleBlur}
													isError={!!formik.errors.max_deposit}
													required
												/>
												<StyledLeadingI>$</StyledLeadingI>
											</div>
										),
									},
									{
										flexGrow: '4',
										child: (
											<StyledErrorHint>
												{mode !== 'view' && formik.errors?.max_deposit
													? `(${formik.errors?.max_deposit})`
													: ''}
											</StyledErrorHint>
										),
									},
								]}
							</ColumnedLabeledArea>
							<ColumnedLabeledArea
								id="activeArea"
								snapWidth="250px"
								margin="4px"
								label="Free Bet"
							>
								{[
									{
										flexGrow: '1',
										child: (
											<ToggleSwitch
												id="free_bet"
												title="free_bet"
												checked={rewardTypeToggle}
												onChange={e => setRewardTypeToggle(!rewardTypeToggle)}
												onBlur={formik.handleBlur}
												displayNotAllowedOnDisabled
												tooltipContent="Enable/Disable Reward Type"
											/>
										),
									},
									{
										flexGrow: '4',
										child: (
											<StyledErrorHint>
												{mode !== 'view' && formik.errors?.reward_type
													? `(${formik.errors?.reward_type})`
													: ''}
											</StyledErrorHint>
										),
									},
								]}
							</ColumnedLabeledArea>
							<ColumnedLabeledArea
								id="activeArea"
								snapWidth="250px"
								margin="4px"
								label="Real Cash"
							>
								{[
									{
										flexGrow: '1',
										child: (
											<ToggleSwitch
												id="real_cash"
												title="real_cash"
												checked={!rewardTypeToggle}
												onChange={e => setRewardTypeToggle(!rewardTypeToggle)}
												onBlur={formik.handleBlur}
												displayNotAllowedOnDisabled
												tooltipContent="Enable/Disable Reward Type"
											/>
										),
									},
									{
										flexGrow: '4',
										child: (
											<StyledErrorHint>
												{mode !== 'view' && formik.errors?.reward_type
													? `(${formik.errors?.reward_type})`
													: ''}
											</StyledErrorHint>
										),
									},
								]}
							</ColumnedLabeledArea>
							<ColumnedLabeledArea
								id="activeArea"
								snapWidth="250px"
								margin="4px"
								label="Turnover Factor"
							>
								{[
									{
										flexGrow: '1',
										child: (
											<div style={{ position: 'relative' }}>
												<InlineInput
													disabled={disableInputs}
													id="winning_turnover_factor"
													type="number"
													min={0}
													step={1}
													value={turnOverFieldValue}
													inputMode="decimal"
													onChange={e =>
														handleChange(e, formik, setTurnOverFieldValue)
													}
													onBlur={formik.handleBlur}
													isError={!!formik.errors.winning_turnover_factor}
													required
												/>
											</div>
										),
									},
									{
										flexGrow: '4',
										child: (
											<StyledErrorHint>
												{mode !== 'view' &&
												formik.errors?.winning_turnover_factor
													? `(${formik.errors?.winning_turnover_factor})`
													: ''}
											</StyledErrorHint>
										),
									},
								]}
							</ColumnedLabeledArea>
							<ColumnedArea
								id="RestrictToClient"
								snapWidth="350px"
								margin="4px"
							>
								{[
									{
										flexGrow: '2',
										child: <InlineLabel>Client List</InlineLabel>,
									},
									{
										flexGrow: '1',
										child: (
											<ToggleSwitch
												disabled={disableInputs}
												title="restrict_to_clients"
												id="restrict_to_clients"
												checked={formik.values.restrict_to_clients}
												onBlur={formik.handleBlur}
												onChange={e => handleChange(e, formik)}
												displayNotAllowedOnDisabled
												tooltipContent="Restrict Promotion to client list"
											/>
										),
									},
									{
										flexGrow: '2',
										child: (
											<>
												{(mode === 'edit' || mode === 'new') &&
													formik.errors?.restrict_to_clients && (
														<StyledErrorHint>
															{formik.errors?.restrict_to_clients}
														</StyledErrorHint>
													)}
												{!(
													(mode === 'edit' || mode === 'new') &&
													formik.errors?.restrict_to_clients
												) &&
													formik.values.restrict_to_clients && (
														<div
															style={{
																width: '100%',
																display: 'flex',
																justifyContent: 'center',
															}}
														>
															<Tooltip id="tooltip_button_client_list">
																{mode !== 'view'
																	? 'Save Promotion First'
																	: 'Open Manage Client List Area'}
															</Tooltip>
															<div
																data-tip
																data-for="tooltip_button_client_list"
															>
																<StyledButton
																	disabled={
																		mode === 'new' ||
																		!(
																			mode === 'view' &&
																			!formik.errors?.restrict_to_clients
																		)
																	}
																	form={
																		'differentFormDontDisableWithParentForm'
																	}
																	onClick={partialApply(
																		setManageClientListOpen,
																		true,
																	)}
																	isactive={true}
																	title={
																		mode === 'new' ||
																		!(
																			mode === 'view' &&
																			!formik.errors?.restrict_to_clients
																		)
																			? 'Save Promotion First'
																			: null
																	}
																	data-testid="buttonRestrictClients"
																>
																	Manage Client List
																</StyledButton>
															</div>
														</div>
													)}
											</>
										),
									},
								]}
							</ColumnedArea>
							<ColumnedLabeledArea
								id="activeArea"
								snapWidth="250px"
								margin="4px"
								label="First Bet Only"
							>
								{[
									{
										flexGrow: '1',
										child: (
											<ToggleSwitch
												id="first_bet_only"
												title="first_bet_only"
												checked={formik.values.first_bet_only}
												onChange={e => handleChange(e, formik)}
												onBlur={formik.handleBlur}
												displayNotAllowedOnDisabled
												tooltipContent="Enable/Disable First Bet Only Rule"
											/>
										),
									},
									{
										flexGrow: '1',
										child: (
											<InlineInput
												disabled={formik.values.first_bet_only}
												id="redemptions_per_customer"
												type="number"
												min={0}
												step={1}
												value={redemptionsPerCustomer}
												inputMode="decimal"
												onChange={e =>
													handleChange(e, formik, setRedemptionsPerCustomer)
												}
												onBlur={formik.handleBlur}
												isError={!!formik.errors.redemptions_per_customer}
												required
											/>
										),
									},
									{
										flexGrow: '3',
										child: (
											<StyledErrorHint>
												{mode !== 'view' &&
												formik.errors?.redemptions_per_customer
													? `(${formik.errors?.redemptions_per_customer})`
													: ''}
											</StyledErrorHint>
										),
									},
								]}
							</ColumnedLabeledArea>
							<ColumnedLabeledArea
								id="activeArea"
								snapWidth="250px"
								margin="4px"
								label="Fixed Odds Only"
							>
								{[
									{
										flexGrow: '1',
										child: (
											<ToggleSwitch
												id="fixed_odds_only"
												title="fixed_odds_only"
												checked={formik.values.fixed_odds_only}
												onChange={e => handleChange(e, formik)}
												onBlur={formik.handleBlur}
												displayNotAllowedOnDisabled
												tooltipContent="Enable/Disable Fixed Odds Only Rule"
											/>
										),
									},
									{
										flexGrow: '4',
										child: (
											<StyledErrorHint>
												{mode !== 'view' && formik.errors?.fixed_odds_only
													? `(${formik.errors?.fixed_odds_only})`
													: ''}
											</StyledErrorHint>
										),
									},
								]}
							</ColumnedLabeledArea>
							<EligibleTerritories
								onClose={close}
								territoriesCallback={territoriesCallback}
								existingTerritories={formik.values.eligible_territories}
							/>
							<ClientTags
								tagCallback={tagCallback}
								existingTags={formik.values.excluded_tags}
							/>
						</DepositMatchFieldSet>
					</form>
				</>
			)}
		</DepositFormLayout>
	);
};

export default RunnerForm;
