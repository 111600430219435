import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { footerConfigInterface as FooterStateInterface } from '../helpers/footerEndpoints/TypesDefs';
import FooterEndpoints, { initialSettings } from '../helpers/footerEndpoints';
import { configurationLoader } from '../context/brandConfig/brandConfig';

export type footerConfigInterface = FooterStateInterface;
const footerEndpointFactory = (state) => {
	const authToken = state?.auth?.accessToken;
	const brand = state?.application?.brand;
	const brandManagerEndpoint = configurationLoader(brand).brandManagerEndpoint;
	const footerApi = new FooterEndpoints(`${brandManagerEndpoint}/footer`);
	footerApi.useAuthCode(authToken);
	return footerApi;
};

export const fetchFooterConfig = createAsyncThunk(
	'footer/configFetch',
	async (_, thunkAPI) =>
		await footerEndpointFactory(thunkAPI.getState()).getFooterConfig(),
);

export const uploadFooterConfig = createAsyncThunk(
	'footer/configSet',
	async (config: footerConfigInterface, thunkAPI) => {
		const footerApi = footerEndpointFactory(thunkAPI.getState());
		return await footerApi.setFooterConfig(config);
	},
);

const { actions, reducer } = createSlice({
	name: 'loadFooter',
	initialState: {
		loading: true,
		config: initialSettings,
	},
	reducers: {
		setFooterConfig(state, { payload }: { payload: footerConfigInterface }) {
			state.config = payload;
			state.loading = false;
		},
	},
	extraReducers: {
		[fetchFooterConfig.pending as any]: (state) => void (state.loading = true),
		[fetchFooterConfig.fulfilled as any]: (state, action) => {
			state.config = action.payload;
			state.loading = false;
		},
		[uploadFooterConfig.pending as any]: (state) => void (state.loading = true),
		[uploadFooterConfig.rejected as any]: (state) =>
			void (state.loading = false),
		[uploadFooterConfig.fulfilled as any]: (state, action) => {
			state.config = action.payload;
			state.loading = false;
		},
	},
});

export const { setFooterConfig } = actions;
export default reducer;
