import React, { useEffect, useState } from 'react';
import { partialApply } from '../../../../helpers/pureFunc';
import {
	socialCodes,
	socialLinksInterface,
} from '../../../../helpers/footerEndpoints/TypesDefs';
import styled from '../../../../common/styled';
import FooterSocialLinkItem, {
	StyledFooterSocialItem,
} from './FooterSocialLinkItem';

const StyledFooterSocialLinksEditor = styled.div`
	width: 100%;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
`;

const StyledFooterItemsContainer = styled.div`
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	flex-direction: row;
	justify-content: center;
`;

interface FooterSocialLinksEditorProps {
	Items: socialLinksInterface['data']['Items'];
	setField: (path: string[], value: any, validate: boolean) => void;
}

export const FooterSocialLinksEditor = ({
	Items,
	setField,
}: FooterSocialLinksEditorProps) => {
	const [itemsLength, setItemsLength] = useState(Items.length);
	const [openIndex, setOpenIndex] = useState<number>(-1);
	useEffect(() => {
		// this detects new items being added
		if (Items.length > itemsLength) {
			setOpenIndex(Items.length - 1);
			setItemsLength(Items.length);
		}
	}, [Items, itemsLength]);

	const setItemAtIndex = (
		index: number,
		item: socialLinksInterface['data']['Items'][number],
	) => {
		if (!index && index !== 0) return; // can't set a item that doesn't exist

		const [prevItems, nextItems] = [
			Items.slice(0, index),
			Items.slice(index + 1),
		];
		setField(['Items'], [...prevItems, item, ...nextItems], true);
	};

	const deleteItemAtIndex = (index: number) => {
		// if (!index || index !==0) return; // cant delete a item that doesn't exist
		setOpenIndex(-1);
		const [prevItems, nextItems] = [
			Items.slice(0, index),
			Items.slice(index + 1),
		];
		setField(['Items'], [...prevItems, ...nextItems], true);
	};

	const createItem = (item: socialLinksInterface['data']['Items'][number]) => {
		setOpenIndex(Items.length);
		setField(['Items'], [...Items, item], true);
	};
	const socialCodesArray = Object.values(socialCodes);
	return (
		<StyledFooterSocialLinksEditor>
			<StyledFooterItemsContainer>
				{socialCodesArray.map((code) => {
					const index = Items.findIndex((item) => item.code === code);

					const href = Items?.[index]?.href || null;
					const setItem = partialApply(setItemAtIndex, index);
					const deleteItem = partialApply(deleteItemAtIndex, index);
					const setOpen = (req: boolean) => {
						if (index >= 0) {
							// item exists and request to open or close it
							setOpenIndex(req ? index : -1);
						} else if (req) {
							// item doesn't exist and request to open it
							createItem({ code, href: '' });
						} else {
							// request is to close a item that doesn't exist
							setOpenIndex(-1);
						}
					};
					return (
						<FooterSocialLinkItem
							key={code}
							code={code}
							href={href}
							setItem={setItem}
							deleteItem={deleteItem}
							open={index !== -1 && openIndex === index}
							setOpen={setOpen}
						/>
					);
				})}
				{socialCodesArray.length % 2 > 0 && (
					<StyledFooterSocialItem visibility="hidden" />
				)}
			</StyledFooterItemsContainer>
		</StyledFooterSocialLinksEditor>
	);
};
