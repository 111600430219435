import { useTheme } from 'emotion-theming';
import { useFormik } from 'formik';
import React from 'react';
import { FiSave, FiX } from 'react-icons/fi';
import { ToastsStore } from 'react-toasts';
import ReactTooltip from 'react-tooltip';
import { bannersValidation } from '../../common/validations';
import { useBrandConfig } from '../../context/brandConfig/brandConfigContext';
import {
	AuthenticationStatusType,
	banner_types
} from '../../helpers/bannerEndpoints/TypesDefs';
import MobileRouteEditor, {
	cleanMobileRoute
} from '../mobileRoute/mobileRouteEditor';
import Card from '../shared/Card';
import {
	Divider,
	Holder,
	Input,
	LabelWithError,
	Select
} from '../shared/FormElements';
import { NavGroup, StyledButton } from '../shared/Navs';
import PageHeader from '../shared/PageHeader';
import UserImageUpload from '../shared/UserImageUpload';
import BannerTagInput from './BannerTagInput';

const BannerCreate = ({
	handleCreate,
	handleSave,
	closeModal,
	context,
	bannerToEdit,
}) => {
	const { brandConfig } = useBrandConfig();
	const featureBanners = brandConfig.featureFlags.banners;

	const initialValues = bannerToEdit
		? bannerToEdit
		: {
				visible: true,
				authentication_status: AuthenticationStatusType.SIGNED_IN,
				link: '',
				mobileRoute: null,
				image_alt: '',
				image_src: '',
				image_src_mobile: '',
				image_src_web_device: '',
				image_width: 1170,
				banner_type: context,
				context_id: '',
				selected_tags: [],
		  };

	const formik = useFormik({
		initialValues,
		enableReinitialize: true,
		validateOnChange: true,
		validateOnBlur: true,
		validationSchema: bannersValidation,
		onSubmit: async (values, { setSubmitting, resetForm }) => {
			const cleanedValues = {
				...values,
				mobileRoute:
					(values.mobileRoute && cleanMobileRoute(values.mobileRoute)) || null,
			};

			if (values.id) {
				await handleSave(values.banner_type, cleanedValues);
			} else {
				await handleCreate(cleanedValues, values.banner_type);
			}

			closeModal();
			resetForm();
			setSubmitting(false);
		},
	});

	const {
		values: { banner_type },
		errors,
		isError,
		isValidating,
		isSubmitting,
		isValid,
		dirty,
	} = formik;

	const needsContextId = ['race', 'event'].includes(banner_type);

	// Titles for contextId field
	const bannerTypeTitles = {
		race: 'Race or Meeting ID',
		event: 'Event ID',
	};

	const toolTipConfig = {
		backgroundColor: '#000',
		delayShow: 100,
		multiLine: true,
		delayHide: 1000,
	};

	const selectedTagsLabel =
		AuthenticationStatusType.TAGS === formik.values.authentication_status
			? 'Included Tags'
			: 'Excluded Tags';

	const selectedTagsEnabled =
		featureBanners?.tags &&
		[
			AuthenticationStatusType.ANY,
			AuthenticationStatusType.SIGNED_IN,
			AuthenticationStatusType.TAGS,
		].includes(formik.values.authentication_status);

	const theme = useTheme();

	return (
		<Card isModal>
			<form onSubmit={formik.handleSubmit} disabled={isSubmitting}>
				<PageHeader title={`${formik.values.id ? 'Edit' : 'Create'} Banner`}>
					<NavGroup>
						<StyledButton
							isactive="true"
							type="submit"
							disabled={
								!isValid || isError || isValidating || isSubmitting || !dirty
							}
						>
							Save <FiSave />
						</StyledButton>
						<StyledButton onClick={closeModal}>
							Close <FiX />
						</StyledButton>
					</NavGroup>
				</PageHeader>
				<Holder>
					<LabelWithError
						htmlFor={'link'}
						title="URL banner link"
						error={errors.link}
					>
						<Input
							id="link"
							value={formik.values.link}
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
						/>
					</LabelWithError>

					<Divider />
					<MobileRouteEditor
						values={formik.values.mobileRoute}
						errors={formik.errors.mobileRoute}
						setField={(fieldName, value) =>
							formik.setFieldValue('mobileRoute.' + fieldName, value)
						}
						initialValues={formik.initialValues}
						disabled={false}
					/>
					<Divider />

					<LabelWithError
						htmlFor={formik.values.image_src}
						title="Desktop Image"
						error={formik.errors.image_src}
						hint={'Must be exactly 1170 x 150 pixels in size'}
					>
						<UserImageUpload
							folder="banner"
							selectedImage={formik.values.image_src}
							setSelectedImage={newImageUrl =>
								formik.setFieldValue('image_src', newImageUrl, true)
							}
							validationTest={obj => {
								if (obj.meta.width !== 1170 || obj.meta.height !== 150) {
									ToastsStore.error(
										'Incorrect image dimensions. Must be 1170 x 150 pixels.',
										6000,
									);
									obj.remove();
									return true;
								}
							}}
						/>
					</LabelWithError>
					<LabelWithError
						htmlFor={formik.values.image_src_mobile}
						title="Mobile Image"
						error={formik.errors.image_src_mobile}
						hint={'Must be exactly 1170 x 440 pixels in size'}
					>
						<UserImageUpload
							folder="banner"
							selectedImage={formik.values.image_src_mobile}
							setSelectedImage={newImageUrl =>
								formik.setFieldValue('image_src_mobile', newImageUrl, true)
							}
							validationTest={obj => {
								if (obj.meta.width !== 1170 || obj.meta.height !== 440) {
									ToastsStore.error(
										'Incorrect image dimensions. Must be 1170 x 440 pixels.',
										6000,
									);
									obj.remove();
									return true;
								}
							}}
						/>
					</LabelWithError>
					<LabelWithError
						htmlFor={formik.values.image_src_web_device}
						title="Mobile Website Image"
						error={formik.errors.image_src_web_device}
						hint={'Must be exactly 1170 x 440 pixels in size'}
					>
						<UserImageUpload
							folder="banner"
							selectedImage={formik.values.image_src_web_device}
							setSelectedImage={newImageUrl =>
								formik.setFieldValue('image_src_web_device', newImageUrl, true)
							}
							validationTest={obj => {
								if (obj.meta.width !== 1170 || obj.meta.height !== 440) {
									ToastsStore.error(
										'Incorrect image dimensions. Must be 1170 x 440 pixels.',
										6000,
									);
									obj.remove();
									return true;
								}
							}}
						/>
					</LabelWithError>
					<LabelWithError
						htmlFor="image_alt"
						title="Banner alternate text"
						error={formik.errors.image_alt}
						hint={'Brief banner description'}
					>
						<Input
							name="image_alt"
							value={formik.values.image_alt}
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							isError={false}
							type="text"
						/>
					</LabelWithError>
					<LabelWithError
						htmlFor={formik.values.authentication_status}
						title="Display"
						error={formik.errors.authentication_status}
						hasToolTip={true}
						toolTipFor={'bannerDisplayTip'}
					>
						<Select
							name="authentication_status"
							className="thin"
							value={formik.values.authentication_status}
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							isError={false}
						>
							<option value={AuthenticationStatusType.ANY} label="All" />
							<option
								value={AuthenticationStatusType.SIGNED_OUT}
								label="Guests Only"
							/>
							<option
								value={AuthenticationStatusType.SIGNED_IN}
								label="Logged in Users"
							/>
							{featureBanners?.tags && (
								<option
									value={AuthenticationStatusType.TAGS}
									label="Selected Tags"
								/>
							)}
						</Select>
					</LabelWithError>
					{selectedTagsEnabled && (
						<React.Fragment>
							<LabelWithError
								htmlFor="selected_tags"
								title={selectedTagsLabel}
								error={null}
								hint={'Tags who can see the banner'}
								hasToolTip={true}
								toolTipFor={'bannerTagsTip'}
							>
								<BannerTagInput
									onChange={tags => formik.setFieldValue('selected_tags', tags)}
									selectedTags={formik.values.selected_tags}
								/>
							</LabelWithError>
							<ReactTooltip
								id="bannerTagsTip"
								effect="solid"
								{...toolTipConfig}
								getContent={() => (
									<div>
										Tags only apply to registered members and determine who can
										see, or not see, the banner.
										<br />
										When including, will show the banner if any of the member's
										tags match any of the tags here.
										<br />
										Otherwise if excluding, will hide the banner if any tags
										here match the member's.
									</div>
								)}
							></ReactTooltip>
						</React.Fragment>
					)}
					<LabelWithError
						htmlFor={formik.values.banner_type}
						title="Banner location"
						error={formik.errors.banner_type}
						hasToolTip={true}
						toolTipFor={'bannerContextIdTip'}
					>
						<Select
							name="banner_type"
							className="thin"
							value={formik.values.banner_type}
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							isError={false}
						>
							<option value={banner_types.homepage} label="Homepage" />
							<option value={banner_types.racing} label="Racing Homepage" />
							<option value={banner_types.race} label="Race Or Meeting" />
							<option value={banner_types.sports} label="Sports Homepage" />
							<option value={banner_types.event} label="Sport or Event" />
						</Select>
					</LabelWithError>
					{needsContextId && (
						<LabelWithError
							htmlFor={formik.values.context_id}
							title={bannerTypeTitles[formik.values.banner_type]}
							error={formik.errors.context_id}
							hint="Required for banner to be visible"
						>
							<Input
								id="context_id"
								className="thin"
								value={formik.values.context_id}
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
							/>
						</LabelWithError>
					)}
				</Holder>
				<ReactTooltip
					id="bannerDisplayTip"
					effect="solid"
					{...toolTipConfig}
					getContent={() => (
						<div>
							Due to gambling regulation laws, some banners <br />
							must only be visible to signed in users.
							<br />
							<a
								style={{ color: theme.brand.color_1 }}
								href="https://www.acma.gov.au/gambling-ads-during-live-sport-streamed-over-internet"
							>
								Learn more
							</a>
						</div>
					)}
				></ReactTooltip>
				<ReactTooltip id="bannerContextIdTip" effect="solid" {...toolTipConfig}>
					Race or Meeting Banners can display for single races or entire race
					meetings.
					<br />
					i.e. Meeting banners will show for every race in that meeting.
					<br />
					<br />
					Sport or Event Banners can display for single events or whole sport
					categories.
					<br />
					Add event ID for single events.
					<br />
					Add sport name as displayed in the address bar when viewing the sport
					(e.g. rugby-league).
				</ReactTooltip>
			</form>
		</Card>
	);
};
export default BannerCreate;
