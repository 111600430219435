import React, { useState } from 'react';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import arrayMove from 'array-move';
import styled from '../../common/styled';
import { StyledButton } from '../shared/Navs';

const SortListWrapper = styled.div`
	label: QuickLinksSort__SortListWrapper;

	display: flex;
	flex-wrap: wrap;
	gap: ${(props) => props.theme.spacings.compact}px;
`;

const DraggableItem = styled.div`
	label: QuickLinksSort__DraggableItem;

	border: 1px solid #eee;
	background: ${(props) => (props.disabled ? '#e1e1e1' : '#fcfcfc')};
	border-radius: 4px;
	box-shadow: ${(props) => props.theme.application.box_shadow};
	padding: ${(props) => props.theme.spacings.cozy}px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-direction: column;
	white-space: pre;
	font-size: 12px;
`;

const TextContainer = styled.div`
	label: QuickLinksSort__TextContainer;

	max-width: 70px;
	text-overflow: ellipsis;
	overflow: hidden;
`;

const PositionContainer = styled.div`
	label: QuickLinksSort__PositionContainer;

	color: ${(props) => props.theme.ui.color_4};
	display: block;
	margin-bottom: ${(props) => props.theme.spacings.tight}px;
`;

const SubmitContainer = styled('div')`
	display: flex;
	justify-content: flex-end;
	align-self: center;
	margin-top: ${(props) => props.theme.spacings.cozy}px;
`;

const QuickLinksSort = ({
	quickLinks,
	handleSortPublish,
	buildMutableQuickLinksArray,
}) => {
	const [stateQuickLinks, setQuickLinks] = useState(quickLinks);
	const SortableItem = SortableElement(({ quickLink }) => {
		return (
			<DraggableItem>
				<PositionContainer>{quickLink.order}</PositionContainer>
				<TextContainer>{quickLink.title}</TextContainer>
			</DraggableItem>
		);
	});

	const SortableList = SortableContainer(() => {
		return (
			<SortListWrapper>
				{stateQuickLinks.map((ql, i) => (
					<SortableItem quickLink={ql} key={ql.id} index={i} />
				))}
			</SortListWrapper>
		);
	});

	const handleSort = ({ oldIndex, newIndex }) => {
		document.body.style.cursor = 'default';

		const newQuickLinks = arrayMove(stateQuickLinks, oldIndex, newIndex);

		const mutableQuickLinks = buildMutableQuickLinksArray(newQuickLinks);
		mutableQuickLinks.forEach((ql, i) => {
			ql.order = i + 1;
		});

		setQuickLinks(mutableQuickLinks);
	};

	const onSortStart = () => (document.body.style.cursor = 'grabbing');
	const onSortEnd = ({ oldIndex, newIndex, collection }) =>
		handleSort({ oldIndex, newIndex });

	return (
		<div>
			<SortableList
				onSortStart={onSortStart}
				onSortEnd={onSortEnd}
				useDragHandle={false}
				axis={'x'}
			/>
			<SubmitContainer>
				<StyledButton onClick={() => handleSortPublish(stateQuickLinks)}>
					Publish
				</StyledButton>
			</SubmitContainer>
		</div>
	);
};

export default QuickLinksSort;
