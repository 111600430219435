// state imports
import React, { useEffect, useState } from 'react';
import deepIs from 'deep-is';
import { css } from '@emotion/core';

import { connect } from 'react-redux';
import { useFormik } from 'formik';

import { partialApply } from '../helpers/pureFunc';
import { fetchFooterConfig, uploadFooterConfig } from '../store/footers';
import { ContentItemsByStatus, fetchAllContent } from '../store/pages';

// components 3rd party imports
import 'react-loading-bar/dist/index.css';
import Loading from 'react-loading-bar';

// component imports
import Card from '../components/shared/Card';
import {
	FooterConfigContainer,
	FooterConfigItem,
} from '../components/footers/footerConfigItem';

import PageHeader from '../components/shared/PageHeader';
import { StyledButtonInverse } from '../components/shared/Navs';
import { footerConfigInterface } from '../helpers/footerEndpoints/TypesDefs';
import { footerItemInterface } from '../components/footers/FooterMenuItems/FooterContentEditor/FooterContentGroups';
import styled from '../common/styled';
import { FooterConfigValidator } from '../components/footers/FooterMenuItems/FooterValidation';
import { staticTheme } from '../common/theme';
import { useBrandConfig } from '../context/brandConfig/brandConfigContext';

const isEqual = (a, b): boolean => !!(a === b);

interface FooterContainerProps {
	isLoading: boolean;
	footerConfig: footerConfigInterface;
	fetchFooterConfig: () => Promise<any>;
	uploadFooterConfig: (input: footerConfigInterface) => void;
	fetchAllContent: () => void;
	ContentPageRecommendations: (footerItemInterface & { id: string })[];
}

const VisibilityDiv = styled.div<{
	visibility: 'hidden' | 'unset';
	display: 'block' | 'flex';
}>(
	({ visibility, display }) => css`
		visibility: ${visibility};
		display: ${display};
	`,
);

const FooterContainer = ({
	isLoading,
	footerConfig,
	fetchFooterConfig,
	uploadFooterConfig,
	fetchAllContent,
	ContentPageRecommendations,
}: FooterContainerProps) => {
	const [fetchLoading, setFetchLoading] = useState(true);
	const brandConfig = useBrandConfig();
	useEffect(() => {
		setFetchLoading(true);

		Promise.all([fetchFooterConfig(), fetchAllContent()]).then(() =>
			setFetchLoading(false),
		);
	}, [fetchFooterConfig, fetchAllContent, brandConfig]);

	const formik = useFormik<footerConfigInterface>({
		initialValues: footerConfig,
		onSubmit: async (values, { setSubmitting }) => {
			setSubmitting(true);
			await uploadFooterConfig(values);
			setSubmitting(false);
		},
		validateOnMount: true,
		validationSchema: FooterConfigValidator,
		enableReinitialize: true,
	});

	const [openFeature, setOpenFeature] = useState(null);
	const formDirty = formik.dirty;
	const isFormError = !!formik.isValidating || !formik.isValid;

	return (
		<Card>
			<Loading
				show={isLoading}
				color={staticTheme.brand.color_1}
				showSpinner={false}
			/>
			<PageHeader title="Footer">
				<VisibilityDiv
					visibility={formDirty || isFormError ? 'unset' : 'hidden'}
					display="flex"
				>
					<span>
						{isFormError ? '* please review errors' : '* unpublished Changes'}
					</span>
					<StyledButtonInverse
						type="button"
						isactive={true}
						disabled={!formik.dirty || formik.isValidating || !formik.isValid}
						onClick={formik.submitForm}
					>
						Publish
					</StyledButtonInverse>
					<StyledButtonInverse
						type="button"
						disabled={!formik.dirty}
						onClick={() => {
							if (
								window.confirm(
									'Are you sure you want to clear all changes to footer config',
								)
							)
								formik.resetForm({ values: footerConfig });
						}}
					>
						Reset Changes
					</StyledButtonInverse>
				</VisibilityDiv>
			</PageHeader>
			{!fetchLoading && (
				<FooterConfigContainer onSubmit={formik.handleSubmit}>
					{Object.keys(formik.values.Items).map((key, index) => {
						const updateGroups = (
							paths: string[],
							value: any,
							validate: boolean = true,
						) =>
							formik.setFieldValue(
								['Items', key, ...paths].join('.'),
								value,
								validate,
							);
						const [dirty, allDirty] = [
							!deepIs(formik.values?.Items?.[key], footerConfig?.Items?.[key]),
							formDirty,
						];

						const props = {
							// needed functions
							setOpen: partialApply(
								setOpenFeature,
								openFeature !== key ? key : null,
							),
							updateGroups,
							// ui state
							title: key,
							open: isEqual(openFeature, key),
							key,
							topLabel: index === 0, /// if first one put labels above it
							dirty,
							enablePublish: (dirty || allDirty) && !isLoading,
							submitForm: formik.submitForm,
							errors: formik?.errors?.Items?.[key]?.data,
						};

						return (
							<FooterConfigItem {...props} isFormError={isFormError}>
								{{
									code: key,
									enabled: formik.values.Items?.[key]?.enabled ?? false,
									data: formik.values.Items?.[key]?.data || {},
									ContentPages: ContentPageRecommendations,
								}}
							</FooterConfigItem>
						);
					})}
				</FooterConfigContainer>
			)}
		</Card>
	);
};

const mapStateToProps = (state) => {
	const pageData = (state.pages.data || {}) as ContentItemsByStatus;

	const publishedPagesAsFooterItems = Object.entries(pageData).flatMap<
		footerItemInterface & { id: string }
	>(([_key, value]) => {
		const published = value?.published;
		return published
			? { id: published?.id, title: published?.title, slug: published?.slug }
			: [];
	});
	return {
		isLoading: state.footer.loading || state.pages.isFetching,
		footerConfig: state.footer.config,
		ContentPageRecommendations: publishedPagesAsFooterItems,
	};
};

export default connect(mapStateToProps, {
	fetchFooterConfig,
	uploadFooterConfig,
	fetchAllContent,
})(FooterContainer);
